import { z } from 'zod'

export const createArticleSchema = z.object({
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, {
      message: 'Минимальное количество символов 2',
    })
    .max(250, {
      message: 'Максимальное количество символов 250',
    })
    .trim(),
  tags: z.array(z.string()).optional(),
  folder: z.string().optional(),
})
