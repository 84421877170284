import { atomWithQuery } from 'jotai-tanstack-query'

import { getUserGrants } from './api'

export const grantsAtom = atomWithQuery(get => ({
  queryKey: ['grants'],
  queryFn: async () => {
    const res = await getUserGrants()
    return res.data
  },
}))
