import dayjs from 'dayjs'

export const getMonthData = (year: number) => {
  return [
    {
      id: 0,
      title: 'Январь',
      daysCount: dayjs(`01/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`01/01/${year}`).day() - 1,
    },
    {
      id: 1,
      title: 'Февраль',
      daysCount: dayjs(`02/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`02/01/${year}`).day() - 1,
    },
    {
      id: 2,
      title: 'Март',
      daysCount: dayjs(`03/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`03/01/${year}`).day() - 1,
    },
    {
      id: 3,
      title: 'Апрель',
      daysCount: dayjs(`04/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`04/01/${year}`).day() - 1,
    },
    {
      id: 4,
      title: 'Май',
      daysCount: dayjs(`05/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`05/01/${year}`).day() - 1,
    },
    {
      id: 5,
      title: 'Июнь',
      daysCount: dayjs(`06/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`06/01/${year}`).day() - 1,
    },
    {
      id: 6,
      title: 'Июль',
      daysCount: dayjs(`07/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`07/01/${year}`).day() - 1,
    },
    {
      id: 7,
      title: 'Август',
      daysCount: dayjs(`08/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`08/01/${year}`).day() - 1,
    },
    {
      id: 8,
      title: 'Сентябрь',
      daysCount: dayjs(`09/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`09/01/${year}`).day() - 1,
    },
    {
      id: 9,
      title: 'Октябрь',
      daysCount: dayjs(`10/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`10/01/${year}`).day() - 1,
    },
    {
      id: 10,
      title: 'Ноябрь',
      daysCount: dayjs(`11/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`11/01/${year}`).day() - 1,
    },
    {
      id: 11,
      title: 'Декабрь',
      daysCount: dayjs(`12/01/${year}`).daysInMonth(),
      firstWeekDay: dayjs(`12/01/${year}`).day() - 1,
    },
  ]
}
