import { NewsbreakInterface } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from '../../../features/api'

export const getExcelFile = (newsbreakId: string) => {
  return instance.get('/reports/get-excel-file', {
    responseType: 'blob',
    params: {
      newsbreakId,
    },
  })
}

export const createReportLink = (data: {
  url: string
  format: string
  report?: {
    id: string
  }
}): Promise<AxiosResponse<string>> => {
  return instance.post('/reports/create-link', data)
}

export const updateReportLink = (
  id: string,
  data: {
    url: string
    format: string
  },
): Promise<AxiosResponse<string>> => {
  return instance.patch(`/reports/update-link/${id}`, data)
}

export const createReport = (data: {
  newsbreak?: NewsbreakInterface
  title: string
  theme: {
    id: string
  }
  links: Array<{
    id: string
  }>
}): Promise<AxiosResponse<string>> => {
  return instance.post('/reports/create', data)
}

export const updateReport = (
  id: string,
  data: {
    title: string
    theme: {
      id: string
    }
  },
): Promise<AxiosResponse<string>> => {
  return instance.patch(`/reports/update/${id}`, data)
}

export const deleteReport = (id: string) => {
  return instance.delete(`/reports/delete/${id}`)
}

export const deleteReportLink = (id: string) => {
  return instance.delete(`/reports/delete-link/${id}`)
}
