import {
  PostInterface,
  PublicGroupInterface,
  PublicInterface,
} from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from '../../../features/api'

export const getPosts = (params: {
  skip: number
  take: number
  dateStart: Date
  dateEnd: Date
  publicId?: string
  searchString?: string
  groupId?: string
  platform?: string
}): Promise<AxiosResponse<[Array<PostInterface>, number]>> => {
  return instance.get('/publics/all-posts', { params })
}

export const getTgPostMetaInfo = (
  channelUsername: string,
  originalId: string,
): Promise<
  AxiosResponse<{
    views: number
    reposts: number
    likes: number
    comments: number
    reactions: number
    replies: Array<{ date: number; message: string; id: number }>
  }>
> => {
  return instance.get('/publics/tg-post-meta-info', {
    params: { channelUsername, originalId },
  })
}

export const getVkPostMetaInfo = (
  originalId: string,
): Promise<
  AxiosResponse<{
    views: number
    reposts: number
    likes: number
    comments: number
    reactions: number
    replies: Array<{ date: number; message: string; id: number }>
  }>
> => {
  return instance.get('/publics/vk-post-meta-info', { params: { originalId } })
}

export const getAllPostsPublics = (
  groupId?: string,
  platform?: string,
): Promise<AxiosResponse<Array<PublicInterface>>> => {
  return instance.get('publics/all-posts-publics', {
    params: {
      groupId,
      platform,
    },
  })
}

export const getAllPostsPublicsGroups = (): Promise<
  AxiosResponse<Array<PublicGroupInterface>>
> => {
  return instance.get('publics/all-posts-publics-groups')
}

export const parseNewPosts = () => {
  return instance.get('publics/check-for-new-posts')
}

export const updatePostIsFavorite = (id: string, isFavorite: boolean) => {
  return instance.patch(`publics/update-post-is-favorite/${id}`, {
    isFavorite,
  })
}

export const updatePost = (
  id: string,
  data: {
    risk: {
      id: string
    }
  },
) => {
  return instance.patch(`publics/update-post/${id}`, data)
}
