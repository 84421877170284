import { Tab, Tabs } from '@nextui-org/react'
import { useAtom } from 'jotai/index'

import { skipAtom, tasksViewAtom } from '../store/tasks.store'
import { AllTasks } from './AllTasks'
import { TasksByCollections } from './TasksByCollections'
import { TasksByExecutors } from './TasksByExecutors'

export const TasksList = () => {
  const [tasksView, setTasksView] = useAtom(tasksViewAtom)
  const [, setSkip] = useAtom(skipAtom)

  return (
    <div>
      <Tabs
        aria-label='Вид задач'
        color='primary'
        variant='bordered'
        size='sm'
        selectedKey={tasksView}
        onSelectionChange={tab => {
          setSkip(0)
          setTasksView(tab as 'table' | 'byExecutor' | 'byCollection')
        }}
      >
        <Tab key='table' title='Список'>
          <AllTasks />
        </Tab>
        <Tab key='byExecutor' title='По исполнителю'>
          <TasksByExecutors />
        </Tab>
        <Tab key='byCollection' title='По проекту'>
          <TasksByCollections />
        </Tab>
      </Tabs>
    </div>
  )
}
