import { FolderInterface } from '@hydra/interfaces'
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { foldersAtom } from '../store/folders.store'

interface Props {
  folder: FolderInterface | null
  refetch: () => void
  updateFn: (value: string) => void
}

export const FolderChip = ({ folder, updateFn }: Props) => {
  const [{ data: folders, isPending }] = useAtom(foldersAtom)

  const handleChange = useCallback(
    async (value: string) => {
      updateFn(value)
    },
    [updateFn],
  )

  return (
    <Dropdown
      backdrop='blur'
      placement='bottom-end'
      showArrow
      isDisabled={isPending}
    >
      <DropdownTrigger>
        <Chip
          as='span'
          color='default'
          variant='bordered'
          size='sm'
          className='cursor-pointer'
          endContent={<TbChevronDown className='text-xl' />}
        >
          {folder ? folder.title : 'Не выбрана'}
        </Chip>
      </DropdownTrigger>
      <DropdownMenu
        variant='faded'
        aria-label='Автор задачи'
        onAction={key => handleChange(key as string)}
        items={folders || []}
        className='max-h-[300px] overflow-auto'
      >
        {folder => (
          <DropdownItem key={folder.id} textValue={folder.title}>
            {folder.title}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}
