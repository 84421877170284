import { zodResolver } from '@hookform/resolvers/zod'
import { Grant } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
} from '@nextui-org/react'
import { TElement } from '@udecode/plate-common'
import { useAtom } from 'jotai/index'
import { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { IoCloseCircle } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import slug from 'slug'
import { z } from 'zod'

import { getFileUrlFromS3, uploadFileToS3 } from '../../../features/api'
import {
  audioExtensions,
  docExtensions,
  imageExtensions,
  videoExtensions,
} from '../../../features/constants/fileExtensions.const'
import { ONE_HUNDRED_MEGABYTES_IN_BYTES } from '../../../features/constants/fileSizes.const'
import { routesConst } from '../../../features/constants/routes.const'
import { allUsersAtom } from '../../../features/store'
import {
  FileDnD,
  FileItem,
  Header,
  Layout,
  PlateEditorWrapper,
} from '../../../features/ui'
import { getFileExtension } from '../../../features/utils/getFileExtension'
import { createCollection, createCollectionFile } from '../api/collections.api'
import { menuItems } from '../constants/menuItems'
import { createCollectionSchema } from '../schemas/createCollection.schema'

interface Props {
  isEdit?: boolean
}

type Form = z.infer<typeof createCollectionSchema>

export const CreateOrUpdateCollection = ({ isEdit }: Props) => {
  const navigate = useNavigate()
  const [files, setFiles] = useState<File[] | null>()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [editorDescriptionValue, setEditorDescriptionValue] = useState<
    Array<TElement>
  >([])
  const [{ data: allUsers, isPending: isPendingAllUsers }] =
    useAtom(allUsersAtom)
  const form = useForm<Form>({
    defaultValues: {},
    resolver: zodResolver(createCollectionSchema),
  })

  const pageTitle = useMemo(
    () => (isEdit ? 'Редактирование' : 'Создание'),
    [isEdit],
  )

  const onDropAccepted = useCallback(
    (newFiles: Array<File>) => {
      setFiles(files ? [...files, ...newFiles] : newFiles)
    },
    [files],
  )

  const onSubmit = useCallback(
    async (formData: Form) => {
      try {
        setIsFormLoading(true)
        let createdFiles
        if (files) {
          const filesResponses = await Promise.allSettled(
            files.map(file => {
              return uploadFileToS3(
                new File(
                  [file],
                  slug(file.name.replace(/\.[^/.]+$/, '')) +
                    '.' +
                    getFileExtension(file.name),
                  {
                    type: file.type,
                  },
                ),
              )
            }),
          )
          const filesUrlResponses = await Promise.allSettled(
            filesResponses.map(file => {
              if (file.status === 'fulfilled' && file.value) {
                return getFileUrlFromS3(
                  file.value.data,
                  import.meta.env.VITE_BUCKET_NAME,
                )
              }

              return null
            }),
          )

          createdFiles = await Promise.allSettled(
            filesUrlResponses.map(file => {
              if (file.status === 'fulfilled' && file.value) {
                return createCollectionFile(file.value.data)
              }
              return null
            }),
          )
        }

        const response = await createCollection({
          ...formData,
          responsible: formData.responsible
            ? {
                id: formData.responsible,
              }
            : undefined,
          description: editorDescriptionValue,
          files: createdFiles
            ? createdFiles.map(file => {
                if (file.status === 'fulfilled' && file.value) {
                  return { id: file.value.data }
                }
                return { id: '' }
              })
            : null,
        })
        if (response.status && response.status === 201) {
          toast.success('Проект успешно создан')
          setFiles(null)
          navigate(routesConst.collections)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [editorDescriptionValue, files, navigate],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle} проекта</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={<Header text={pageTitle + ' проекта'} />}
      >
        <form className='p-4' onSubmit={form.handleSubmit(onSubmit)}>
          <div className='bg-background min-h-[calc(100vh-104px)] space-y-6 rounded-xl p-4 shadow'>
            <div className='space-y-4'>
              <div className='flex items-center justify-between'>
                <div className='flex-1 text-lg font-semibold'>
                  Основная информация
                </div>
                <div>
                  <Button
                    onPress={() => {
                      setFiles(null)
                      navigate(routesConst.tasks)
                    }}
                    color='primary'
                    endContent={<IoCloseCircle className='text-xl' />}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
              <Controller
                control={form.control}
                name='title'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    placeholder='Название'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                    isInvalid={!!form.formState.errors.title}
                    errorMessage={form.formState.errors.title?.message}
                  />
                )}
              />
              <div className='flex w-1/2 space-x-4'>
                <Controller
                  control={form.control}
                  name='responsible'
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      isInvalid={!!form.formState.errors.responsible}
                      errorMessage={form.formState.errors.responsible?.message}
                      isDisabled={isFormLoading}
                      aria-label='Ответственный'
                      isLoading={isPendingAllUsers || isFormLoading}
                      variant='bordered'
                      defaultItems={
                        allUsers
                          ? allUsers[0].filter(user =>
                              user.grants.includes(Grant.tasks),
                            )
                          : []
                      }
                      placeholder='Выберите исполнителя'
                      onSelectionChange={onChange}
                      defaultSelectedKey={value}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      onKeyDown={e => e.continuePropagation()}
                    >
                      {item => (
                        <AutocompleteItem
                          key={item.id}
                          textValue={item.name + ' ' + item.surname}
                        >
                          {item.name} {item.surname}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                  )}
                />
              </div>

              <PlateEditorWrapper
                withComments={false}
                placeholder='Введите описание'
                onChange={setEditorDescriptionValue}
              />
            </div>
            {!isEdit && (
              <>
                <div className='space-y-4'>
                  <div className='flex space-x-4'>
                    <div className='w-1/3 space-y-3'>
                      <div className='text-lg font-semibold '>
                        Материалы задачи
                      </div>
                      <div className='text-default-600'>
                        Вы можете загрузить изображения, видео, аудиозапись,
                        текстовый документ.
                      </div>
                    </div>
                    <div className='flex-1'>
                      <FileDnD
                        disabled={isFormLoading}
                        maxFiles={100}
                        maxSize={ONE_HUNDRED_MEGABYTES_IN_BYTES}
                        multiple
                        onDropAccepted={onDropAccepted}
                        accept={{
                          ...audioExtensions,
                          ...videoExtensions,
                          ...imageExtensions,
                          ...docExtensions,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-3 gap-4'>
                  {files?.map((file, index) => (
                    <FileItem
                      key={Math.random()}
                      file={file}
                      setFiles={setFiles}
                      index={index}
                    />
                  ))}
                </div>
              </>
            )}
            <div className='flex justify-end space-x-4'>
              <Button
                color='primary'
                type='submit'
                isDisabled={isFormLoading}
                isLoading={isFormLoading}
              >
                Создать
              </Button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  )
}
