import { Grant } from '@hydra/interfaces'
import { Button } from '@nextui-org/react'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'

import { Grants } from '../../../features/grants'
import { profileAtom } from '../../../features/store'
import { Header, Layout } from '../../../features/ui'
import { parseNewPosts } from '../api/post.api'
import { GroupFilter } from '../components/GroupFilter'
import { PostsList } from '../components/PostsList'
import { PublicFilter } from '../components/PublicFilter'
import { menuItems } from '../constants/menuItems'
import { postsInfiniteAtom } from '../store/post.store'

const pageTitle = 'Мониторинг'

export const Monitoring = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { refetch } = useAtomValue(postsInfiniteAtom)
  const { data: profile } = useAtomValue(profileAtom)

  const handleParseNewPosts = async () => {
    setIsLoading(true)
    try {
      const response = await parseNewPosts()

      if (response.status && response.status === 200) {
        toast.success('Посты успешно спарсены')
        await refetch()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grants grants={[Grant.publics]}>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <div>
                {profile?.profile.login === 'ngorchakov' ||
                  (profile?.profile.login === 'admin' && (
                    <Button
                      isLoading={isLoading}
                      isDisabled={isLoading}
                      onPress={handleParseNewPosts}
                    >
                      Спарсить посты
                    </Button>
                  ))}
              </div>
            }
          />
        }
      >
        <div className='grid grid-cols-[72px_364px_1fr]'>
          <GroupFilter />

          <PublicFilter />
          <div className='bg-default-200 h-[calc(100vh-72px)] min-h-full space-y-4 p-2'>
            <PostsList />
          </div>
        </div>
      </Layout>
    </Grants>
  )
}
