import { parseDate } from '@internationalized/date'
import { DateValue, RangeValue } from '@nextui-org/react'
import { atomWithInfiniteQuery, atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'

import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import {
  getAllPostsPublics,
  getAllPostsPublicsGroups,
  getPosts,
} from '../api/post.api'

export const takeAtom = atom(10)
export const activeGroupAtom = atom<string | undefined>(undefined)
export const activePublicAtom = atom<string | undefined>(undefined)
export const searchStringAtom = atom<string>('')
export const platformAtom = atom<string | undefined>(undefined)

export const postsPubDatesAtom = atom<RangeValue<DateValue>>({
  start: parseDate(extendedDayjs().subtract(1, 'day').format('YYYY-MM-DD')),
  end: parseDate(extendedDayjs().add(1, 'day').format('YYYY-MM-DD')),
})

export const postsInfiniteAtom = atomWithInfiniteQuery(get => {
  return {
    queryKey: [
      'publics-posts-infinite',
      get(takeAtom),
      get(activePublicAtom),
      get(searchStringAtom),
      get(postsPubDatesAtom),
      get(activeGroupAtom),
      get(platformAtom),
    ],
    queryFn: async ({ pageParam = 0 }) => {
      const dateStart = get(postsPubDatesAtom).start.toString()
      const dateEnd = get(postsPubDatesAtom).end.toString()

      const response = await getPosts({
        skip: (pageParam as number) * get(takeAtom),
        take: get(takeAtom),
        publicId: get(activePublicAtom),
        searchString:
          get(searchStringAtom) === '' ? undefined : get(searchStringAtom),
        dateStart: extendedDayjs(dateStart).utc(true).toDate(),
        dateEnd: extendedDayjs(dateEnd).utc(true).toDate(),
        groupId: get(activePublicAtom) ? undefined : get(activeGroupAtom),
        platform: get(activePublicAtom) ? undefined : get(platformAtom),
      })
      return response.data
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage[0].length === get(takeAtom) ? allPages.length : undefined
    },
    initialPageParam: 0,
    refetchInterval: 1000 * 60 * 11, // 11 минут, что на 1 минуту больше чем у крона на парсинг постов
  }
})

export const postsPublicsAtom = atomWithQuery(get => {
  return {
    queryKey: ['all-posts-publics', get(activeGroupAtom), get(platformAtom)],
    queryFn: async () => {
      const response = await getAllPostsPublics(
        get(activeGroupAtom),
        get(platformAtom),
      )
      return response.data
    },
  }
})

export const postsPublicsGroupsAtom = atomWithQuery(get => {
  return {
    queryKey: ['getAllPostsPublicsGroups'],
    queryFn: async () => {
      const response = await getAllPostsPublicsGroups()
      return response.data
    },
  }
})
