import {
  Grant,
  NewsbreakEditorStatusEnum,
  NewsbreakStatusEnum,
} from '@hydra/interfaces'
import {
  Button,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import dayjs from 'dayjs'
import { useAtom } from 'jotai/index'
import { useCallback, useMemo, useState } from 'react'
import { TbArchive } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { routesConst } from '../../../features/constants/routes.const'
import { useGrant } from '../../../features/grants'
import { useUserOrganizationId } from '../../../features/hooks/useUserOrganizationId'
import { profileAtom } from '../../../features/store'
import {
  createComment,
  updateImplementOrganizations,
  updateNewsbreak,
} from '../api/newsbreaks.api'
import {
  newsbreakAtom,
  newsbreakPriorityAtom,
  newsbreakProjectAtom,
  newsbreakThemeAtom,
  newsbreakTypeAtom,
  newsbreakUsersAtom,
  organizationToNewsbreakAtom,
} from '../store/newsbreaks.store'
import { AdditionalInformation } from './AdditionalInformation'
import { DoneModal } from './DoneModal'
import { PlanActionModal } from './PlanActionModal'

interface Props {
  canEdit: boolean
}

export const Actions = ({ canEdit }: Props) => {
  const navigate = useNavigate()
  const orgId = useUserOrganizationId()

  const [{ data: organizationToNewsbreak }] = useAtom(
    organizationToNewsbreakAtom,
  )

  const [{ data: newsbreak, refetch }] = useAtom(newsbreakAtom)
  const [{ data: theme }] = useAtom(newsbreakThemeAtom)
  const [{ data: type }] = useAtom(newsbreakTypeAtom)
  const [{ data: project }] = useAtom(newsbreakProjectAtom)
  const [{ data: priority }] = useAtom(newsbreakPriorityAtom)
  const [{ data: users }] = useAtom(newsbreakUsersAtom)
  const [{ data: profile }] = useAtom(profileAtom)
  const [newComment, setNewComment] = useState('')
  const [isCreateCommentLoading, setIsCreateCommentLoading] = useState(false)

  const { isValidGrant: isModerator } = useGrant([
    Grant.newsbreaksModerator,
    Grant.newsbreaksCategoryModerator,
  ])

  const { isValidGrant: isProvider } = useGrant([Grant.newsbreaksProvider])

  const { isValidGrant: isModeratorOrProvider } = useGrant([
    Grant.newsbreaksModerator,
    Grant.newsbreaksCategoryModerator,
    Grant.newsbreaksProvider,
  ])

  const {
    isOpen: isOpenCommentModal,
    onOpen: onOpenCommentModal,
    onOpenChange: onOpenChangeCommentModal,
    onClose: onCloseCommentModal,
  } = useDisclosure()

  const {
    isOpen: isOpenCanceledModal,
    onOpen: onOpenCanceledModal,
    onOpenChange: onOpenChangeCanceledModal,
    onClose: onCloseCanceledModal,
  } = useDisclosure()

  const {
    isOpen: isOpenPlanStatusModal,
    onOpen: onOpenPlanStatusModal,
    onOpenChange: onOpenChangePlanStatusModal,
    onClose: onClosePlanStatusModal,
  } = useDisclosure()

  const {
    isOpen: isOpenOkStatusModal,
    onOpen: onOpenOkStatusModal,
    onClose: onCloseOkStatusModal,
  } = useDisclosure()

  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onOpenChange: onOpenChangeDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure()

  const {
    isOpen: isOpenDoneModal,
    onOpen: onOpenDoneModal,
    onOpenChange: onOpenChangeDoneModal,
    onClose: onCloseDoneModal,
  } = useDisclosure()

  const foundOrgStatus = useMemo(
    () =>
      organizationToNewsbreak?.organizationToNewsbreak.find(
        org => org.organizationId === orgId,
      )?.status,
    [organizationToNewsbreak, orgId],
  )

  const onButtonPress = useCallback(
    async (status: NewsbreakStatusEnum) => {
      if (!newsbreak) return null

      try {
        const response = await updateNewsbreak(newsbreak.current.id, {
          status,
        })
        if (response.status && response.status === 200) {
          toast.success('Статус успешно обновлен')
          await refetch()
        }
      } catch (e) {
        console.error(e)
      }
    },
    [newsbreak, refetch],
  )

  const onSendButtonPress = useCallback(async () => {
    if (!newsbreak) return null
    if (newComment === '') {
      return toast.error('Введите комменатрий')
    }
    setIsCreateCommentLoading(true)
    await createComment({
      value: newComment,
      newsbreakId: newsbreak.current.id,
    })
    setNewComment('')
    await onButtonPress(NewsbreakStatusEnum.rework)
    await refetch()
    onCloseCommentModal()
    setIsCreateCommentLoading(false)
  }, [newComment, newsbreak, onButtonPress, onCloseCommentModal, refetch])

  const onDeleteButtonPress = useCallback(async () => {
    if (!newsbreak) return null
    if (newComment === '') {
      return toast.error('Введите комменатрий')
    }
    setIsCreateCommentLoading(true)
    await createComment({
      value: newComment,
      newsbreakId: newsbreak.current.id,
    })
    setNewComment('')
    await onButtonPress(NewsbreakStatusEnum.deleted)
    await refetch()
    onCloseDeleteModal()
    setIsCreateCommentLoading(false)
  }, [newComment, newsbreak, onButtonPress, onCloseDeleteModal, refetch])

  const setEditorStatus = useCallback(
    async (status: NewsbreakEditorStatusEnum) => {
      const impOrgId = organizationToNewsbreak?.organizationToNewsbreak.find(
        org => org.organizationId === profile?.profile.organization.id,
      )?.id

      if (profile && impOrgId) {
        await updateImplementOrganizations(impOrgId, {
          status,
        })
        await refetch()
      } else {
        toast.error('Данный инфоповод не назначен для вашей организации')
      }
    },
    [organizationToNewsbreak, profile, refetch],
  )

  const onCancelledButtonPress = useCallback(async () => {
    if (!newsbreak) return null
    if (newComment === '') {
      return toast.error('Введите комменатрий')
    }
    setIsCreateCommentLoading(true)
    await createComment({
      value: newComment,
      newsbreakId: newsbreak.current.id,
    })
    setNewComment('')
    await setEditorStatus(NewsbreakEditorStatusEnum.canceled)
    await refetch()
    onCloseCanceledModal()
    setIsCreateCommentLoading(false)
  }, [newComment, newsbreak, setEditorStatus, onCloseCanceledModal, refetch])

  return (
    <>
      <Divider />
      <div className='flex flex-wrap items-center justify-end gap-4'>
        {isModeratorOrProvider
          ? canEdit && (
              <>
                {(isModerator ||
                  (isProvider &&
                    newsbreak &&
                    [
                      NewsbreakStatusEnum.draft,
                      NewsbreakStatusEnum.rework,
                      NewsbreakStatusEnum.agreement,
                    ].includes(newsbreak.current.status))) && (
                  <Button
                    variant='bordered'
                    color='primary'
                    onPress={() => {
                      const params = new URLSearchParams({
                        id: newsbreak?.current.id || '',
                        title: newsbreak?.current.title || '',
                        status: newsbreak?.current.status || '',
                        isUrgent: newsbreak
                          ? String(newsbreak.current.isUrgent)
                          : '',
                        isAllDay: newsbreak
                          ? String(newsbreak.current.isAllDay)
                          : '',
                        deadline:
                          dayjs(newsbreak?.current.deadline).toString() || '',
                        address: newsbreak?.current.address || '',
                        theme: theme?.theme?.id || '',
                        type: type?.type?.id || '',
                        project: project?.project?.id || '',
                        priority: priority?.priority?.id || '',
                      })
                      navigate(routesConst.editNewsbreak + '?' + params)
                    }}
                  >
                    Редактировать
                  </Button>
                )}

                {newsbreak?.current.status === NewsbreakStatusEnum.agreement &&
                  isProvider && (
                    <Button
                      onPress={() => onButtonPress(NewsbreakStatusEnum.draft)}
                      color='primary'
                    >
                      Вернуть в черновик
                    </Button>
                  )}

                {(newsbreak?.current.status === NewsbreakStatusEnum.draft ||
                  newsbreak?.current.status === NewsbreakStatusEnum.rework) &&
                  isModeratorOrProvider &&
                  (users?.author?.organization.id ===
                    profile?.profile.organization.id ||
                    users?.coAuthor?.organization.id ===
                      profile?.profile.organization.id) && (
                    <Button
                      color='primary'
                      onPress={() =>
                        onButtonPress(NewsbreakStatusEnum.agreement)
                      }
                    >
                      Отдать на согласование
                    </Button>
                  )}

                {newsbreak?.current.status === NewsbreakStatusEnum.agreement &&
                  isModerator && (
                    <Button
                      color='primary'
                      onPress={() => {
                        if (newsbreak?.current.isUrgent) {
                          onOpenOkStatusModal()
                        } else {
                          onOpenPlanStatusModal()
                        }
                      }}
                    >
                      {newsbreak?.current.isUrgent ? 'Согласовать' : 'В план'}
                    </Button>
                  )}

                {newsbreak?.current.status === NewsbreakStatusEnum.agreement &&
                  isModerator && (
                    <Button
                      color='warning'
                      variant='bordered'
                      onPress={onOpenCommentModal}
                    >
                      На доработку
                    </Button>
                  )}

                {newsbreak?.current.status !== NewsbreakStatusEnum.deleted &&
                  isModerator && (
                    <Button
                      variant='bordered'
                      isIconOnly
                      onPress={onOpenDeleteModal}
                    >
                      <TbArchive className='text-2xl' />
                    </Button>
                  )}
                <Modal
                  isOpen={isOpenCommentModal}
                  onOpenChange={onOpenChangeCommentModal}
                >
                  <ModalContent>
                    {onClose => (
                      <>
                        <ModalHeader className='flex flex-col gap-1'>
                          Комментарий
                        </ModalHeader>
                        <ModalBody>
                          <Input
                            variant='bordered'
                            placeholder='Комментировать'
                            value={newComment}
                            onValueChange={setNewComment}
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button variant='light' onPress={onClose}>
                            Закрыть
                          </Button>
                          <Button
                            isLoading={isCreateCommentLoading}
                            isDisabled={isCreateCommentLoading}
                            color='danger'
                            onPress={onSendButtonPress}
                          >
                            На доработку
                          </Button>
                        </ModalFooter>
                      </>
                    )}
                  </ModalContent>
                </Modal>

                <Modal
                  isOpen={isOpenOkStatusModal}
                  onOpenChange={onOpenOkStatusModal}
                  size='xl'
                  isDismissable={false}
                  hideCloseButton
                >
                  <ModalContent>
                    {() => (
                      <>
                        <ModalHeader className='flex flex-col gap-1'>
                          Дополнительная информация
                        </ModalHeader>
                        <ModalBody>
                          <AdditionalInformation
                            onCloseModal={onCloseOkStatusModal}
                          />
                        </ModalBody>
                      </>
                    )}
                  </ModalContent>
                </Modal>

                <Modal
                  isOpen={isOpenPlanStatusModal}
                  onOpenChange={onOpenChangePlanStatusModal}
                  size='xl'
                  isDismissable={false}
                  hideCloseButton
                >
                  <ModalContent>
                    {() => (
                      <>
                        <ModalHeader className='flex flex-col gap-1'>
                          Согласование инфоповода в план
                        </ModalHeader>
                        <ModalBody>
                          <PlanActionModal
                            onCloseModal={onClosePlanStatusModal}
                          />
                        </ModalBody>
                      </>
                    )}
                  </ModalContent>
                </Modal>

                <Modal
                  isOpen={isOpenDeleteModal}
                  onOpenChange={onOpenChangeDeleteModal}
                >
                  <ModalContent>
                    {onClose => (
                      <>
                        <ModalHeader className='flex flex-col gap-1'>
                          Укажите причину архивирования
                        </ModalHeader>
                        <ModalBody>
                          <Input
                            variant='bordered'
                            placeholder='Комментировать'
                            value={newComment}
                            onValueChange={setNewComment}
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button variant='light' onPress={onClose}>
                            Закрыть
                          </Button>
                          <Button
                            isLoading={isCreateCommentLoading}
                            color='primary'
                            isDisabled={isCreateCommentLoading}
                            onPress={onDeleteButtonPress}
                          >
                            Архивировать
                          </Button>
                        </ModalFooter>
                      </>
                    )}
                  </ModalContent>
                </Modal>
              </>
            )
          : newsbreak?.current.status !== NewsbreakStatusEnum.plan && (
              <>
                {foundOrgStatus !== NewsbreakEditorStatusEnum.inWork && (
                  <Button
                    color='primary'
                    variant='bordered'
                    onPress={() =>
                      setEditorStatus(NewsbreakEditorStatusEnum.inWork)
                    }
                  >
                    Взять в работу
                  </Button>
                )}
                {foundOrgStatus !== NewsbreakEditorStatusEnum.canceled && (
                  <Button
                    color='danger'
                    variant='bordered'
                    onPress={onOpenCanceledModal}
                  >
                    Отклонить
                  </Button>
                )}
                {foundOrgStatus === NewsbreakEditorStatusEnum.inWork && (
                  <Button
                    color='success'
                    variant='bordered'
                    onPress={onOpenDoneModal}
                  >
                    Отработано
                  </Button>
                )}

                <Modal
                  isOpen={isOpenCanceledModal}
                  onOpenChange={onOpenChangeCanceledModal}
                >
                  <ModalContent>
                    {onClose => (
                      <>
                        <ModalHeader className='flex flex-col gap-1'>
                          Укажите причину отклонения
                        </ModalHeader>
                        <ModalBody>
                          <Input
                            variant='bordered'
                            placeholder='Комментировать'
                            value={newComment}
                            onValueChange={setNewComment}
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button variant='light' onPress={onClose}>
                            Закрыть
                          </Button>
                          <Button
                            isLoading={isCreateCommentLoading}
                            isDisabled={isCreateCommentLoading}
                            color='danger'
                            onPress={onCancelledButtonPress}
                          >
                            Отклонить
                          </Button>
                        </ModalFooter>
                      </>
                    )}
                  </ModalContent>
                </Modal>

                <DoneModal
                  isOpen={isOpenDoneModal}
                  onClose={onCloseDoneModal}
                  onOpenChange={onOpenChangeDoneModal}
                  handleDoneClick={() =>
                    setEditorStatus(NewsbreakEditorStatusEnum.done)
                  }
                />
              </>
            )}
      </div>
    </>
  )
}
