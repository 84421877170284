import { RiskInterface } from '@hydra/interfaces'
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtomValue } from 'jotai/index'
import { Key, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { routesConst } from '../../../features/constants/routes.const'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { statusConst } from '../constants/status.const'
import { chatAtom } from '../store/chat.store'

export const ChatRisks = () => {
  const navigate = useNavigate()
  const { data: chat, isPending } = useAtomValue(chatAtom)

  const renderUserCell = useCallback((item: RiskInterface, columnKey: Key) => {
    if (columnKey === 'title') {
      return <div>{item.title}</div>
    }

    if (columnKey === 'createdAt') {
      return (
        <div>
          {getPrettyDate({
            date: item.createdAt,
          })}
        </div>
      )
    }

    if (columnKey === 'date') {
      return (
        <div>
          {getPrettyDate({
            date: item.date,
            template: 'DD MM YYYY HH:mm',
          })}
        </div>
      )
    }

    if (columnKey === 'isPlan') {
      return (
        <div>
          {item.isPlan ? (
            <span className='text-success'>Плановый</span>
          ) : (
            <span className='text-danger'>Срочный</span>
          )}
        </div>
      )
    }

    if (columnKey === 'status') {
      return (
        <div>
          <Chip
            variant='bordered'
            color={statusConst[item.status].color}
            size='sm'
          >
            {statusConst[item.status].title}
          </Chip>
        </div>
      )
    }
  }, [])

  return (
    <SimpleBar className='h-[calc(100vh-356px)] overflow-auto'>
      <Table
        aria-label='Риски чата'
        isHeaderSticky
        isStriped
        removeWrapper
        onRowAction={key => navigate(routesConst.risks + '/' + key)}
      >
        <TableHeader
          columns={[
            {
              key: 'title',
              label: 'Название',
            },
            {
              key: 'createdAt',
              label: 'Дата создания',
            },
            {
              key: 'date',
              label: 'Дата натсупления риска',
            },
            {
              key: 'isPlan',
              label: 'Срочность риска',
            },
            {
              key: 'status',
              label: 'Статус',
            },
          ]}
        >
          {column => (
            <TableColumn key={column.key} textValue={column.label}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={chat?.risks || []}
          isLoading={isPending}
          emptyContent={
            <div className='text-default-400 text-sm'>Нет данных</div>
          }
        >
          {item => (
            <TableRow key={item.id} textValue={item.id.toString()}>
              {columnKey => (
                <TableCell>{renderUserCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </SimpleBar>
  )
}
