import { CollectionInterface } from '@hydra/interfaces'
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { updateTask } from '../api/tasks.api'
import { collectionsAtom } from '../store/colletcions.store'

interface Props {
  id: string
  collection: CollectionInterface | null
  refetch: () => void
}

export const CollectionChip = ({ id, collection, refetch }: Props) => {
  const [{ data: collections, isPending }] = useAtom(collectionsAtom)

  const handleChangeCollection = useCallback(
    async (value: string) => {
      await updateTask(id, {
        collection: { id: value },
      })
      refetch()
    },
    [id, refetch],
  )

  return (
    <Dropdown
      backdrop='blur'
      placement='bottom-end'
      showArrow
      isDisabled={isPending}
    >
      <DropdownTrigger>
        <Chip
          size='sm'
          variant='bordered'
          color='default'
          className='cursor-pointer'
          endContent={<TbChevronDown className='text-xl' />}
          as='span'
        >
          {collection?.title || 'Не выбран'}
        </Chip>
      </DropdownTrigger>
      <DropdownMenu
        variant='faded'
        aria-label={`Проект задачи ${id}`}
        onAction={key => handleChangeCollection(key as string)}
        items={collections ? collections[0] : []}
        className='max-h-[300px] overflow-auto'
      >
        {collection => (
          <DropdownItem key={collection.id}>{collection.title}</DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}
