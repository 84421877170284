import { Grant } from '@hydra/interfaces'
import { Button } from '@nextui-org/react'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { TbSquareRoundedPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants, useGrant } from '../../../features/grants'
import { Layout } from '../../../features/ui'
import { OrganizationsList } from '../components/OrganizationsList'
import { menuItems } from '../constants/menuItems'

export const Organizations = () => {
  const navigate = useNavigate()
  const { isValidGrant: canCreateOrganization } = useGrant(
    Grant.newsbreaksModerator,
  )

  const onCreateButtonClick = useCallback(() => {
    navigate(routesConst.createOrganization)
  }, [navigate])

  return (
    <>
      <Helmet>
        <title>Гидра | Организации</title>
      </Helmet>
      <Grants grants={[Grant.newsbreaksModerator]}>
        <Layout
          menuItems={menuItems}
          headerContent={
            <div className='flex h-full items-center justify-between space-x-4 px-4'>
              <h1 className='flex-1 text-3xl font-semibold'>Организации</h1>
              {canCreateOrganization && (
                <Button
                  color='primary'
                  startContent={<TbSquareRoundedPlus className='text-2xl' />}
                  onClick={onCreateButtonClick}
                >
                  Создать
                </Button>
              )}
            </div>
          }
        >
          <div className='p-4'>
            <OrganizationsList />
          </div>
        </Layout>
      </Grants>
    </>
  )
}
