import {
  MARK_BG_COLOR,
  MARK_COLOR,
  useColorDropdownMenuState,
} from '@udecode/plate-font'
import { PropsWithChildren, useCallback } from 'react'

import { DEFAULT_COLORS, DEFAULT_CUSTOM_COLORS } from './color-constants'
import { ToolbarButton } from './toolbar'

export function ClearTextFormatting({ children }: PropsWithChildren) {
  const stateBg = useColorDropdownMenuState({
    nodeType: MARK_BG_COLOR,
    colors: DEFAULT_COLORS,
    customColors: DEFAULT_CUSTOM_COLORS,
    closeOnSelect: true,
  })

  const stateColor = useColorDropdownMenuState({
    nodeType: MARK_COLOR,
    colors: DEFAULT_COLORS,
    customColors: DEFAULT_CUSTOM_COLORS,
    closeOnSelect: true,
  })

  const handleClearFormatting = useCallback(() => {
    stateBg.clearColor()
    stateColor.clearColor()
    const textBoxes = document.querySelectorAll('[role="textbox"]')
    textBoxes.forEach(textBox => {
      const allNestedElements = Array.from(textBox.getElementsByTagName('*'))
      allNestedElements.forEach(element => {
        if (element instanceof HTMLElement && element.style.fontFamily) {
          element.style.fontFamily = 'inherit'
          element.style.fontSize = '14px'
        }
      })
    })
  }, [stateBg, stateColor])

  return (
    <ToolbarButton onClick={handleClearFormatting}>{children}</ToolbarButton>
  )
}
