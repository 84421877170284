import { Button, Divider, ScrollShadow } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback } from 'react'

import {
  endOfCreatedDayAtom,
  endOfUpdatedDayAtom,
  formOfWorkAtom,
  startOfCreatedDayAtom,
  startOfUpdatedDayAtom,
  themeIdAtom,
} from '../store/newsbreaks.store'
import { CreatedDateFilter } from './CreatedDateFilter'
// import { FormOfWorkFilter } from './FormOfWorkFilter'
import { ThemeFilter } from './ThemeFilter'
import { UpdatedDateFilter } from './UpdatedDateFilter'

export const ReportsFilters = () => {
  const [, setStartOfCreatedDay] = useAtom(startOfCreatedDayAtom)
  const [, setEndOfCreatedDay] = useAtom(endOfCreatedDayAtom)
  const [, setStartOfUpdatedDay] = useAtom(startOfUpdatedDayAtom)
  const [, setEndOfUpdatedDay] = useAtom(endOfUpdatedDayAtom)
  const [, setThemeId] = useAtom(themeIdAtom)
  const [, setFormOfWork] = useAtom(formOfWorkAtom)

  const onClearButtonPress = useCallback(() => {
    setStartOfCreatedDay('')
    setEndOfCreatedDay('')
    setStartOfUpdatedDay('')
    setEndOfUpdatedDay('')
    setThemeId(undefined)
    setFormOfWork(undefined)
  }, [
    setStartOfCreatedDay,
    setEndOfCreatedDay,
    setStartOfUpdatedDay,
    setEndOfUpdatedDay,
    setThemeId,
    setFormOfWork,
  ])

  return (
    <div className='bg-background space-y-4 rounded-xl p-4 shadow'>
      <div className='text-lg font-semibold'>Фильтры</div>
      <Divider />
      <ScrollShadow size={0} className='h-[calc(100vh-374px)] w-[300px] '>
        <div className='space-y-4'>
          <CreatedDateFilter />
          <UpdatedDateFilter />
          <ThemeFilter />
          {/* <FormOfWorkFilter /> */}
        </div>
      </ScrollShadow>
      <Divider />
      <div className='flex flex-col items-center space-y-4'>
        <Button fullWidth variant='light' onPress={onClearButtonPress}>
          Сбросить все
        </Button>
      </div>
    </div>
  )
}
