import { zodResolver } from '@hookform/resolvers/zod'
import { Grant, NotifyMomentEnum } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Select,
  SelectItem,
  Switch,
} from '@nextui-org/react'
import { TElement } from '@udecode/plate-common'
import { useAtom } from 'jotai/index'
import { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { IoCloseCircle } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import slug from 'slug'
import { z } from 'zod'

import { getFileUrlFromS3, uploadFileToS3 } from '../../../features/api'
import {
  audioExtensions,
  docExtensions,
  imageExtensions,
  videoExtensions,
} from '../../../features/constants/fileExtensions.const'
import { ONE_HUNDRED_MEGABYTES_IN_BYTES } from '../../../features/constants/fileSizes.const'
import { routesConst } from '../../../features/constants/routes.const'
import { allUsersAtom } from '../../../features/store'
import {
  FileDnD,
  FileItem,
  Header,
  Layout,
  PlateEditorWrapper,
} from '../../../features/ui'
import { getFileExtension } from '../../../features/utils/getFileExtension'
import { createTask, createTaskFile } from '../api/tasks.api'
import { menuItems } from '../constants/menuItems'
import { notifyMomentConst } from '../constants/notifyMoment.const'
import { taskPriorityConst } from '../constants/taskPriority.const'
import { taskStatusConst } from '../constants/taskStatus.const'
import { createTaskSchema } from '../schemas/createTask.schema'
import { collectionsAtom } from '../store/colletcions.store'

interface Props {
  isEdit?: boolean
}

type Form = z.infer<typeof createTaskSchema>

export const CreateOrUpdateTask = ({ isEdit }: Props) => {
  const navigate = useNavigate()
  const [files, setFiles] = useState<File[] | null>()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [editorDescriptionValue, setEditorDescriptionValue] = useState<
    Array<TElement> | undefined
  >(undefined)
  const [{ data: allUsers, isPending: isPendingAllUsers }] =
    useAtom(allUsersAtom)
  const [{ data: allCollections, isPending: isPendingAllCollections }] =
    useAtom(collectionsAtom)
  const form = useForm<Form>({
    defaultValues: {
      isNotify: false,
    },
    resolver: zodResolver(createTaskSchema),
  })

  const pageTitle = useMemo(
    () => (isEdit ? 'Редактирование' : 'Создание'),
    [isEdit],
  )

  const onDropAccepted = useCallback(
    (newFiles: Array<File>) => {
      setFiles(files ? [...files, ...newFiles] : newFiles)
    },
    [files],
  )

  const onSubmit = useCallback(
    async (formData: Form) => {
      try {
        setIsFormLoading(true)
        let createdFiles
        if (files) {
          const filesResponses = await Promise.allSettled(
            files.map(file => {
              return uploadFileToS3(
                new File(
                  [file],
                  slug(file.name.replace(/\.[^/.]+$/, '')) +
                    '.' +
                    getFileExtension(file.name),
                  {
                    type: file.type,
                  },
                ),
              )
            }),
          )
          const filesUrlResponses = await Promise.allSettled(
            filesResponses.map(file => {
              if (file.status === 'fulfilled' && file.value) {
                return getFileUrlFromS3(
                  file.value.data,
                  import.meta.env.VITE_BUCKET_NAME,
                )
              }

              return null
            }),
          )

          createdFiles = await Promise.allSettled(
            filesUrlResponses.map(file => {
              if (file.status === 'fulfilled' && file.value) {
                return createTaskFile(file.value.data)
              }
              return null
            }),
          )
        }

        const response = await createTask({
          ...formData,
          collection: formData.collection
            ? {
                id: formData.collection,
              }
            : undefined,
          executor: formData.executor
            ? {
                id: formData.executor,
              }
            : undefined,
          date: formData.date !== '' ? formData.date : undefined,
          time: formData.time !== '' ? formData.time : undefined,
          description: editorDescriptionValue,
          files: createdFiles
            ? createdFiles.map(file => {
                if (file.status === 'fulfilled' && file.value) {
                  return { id: file.value.data }
                }
                return { id: '' }
              })
            : null,
        })
        if (response.status && response.status === 201) {
          toast.success('Задача успешно создана')
          setFiles(null)
          navigate(routesConst.tasks)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [editorDescriptionValue, files, navigate],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle} задачи</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={<Header text={pageTitle + ' задачи'} />}
      >
        <form className='p-4' onSubmit={form.handleSubmit(onSubmit)}>
          <div className='bg-background min-h-[calc(100vh-104px)] space-y-6 rounded-xl p-4 shadow'>
            <div className='space-y-4'>
              <div className='flex items-center justify-between'>
                <div className='flex-1 text-lg font-semibold'>
                  Основная информация
                </div>
                <div>
                  <Button
                    onPress={() => {
                      setFiles(null)
                      navigate(routesConst.tasks)
                    }}
                    color='primary'
                    endContent={<IoCloseCircle className='text-xl' />}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
              <Controller
                control={form.control}
                name='title'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    placeholder='Название'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                    isInvalid={!!form.formState.errors.title}
                    errorMessage={form.formState.errors.title?.message}
                  />
                )}
              />
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='executor'
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      isInvalid={!!form.formState.errors.executor}
                      errorMessage={form.formState.errors.executor?.message}
                      isDisabled={isFormLoading}
                      aria-label='Исполнитель'
                      isLoading={isPendingAllUsers || isFormLoading}
                      variant='bordered'
                      defaultItems={
                        allUsers
                          ? allUsers[0].filter(user =>
                              user.grants.includes(Grant.tasks),
                            )
                          : []
                      }
                      placeholder='Выберите исполнителя'
                      onSelectionChange={onChange}
                      defaultSelectedKey={value}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      onKeyDown={e => e.continuePropagation()}
                    >
                      {item => (
                        <AutocompleteItem
                          key={item.id}
                          textValue={item.name + ' ' + item.surname}
                        >
                          {item.name} {item.surname}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                  )}
                />
                <Controller
                  control={form.control}
                  name='participants'
                  render={({ field: { onChange } }) => (
                    <Select
                      isDisabled={isPendingAllUsers || isFormLoading}
                      isLoading={isPendingAllUsers || isFormLoading}
                      items={
                        allUsers
                          ? allUsers[0].filter(user =>
                              user.grants.includes(Grant.tasks),
                            )
                          : []
                      }
                      aria-label='Участники'
                      placeholder='Выберите участников'
                      variant='bordered'
                      selectionMode='multiple'
                      onChange={event => {
                        onChange(event.target.value.split(','))
                      }}
                    >
                      {item => (
                        <SelectItem
                          key={item.id}
                          textValue={item.name + ' ' + item.surname}
                        >
                          {item.name} {item.surname}
                        </SelectItem>
                      )}
                    </Select>
                  )}
                />
              </div>
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='status'
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isDisabled={isFormLoading}
                      isLoading={isFormLoading}
                      items={Object.entries(taskStatusConst).map(item => ({
                        label: item[1],
                        value: item[0],
                      }))}
                      aria-label='Статус задачи'
                      placeholder='Выберите статус'
                      variant='bordered'
                      onChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.status}
                      errorMessage={form.formState.errors.status?.message}
                    >
                      {item => (
                        <SelectItem key={item.value}>{item.label}</SelectItem>
                      )}
                    </Select>
                  )}
                />
                <Controller
                  control={form.control}
                  name='priority'
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isDisabled={isFormLoading}
                      isLoading={isFormLoading}
                      items={Object.entries(taskPriorityConst).map(item => ({
                        label: item[1],
                        value: item[0],
                      }))}
                      aria-label='Приоритет задачи'
                      placeholder='Выберите приоритет'
                      variant='bordered'
                      onChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.priority}
                      errorMessage={form.formState.errors.priority?.message}
                    >
                      {item => (
                        <SelectItem key={item.value}>{item.label}</SelectItem>
                      )}
                    </Select>
                  )}
                />
                <Controller
                  control={form.control}
                  name='collection'
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      isInvalid={!!form.formState.errors.collection}
                      errorMessage={form.formState.errors.collection?.message}
                      isDisabled={isFormLoading}
                      aria-label='Проект'
                      isLoading={isPendingAllCollections || isFormLoading}
                      variant='bordered'
                      defaultItems={allCollections ? allCollections[0] : []}
                      placeholder='Выберите проект'
                      onSelectionChange={onChange}
                      defaultSelectedKey={value}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      onKeyDown={e => e.continuePropagation()}
                    >
                      {item => (
                        <AutocompleteItem key={item.id}>
                          {item.title}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                  )}
                />
              </div>
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='date'
                  render={({ field: { onChange, value } }) => (
                    <div className='w-[150px]'>
                      <Input
                        id='date'
                        isDisabled={isFormLoading}
                        type='date'
                        variant='bordered'
                        value={value || ''}
                        onChange={onChange}
                      />
                    </div>
                  )}
                />
                <Controller
                  control={form.control}
                  name='time'
                  render={({ field: { onChange, value } }) => (
                    <div className='w-[150px]'>
                      <Input
                        id='time'
                        isDisabled={isFormLoading}
                        type='time'
                        variant='bordered'
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  )}
                />
              </div>
              {form.watch('date') && (
                <div className='flex h-10 items-center space-x-4'>
                  <Controller
                    control={form.control}
                    name='isNotify'
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        isDisabled={isFormLoading}
                        size='sm'
                        onValueChange={onChange}
                        checked={value}
                      >
                        Напомнить
                      </Switch>
                    )}
                  />
                  {form.watch('isNotify') && (
                    <div className='w-[200px]'>
                      <Controller
                        control={form.control}
                        name='notifyMoment'
                        render={({ field: { onChange, value } }) => (
                          <Select
                            isDisabled={isFormLoading}
                            isLoading={isFormLoading}
                            aria-label='Момент уведомления'
                            placeholder='Момент уведомления'
                            variant='bordered'
                            onChange={onChange}
                            value={value}
                            disabledKeys={
                              !form.watch('time')
                                ? [
                                    NotifyMomentEnum.fifteenMinute,
                                    NotifyMomentEnum.thirtyMinute,
                                    NotifyMomentEnum.hour,
                                  ]
                                : []
                            }
                          >
                            <SelectItem key={NotifyMomentEnum.fifteenMinute}>
                              {
                                notifyMomentConst[
                                  NotifyMomentEnum.fifteenMinute
                                ]
                              }
                            </SelectItem>
                            <SelectItem key={NotifyMomentEnum.thirtyMinute}>
                              {notifyMomentConst[NotifyMomentEnum.thirtyMinute]}
                            </SelectItem>
                            <SelectItem key={NotifyMomentEnum.hour}>
                              {notifyMomentConst[NotifyMomentEnum.hour]}
                            </SelectItem>
                            <SelectItem key={NotifyMomentEnum.day}>
                              {notifyMomentConst[NotifyMomentEnum.day]}
                            </SelectItem>
                            <SelectItem key={NotifyMomentEnum.week}>
                              {notifyMomentConst[NotifyMomentEnum.week]}
                            </SelectItem>
                          </Select>
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
              <PlateEditorWrapper
                withComments={false}
                placeholder='Введите описание'
                onChange={setEditorDescriptionValue}
              />
            </div>
            {!isEdit && (
              <>
                <div className='space-y-4'>
                  <div className='flex space-x-4'>
                    <div className='w-1/3 space-y-3'>
                      <div className='text-lg font-semibold '>
                        Материалы задачи
                      </div>
                      <div className='text-default-600'>
                        Вы можете загрузить изображения, видео, аудиозапись,
                        текстовый документ.
                      </div>
                    </div>
                    <div className='flex-1'>
                      <FileDnD
                        disabled={isFormLoading}
                        maxFiles={100}
                        maxSize={ONE_HUNDRED_MEGABYTES_IN_BYTES}
                        multiple
                        onDropAccepted={onDropAccepted}
                        accept={{
                          ...audioExtensions,
                          ...videoExtensions,
                          ...imageExtensions,
                          ...docExtensions,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-3 gap-4'>
                  {files?.map((file, index) => (
                    <FileItem
                      key={Math.random()}
                      file={file}
                      setFiles={setFiles}
                      index={index}
                    />
                  ))}
                </div>
              </>
            )}
            <div className='flex justify-end space-x-4'>
              <Button
                color='primary'
                type='submit'
                isDisabled={isFormLoading}
                isLoading={isFormLoading}
              >
                Создать
              </Button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  )
}
