import { useAtom } from 'jotai/index'

import { Empty, PageSpinner } from '../../../features/ui'
import { tasksAtom } from '../store/tasks.store'
import { TasksTable } from './TasksTable'

export const AllTasks = () => {
  const [{ data, isPending, refetch }] = useAtom(tasksAtom)

  if (isPending) return <PageSpinner />

  if (!data || data[0].length === 0) return <Empty />

  return (
    <TasksTable
      items={data[0]}
      refetch={refetch}
      isFullHeight
      withCollection
      withExecutor
    />
  )
}
