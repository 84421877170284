import { z } from 'zod'

export const createSettingsSchema = z.object({
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Максимальная длина 250')
    .min(1, 'Минимальная длина 1')
    .trim(),
})
