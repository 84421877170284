import { PostInterface } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Chip,
  Divider,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Tooltip,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom, useAtomValue } from 'jotai/index'
import { Key, useCallback, useEffect, useState } from 'react'
import { AiFillEye, AiFillLike } from 'react-icons/ai'
import { BiRepost } from 'react-icons/bi'
import { FaComments } from 'react-icons/fa6'
import { MdAddReaction } from 'react-icons/md'
import { TbCopy } from 'react-icons/tb'
import ReactPlayer from 'react-player/lazy'
import { toast } from 'react-toastify'
import { useCopyToClipboard, useIntersectionObserver } from 'usehooks-ts'
import Lightbox from 'yet-another-react-lightbox'

import { routesConst } from '../../../features/constants/routes.const'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { getSocialNetworkName } from '../../../features/utils/getSocialIcon'
import {
  getTgPostMetaInfo,
  getVkPostMetaInfo,
  updatePost,
} from '../api/post.api'
import { chatIdAtom } from '../store/chat.store'
import { postsInfiniteAtom, takeAtom } from '../store/post.store'
import { allRisksAtom } from '../store/risk.store'
import { SendMessageMediaModal } from './SendMessageMediaModal'

interface Props {
  post: PostInterface
  index: number
  isShowRiskButton: boolean
}

export const PostItem = ({ post, index, isShowRiskButton }: Props) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 1,
  })

  const [risk, setRisk] = useState<Key | null>(null)
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const [, setChatId] = useAtom(chatIdAtom)
  const [additionalTextForChat, setAdditionalTextForChat] = useState(
    post.originalLink,
  )

  const {
    isOpen: isOpenRisk,
    onOpen: onOpenRisk,
    onOpenChange: onOpenChangeRisk,
    onClose: onCloseRisk,
  } = useDisclosure()

  const {
    isOpen: isOpenSendMessageMedia,
    onOpenChange: onOpenChangeSendMessageMedia,
    onClose: onCloseSendMessageMedia,
    onOpen: onOpenSendMessageMedia,
  } = useDisclosure()

  const [{ fetchNextPage, hasNextPage }] = useAtom(postsInfiniteAtom)
  const { data: risks, isPending: isPendingRisks } = useAtomValue(allRisksAtom)
  const [take] = useAtom(takeAtom)

  const [metaInfo, setMetaInfo] = useState<{
    views: number
    reposts: number
    likes: number
    comments: number
    reactions: number
    replies: Array<{ date: number; message: string; id: number }>
  } | null>(null)

  const [open, setOpen] = useState(false)
  const [slideIndex, setSlideIndex] = useState<number | undefined>(undefined)
  const [, setCopy] = useCopyToClipboard()

  const fetchPostMetaInfo = useCallback(async () => {
    try {
      if (post.public?.link.startsWith('https://vk.com/')) {
        const response = await getVkPostMetaInfo(post.originalId)

        if (response.status && response.status === 200) {
          setMetaInfo(response.data)
        }
      }

      if (post.public?.link.startsWith('https://t.me/')) {
        const channelUsername = post.public?.link.split('/').pop()

        if (channelUsername) {
          const response = await getTgPostMetaInfo(
            post.public.link,
            post.originalId,
          )
          if (response.status && response.status === 200) {
            setMetaInfo(response.data)
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [post.originalId, post.public])

  const handleCopyText = useCallback(async () => {
    try {
      await setCopy(post.originalLink)
      toast.success('Ссылка скопирован в буфер обмена')
    } catch (e) {
      console.error(e)
      toast.error('Не удалось скопировать ссылку')
    }
  }, [post.originalLink, setCopy])

  const handleAddPostToRisk = useCallback(async () => {
    try {
      const response = await updatePost(post.id, {
        risk: { id: risk as string },
      })

      if (response.status && response.status === 200) {
        setRisk(null)
        onCloseRisk()
        toast.success('Пост добавлен в риск')
        return
      }

      toast.error('Не удалось добавить пост в риск')
    } catch (e) {
      console.error(e)
    }
  }, [onCloseRisk, post.id, risk])

  const handleUpdatePost = useCallback(async () => {
    if (!risk) {
      toast.error('Не выбран риск')
      return
    }

    if (risks) {
      const foundedRisk = risks[0].find(r => r.id === risk)

      if (foundedRisk) {
        if (foundedRisk.chat) {
          setChatId(foundedRisk.chat.id)
          onOpenSendMessageMedia()
          return
        }
      }
    }

    await handleAddPostToRisk()
  }, [handleAddPostToRisk, onOpenSendMessageMedia, risk, risks, setChatId])

  useEffect(() => {
    !metaInfo && isIntersecting && fetchPostMetaInfo()
  }, [fetchPostMetaInfo, isIntersecting, metaInfo])

  useEffect(() => {
    index === take - 2 && isIntersecting && hasNextPage && fetchNextPage()
  }, [fetchNextPage, hasNextPage, index, isIntersecting, take])

  return (
    <>
      <Lightbox
        index={slideIndex}
        open={open}
        close={() => setOpen(false)}
        slides={post.attachments?.map(a => ({ src: a })) || []}
      />
      <div ref={ref} className='bg-background space-y-2 rounded-lg p-4 shadow'>
        <div className='flex items-center justify-between'>
          <div className='flex flex-1 items-center space-x-2'>
            {post.public?.link && (
              <>
                <img
                  alt={post.public.link}
                  src={`/social-icons/${getSocialNetworkName(post.public.link)}.svg`}
                  className='h-4 w-4'
                />
                <Link
                  href={routesConst.publics + '/' + post.public.id}
                  size='sm'
                  isBlock
                >
                  {post.public.title || post.public.link}
                </Link>
              </>
            )}
            {post.isRepost && (
              <Chip size='sm' color='success' variant='dot'>
                Репост
              </Chip>
            )}
          </div>
          <div className='text-default-400 text-sm'>
            {getPrettyDate({
              date: post.publicationDate,
              template: 'DD.MM.YYYY HH:mm',
            })}
          </div>
        </div>
        <div className='flex flex-wrap gap-1'>
          {post.attachments?.map((a, index) => {
            if (a.startsWith('https://vk.com/video_ext.php')) {
              return (
                <iframe
                  key={a}
                  title={a}
                  src={a}
                  width='286'
                  height='150'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowFullScreen
                  className='bg-default-200 h-[150px] w-[286px] rounded'
                />
              )
            }
            if (a.includes('.mp4')) {
              return (
                <div
                  key={a}
                  className='bg-default-200 h-[150px] w-[286px] rounded'
                >
                  <ReactPlayer controls url={a} width={286} height={150} />
                </div>
              )
            }
            return (
              <Image
                key={a}
                src={a}
                width={286}
                height={150}
                radius='sm'
                loading='lazy'
                isBlurred
                isZoomed
                className='h-[150px] w-[286px] rounded object-contain'
                onClick={() => {
                  setOpen(true)
                  setSlideIndex(index)
                }}
              />
            )
          })}
        </div>
        <div className='text-sm'>{post.text}</div>
        <div className='flex justify-between'>
          <div className='flex items-center space-x-2'>
            {/*<Button size='sm' color='primary'>*/}
            {/*  Комментирование*/}
            {/*</Button>*/}
            {isShowRiskButton && (
              <Button
                size='sm'
                variant='bordered'
                color='danger'
                onPress={onOpenRisk}
              >
                В риск
              </Button>
            )}
            <Link isExternal href={post.originalLink} isBlock showAnchorIcon>
              Оригинальный пост
            </Link>
          </div>
          <div className='flex items-center space-x-2'>
            <Button
              variant='light'
              size='sm'
              isIconOnly
              onPress={handleCopyText}
            >
              <TbCopy className='text-xl' />
            </Button>
          </div>
        </div>
        <Divider />
        {isShowRiskButton &&
          (metaInfo ? (
            <div className='flex items-center space-x-2'>
              {metaInfo.views > 0 ? (
                <div className='bg-default-100 flex h-[32px] items-center space-x-2 rounded-lg px-3 py-[6px] text-sm font-semibold'>
                  <AiFillEye className='text-xl' />
                  <Tooltip content='Просмотры'>
                    <span>{metaInfo.views}</span>
                  </Tooltip>
                </div>
              ) : null}
              {metaInfo.reposts > 0 ? (
                <div className='bg-default-100 flex h-[32px] items-center space-x-2 rounded-lg px-3 py-[6px] text-sm font-semibold'>
                  <BiRepost className='text-xl' />
                  <Tooltip content='Репосты'>
                    <span>{metaInfo.reposts}</span>
                  </Tooltip>
                </div>
              ) : null}
              {metaInfo.likes > 0 ? (
                <div className='bg-default-100 flex h-[32px] items-center space-x-2 rounded-lg px-3 py-[6px] text-sm font-semibold'>
                  <AiFillLike className='text-xl' />
                  <Tooltip content='Лайки'>
                    <span>{metaInfo.likes}</span>
                  </Tooltip>
                </div>
              ) : null}
              {metaInfo.comments > 0 ? (
                <div className='bg-default-100 flex h-[32px] items-center space-x-2 rounded-lg px-3 py-[6px] text-sm font-semibold'>
                  <FaComments className='text-xl' />
                  <Tooltip content='Комментарии'>
                    <span>{metaInfo.comments}</span>
                  </Tooltip>
                </div>
              ) : null}
              {metaInfo.reactions > 0 ? (
                <div className='bg-default-100 flex h-[32px] items-center space-x-2 rounded-lg px-3 py-[6px] text-sm font-semibold'>
                  <MdAddReaction className='text-xl' />
                  <Tooltip content='Реакции'>
                    <span>{metaInfo.reactions}</span>
                  </Tooltip>
                </div>
              ) : null}
              {metaInfo.replies?.length > 0 ? (
                <Button
                  variant='bordered'
                  color='primary'
                  size='sm'
                  onPress={onOpen}
                >
                  Прочитать комментарии
                </Button>
              ) : null}
            </div>
          ) : (
            <Skeleton className='w-[60px] rounded-lg'>
              <div className='bg-default-300 h-[32px]  rounded-lg' />
            </Skeleton>
          ))}
      </div>

      <SendMessageMediaModal
        isOpen={isOpenSendMessageMedia}
        onOpenChange={onOpenChangeSendMessageMedia}
        onClose={onCloseSendMessageMedia}
        additionalAction={handleAddPostToRisk}
        additionalText={additionalTextForChat}
      />

      <Modal
        isOpen={isOpenRisk}
        onOpenChange={onOpenChangeRisk}
        scrollBehavior='inside'
        size='3xl'
      >
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader>Выбрать риск</ModalHeader>
              <ModalBody>
                <Autocomplete
                  aria-label='Риски'
                  isLoading={isPendingRisks}
                  variant='bordered'
                  defaultItems={
                    risks
                      ? risks[0].filter(
                          r => !r.posts?.some(p => p.id === post.id),
                        )
                      : []
                  }
                  label='Риски'
                  onSelectionChange={setRisk}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  selectedKey={risk} // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  onKeyDown={e => e.continuePropagation()}
                >
                  {item => (
                    <AutocompleteItem key={item.id}>
                      {item.title}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
              </ModalBody>
              <ModalFooter>
                <Button color='danger' variant='light' onPress={onClose}>
                  Закрыть
                </Button>
                <Button
                  color='primary'
                  variant='light'
                  onPress={handleUpdatePost}
                >
                  Прикрепить
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior='inside'
        size='3xl'
      >
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader>Комментарии</ModalHeader>
              <ModalBody>
                <div className='space-y-4 text-sm'>
                  {metaInfo?.replies?.map(r => (
                    <div
                      key={r.id}
                      className='bg-default-100 space-y-1 rounded-2xl p-3'
                    >
                      <span className='text-default-400 text-xs'>
                        {getPrettyDate({
                          date: new Date(r.date * 1000),
                          template: 'DD MMMM YYYY HH:mm',
                        })}
                      </span>
                      <p>{r.message}</p>
                    </div>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color='danger' variant='light' onPress={onClose}>
                  Закрыть
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
