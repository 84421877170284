import { z } from 'zod'

export const createNewsbreakSchema = z.object({
  isUrgent: z.boolean(),
  isAllDay: z.boolean(),
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  type: z.string().trim().optional(),
  deadline: z.string().trim().optional(),
  theme: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  project: z.string().trim().optional(),
  priority: z.string().trim().optional(),
  address: z.string().trim().optional(),
  formOfWork: z.array(z.string()).optional(),
  status: z.string().trim().optional(),
})
