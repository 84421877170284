import { LocationInterface } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from './instance'

export const getAllLocations = (): Promise<
  AxiosResponse<[Array<LocationInterface>, number]>
> => {
  return instance.get('/locations/all')
}

export const updateLocation = (
  id: string,
  data: {
    title?: string
  },
) => {
  return instance.patch(`/locations/update/${id}`, data)
}

export const createLocation = (data: { title: string }) => {
  return instance.post('/locations/create', data)
}
