import {
  ChatInterface,
  PublicInterface,
  UserInterface,
} from '@hydra/interfaces'
import { AxiosResponse } from 'axios'
import { SortDirection } from 'typeorm'

import { instance } from '../../../features/api'

export const getChats = async (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof PublicInterface
}): Promise<AxiosResponse<[Array<ChatInterface>, number]>> => {
  return instance.get('/chats/all-chats', { params })
}

export const getChatById = (
  id: string,
): Promise<AxiosResponse<ChatInterface>> => {
  return instance.get(`/chats/chat-by-id/${id}`, {})
}

export const createChat = (data: {
  title: string
  link?: string
  level?: string
}): Promise<AxiosResponse<string>> => {
  return instance.post('/chats/create-chat', data)
}

export const sendMessage = (
  id: string,
  data: {
    message: string
  },
): Promise<AxiosResponse<string>> => {
  return instance.post(`/chats/send-message/${id}`, data)
}

export const sendMedia = (
  id: string,
  data: {
    file: File
    caption: string
  },
): Promise<AxiosResponse<string>> => {
  const formData = new FormData()
  formData.append('file', data.file)
  formData.append('caption', data.caption)

  return instance.post(`/chats/send-media/${id}`, formData)
}

export const updateChat = (id: string, data: Partial<ChatInterface>) => {
  return instance.patch(`/chats/update-chat/${id}`, data)
}

export const updateChatUser = (
  id: string,
  data: { user: UserInterface; value: boolean },
) => {
  return instance.patch(`/chats/update-chat-users/${id}`, data)
}

export const removeExternalUser = (
  id: string,
  data: { user: { telegram: string; chatId: number } },
) => {
  return instance.patch(`/chats/remove-external-user/${id}`, data)
}

export const deleteChat = (id: string) => {
  return instance.delete(`/chats/delete-chat/${id}`)
}
