import { Grant, LogInterface, NewsbreakStatusEnum } from '@hydra/interfaces'
import {
  Accordion,
  AccordionItem,
  Button,
  Chip,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai'
import { Key, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { IoArrowForward, IoCloseCircle } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { routesConst } from '../../../features/constants/routes.const'
import { statusConst } from '../../../features/constants/status.const'
import { Grants } from '../../../features/grants'
import {
  Empty,
  Header,
  Layout,
  PageSpinner,
  PlateEditorWrapper,
} from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { menuItems } from '../constants/menuItems'
import { allLogsAtom, newsbreakIdAtom } from '../store/newsbreaks.store'

export const Log = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [{ data: logs, isPending }] = useAtom(allLogsAtom)
  const [, setNewsbreakId] = useAtom(newsbreakIdAtom)

  useEffect(() => {
    if (id) {
      setNewsbreakId(id)
    }
    return () => {
      setNewsbreakId('')
    }
  }, [id, setNewsbreakId])

  const renderCell = useCallback((group: LogInterface, columnKey: Key) => {
    const time = getPrettyDate({
      date: group.createdAt,
      template: 'DD MMMM YYYY HH:mm:ss',
    })
    const { author } = group

    switch (columnKey) {
      case 'user':
        return (
          <div>
            <Chip
              as={Link}
              href={routesConst.users + '/' + author?.id}
              color='primary'
              variant='flat'
              size='sm'
            >
              {author?.name} {author?.surname}
            </Chip>
          </div>
        )
      case 'time':
        return time
      case 'field':
        return group.field
      case 'changes':
        return group.field === 'описание' || group.field === 'акценты' ? (
          <Accordion isCompact variant='light'>
            <AccordionItem
              classNames={{
                trigger: 'p-0',
              }}
              key='1'
              aria-label='description'
              title={
                <div className='text-sm'>
                  Сравнение двух описательных частей (нажмите что бы раскрыть)
                </div>
              }
            >
              <div className='flex space-x-4'>
                <div className='flex-1 rounded-lg border border-red-700 p-2'>
                  <PlateEditorWrapper
                    withComments={false}
                    onChange={() => null}
                    isReadOnly
                    initialValue={JSON.parse(group.oldValue)}
                  />
                </div>
                <div className='border-success-700 flex-1 rounded-lg border p-2'>
                  <PlateEditorWrapper
                    withComments={false}
                    onChange={() => null}
                    isReadOnly
                    initialValue={JSON.parse(group.newValue)}
                  />
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        ) : (
          <div className='flex items-center space-x-4'>
            <div className='line-clamp-2 max-w-[50%] break-all text-red-700'>
              {group.field === 'статус' &&
                (group.oldValue
                  ? statusConst[group.oldValue as NewsbreakStatusEnum]?.title
                  : 'значение отсутствовало')}
              {group.field !== 'статус' &&
                (group.oldValue ? group.oldValue : 'значение отсутствовало')}
            </div>
            <div className='flex items-center justify-center'>
              <IoArrowForward className='text-lg' />
            </div>
            <div className='line-clamp-2 max-w-[50%] break-all text-green-700'>
              {group.field === 'статус' &&
                statusConst[group.newValue as NewsbreakStatusEnum]?.title}
              {group.field !== 'статус' && group.newValue}
            </div>
          </div>
        )

      default:
        return <div></div>
    }
  }, [])

  if (isPending) return <PageSpinner />

  if (!logs) return <Empty />

  return (
    <>
      <Helmet>
        <title>Гидра | Журнал изменений</title>
      </Helmet>
      <Grants
        grants={[
          Grant.newsbreaksModerator,
          Grant.newsbreaksProvider,
          Grant.newsbreaksCategoryModerator,
        ]}
      >
        <Layout
          menuItems={menuItems}
          headerContent={<Header text='Журнал изменений' />}
        >
          <div className='p-4'>
            <div className='bg-background h-[calc(100vh-104px)] overflow-hidden rounded-xl shadow'>
              <div className='space-y-4 p-4'>
                <Button
                  onPress={() => {
                    navigate(-1)
                  }}
                  color='primary'
                  endContent={<IoCloseCircle className='text-xl' />}
                  className='ml-auto flex'
                >
                  Закрыть
                </Button>

                <Divider />

                <SimpleBar className='h-[calc(100vh-208px)] overflow-auto'>
                  <Table
                    aria-label='Журнал изменений'
                    isHeaderSticky
                    isStriped
                    removeWrapper
                  >
                    <TableHeader>
                      <TableColumn width='200' key='user'>
                        Пользователь
                      </TableColumn>
                      <TableColumn width='200' key='time'>
                        Время изменений
                      </TableColumn>
                      <TableColumn width='200' key='field'>
                        Поле
                      </TableColumn>
                      <TableColumn key='changes'>Изменения</TableColumn>
                    </TableHeader>
                    <TableBody
                      items={Object.entries(logs[0])}
                      emptyContent={
                        <div className='text-default-400 text-sm'>
                          Нет данных
                        </div>
                      }
                    >
                      {item => (
                        <TableRow key={item[0]}>
                          {columnKey => (
                            <TableCell>
                              {renderCell(item[1], columnKey)}
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </SimpleBar>
              </div>
            </div>
          </div>
        </Layout>
      </Grants>
    </>
  )
}
