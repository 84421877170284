import { PerformanceInterface } from '@hydra/interfaces'
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai'
import { TbEdit } from 'react-icons/tb'

import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import { groupAtom } from '../store/groups.store'
import { CreateOrUpdatePerformanceModal } from './CreateOrUpdatePerformanceModal'

interface Props {
  performance: PerformanceInterface
}

export const PerformanceItem = ({ performance }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const [{ refetch }] = useAtom(groupAtom)

  return (
    <>
      <CreateOrUpdatePerformanceModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onClose={onClose}
        defaultValues={performance}
        refetch={refetch}
      />
      <div
        key={performance.id}
        className='bg-background flex flex-col space-y-4 rounded-2xl p-4 shadow'
      >
        <div className='flex items-center justify-between'>
          <div>
            <Chip size='sm' variant='faded' color='primary'>
              {extendedDayjs().month(performance.month).format('MMMM')}{' '}
              {performance.year}
            </Chip>
          </div>
          <div>
            <Button
              isDisabled={extendedDayjs().month() !== performance.month}
              size='sm'
              variant='bordered'
              startContent={<TbEdit className='text-2xl' />}
              onPress={onOpen}
            >
              Редактировать
            </Button>
          </div>
        </div>
        <Table aria-label='Показатели КПЭ' isStriped removeWrapper>
          <TableHeader>
            <TableColumn>Показатель</TableColumn>
            <TableColumn>КПЭ</TableColumn>
          </TableHeader>
          <TableBody>
            <TableRow key='1'>
              <TableCell>Количество поводов</TableCell>
              <TableCell>{performance.occasionsCount}</TableCell>
            </TableRow>
            {/*<TableRow key='2'>*/}
            {/*  <TableCell>Количество поводов для соц.сетей</TableCell>*/}
            {/*  <TableCell>*/}
            {/*    {performance.occasionsCountForSocialNetwork}*/}
            {/*  </TableCell>*/}
            {/*</TableRow>*/}
            {/*<TableRow key='3'>*/}
            {/*  <TableCell>Попадание в темник</TableCell>*/}
            {/*  <TableCell>{performance.themeHit}</TableCell>*/}
            {/*</TableRow>*/}
            {/*<TableRow key='4'>*/}
            {/*  <TableCell>Попадание в темник для соц.сетей</TableCell>*/}
            {/*  <TableCell>{performance.themeHitForSocialNetwork}</TableCell>*/}
            {/*</TableRow>*/}
            {/*<TableRow key='5'>*/}
            {/*  <TableCell>Пресс-релиз</TableCell>*/}
            {/*  <TableCell>{performance.qualityRelease}</TableCell>*/}
            {/*</TableRow>*/}
            {/*<TableRow key='6'>*/}
            {/*  <TableCell>Продюсирование</TableCell>*/}
            {/*  <TableCell>{performance.producing}</TableCell>*/}
            {/*</TableRow>*/}
            {/*<TableRow key='7'>*/}
            {/*  <TableCell>Выходы на федеральном уровне</TableCell>*/}
            {/*  <TableCell>{performance.graphic}</TableCell>*/}
            {/*</TableRow>*/}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
