import { Link } from '@nextui-org/react'
import { Helmet } from 'react-helmet'

import { routesConst } from '../../../features/constants/routes.const'

export const AccessDenied = () => {
  return (
    <>
      <Helmet>
        <title>Гидра | Доступ запрещен</title>
      </Helmet>
      <div className='bg-background flex h-screen w-screen flex-col items-center justify-center'>
        <h1 className='text-4xl font-bold'>Доступ запрещен</h1>
        <Link href={routesConst.main}>Вернуться на главную</Link>
      </div>
    </>
  )
}
