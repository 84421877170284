import { NewsbreakInterface } from '@hydra/interfaces'
import { useDisclosure } from '@nextui-org/react'
import { PropsWithChildren } from 'react'

import { ReportsModal } from './ReportsModal'

interface Props extends PropsWithChildren {
  newsbreak: NewsbreakInterface
}

export const NewsbreaksWithReportsItem = ({ newsbreak, children }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()

  return (
    <>
      <div
        className='hover:text-primary h-full cursor-pointer transition-all hover:underline'
        onClick={onOpen}
      >
        {children}
      </div>

      <ReportsModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onClose={onClose}
        newsbreak={newsbreak}
      />
    </>
  )
}
