import {
  NotifyMomentEnum,
  TaskPriorityEnum,
  TaskStatusEnum,
} from '@hydra/interfaces'
import { z } from 'zod'

export const createTaskSchema = z.object({
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, {
      message: 'Минимальная длина 2 символов',
    })
    .max(1000, {
      message: 'Максимальная длина 1000 символов',
    })
    .trim(),
  collection: z.string().trim().optional(),
  executor: z.string().trim().optional(),
  status: z.nativeEnum(TaskStatusEnum).optional(),
  priority: z.nativeEnum(TaskPriorityEnum).optional(),
  date: z.string().trim().optional(),
  time: z.string().trim().optional(),
  isNotify: z.boolean(),
  notifyMoment: z.nativeEnum(NotifyMomentEnum).optional(),
  participants: z.array(z.string().trim()).optional(),
})
