import { UserInterface } from '@hydra/interfaces'
import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtomValue } from 'jotai/index'
import { Key, useCallback, useMemo, useState } from 'react'
import { TbCopy } from 'react-icons/tb'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { useCopyToClipboard } from 'usehooks-ts'

import { allUsersAtom } from '../../../features/store'
import { formatRussianPhoneNumber } from '../../../features/utils/formatRussianPhoneNumber.util'
import { chatAtom } from '../store/chat.store'
import { HighLightSearch } from './HighLightSearch'
import { InChatUserCheckbox } from './InChatUserCheckbox'

export const InternalChatUsers = () => {
  const [search, setSearch] = useState('')
  const [, copy] = useCopyToClipboard()
  const { data: users } = useAtomValue(allUsersAtom)
  const { data: chat } = useAtomValue(chatAtom)

  const renderUserCell = useCallback(
    (item: UserInterface, columnKey: Key) => {
      if (columnKey === 'name' + search) {
        return (
          <>
            <HighLightSearch search={search.split(' ')} value={item.name} />{' '}
            <HighLightSearch search={search.split(' ')} value={item.surname} />
          </>
        )
      }

      if (columnKey === 'organization' + search) {
        return (
          <HighLightSearch
            search={search.split(' ')}
            value={item.organization.name}
          />
        )
      }

      if (columnKey === 'telegram' + search) {
        return (
          <div className='flex items-center space-x-2'>
            {item.telegram && (
              <Button
                color='primary'
                size='sm'
                variant='light'
                isIconOnly
                onPress={async () => {
                  try {
                    await copy(item.telegram || '')
                    toast.success('Telegram username скопирован в буфер обмена')
                  } catch (error) {
                    toast.error('Не удалось скопировать telegram username')
                  }
                }}
              >
                <TbCopy className='text-lg' />
              </Button>
            )}
            <div>{item.telegram}</div>
          </div>
        )
      }

      if (columnKey === 'chatId' + search) {
        return (
          <div className='flex items-center space-x-2'>
            {item.chatId && (
              <Button
                color='primary'
                size='sm'
                variant='light'
                isIconOnly
                onPress={async () => {
                  try {
                    await copy(item.chatId?.toString() || '')
                    toast.success('Chat ID скопирован в буфер обмена')
                  } catch (error) {
                    toast.error('Не удалось скопировать Chat ID')
                  }
                }}
              >
                <TbCopy className='text-lg' />
              </Button>
            )}
            <div>{item.chatId}</div>
          </div>
        )
      }

      if (columnKey === 'phone' + search) {
        return (
          <div className='flex items-center space-x-2'>
            <Button
              color='primary'
              size='sm'
              variant='light'
              isIconOnly
              onPress={async () => {
                try {
                  await copy(item.phone)
                  toast.success('Номер телефона скопирован в буфер обмена')
                } catch (error) {
                  toast.error('Не удалось скопировать номер телефона')
                }
              }}
            >
              <TbCopy className='text-lg' />
            </Button>
            <div>{formatRussianPhoneNumber(item.phone)}</div>
          </div>
        )
      }

      if (columnKey === 'inChat' + search) {
        return <InChatUserCheckbox item={item} />
      }
    },
    [copy, search],
  )

  const usersForList = useMemo(
    () =>
      users
        ? users[0]
            .sort((a, b) => {
              if (
                chat?.users?.some(u => u.id === b.id) &&
                !chat.users?.some(u => u.id === a.id)
              )
                return 0
              return -1
            })
            .filter(
              u =>
                u.name.toLowerCase().includes(search.toLowerCase()) ||
                u.surname.toLowerCase().includes(search.toLowerCase()) ||
                u.organization.name
                  .toLowerCase()
                  .includes(search.toLowerCase()),
            )
        : [],
    [chat?.users, search, users],
  )

  return (
    <div className='space-y-4'>
      <Input
        variant='bordered'
        size='sm'
        color='primary'
        aria-label='Найти по имени, фамилии, организации'
        placeholder='Найти по имени, фамилии, организации'
        value={search}
        onValueChange={setSearch}
      />
      <SimpleBar className='h-[calc(100vh-356px)] overflow-auto' key={search}>
        <Table
          aria-label='Пользователи чата'
          isHeaderSticky
          isStriped
          removeWrapper
        >
          <TableHeader
            columns={[
              {
                key: 'inChat' + search,
                label: 'В чате',
              },
              {
                key: 'name' + search,
                label: 'ФИО',
              },
              {
                key: 'telegram' + search,
                label: 'Telegram username',
              },
              {
                key: 'chatId' + search,
                label: 'Chat ID',
              },
              {
                key: 'phone' + search,
                label: 'Номер телефона',
              },
              {
                key: 'organization' + search,
                label: 'Организация',
              },
            ]}
          >
            {column => (
              <TableColumn key={column.key} textValue={column.label}>
                {column.label}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody
            items={usersForList}
            emptyContent={
              <div className='text-default-400 text-sm'>Нет данных</div>
            }
          >
            {item => (
              <TableRow key={item.id + search} textValue={item.id}>
                {columnKey => (
                  <TableCell>{renderUserCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </SimpleBar>
    </div>
  )
}
