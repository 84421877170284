export const linkFormatConst = {
  video: 'Ролик',
  photo: 'Фотопост',
  lom: 'ЛОМ',
  telegram: 'Телеграм',
  card: 'Карточка',
  cards: 'Карточки',
  text: 'Текст',
  broadcasting: 'Эфир',
  mem: 'Мем',
  smi: 'СМИ',
} as const
