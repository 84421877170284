import { Grant } from '@hydra/interfaces'
import { BiCollection, BiTask } from 'react-icons/bi'
import { TbArticle, TbCalendarFilled, TbFolder, TbTags } from 'react-icons/tb'

import { routesConst } from '../../../features/constants/routes.const'

export const menuItems = [
  {
    title: 'Задачи',
    link: routesConst.tasks,
    icon: <BiTask />,
    grant: [Grant.tasks],
  },
  {
    title: 'Проекты',
    link: routesConst.collections,
    icon: <BiCollection />,
    grant: [Grant.tasks],
  },
  {
    title: 'Календарь',
    link: routesConst.tasksCalendar,
    icon: <TbCalendarFilled />,
    grant: [Grant.tasks],
  },
  {
    title: 'Папки',
    link: routesConst.folders,
    icon: <TbFolder />,
    grant: [Grant.tasks],
  },
  {
    title: 'Статьи',
    link: routesConst.articles,
    icon: <TbArticle />,
    grant: [Grant.tasks],
  },
  {
    title: 'Тэги',
    link: routesConst.tags,
    icon: <TbTags />,
    grant: [Grant.tasks],
  },
]
