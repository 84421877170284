import { Grant } from '@hydra/interfaces'
import { useAtom } from 'jotai'
import { useMemo } from 'react'

import { grantsAtom } from '../store'

export const useGrant = (
  grant: Grant | Grant[] | undefined,
): { isValidGrant: boolean } => {
  const [{ data }] = useAtom(grantsAtom)

  const isValidGrant = useMemo(() => {
    if (!data?.grants) return false

    if (Array.isArray(grant)) {
      return grant.some(g => data.grants.includes(g))
    } else if (grant) {
      return data.grants.includes(grant)
    } else {
      return true
    }
  }, [grant, data?.grants])

  return {
    isValidGrant,
  }
}
