import { Link } from '@nextui-org/react'
import {
  CommentProvider,
  useCommentById,
  useCommentItemContentState,
} from '@udecode/plate-comments'
import { formatDistance } from 'date-fns'
import { ru } from 'date-fns/locale/ru'
import React from 'react'

import { routesConst } from '../../../../constants/routes.const'
import { CommentMoreDropdown } from './comment-more-dropdown'
import { CommentResolveButton } from './comment-resolve-button'
import { CommentValue } from './comment-value'

type PlateCommentProps = {
  commentId: string
}

function CommentItemContent() {
  const {
    comment,
    isMyComment,
    isReplyComment,
    user,
    editingValue,
    commentText,
  } = useCommentItemContentState()

  return (
    <div>
      <div className='relative flex items-center gap-2'>
        <div className='flex flex-col'>
          <Link className='text-sm' href={routesConst.users + '/' + user?.id}>
            {user?.name}
          </Link>

          <div className='text-default-400 text-xs leading-none'>
            {formatDistance(comment.createdAt, Date.now(), {
              locale: ru,
            })}
          </div>
        </div>

        {isMyComment && (
          <div className='absolute -right-0.5 -top-0.5 flex space-x-1'>
            {isReplyComment ? null : <CommentResolveButton />}

            <CommentMoreDropdown />
          </div>
        )}
      </div>

      <div className='mb-4 pt-2'>
        {editingValue ? (
          <CommentValue />
        ) : (
          <div className='whitespace-pre-wrap text-sm'>{commentText}</div>
        )}
      </div>
    </div>
  )
}

export function CommentItem({ commentId }: PlateCommentProps) {
  const comment = useCommentById(commentId)

  if (!comment) return null

  return (
    <CommentProvider key={commentId} id={commentId}>
      <CommentItemContent />
    </CommentProvider>
  )
}
