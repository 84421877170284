import { Card, Link } from '@nextui-org/react'
import { Dayjs } from 'dayjs'
import { cloneElement } from 'react'
import SimpleBar from 'simplebar-react'

import { extendedDayjs } from '../../../utils/dayjsConfig'
import { Items } from './types'

interface Props {
  items: Items
  currentDay: Dayjs
  index: number
}

export const WeekViewColumn = ({ items, currentDay, index }: Props) => {
  return (
    <SimpleBar className='border-default-200 h-[calc(100vh-200px)] rounded-lg border p-1'>
      {items
        .filter(item =>
          extendedDayjs(currentDay.weekday(index)).isSame(
            extendedDayjs(item.deadline),
            'date',
          ),
        )
        .map(item => (
          <Card
            as={Link}
            href={item.link}
            radius='sm'
            shadow='none'
            isBlurred
            key={item.id}
            className='border-default-200 bg-default-50 mt-1 items-start  space-y-1 border p-2 transition-all first:mt-0'
          >
            {!item.isAllDay && extendedDayjs(item.deadline).format('HH:mm')}
            {item.status && cloneElement(item.status)}
            <span className='break-words text-xs'>{item.title}</span>
          </Card>
        ))}
    </SimpleBar>
  )
}
