import { ArticleInterface } from '@hydra/interfaces'
import {
  Button,
  Chip,
  Input,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { TbSquareRoundedPlus, TbTrash } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { routesConst } from '../../../features/constants/routes.const'
import {
  DeleteModal,
  Empty,
  Header,
  Layout,
  PageSpinner,
} from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { updateArticle } from '../api/articles.api'
import { deleteFolder, updateFolder } from '../api/folders.api'
import { menuItems } from '../constants/menuItems'
import { folderAtom, folderIdAtom } from '../store/folders.store'
import { tagsAtom } from '../store/tags.store'

const columns = [
  {
    key: 'title',
    label: 'Название статьи',
  },
  {
    key: 'createdAt',
    label: 'Дата создания',
  },
  {
    key: 'tags',
    label: 'Тэги',
  },
  {
    key: 'actions',
    label: '',
    width: 50,
  },
]

const pageTitle = 'Папка'

export const Folder = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [, setId] = useAtom(folderIdAtom)
  const [{ data, isPending, refetch }] = useAtom(folderAtom)
  const [{ data: tags }] = useAtom(tagsAtom)
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
    onClose: onDeleteClose,
  } = useDisclosure()

  const renderCell = useCallback(
    (item: ArticleInterface, columnKey: Key) => {
      if (columnKey === 'title') {
        return <div className='line-clamp-1'>{item.title}</div>
      }
      if (columnKey === 'createdAt') {
        return (
          <div>
            {getPrettyDate({
              date: item.createdAt,
            })}
          </div>
        )
      }
      if (columnKey === 'tags') {
        return item.tags && item.tags.length > 0 ? (
          <div className='flex flex-wrap gap-1'>
            {item.tags.map(tag => (
              <Chip key={tag} size='sm' color='secondary' variant='faded'>
                {tags?.find(t => t.id === tag)?.title}
              </Chip>
            ))}
          </div>
        ) : null
      }
      if (columnKey === 'actions') {
        return (
          <Button
            isIconOnly
            size='sm'
            color='danger'
            variant='light'
            onPress={async () => {
              await updateArticle(item.id, {
                folder: null,
              })
              await refetch()
            }}
          >
            <TbTrash />
          </Button>
        )
      }
    },
    [refetch, tags],
  )

  useEffect(() => {
    if (id) {
      setId(id)
    }
    return () => {
      setId(undefined)
    }
  }, [id, setId])

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <div>
                <Button
                  color='danger'
                  startContent={<TbTrash className='text-2xl' />}
                  onPress={onDeleteOpen}
                >
                  Удалить папку
                </Button>
              </div>
            }
          />
        }
      >
        {isPending ? (
          <PageSpinner />
        ) : !data ? (
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <Empty />
          </div>
        ) : (
          <div className='space-y-4 p-4'>
            <div className='flex justify-between space-x-4'>
              <div className='flex-1'>
                <Input
                  size='sm'
                  defaultValue={data.title}
                  variant='underlined'
                  onValueChange={value =>
                    updateFolder(data.id, { title: value })
                  }
                />
              </div>
              <div>
                <Button
                  color='primary'
                  startContent={<TbSquareRoundedPlus className='text-2xl' />}
                  onPress={() => {
                    const params = new URLSearchParams({
                      folderId: data.id || '',
                    })
                    navigate(
                      routesConst.createArticle + '?' + params.toString(),
                    )
                  }}
                >
                  Новая статья
                </Button>
              </div>
            </div>
            <Table
              aria-label='Задачи'
              isHeaderSticky
              isStriped
              removeWrapper
              onRowAction={key => navigate(routesConst.articles + '/' + key)}
              classNames={{
                base: 'h-[calc(100vh-160px)] overflow-auto',
              }}
            >
              <TableHeader columns={columns}>
                {column => (
                  <TableColumn width={column.width} key={column.key}>
                    {column.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody
                items={data.articles || []}
                emptyContent={
                  <div className='text-default-400 text-sm'>Нет данных</div>
                }
              >
                {item => (
                  <TableRow key={item.id}>
                    {columnKey => (
                      <TableCell>{renderCell(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}
      </Layout>

      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        onClose={onDeleteClose}
        message={`Вы уверены что хотите удалить папку? Все привязанные статьи в папке будут перемещены в раздел "Без папки" и станут публичными.`}
        deleteAction={async () => {
          await deleteFolder(id || '')
          navigate(-1)
          toast.success('Папка удалена')
        }}
      />
    </>
  )
}
