import { AreaInterface } from '@hydra/interfaces'
import {
  Button,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback, useState } from 'react'
import { TbSquareRoundedPlus, TbTrash } from 'react-icons/tb'

import { deleteArea } from '../../../features/api'
import { areaConst } from '../../../features/constants/area.const'
import { organizationByIdAtom } from '../../../features/store'
import { DeleteModal, Empty } from '../../../features/ui'
import { CreateAreaModal } from './CreateAreaModal'

export const OrganizationAreas = () => {
  const [{ data, refetch }] = useAtom(organizationByIdAtom)
  const [areaId, setAreaId] = useState('')

  const {
    isOpen: isOpenCreateAreaModal,
    onOpen: onOpenCreateAreaModal,
    onOpenChange: onOpenChangeCreateAreaModal,
    onClose: onCloseCreateAreaModal,
  } = useDisclosure()

  const {
    isOpen: isOpenDeleteAreaModal,
    onOpen: onOpenDeleteAreaModal,
    onOpenChange: onOpenChangeDeleteAreaModal,
    onClose: onCloseDeleteAreaModal,
  } = useDisclosure()

  const handleDeleteArea = useCallback(async () => {
    await deleteArea(areaId)
    onCloseDeleteAreaModal()
    setAreaId('')
    refetch()
  }, [areaId, onCloseDeleteAreaModal, refetch])

  const renderAreaCell = useCallback(
    (area: AreaInterface, columnKey: Key) => {
      switch (columnKey) {
        case 'title':
          return area.title
        case 'link':
          return (
            <Link
              size='sm'
              isBlock
              isExternal
              href={area.link}
              target='_blank'
              rel='noopener noreferrer'
            >
              {area.link}
            </Link>
          )
        case 'type':
          return (
            <Chip size='sm' variant='flat'>
              {areaConst[area.type]}
            </Chip>
          )
        case 'actions':
          return (
            <div className='flex justify-end'>
              <Button
                isIconOnly
                size='sm'
                variant='light'
                color='danger'
                onPress={() => {
                  setAreaId(area.id)
                  onOpenDeleteAreaModal()
                }}
              >
                <TbTrash className='text-xl' />
              </Button>
            </div>
          )
        default:
          return
      }
    },
    [onOpenDeleteAreaModal],
  )

  if (!data) return <Empty />

  return (
    <>
      <CreateAreaModal
        organizationId={data.id}
        isOpen={isOpenCreateAreaModal}
        onOpenChange={onOpenChangeCreateAreaModal}
        onClose={onCloseCreateAreaModal}
        refetch={refetch}
      />

      <DeleteModal
        isOpen={isOpenDeleteAreaModal}
        onOpenChange={onOpenChangeDeleteAreaModal}
        deleteAction={handleDeleteArea}
        onClose={onCloseDeleteAreaModal}
        message='Удалить площадку?'
      />

      <div className='space-y-4'>
        <div className='flex justify-end'>
          <Button
            size='sm'
            color='primary'
            startContent={<TbSquareRoundedPlus className='text-lg' />}
            onPress={onOpenCreateAreaModal}
          >
            Добавить
          </Button>
        </div>
        <Table
          aria-label='Площадки организации'
          isHeaderSticky
          isStriped
          removeWrapper
          classNames={{
            base: 'h-[calc(100vh-208px)] overflow-auto',
          }}
        >
          <TableHeader>
            <TableColumn key='title'>Название</TableColumn>
            <TableColumn key='link'>Ссылка</TableColumn>
            <TableColumn key='type'>Тип</TableColumn>
            <TableColumn key='actions'>
              <div />
            </TableColumn>
          </TableHeader>
          <TableBody
            items={data.areas}
            emptyContent={
              <div className='text-default-400 text-sm'>Не указаны</div>
            }
          >
            {item => (
              <TableRow key={item.id}>
                {columnKey => (
                  <TableCell>{renderAreaCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
