export const grantConst = {
  newsbreaksModerator: 'Модератор инфоповодов',
  newsbreaksCategoryModerator: 'Модератор категории инфоповодов',
  newsbreaksProvider: 'Поставщик инфоповодов',
  newsbreaksEditor: 'Выпускающий редактор инфоповодов',
  tasks: 'Задачи',
  publics: 'Инфокарта',
  riskModerator: 'Модератор рисков',
  riskProvider: 'Поставщик рисков',
  superadmin: 'Суперадмин',
} as const
