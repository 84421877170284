import { useAtom } from 'jotai/index'

import {
  ratingHalfYearAtom,
  ratingMonthAtom,
  ratingPeriodAtom,
  ratingQuarterAtom,
  ratingYearAtom,
} from '../../../features/store'
import { extendedDayjs } from '../../../features/utils/dayjsConfig'

export const useRatingPeriodMask = () => {
  const [ratingPeriod] = useAtom(ratingPeriodAtom)
  const [year] = useAtom(ratingYearAtom)
  const [halfYear] = useAtom(ratingHalfYearAtom)
  const [quarter] = useAtom(ratingQuarterAtom)
  const [month] = useAtom(ratingMonthAtom)

  const getPeriod = (): string => {
    let value = `${year} год`

    if (ratingPeriod === 'halfYear') {
      value = `${halfYear} полугодие ${year} года`
    }

    if (ratingPeriod === 'quarter') {
      value = `${quarter} квартал ${year} года`
    }

    if (ratingPeriod === 'month') {
      value = `${extendedDayjs().month(+month).format('MMMM')} ${year} года`
    }

    return value
  }

  return getPeriod()
}
