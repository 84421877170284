import { CommentsProvider, TComment } from '@udecode/plate-comments'
import { useAtom } from 'jotai'
import React, { PropsWithChildren } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { allUsersAtom, profileAtom } from '../../../store'

interface Props extends PropsWithChildren {
  comments?: Record<string, TComment>
}

export const PlateEditorProvider = ({ children, comments }: Props) => {
  const [{ data: user }] = useAtom(profileAtom)
  const [{ data: users }] = useAtom(allUsersAtom)

  if (!user) return null
  if (!users) return null

  const mappedUsers = users[0].reduce((acc, user) => {
    const newUser = {
      [user.id]: { id: user.id, name: user.name + ' ' + user.surname },
    }
    return Object.assign(acc, newUser)
  }, {})

  return (
    <DndProvider backend={HTML5Backend}>
      <CommentsProvider
        users={mappedUsers}
        myUserId={user.profile.id}
        comments={comments}
      >
        {children}
      </CommentsProvider>
    </DndProvider>
  )
}
