import { Tab, Tabs } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useSearchParams } from 'react-router-dom'

import {
  organizationByIdAtom,
  organizationIdAtom,
} from '../../../features/store'
import { Empty, Layout, PageSpinner } from '../../../features/ui'
import { OrganizationAreas } from '../components/OrganizationAreas'
import { OrganizationLocations } from '../components/OrganizationLocations'
import { OrganizationMainInfo } from '../components/OrganizationMainInfo'
import { OrganizationRating } from '../components/OrganizationRating'
import { OrganizationUsers } from '../components/OrganizationUsers'
import { menuItems } from '../constants/menuItems'

export const Organization = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultTab = useMemo(
    () => searchParams.get('tab') || 'rating',
    [searchParams],
  )
  const [selected, setSelected] = useState<string>(defaultTab)
  const { id } = useParams()
  const [, setOrganizationId] = useAtom(organizationIdAtom)
  const [{ data, isPending }] = useAtom(organizationByIdAtom)

  useEffect(() => {
    setSearchParams({ tab: selected })
    if (id) {
      setOrganizationId(id)
    }
    return () => {
      setOrganizationId(undefined)
    }
  }, [id, selected, setOrganizationId, setSearchParams])

  if (isPending) return <PageSpinner />

  if (!data) return <Empty />

  return (
    <>
      <Helmet>
        <title>Гидра | Страница организации</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <div className='flex h-full items-center justify-between space-x-4 px-4'>
            <h1 className='flex-1 text-3xl font-semibold'>
              {data.name || 'Страница организации'}
            </h1>
          </div>
        }
      >
        <div className='p-4'>
          <Tabs
            selectedKey={selected}
            onSelectionChange={e => setSelected(e as string)}
            aria-label='Табы организации'
            classNames={{
              panel: '!pt-4 !px-0 !pb-0',
            }}
          >
            <Tab key='main' title='Основная информация'>
              <OrganizationMainInfo />
            </Tab>

            <Tab key='locations' title='Места публикации'>
              <OrganizationLocations />
            </Tab>

            <Tab key='areas' title='Площадки'>
              <OrganizationAreas />
            </Tab>

            <Tab key='users' title='Сотрудники'>
              <OrganizationUsers />
            </Tab>

            {data.group && (
              <Tab key='rating' title='Рейтинг'>
                <OrganizationRating />
              </Tab>
            )}
          </Tabs>
        </div>
      </Layout>
    </>
  )
}
