import { cn } from '@udecode/cn'
import {
  useCommentDeleteButton,
  useCommentDeleteButtonState,
  useCommentEditButton,
  useCommentEditButtonState,
} from '@udecode/plate-comments'
import React from 'react'

import { Icons } from '../icons'
import { Button } from './button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './dropdown-menu'

export function CommentMoreDropdown() {
  const editButtonState = useCommentEditButtonState()
  const { props: editProps } = useCommentEditButton(editButtonState)
  const deleteButtonState = useCommentDeleteButtonState()
  const { props: deleteProps } = useCommentDeleteButton(deleteButtonState)

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant='ghost' className={cn('text-default-400 h-6 p-1')}>
          <Icons.more className='size-4' />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem {...editProps}>Редактировать</DropdownMenuItem>
        <DropdownMenuItem {...deleteProps}>Удалить</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
