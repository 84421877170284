import { Grant } from '@hydra/interfaces'
import { ScrollShadow } from '@nextui-org/react'
import { Helmet } from 'react-helmet'

import { Grants } from '../../../features/grants'
import { Header, Layout } from '../../../features/ui'
import { NewsbreaksWithReportsList } from '../components/NewsbreaksWithReportsList'
import { NewsbreaksWithReportsPagination } from '../components/NewsbreaksWithReportsPagination'
import { ReportsFilters } from '../components/ReportsFilters'
import { menuItems } from '../constants/menuItems'

export const Reports = () => {
  return (
    <>
      <Helmet>
        <title>Гидра | Отчеты</title>
      </Helmet>
      <Grants
        grants={[
          Grant.newsbreaksModerator,
          Grant.newsbreaksProvider,
          Grant.newsbreaksEditor,
          Grant.newsbreaksCategoryModerator,
        ]}
      >
        <Layout menuItems={menuItems} headerContent={<Header text='Отчеты' />}>
          <div className='space-y-4 overflow-hidden p-4'>
            <div className='flex space-x-4'>
              <ScrollShadow
                size={0}
                className='h-[calc(100vh-156px)] flex-1 overflow-auto'
              >
                <NewsbreaksWithReportsList />
              </ScrollShadow>
              <ReportsFilters />
            </div>
            <NewsbreaksWithReportsPagination />
          </div>
        </Layout>
      </Grants>
    </>
  )
}
