import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { directionAtom, orderAtom } from '../store/newsbreaks.store'

export const NewsbreaksSort = () => {
  const [order, setOrder] = useAtom(orderAtom)
  const [direction, setDirection] = useAtom(directionAtom)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='bg-background rounded-xl px-4 py-[6px] text-sm shadow'>
      <div className='flex space-x-2'>
        <span>Сортировать по:</span>
        <Popover
          placement='bottom-end'
          showArrow
          backdrop='opaque'
          isOpen={isOpen}
          onOpenChange={open => setIsOpen(open)}
        >
          <PopoverTrigger>
            <span className='text-primary flex cursor-pointer items-center space-x-1'>
              {order === 'createdAt' &&
                direction === 'desc' &&
                'дате создания: сначала новые'}
              {order === 'createdAt' &&
                direction === 'asc' &&
                'дате создания: сначала старые'}
              {order === 'deadline' &&
                direction === 'desc' &&
                'дате реализации: по убыванию'}
              {order === 'deadline' &&
                direction === 'asc' &&
                'дате реализации: по возрастанию'}
              <TbChevronDown
                className={clsx('transition-all', { 'rotate-180': isOpen })}
              />
            </span>
          </PopoverTrigger>
          <PopoverContent>
            <div className='flex w-[300px] flex-col space-y-2 py-2'>
              <div
                onClick={() => {
                  setOrder('createdAt')
                  setDirection('desc')
                }}
                className={clsx(
                  'hover:bg-secondary cursor-pointer rounded-lg p-2 transition-colors hover:text-white',
                  {
                    'bg-secondary-200':
                      order === 'createdAt' && direction === 'desc',
                  },
                )}
              >
                Дата создания: сначала новые
              </div>
              <div
                onClick={() => {
                  setOrder('createdAt')
                  setDirection('asc')
                }}
                className={clsx(
                  'hover:bg-secondary cursor-pointer rounded-lg p-2 transition-colors hover:text-white',
                  {
                    'bg-secondary-200':
                      order === 'createdAt' && direction === 'asc',
                  },
                )}
              >
                Дата создания: сначала старые
              </div>
              <div
                onClick={() => {
                  setOrder('deadline')
                  setDirection('desc')
                }}
                className={clsx(
                  'hover:bg-secondary cursor-pointer rounded-lg p-2 transition-colors hover:text-white',
                  {
                    'bg-secondary-200':
                      order === 'deadline' && direction === 'desc',
                  },
                )}
              >
                Дата реализации: по убыванию
              </div>
              <div
                onClick={() => {
                  setOrder('deadline')
                  setDirection('asc')
                }}
                className={clsx(
                  'hover:bg-secondary cursor-pointer rounded-lg p-2 transition-colors hover:text-white',
                  {
                    'bg-secondary-200':
                      order === 'deadline' && direction === 'asc',
                  },
                )}
              >
                Дата реализации: по возрастанию
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}
