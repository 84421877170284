import { Grant, RiskStatusEnum } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
} from '@nextui-org/react'
import { useAsyncList } from '@react-stately/data'
import axios from 'axios'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai/index'
import { Key, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useGrant } from '../../../features/grants'
import { allLocationsAtom } from '../../../features/store'
import { DateAndTimePicker } from '../../../features/ui/components/DateAndTimePicker'
import { updateRisk } from '../api/risk.api'
import { chatsAtom } from '../store/chat.store'
import {
  riskAtom,
  riskImportancesAtom,
  riskThemesAtom,
  riskTypesAtom,
} from '../store/risk.store'

interface Props {
  onClose: () => void
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const EditRiskModal = ({ isOpen, onOpenChange, onClose }: Props) => {
  const { isValidGrant: isModerator } = useGrant([Grant.riskModerator])
  const [title, setTitle] = useState('')
  const [date, setDate] = useState('')
  const [isPlan, setIsPlan] = useState(false)
  const [location, setLocation] = useState<Key | null | undefined>(null)
  const [theme, setTheme] = useState<Key | null | undefined>(null)
  const [type, setType] = useState<Key | null | undefined>(null)
  const [importance, setImportance] = useState<Key | null | undefined>(null)
  const [chat, setChat] = useState<Key | null | undefined>(null)
  const [address, setAddress] = useState<Key | null | undefined>(null)
  const [isEditLoading, setIsEditLoading] = useState(false)
  const { data: risk, refetch } = useAtomValue(riskAtom)
  const { data: chats, isPending: isChatsPending } = useAtomValue(chatsAtom)

  const [{ data: locations, isPending: isPendingLocations }] =
    useAtom(allLocationsAtom)

  const { data: themes, isPending: isThemesPending } =
    useAtomValue(riskThemesAtom)

  const { data: importances, isPending: isImportancesPending } =
    useAtomValue(riskImportancesAtom)

  const { data: types, isPending: isTypesPending } = useAtomValue(riskTypesAtom)

  const onEditButtonPress = async () => {
    if (!risk) return null

    if (title === '') {
      return toast.error('Название не может быть пустым')
    }

    setIsEditLoading(true)

    await updateRisk(risk.id, {
      title,
      isPlan,
      date: date !== '' ? date : undefined,
      address: address as string,
      location: location
        ? {
            id: location as string,
          }
        : undefined,
      theme: theme
        ? {
            id: theme as string,
          }
        : undefined,
      type: type
        ? {
            id: type as string,
          }
        : undefined,
      importance: importance
        ? {
            id: importance as string,
          }
        : undefined,
      chat: chat
        ? {
            id: chat as string,
          }
        : undefined,
    })
    await refetch()
    setIsEditLoading(false)
    onClose()
  }

  const list = useAsyncList({
    load: async ({ signal, filterText, items }) => {
      if (filterText && filterText.length % 3 !== 0) return { items }

      const { data } = await axios.post(
        `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
        { query: filterText },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token ' + import.meta.env.VITE_DADATA_TOKEN,
          },
          signal: signal,
        },
      )

      return {
        items: data.suggestions.map((el: { value: string }) => el.value),
      }
    },
  })

  useEffect(() => {
    if (risk) {
      setTitle(risk.title)
      setIsPlan(risk.isPlan)
      risk.date && setDate(dayjs(risk.date).toString())
      risk.location && setLocation(risk.location.id)
      risk.theme && setTheme(risk.theme.id)
      risk.type && setType(risk.type.id)
      risk.chat && setChat(risk.chat.id)
      risk.importance && setImportance(risk.importance.id)
      risk.address && setAddress(risk.address)
    }
  }, [risk])

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} size='2xl'>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              Редактирование риска
            </ModalHeader>
            <ModalBody>
              {risk &&
                ![RiskStatusEnum.current, RiskStatusEnum.archive].includes(
                  risk.status,
                ) && (
                  <Switch
                    size='sm'
                    onValueChange={setIsPlan}
                    isSelected={isPlan}
                  >
                    {isPlan ? 'Плановый риск' : 'Срочный риск'}
                  </Switch>
                )}
              {risk &&
                ![RiskStatusEnum.current, RiskStatusEnum.archive].includes(
                  risk.status,
                ) && (
                  <Input
                    variant='bordered'
                    label='Название'
                    value={title}
                    onValueChange={setTitle}
                  />
                )}
              {risk &&
                ![RiskStatusEnum.current, RiskStatusEnum.archive].includes(
                  risk.status,
                ) && (
                  <DateAndTimePicker
                    onChange={setDate}
                    label='Дата наступления риска'
                    isTimeShow={true}
                    value={dayjs(date).isValid() ? date : ''}
                  />
                )}
              {risk &&
                ![RiskStatusEnum.current, RiskStatusEnum.archive].includes(
                  risk.status,
                ) && (
                  <Autocomplete
                    isDisabled={isPendingLocations}
                    aria-label='Локация'
                    isLoading={isPendingLocations}
                    variant='bordered'
                    defaultItems={locations ? locations[0] : []}
                    size='sm'
                    label='Локация'
                    onSelectionChange={setLocation}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    selectedKey={location}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id} textValue={item.title}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              {risk &&
                ![RiskStatusEnum.current, RiskStatusEnum.archive].includes(
                  risk.status,
                ) && (
                  <Autocomplete
                    aria-label='Адрес'
                    inputValue={list.filterText}
                    isLoading={list.isLoading}
                    className='flex-1'
                    items={
                      list.items
                        ? Object.entries(list.items).map(([key, value]) => ({
                            label: key,
                            value,
                          }))
                        : []
                    }
                    label='Адрес'
                    variant='bordered'
                    onSelectionChange={setAddress}
                    onInputChange={list.setFilterText}
                  >
                    {item => (
                      <AutocompleteItem
                        key={String(item.value)}
                        className='capitalize'
                      >
                        {String(item.value)}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              {risk &&
                ![RiskStatusEnum.current, RiskStatusEnum.archive].includes(
                  risk.status,
                ) && (
                  <Autocomplete
                    aria-label='Тема риска'
                    isLoading={isThemesPending}
                    variant='bordered'
                    defaultItems={themes ? themes : []}
                    label='Тема риска'
                    onSelectionChange={setTheme}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    selectedKey={theme} // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              {risk &&
                ![RiskStatusEnum.current, RiskStatusEnum.archive].includes(
                  risk.status,
                ) && (
                  <Autocomplete
                    aria-label='Тип риска'
                    isLoading={isTypesPending}
                    variant='bordered'
                    defaultItems={types ? types : []}
                    label='Тип риска'
                    onSelectionChange={setType}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    selectedKey={type} // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              {isModerator && (
                <Autocomplete
                  aria-label='Важность риска'
                  isLoading={isImportancesPending}
                  variant='bordered'
                  defaultItems={importances ? importances : []}
                  label='Важность риска'
                  onSelectionChange={setImportance}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  selectedKey={importance} // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  onKeyDown={e => e.continuePropagation()}
                >
                  {item => (
                    <AutocompleteItem key={item.id}>
                      {item.title}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
              )}
              {risk &&
                ![RiskStatusEnum.current, RiskStatusEnum.archive].includes(
                  risk.status,
                ) && (
                  <Autocomplete
                    aria-label='Чат риска'
                    isLoading={isChatsPending}
                    variant='bordered'
                    defaultItems={chats ? chats[0] : []}
                    label='Чат риска'
                    onSelectionChange={setChat}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    selectedKey={chat} // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
            </ModalBody>
            <ModalFooter>
              <Button variant='light' onPress={onClose}>
                Закрыть
              </Button>
              <Button
                isLoading={isEditLoading}
                color='primary'
                isDisabled={isEditLoading}
                onPress={onEditButtonPress}
              >
                Сохранить
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
