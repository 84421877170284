import { Grant, NewsbreakStatusEnum } from '@hydra/interfaces'
import { Button, Divider } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { equals } from 'ramda'
import { useCallback, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { IoCloseCircle } from 'react-icons/io5'
import { TbChevronDown, TbChevronUp, TbExternalLink } from 'react-icons/tb'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { useCopyToClipboard } from 'usehooks-ts'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants, useGrant } from '../../../features/grants'
import { profileAtom } from '../../../features/store'
import {
  Empty,
  Header,
  Layout,
  PageSpinner,
  PlateEditorWrapper,
} from '../../../features/ui'
import { updateNewsbreak } from '../api/newsbreaks.api'
import { Actions } from '../components/Actions'
import { Comments } from '../components/Comments'
import { MainInfo } from '../components/MainInfo'
import { NewsbreakFiles } from '../components/NewsbreakFiles'
import { ReportsComplete } from '../components/ReportsComplete'
import { Users } from '../components/Users'
import { menuItems } from '../constants/menuItems'
import { newsbreakAtom, newsbreakIdAtom } from '../store/newsbreaks.store'

export const Newsbreak = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const [, copy] = useCopyToClipboard()
  const [, setNewsbreakId] = useAtom(newsbreakIdAtom)
  const [{ data, isPending, refetch }] = useAtom(newsbreakAtom)
  const [{ data: profile }] = useAtom(profileAtom)
  const { isValidGrant: isEditor } = useGrant([Grant.newsbreaksEditor])

  const { isValidGrant: isModeratorOrProvider } = useGrant([
    Grant.newsbreaksModerator,
    Grant.newsbreaksProvider,
    Grant.newsbreaksCategoryModerator,
  ])

  const { isValidGrant: isModerator } = useGrant([
    Grant.newsbreaksModerator,
    Grant.newsbreaksCategoryModerator,
  ])

  const canEditNewsbreak = useMemo(
    () =>
      isModeratorOrProvider ||
      profile?.profile.id === data?.current.author?.id ||
      profile?.profile.id === data?.current.coAuthor?.id,
    [data, isModeratorOrProvider, profile?.profile.id],
  )

  const isReadonlyEditor = useMemo(
    () =>
      data &&
      (!canEditNewsbreak ||
        isEditor ||
        [
          NewsbreakStatusEnum.inWork,
          NewsbreakStatusEnum.ok,
          NewsbreakStatusEnum.canceled,
          NewsbreakStatusEnum.deleted,
          NewsbreakStatusEnum.done,
        ].includes(data.current.status)),
    [canEditNewsbreak, data, isEditor],
  )

  const onButtonPress = useCallback(async () => {
    await copy(
      window.location.origin +
        location.pathname +
        location.search +
        location.hash,
    )
    toast.success('Ссылка скопирована в буфер обмена')
  }, [copy, location.pathname, location.search, location.hash])

  const onPrevNewsbreak = useCallback(() => {
    if (data?.prev) navigate(routesConst.newsbreaks + '/' + data.prev)
  }, [data?.prev, navigate])

  const onNextNewsbreak = useCallback(() => {
    if (data?.next) navigate(routesConst.newsbreaks + '/' + data.next)
  }, [data?.next, navigate])

  useEffect(() => {
    if (id) {
      setNewsbreakId(id)
    }
    return () => {
      setNewsbreakId(undefined)
    }
  }, [id, setNewsbreakId])

  return (
    <>
      <Helmet>
        <title>Гидра | Инфоповод</title>
      </Helmet>
      <Grants
        grants={[
          Grant.newsbreaksModerator,
          Grant.newsbreaksCategoryModerator,
          Grant.newsbreaksProvider,
          Grant.newsbreaksEditor,
        ]}
      >
        <Layout
          menuItems={menuItems}
          headerContent={
            <Header text={`Инфоповод: ${data ? data.current.title : ''}`} />
          }
        >
          {isPending ? (
            <PageSpinner />
          ) : !data ? (
            <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
              <Empty />
            </div>
          ) : (
            <div className='p-4'>
              <div className='bg-background h-[calc(100vh-104px)] overflow-hidden rounded-xl shadow'>
                <div className='space-y-4 p-4'>
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center space-x-4'>
                      {isModerator && (
                        <div className='flex space-x-1'>
                          <Button
                            isIconOnly
                            variant='bordered'
                            color='default'
                            size='sm'
                            isDisabled={
                              !data.next || data.next === data.current.id
                            }
                            onPress={onNextNewsbreak}
                          >
                            <TbChevronUp className='text-primary text-lg' />
                          </Button>
                          <Button
                            isIconOnly
                            variant='bordered'
                            color='default'
                            size='sm'
                            isDisabled={
                              !data.prev || data.prev === data.current.id
                            }
                            onPress={onPrevNewsbreak}
                          >
                            <TbChevronDown className='text-primary text-lg' />
                          </Button>
                        </div>
                      )}
                      <div>
                        <Button
                          variant='light'
                          color='primary'
                          endContent={<TbExternalLink className='text-lg' />}
                          onPress={onButtonPress}
                        >
                          Копировать ссылку
                        </Button>
                      </div>
                    </div>
                    <div>
                      {isModerator && (
                        <Button
                          onPress={() => {
                            navigate(routesConst.log + '/' + id)
                          }}
                          color='primary'
                          className='mr-2'
                          variant='bordered'
                        >
                          Журнал
                        </Button>
                      )}
                      <Button
                        onPress={() => {
                          navigate(routesConst.newsbreaks)
                        }}
                        color='primary'
                        endContent={<IoCloseCircle className='text-xl' />}
                      >
                        Закрыть
                      </Button>
                    </div>
                  </div>

                  <Divider />

                  <div className='grid grid-cols-[60%_1px_1fr] gap-4'>
                    <div className='space-y-4'>
                      <div className='flex space-x-4'>
                        <div className='flex-1 items-start text-lg font-semibold'>
                          {data.current.title}
                        </div>
                      </div>
                      <SimpleBar className='h-[calc(100vh-265px)]'>
                        <div className='tiptap space-y-2'>
                          <PlateEditorWrapper
                            withComments={!isReadonlyEditor}
                            initialValue={
                              data.current.plateAccents?.length
                                ? data.current.plateAccents
                                : undefined
                            }
                            isReadOnly={isReadonlyEditor}
                            onChange={async value => {
                              if (!equals(value, data.current.plateAccents)) {
                                await updateNewsbreak(data.current.id, {
                                  plateAccents: value,
                                })
                                await refetch()
                              }
                            }}
                            commentsProps={data.current.plateAccentComments}
                            setComments={async comments => {
                              if (
                                JSON.stringify(comments) !==
                                JSON.stringify(data.current.plateAccentComments)
                              ) {
                                await updateNewsbreak(data.current.id, {
                                  plateAccentComments: comments,
                                })
                              }
                            }}
                          />

                          {data.current.plateAccents?.length ? (
                            <Divider />
                          ) : null}

                          <PlateEditorWrapper
                            withComments={!isReadonlyEditor}
                            initialValue={data.current.plateDescription}
                            isReadOnly={isReadonlyEditor}
                            onChange={async value => {
                              if (
                                !equals(value, data.current.plateDescription)
                              ) {
                                await updateNewsbreak(data.current.id, {
                                  plateDescription: value,
                                  isUrgent:
                                    data.current.status ===
                                    NewsbreakStatusEnum.plan
                                      ? true
                                      : undefined,
                                  status:
                                    data.current.status ===
                                      NewsbreakStatusEnum.ok ||
                                    data.current.status ===
                                      NewsbreakStatusEnum.plan
                                      ? NewsbreakStatusEnum.rework
                                      : undefined,
                                })
                                await refetch()
                              }
                            }}
                            commentsProps={data.current.plateComments}
                            setComments={async comments => {
                              if (
                                JSON.stringify(comments) !==
                                JSON.stringify(data.current.plateComments)
                              ) {
                                await updateNewsbreak(data.current.id, {
                                  plateComments: comments,
                                })
                              }
                            }}
                          />
                        </div>
                      </SimpleBar>
                    </div>

                    <Divider orientation='vertical' className='h-[initial]' />

                    <div className='space-y-4 overflow-auto'>
                      <SimpleBar className='h-[calc(100vh-294px)]'>
                        <div className='space-y-6'>
                          <Users
                            status={data.current.status}
                            newsbreakId={data.current.id}
                            canEdit={canEditNewsbreak}
                          />

                          <Divider />

                          <MainInfo />

                          <ReportsComplete />

                          <NewsbreakFiles />

                          {isModeratorOrProvider && <Comments />}
                        </div>
                      </SimpleBar>

                      <Actions canEdit={canEditNewsbreak} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </Grants>
    </>
  )
}
