import { zodResolver } from '@hookform/resolvers/zod'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { TElement } from '@udecode/plate-common'
import { useAtom } from 'jotai/index'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { IoCloseCircle } from 'react-icons/io5'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { routesConst } from '../../../features/constants/routes.const'
import { Header, Layout, PlateEditorWrapper } from '../../../features/ui'
import { createArticle } from '../api/articles.api'
import { menuItems } from '../constants/menuItems'
import { createArticleSchema } from '../schemas/createArticleSchema'
import { foldersAtom } from '../store/folders.store'
import { tagsAtom } from '../store/tags.store'

type Form = z.infer<typeof createArticleSchema>

const pageTitle = 'Создание статьи'

export const CreateArticle = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [{ data: tags, isPending: isPendingTags }] = useAtom(tagsAtom)
  const [{ data: folders, isPending: isPendingFolders }] = useAtom(foldersAtom)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [editorDescriptionValue, setEditorDescriptionValue] = useState<
    Array<TElement> | undefined
  >(undefined)
  const form = useForm<Form>({
    defaultValues: {
      title: '',
      folder: searchParams.get('folderId') || '',
    },
    resolver: zodResolver(createArticleSchema),
  })

  const onSubmit = useCallback(
    async (formData: Form) => {
      try {
        setIsFormLoading(true)

        const response = await createArticle({
          ...formData,
          description: editorDescriptionValue,
          folder: formData.folder
            ? {
                id: formData.folder,
              }
            : undefined,
        })
        if (response.status && response.status === 201) {
          toast.success('Статья успешно создана')
          navigate(-1)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [editorDescriptionValue, navigate],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle} </title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        <form className='p-4' onSubmit={form.handleSubmit(onSubmit)}>
          <div className='bg-background min-h-[calc(100vh-104px)] space-y-6 rounded-xl p-4 shadow'>
            <div className='space-y-4'>
              <div className='flex items-center justify-between'>
                <div className='flex-1 text-lg font-semibold'>
                  Основная информация
                </div>
                <div>
                  <Button
                    onPress={() => {
                      navigate(routesConst.tasks)
                    }}
                    color='primary'
                    endContent={<IoCloseCircle className='text-xl' />}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
              <Controller
                control={form.control}
                name='title'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    placeholder='Название'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                    isInvalid={!!form.formState.errors.title}
                    errorMessage={form.formState.errors.title?.message}
                  />
                )}
              />
              <div className='flex space-x-4'>
                {!searchParams.get('folderId') && (
                  <Controller
                    control={form.control}
                    name='folder'
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        isDisabled={isFormLoading}
                        aria-label='Папка'
                        isLoading={isPendingFolders || isFormLoading}
                        variant='bordered'
                        defaultItems={folders || []}
                        placeholder='Выберите папку'
                        onSelectionChange={onChange}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        onKeyDown={e => e.continuePropagation()}
                      >
                        {item => (
                          <AutocompleteItem
                            key={item.id}
                            textValue={item.title}
                          >
                            {item.title}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    )}
                  />
                )}
                <Controller
                  control={form.control}
                  name='tags'
                  render={({ field: { onChange } }) => (
                    <Select
                      isDisabled={isPendingTags || isFormLoading}
                      isLoading={isPendingTags || isFormLoading}
                      items={tags || []}
                      aria-label='Тэги'
                      placeholder='Выберите тэги'
                      variant='bordered'
                      selectionMode='multiple'
                      onChange={event => {
                        onChange(event.target.value.split(','))
                      }}
                    >
                      {item => (
                        <SelectItem key={item.id} textValue={item.title}>
                          {item.title}
                        </SelectItem>
                      )}
                    </Select>
                  )}
                />
              </div>
              <PlateEditorWrapper
                withComments={false}
                placeholder='Введите описание'
                onChange={setEditorDescriptionValue}
              />
            </div>
            <div className='flex justify-end space-x-4'>
              <Button
                color='primary'
                type='submit'
                isDisabled={isFormLoading}
                isLoading={isFormLoading}
              >
                Создать
              </Button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  )
}
