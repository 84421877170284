import { useAtom } from 'jotai/index'
import { useEffect, useState } from 'react'

import { ratingPeriodAtom } from '../store'

export const useMultiplier = () => {
  const [multiplier, setMultiplier] = useState(1)
  const [ratingPeriod] = useAtom(ratingPeriodAtom)

  useEffect(() => {
    if (ratingPeriod === 'quarter') {
      setMultiplier(3)
    }
    if (ratingPeriod === 'halfYear') {
      setMultiplier(6)
    }
    if (ratingPeriod === 'year') {
      setMultiplier(12)
    }
    if (ratingPeriod === 'month') {
      setMultiplier(1)
    }
  }, [ratingPeriod])

  return multiplier
}
