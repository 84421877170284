import { AreaTypeEnum } from '@hydra/interfaces'
import { z } from 'zod'

export const createAreaSchema = z.object({
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, 'Обязательное поле')
    .trim(),
  link: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, 'Обязательное поле')
    .url('Некорректный url')
    .trim(),
  type: z.nativeEnum(AreaTypeEnum, {
    required_error: 'Обязательное поле',
  }),
})
