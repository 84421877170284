import { zodResolver } from '@hookform/resolvers/zod'
import { AreaTypeEnum, Grant, NewsbreakStatusEnum } from '@hydra/interfaces'
import { Button, Input, Select, SelectItem, Switch } from '@nextui-org/react'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { areaConst } from '../../../features/constants/area.const'
import { useGrant } from '../../../features/grants'
import { allLocationsAtom } from '../../../features/store'
import { updateNewsbreak } from '../api/newsbreaks.api'
import { additionalInformationSchema } from '../schemas/additionalInformation.schema'
import { newsbreakAtom } from '../store/newsbreaks.store'

interface Props {
  onCloseModal: () => void
}

interface Form {
  isUrgent: boolean
  isProduced: boolean
  isImportant: boolean
  deadline: Date | null
  locations: Array<{ id: string; title: string }>
  areas: Array<AreaTypeEnum>
}

export const AdditionalInformation = ({ onCloseModal }: Props) => {
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [{ refetch, data: newsbreak }] = useAtom(newsbreakAtom)

  const { isValidGrant: isCategoryModerator } = useGrant([
    Grant.newsbreaksCategoryModerator,
  ])

  const [{ data: locations, isPending: isPendingLocations }] =
    useAtom(allLocationsAtom)

  const form = useForm<Form>({
    defaultValues: {
      isUrgent: Boolean(newsbreak?.current.isUrgent),
      isImportant: Boolean(newsbreak?.current.isImportant),
      isProduced: Boolean(newsbreak?.current.isProduced),
      deadline: newsbreak?.current.deadline,
      locations: undefined,
      areas: undefined,
    },
    resolver: zodResolver(additionalInformationSchema),
  })

  const onSaveButtonPress = async (data: Form) => {
    if (!newsbreak) return null
    setIsFormLoading(true)
    try {
      const response = await updateNewsbreak(newsbreak.current.id, {
        ...data,
        deadline: dayjs(data.deadline).toDate(),
        status: data.isImportant
          ? NewsbreakStatusEnum.inWork
          : NewsbreakStatusEnum.ok,
      })
      if (response.status && response.status === 200) {
        toast.success('Инфоповод успешно обновлен')
        form.reset()
        await refetch()
        onCloseModal()
      }
    } catch (e) {
      toast.error('Что то пошло не так')
    } finally {
      setIsFormLoading(false)
    }
  }

  const isImportant = form.watch('isImportant')

  useEffect(() => {
    if (isImportant) {
      toast.info(
        'Вы установили инфоповоду значимость. Это означает, что СМИ не смогут отказаться от отработки данного инфоповода и он является обязательным к исполнению.',
        {
          autoClose: 30000,
        },
      )
    }
  }, [isImportant])

  return (
    <div className='space-y-4'>
      <div className='grid grid-cols-[130px,1fr] items-center gap-x-2 gap-y-4'>
        <div className='text-small text-default-400'>Срочность</div>
        <Controller
          control={form.control}
          name='isUrgent'
          render={({ field: { onChange, value } }) => (
            <Switch
              isDisabled={isFormLoading}
              size='sm'
              defaultSelected={Boolean(newsbreak?.current.isUrgent)}
              onValueChange={onChange}
              checked={value}
            >
              {value ? 'Текущий инфоповод' : 'Плановый инфоповод'}
            </Switch>
          )}
        />
        {!isCategoryModerator && (
          <>
            <div className='text-small text-default-400'>Значимость</div>
            <Controller
              control={form.control}
              name='isImportant'
              render={({ field: { onChange, value } }) => (
                <Switch
                  isDisabled={isFormLoading}
                  size='sm'
                  defaultSelected={Boolean(newsbreak?.current.isImportant)}
                  onValueChange={onChange}
                  checked={value}
                >
                  {value ? 'Значимый' : 'Не значимый'}
                </Switch>
              )}
            />
            <div className='text-small text-default-400'>Продюсирование</div>
            <Controller
              control={form.control}
              name='isProduced'
              render={({ field: { onChange, value } }) => (
                <Switch
                  isDisabled={isFormLoading}
                  size='sm'
                  defaultSelected={Boolean(newsbreak?.current.isProduced)}
                  onValueChange={onChange}
                  checked={value || undefined}
                />
              )}
            />
          </>
        )}

        <div className='text-small text-default-400'>
          {newsbreak?.current.isUrgent ? 'Срок исполнения' : 'Дата события'}
        </div>
        <Controller
          control={form.control}
          name='deadline'
          render={({ field: { onChange, value } }) => (
            <div className='flex flex-col space-y-2'>
              <Input
                id='deadline'
                isDisabled={isFormLoading}
                type={newsbreak?.current.isAllDay ? 'date' : 'datetime-local'}
                variant='bordered'
                defaultValue={dayjs(value).format(
                  newsbreak?.current.isAllDay
                    ? 'YYYY-MM-DD'
                    : 'YYYY-MM-DDTHH:mm',
                )}
                onChange={onChange}
                isInvalid={!!form.formState.errors.deadline}
                errorMessage={form.formState.errors.deadline?.message}
              />
            </div>
          )}
        />

        <div className='text-small text-default-400'>Места публикации</div>
        <Controller
          control={form.control}
          name='locations'
          render={({ field: { onChange, value } }) => (
            <div className='flex space-x-1'>
              <Select
                isDisabled={isPendingLocations || isFormLoading}
                isLoading={isPendingLocations || isFormLoading}
                items={locations ? locations[0] : []}
                aria-label='Места публикаций'
                placeholder='Выберите места публикаций'
                variant='bordered'
                className='max-w-[300px]'
                selectionMode='multiple'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                selectedKeys={value || []}
                onChange={event => {
                  onChange(event.target.value.split(','))
                }}
                isInvalid={!!form.formState.errors.locations}
                errorMessage={form.formState.errors.locations?.message}
              >
                {item => <SelectItem key={item.id}>{item.title}</SelectItem>}
              </Select>
              {locations && (
                <Button
                  className='flex-1'
                  variant='bordered'
                  onPress={() => {
                    onChange(locations[0].map(l => l.id))
                  }}
                >
                  Все
                </Button>
              )}
            </div>
          )}
        />

        <div className='text-small text-default-400'>Площадки</div>
        <Controller
          control={form.control}
          name='areas'
          render={({ field: { onChange, value } }) => (
            <div className='flex space-x-1'>
              <Select
                isDisabled={isFormLoading}
                isLoading={isFormLoading}
                items={Object.entries(areaConst).map(item => ({
                  label: item[1],
                  value: item[0],
                }))}
                aria-label='Площадки'
                placeholder='Выберите площадки'
                variant='bordered'
                className='max-w-[300px]'
                selectionMode='multiple'
                selectedKeys={value || []}
                onChange={event => {
                  onChange(event.target.value.split(','))
                }}
                isInvalid={!!form.formState.errors.areas}
                errorMessage={form.formState.errors.areas?.message}
              >
                {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
              </Select>
              <Button
                className='flex-1'
                variant='bordered'
                onPress={() => {
                  onChange(Object.entries(areaConst).map(item => item[0]))
                }}
              >
                Все
              </Button>
            </div>
          )}
        />
      </div>
      <div className='flex justify-end space-x-4 pb-4'>
        <Button
          variant='light'
          color='danger'
          onClick={() => {
            form.reset()
            onCloseModal()
          }}
        >
          Отменить
        </Button>
        <Button
          variant='solid'
          color='primary'
          onClick={form.handleSubmit(onSaveButtonPress)}
        >
          Согласовать
        </Button>
      </div>
    </div>
  )
}
