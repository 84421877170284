import { ChatInterface, Grant, UserInterface } from '@hydra/interfaces'
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom, useAtomValue } from 'jotai/index'
import { ChangeEvent, Key, useCallback, useMemo, useState } from 'react'
import { TbEdit, TbPasswordUser } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'

import { updateUser, updateUserPassword } from '../../../features/api'
import { organizationTypeConst } from '../../../features/constants/organizationType.const'
import { routesConst } from '../../../features/constants/routes.const'
import { useGrant } from '../../../features/grants'
import { profileAtom } from '../../../features/store'
import { ExitButton } from '../../../features/ui'
import { formatRussianPhoneNumber } from '../../../features/utils/formatRussianPhoneNumber.util'
import { generateMailtoLink } from '../../../features/utils/generateMailtoLink.util'
import { generateTelegramLink } from '../../../features/utils/generateTelegramLink.util'
import { updateChatUser } from '../../monitoring/api/chat.api'
import { HighLightSearch } from '../../monitoring/components/HighLightSearch'
import { chatsAtom } from '../../monitoring/store/chat.store'
import { InChatUserCheckbox } from './InChatUserCheckbox'

interface Props {
  isMyProfile: boolean
  profile: UserInterface
}

export const UserInfo = ({ isMyProfile, profile }: Props) => {
  const [{ refetch }] = useAtom(profileAtom)
  const { data: chats, refetch: refetchChats } = useAtomValue(chatsAtom)

  const [isLoadingRemoveUserFromAllChats, setIsLoadingRemoveUserFromAllChats] =
    useState(false)

  const [newPassword, setNewPassword] = useState('')
  const [searchChat, setSearchChat] = useState('')
  const [isUpdatePasswordLoading, setIsUpdatePasswordLoading] = useState(false)
  const navigate = useNavigate()
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const { isValidGrant: canViewPermissions } = useGrant(Grant.superadmin)
  const { isValidGrant: isPublicsUser } = useGrant(Grant.publics)

  const { isValidGrant: canEditUser } = useGrant([
    Grant.newsbreaksModerator,
    Grant.newsbreaksCategoryModerator,
  ])

  const { isValidGrant: canEditUserPassword } = useGrant(
    Grant.newsbreaksModerator,
  )

  const onEditButtonPress = useCallback(() => {
    const params = new URLSearchParams({
      id: profile.id,
      login: profile.login,
      name: profile.name,
      surname: profile.surname,
      email: profile.email,
      phone: profile.phone,
      telegram: profile.telegram || '',
      organization: profile.organization?.id || '',
    })
    navigate(routesConst.editUser + '?' + params)
  }, [
    navigate,
    profile.email,
    profile.id,
    profile.login,
    profile.name,
    profile.organization?.id,
    profile.phone,
    profile.surname,
    profile.telegram,
  ])

  const onTelegramNotificationValueChange = useCallback(async () => {
    await updateUser(profile.id, {
      receiveNotificationsInTelegram: !profile.receiveNotificationsInTelegram,
    })
    await refetch()
  }, [profile.id, profile.receiveNotificationsInTelegram, refetch])

  const onSelectNewsbreaksRoleChange = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      if (profile.grants.includes(event.target.value as Grant)) {
        toast.error('У пользователя уже установлена такая роль')
        return
      }
      try {
        const response = await updateUser(profile.id, {
          grants: [...profile.grants, event.target.value as Grant],
        })
        if (response.status && response.status === 200) {
          toast.success('Роль в модуле инфоповов успешно изменена')
        }
      } catch (e) {
        console.error(e)
      }
    },
    [profile.grants, profile.id],
  )

  const onSaveNewPasswordButtonPress = async () => {
    if (!newPassword) {
      return toast.error('Введите новый пароль')
    }

    if (newPassword.length < 2) {
      return toast.error('Пароль должен быть не менее 2 символов')
    }

    if (newPassword.length > 250) {
      return toast.error('Пароль не должен быть более 250 символов')
    }

    setIsUpdatePasswordLoading(true)

    try {
      const response = await updateUserPassword({
        id: !isMyProfile ? profile.id : undefined,
        password: newPassword,
      })

      if (response.status && response.status === 200) {
        toast.success('Пароль успешно изменен')
        setNewPassword('')
        onClose()
      }
    } catch (e) {
      toast.error('Что то пошло не так')
    } finally {
      setIsUpdatePasswordLoading(false)
    }
  }

  const handleUpdateChatUser = useCallback(
    async (id: string, data: { user: UserInterface; value: boolean }) => {
      await updateChatUser(id, data)
    },
    [],
  )

  const handleRemoveFromAllChats = useCallback(async () => {
    try {
      setIsLoadingRemoveUserFromAllChats(true)
      if (chats && chats[0].length > 0) {
        for (const chat1 of chats[0].filter(chat =>
          chat.users?.some(u => u.id === profile.id),
        )) {
          await handleUpdateChatUser(chat1.id, {
            user: profile,
            value: false,
          })
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      await refetchChats()
      setIsLoadingRemoveUserFromAllChats(false)
    }
  }, [chats, handleUpdateChatUser, profile, refetchChats])

  const renderCell = useCallback(
    (item: ChatInterface, columnKey: Key) => {
      if (columnKey === 'title' + searchChat) {
        return (
          <HighLightSearch search={searchChat.split(' ')} value={item.title} />
        )
      }

      if (columnKey === 'link' + searchChat) {
        return (
          <div className='w-[250px] overflow-hidden'>
            <Link
              size='sm'
              isExternal
              href={item.link}
              isBlock
              showAnchorIcon={!!item.link}
            >
              {item.link}
            </Link>
          </div>
        )
      }

      if (columnKey === 'inChat' + searchChat) {
        return (
          <InChatUserCheckbox
            user={profile}
            chat={item}
            refetch={refetchChats}
            updateHandler={handleUpdateChatUser}
          />
        )
      }
    },
    [handleUpdateChatUser, profile, refetchChats, searchChat],
  )

  const chatsForList = useMemo(
    () =>
      chats
        ? chats[0].filter(item =>
            item.title.toLowerCase().includes(searchChat.toLowerCase()),
          )
        : [],
    [chats, searchChat],
  )

  return (
    <>
      <div className='space-y-4 p-4'>
        <div className='bg-background space-y-4 rounded-xl p-4 shadow'>
          <div className='flex items-center justify-between'>
            <div className=''>
              <div className='text-lg font-semibold'>
                {profile.name} {profile.surname}
              </div>
            </div>
            <div className='space-x-2'>
              {(isMyProfile || canEditUserPassword) && (
                <Button
                  variant='bordered'
                  color='primary'
                  startContent={<TbPasswordUser className='text-2xl' />}
                  onPress={onOpen}
                >
                  Изменить пароль
                </Button>
              )}
              {(isMyProfile || canEditUser) && (
                <Button
                  variant='bordered'
                  startContent={<TbEdit className='text-2xl' />}
                  onPress={onEditButtonPress}
                >
                  Редактировать
                </Button>
              )}
            </div>
          </div>
          <Divider />
          <div className='flex space-x-8'>
            <div className='space-y-1'>
              <div className='text-default-400 text-sm'>Телеграм</div>
              <Link
                isExternal
                isBlock
                size='sm'
                href={generateTelegramLink(profile?.telegram)}
              >
                {profile.telegram}
              </Link>
            </div>
            <Divider orientation='vertical' className='h-[initial]' />
            <div className='space-y-1'>
              <div className='text-default-400 text-sm'>Телефон</div>
              <div className='font-semibold'>
                {formatRussianPhoneNumber(profile.phone)}
              </div>
            </div>
            <Divider orientation='vertical' className='h-[initial]' />
            <div className='space-y-1'>
              <div className='text-default-400 text-sm'>Логин</div>
              <div className='font-semibold'>{profile.login}</div>
            </div>
            <Divider orientation='vertical' className='h-[initial]' />
            <div className='space-y-1'>
              <div className='text-default-400 text-sm'>Email</div>
              <div className='font-semibold'>{profile.email}</div>
            </div>
          </div>
        </div>

        {profile.organization && (
          <div className='bg-background space-y-4 rounded-xl p-4 shadow'>
            <div className='flex items-center justify-between'>
              <div className='text-lg font-semibold'>
                {profile.organization.name}
              </div>
            </div>
            <Divider />
            <div className='flex space-x-8'>
              <div className='space-y-1'>
                <div className='text-default-400 text-sm'>Имя руководителя</div>
                <div className='font-semibold'>
                  {profile.organization.contactPerson}
                </div>
              </div>
              <Divider orientation='vertical' className='h-[initial]' />
              <div className='space-y-1'>
                <div className='text-default-400 text-sm'>
                  Телефон руководителя
                </div>
                <div className='font-semibold'>
                  {formatRussianPhoneNumber(
                    profile.organization.contactPersonPhone,
                  )}
                </div>
              </div>
              <Divider orientation='vertical' className='h-[initial]' />
              <div className='space-y-1'>
                <div className='text-default-400 text-sm'>
                  Email руководителя
                </div>
                <Link
                  isBlock
                  isExternal
                  href={generateMailtoLink(
                    profile.organization.contactPersonEmail,
                  )}
                >
                  {profile.organization.contactPersonEmail}
                </Link>
              </div>
              <Divider orientation='vertical' className='h-[initial]' />
              <div className='space-y-1'>
                <div className='text-default-400 text-sm'>Сайт</div>
                {profile.organization.site && (
                  <Link
                    isExternal
                    isBlock
                    size='sm'
                    href={profile.organization.site}
                  >
                    {profile.organization.site}
                  </Link>
                )}
              </div>
              <Divider orientation='vertical' className='h-[initial]' />
              <div className='space-y-1'>
                <div className='text-default-400 text-sm'>Тип организации</div>
                <div className='font-semibold'>
                  {organizationTypeConst[profile.organization.type]}
                </div>
              </div>
            </div>
          </div>
        )}

        {!isMyProfile && canViewPermissions && (
          <div className='bg-background space-y-4 rounded-xl p-4 shadow'>
            <div className='flex items-center justify-between'>
              <div className='text-lg font-semibold'>Роли</div>
            </div>
            <Divider />
            <div className='flex'>
              <Select
                color='primary'
                variant='bordered'
                label='Роль в модуле инфоповодов'
                onChange={onSelectNewsbreaksRoleChange}
              >
                {[
                  {
                    value: Grant.newsbreaksModerator,
                    label: 'Модератор',
                  },
                  {
                    value: Grant.newsbreaksCategoryModerator,
                    label: 'Модератор категории инфоповодов',
                  },
                  {
                    value: Grant.newsbreaksProvider,
                    label: 'Поставщик',
                  },
                  {
                    value: Grant.newsbreaksEditor,
                    label: 'Выпускающий редактор',
                  },
                ].map(item => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </div>
        )}

        {isMyProfile && (
          <div className='bg-background space-y-4 rounded-xl p-4 shadow'>
            <div className='flex items-center justify-between'>
              <div className='text-lg font-semibold'>Настройка уведомлений</div>
            </div>
            <Divider />
            <div className='grid grid-cols-[150px,1fr]'>
              <div>Телеграм</div>
              <div>
                <Checkbox
                  isSelected={profile.receiveNotificationsInTelegram}
                  onValueChange={onTelegramNotificationValueChange}
                />
              </div>
            </div>
          </div>
        )}

        {isPublicsUser && (
          <div className='bg-background space-y-4 rounded-xl p-4 shadow'>
            <div className='flex items-center justify-between space-x-4'>
              <div className='text-lg font-semibold'>Чаты</div>
              <Input
                variant='bordered'
                size='sm'
                color='primary'
                aria-label='Найти чат'
                placeholder='Найти чат'
                value={searchChat}
                onValueChange={setSearchChat}
              />
            </div>
            <Divider />
            <Button
              isDisabled={isLoadingRemoveUserFromAllChats}
              isLoading={isLoadingRemoveUserFromAllChats}
              color='danger'
              size='sm'
              variant='bordered'
              onPress={handleRemoveFromAllChats}
            >
              Удалить из всех чатов
            </Button>
            <SimpleBar className='max-h-[300px] overflow-auto'>
              <Table
                aria-label='Чаты пользователя'
                isHeaderSticky
                isStriped
                removeWrapper
                onRowAction={key => navigate(routesConst.chats + '/' + key)}
              >
                <TableHeader
                  columns={[
                    {
                      key: 'inChat' + searchChat,
                      label: 'В чате',
                    },
                    {
                      key: 'title' + searchChat,
                      label: 'Название',
                    },
                    {
                      key: 'link' + searchChat,
                      label: 'Пригласительная ссылка',
                    },
                  ]}
                >
                  {column => (
                    <TableColumn key={column.key} textValue={column.label}>
                      {column.label}
                    </TableColumn>
                  )}
                </TableHeader>
                <TableBody
                  items={chatsForList}
                  emptyContent={
                    <div className='text-default-400 text-sm'>Нет данных</div>
                  }
                >
                  {item => (
                    <TableRow key={item.id + searchChat} textValue={item.id}>
                      {columnKey => (
                        <TableCell>{renderCell(item, columnKey)}</TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </SimpleBar>
          </div>
        )}

        {isMyProfile && (
          <div className='flex justify-end'>
            <ExitButton />
          </div>
        )}
      </div>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Смена пароля
              </ModalHeader>
              <ModalBody>
                <Input
                  isDisabled={isUpdatePasswordLoading}
                  variant='bordered'
                  label='Новый пароль'
                  color='primary'
                  onValueChange={setNewPassword}
                  value={newPassword}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  isLoading={isUpdatePasswordLoading}
                  isDisabled={isUpdatePasswordLoading}
                  color='danger'
                  variant='light'
                  onPress={onClose}
                >
                  Закрыть
                </Button>
                <Button
                  isLoading={isUpdatePasswordLoading}
                  isDisabled={isUpdatePasswordLoading}
                  color='primary'
                  onPress={onSaveNewPasswordButtonPress}
                >
                  Сохранить
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
