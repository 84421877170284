import { OrganizationInterface } from '@hydra/interfaces'
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { routesConst } from '../../../features/constants/routes.const'
import { allOrganizationsAtom } from '../../../features/store'
import { Empty, PageSpinner } from '../../../features/ui'
import { CopyToClipboardButton } from '../../../features/ui/components/CopyToClipboardButton'
import { formatRussianPhoneNumber } from '../../../features/utils/formatRussianPhoneNumber.util'
import { generateMailtoLink } from '../../../features/utils/generateMailtoLink.util'

const columns = [
  {
    id: 'name',
    label: 'Название',
  },
  {
    id: 'contactPerson',
    label: 'Руководитель',
  },
  {
    id: 'contactPersonPhone',
    label: 'Телефон руководителя',
  },
  {
    id: 'contactPersonEmail',
    label: 'Email руководителя',
  },
  {
    id: 'site',
    label: 'Сайт организации',
  },
]

type ColumnType = Pick<
  OrganizationInterface,
  | 'id'
  | 'name'
  | 'contactPerson'
  | 'contactPersonPhone'
  | 'contactPersonEmail'
  | 'site'
>

export const OrganizationsList = () => {
  const [{ data, isPending }] = useAtom(allOrganizationsAtom)
  const navigate = useNavigate()

  const renderCell = useCallback((organization: ColumnType, columnKey: Key) => {
    const cellValue = organization[columnKey as keyof ColumnType]
    switch (columnKey) {
      case 'contactPersonEmail':
        return (
          <div className='flex items-center space-x-1'>
            <Link
              isExternal
              isBlock
              size='sm'
              href={generateMailtoLink(organization.contactPersonEmail)}
            >
              {organization.contactPersonEmail}
            </Link>
            <CopyToClipboardButton
              value={organization.contactPersonEmail}
              messageText={`${organization.contactPersonEmail}\nскопированно в буфер обмена`}
            />
          </div>
        )
      case 'site':
        return organization.site ? (
          <Link isExternal isBlock href={organization.site} size='sm'>
            {organization.site}
          </Link>
        ) : null
      case 'contactPersonPhone':
        return (
          <div className='flex items-center space-x-1'>
            <span>
              {formatRussianPhoneNumber(organization.contactPersonPhone)}
            </span>
            <CopyToClipboardButton
              value={organization.contactPersonPhone}
              messageText={`${organization.contactPersonPhone}\nскопированно в буфер обмена`}
            />
          </div>
        )
      default:
        return cellValue
    }
  }, [])

  const onTableRowClick = useCallback(
    (id: string) => {
      navigate(routesConst.organizations + '/' + id)
    },
    [navigate],
  )

  if (isPending) return <PageSpinner />

  if (!data) return <Empty />

  return (
    <SimpleBar className='bg-background h-[calc(100vh-104px)] overflow-auto rounded-xl p-4 shadow'>
      <Table
        aria-label='Организации'
        isHeaderSticky
        isStriped
        removeWrapper
        classNames={{
          base: '',
        }}
      >
        <TableHeader columns={columns}>
          {column => <TableColumn key={column.id}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody items={data[0]}>
          {item => (
            <TableRow key={item.id} onClick={() => onTableRowClick(item.id)}>
              {columnKey => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </SimpleBar>
  )
}
