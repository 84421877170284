import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from '@nextui-org/react'
import { useAtomValue } from 'jotai/index'
import { FormEvent, useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import {
  audioExtensions,
  imageExtensions,
  videoExtensions,
} from '../../../features/constants/fileExtensions.const'
import { ONE_HUNDRED_MEGABYTES_IN_BYTES } from '../../../features/constants/fileSizes.const'
import { FileDnD } from '../../../features/ui'
import { sendMedia, sendMessage } from '../api/chat.api'
import { chatAtom } from '../store/chat.store'

interface Props {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onClose: () => void
  additionalAction?: () => Promise<void>
  additionalText?: string
}

export const SendMessageMediaModal = ({
  isOpen,
  onOpenChange,
  onClose,
  additionalAction,
  additionalText,
}: Props) => {
  const { data: chat, refetch } = useAtomValue(chatAtom)
  const [files, setFiles] = useState<File[] | null>()
  const [isFormLoading, setIsFormLoading] = useState(false)

  const handleSndMessage = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const form = event.target as HTMLFormElement
      const captionInput = form.elements.namedItem(
        'caption',
      ) as HTMLInputElement

      if (chat) {
        try {
          setIsFormLoading(true)
          let response

          if (files && files.length > 0) {
            response = await sendMedia(chat.id, {
              file: files[0],
              caption: captionInput.value
                ? captionInput.value + ` ${additionalText}`
                : '',
            })
          } else {
            response = await sendMessage(chat.id, {
              message: captionInput.value + ` ${additionalText}`,
            })
          }

          if (response.status && response.status === 201) {
            onClose()
            toast.success('Сообщение отправлено')
            setFiles(null)

            if (additionalAction) {
              await additionalAction()
            }
          }
        } catch (error) {
          toast.error('Не удалось отправить сообщение, попробуйте позже')
          console.error(error)
        } finally {
          await refetch()
          setIsFormLoading(false)
        }
      }
    },
    [additionalAction, additionalText, chat, files, onClose, refetch],
  )

  const onDropAccepted = useCallback(
    (newFiles: Array<File>) => {
      setFiles(files ? [...files, ...newFiles] : newFiles)
    },
    [files],
  )

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {onClose => (
          <form onSubmit={event => handleSndMessage(event)}>
            <ModalHeader className='flex flex-col gap-1'>
              Отправить сообщение
            </ModalHeader>

            <ModalBody>
              <FileDnD
                disabled={isFormLoading}
                maxFiles={100}
                maxSize={ONE_HUNDRED_MEGABYTES_IN_BYTES}
                multiple
                onDropAccepted={onDropAccepted}
                accept={{
                  ...audioExtensions,
                  ...videoExtensions,
                  ...imageExtensions,
                }}
              />
              <Textarea
                isDisabled={isFormLoading}
                variant='bordered'
                color='primary'
                name='caption'
                label='Сообщение'
              />
            </ModalBody>
            <ModalFooter>
              <Button
                type='button'
                color='danger'
                variant='light'
                onPress={onClose}
              >
                Закрыть
              </Button>
              <Button type='submit' color='primary'>
                Отправить
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  )
}
