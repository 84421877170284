import { FileInterface } from '@hydra/interfaces'
import { Button } from '@nextui-org/react'
import { useAtomValue } from 'jotai/index'
import { useState } from 'react'
import { toast } from 'react-toastify'
import slug from 'slug'

import { getFileUrlFromS3, uploadFileToS3 } from '../../../features/api'
import {
  audioExtensions,
  docExtensions,
  imageExtensions,
  videoExtensions,
} from '../../../features/constants/fileExtensions.const'
import { ONE_HUNDRED_MEGABYTES_IN_BYTES } from '../../../features/constants/fileSizes.const'
import { FileDnD, FileItemPreview } from '../../../features/ui'
import { downloadAllFiles } from '../../../features/utils/downloadAllFiles'
import { getFileExtension } from '../../../features/utils/getFileExtension'
import { createRiskFile } from '../api/risk.api'
import { riskAtom } from '../store/risk.store'

interface Props {
  riskId: string
  files: Array<FileInterface>
}

export const RiskFiles = ({ files, riskId }: Props) => {
  const [visibleFilesCount, setVisibleFilesCount] = useState(2)
  const { refetch } = useAtomValue(riskAtom)

  const loadFunction = async (files: Array<File>) => {
    try {
      if (files) {
        const filesResponses = await Promise.allSettled(
          files.map(file => {
            return uploadFileToS3(
              new File(
                [file],
                slug(file.name.replace(/\.[^/.]+$/, '')) +
                  '.' +
                  getFileExtension(file.name),
                {
                  type: file.type,
                },
              ),
            )
          }),
        )
        const filesUrlResponses = await Promise.allSettled(
          filesResponses.map(file => {
            if (file.status === 'fulfilled' && file.value) {
              return getFileUrlFromS3(
                file.value.data,
                import.meta.env.VITE_BUCKET_NAME,
              )
            }

            return null
          }),
        )

        for (const file of filesUrlResponses) {
          if (file.status === 'fulfilled' && file.value) {
            await createRiskFile({
              ...file.value.data,
              riskId,
            })
          }
        }
      }

      toast.success('Файлы успешно обновлены')
      await refetch()
    } catch (e) {
      console.error(e)
    }
  }

  const onDropAccepted = async (files: Array<File>) => {
    await toast.promise(loadFunction(files), {
      pending: 'Загрузка файлов...',
      success: 'Файлы успешно загружены',
      error: 'Не удалось загрузить файлы',
    })
  }

  return (
    <div className='space-y-4'>
      {files.length ? (
        <div>
          <Button
            size='sm'
            variant='light'
            color='primary'
            onPress={() => {
              downloadAllFiles(files)
            }}
          >
            Скачать все
          </Button>
        </div>
      ) : null}
      <div className='space-y-2'>
        {files.slice(0, visibleFilesCount).map(file => (
          <FileItemPreview file={file} key={file.id} refetch={refetch} />
        ))}

        <FileDnD
          isShowCount={false}
          size='sm'
          maxFiles={100}
          maxSize={ONE_HUNDRED_MEGABYTES_IN_BYTES}
          multiple
          onDropAccepted={onDropAccepted}
          accept={{
            ...audioExtensions,
            ...videoExtensions,
            ...imageExtensions,
            ...docExtensions,
          }}
        />
        {files.length > 2 && (
          <Button
            size='sm'
            variant='light'
            onPress={() => {
              if (visibleFilesCount === files.length) {
                setVisibleFilesCount(2)
              } else {
                setVisibleFilesCount(files.length)
              }
            }}
          >
            <div className='text-default-400'>
              {visibleFilesCount === files.length
                ? 'Скрыть'
                : `Показать все (${files.length})`}
            </div>
          </Button>
        )}
      </div>
    </div>
  )
}
