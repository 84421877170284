import { TaskInterface } from '@hydra/interfaces'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'
import { atomWithStorage } from 'jotai/vanilla/utils'

import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import {
  getTaskById,
  getTasks,
  getTasksByCollections,
  getTasksByDate,
  getTasksByExecutors,
} from '../api/tasks.api'

export const skipAtom = atom(0)
export const takeAtom = atom(25)
export const orderAtom = atom<keyof TaskInterface>('createdAt')
export const directionAtom = atom<'desc' | 'asc'>('desc')
export const taskIdAtom = atom<string | undefined>(undefined)
export const tasksViewAtom = atomWithStorage<
  'table' | 'byExecutor' | 'byCollection'
>('tasksView', 'table')

export const calendarYearAtom = atom(extendedDayjs().year())
export const calendarMonthAtom = atom(extendedDayjs().month())
export const calendarWeekAtom = atom(extendedDayjs().week())
export const calendarDayAtom = atom(extendedDayjs().date())
export const calendarViewAtom = atomWithStorage<'month' | 'week'>(
  'taskCalendarView',
  'week',
)

export const tasksAtom = atomWithQuery(get => {
  return {
    enabled: get(tasksViewAtom) === 'table',
    queryKey: [
      'tasks-list',
      get(skipAtom),
      get(takeAtom),
      get(orderAtom),
      get(directionAtom),
      get(tasksViewAtom),
    ],
    queryFn: async () => {
      const response = await getTasks({
        skip: get(skipAtom),
        take: get(takeAtom),
        order: get(orderAtom),
        direction: get(directionAtom),
      })
      return response.data
    },
  }
})

export const tasksByExecutorsAtom = atomWithQuery(get => {
  return {
    enabled: get(tasksViewAtom) === 'byExecutor',
    queryKey: [
      'tasks-list-by-executors',
      get(skipAtom),
      get(takeAtom),
      get(tasksViewAtom),
    ],
    queryFn: async () => {
      const response = await getTasksByExecutors({
        skip: get(skipAtom),
        take: get(takeAtom),
      })
      return response.data
    },
  }
})

export const tasksByCollectionsAtom = atomWithQuery(get => {
  return {
    enabled: get(tasksViewAtom) === 'byCollection',
    queryKey: [
      'tasks-list-by-collections',
      get(skipAtom),
      get(takeAtom),
      get(tasksViewAtom),
    ],
    queryFn: async () => {
      const response = await getTasksByCollections({
        skip: get(skipAtom),
        take: get(takeAtom),
      })
      return response.data
    },
  }
})

export const taskAtom = atomWithQuery(get => {
  return {
    enabled: !!get(taskIdAtom),
    queryKey: ['risk-by-id', get(taskIdAtom)],
    queryFn: async () => {
      const response = await getTaskById(get(taskIdAtom) || '')
      return response.data
    },
  }
})

export const tasksByWeekAtom = atomWithQuery(get => {
  return {
    enabled: get(calendarViewAtom) === 'week',
    queryKey: [
      'tasks-by-week',
      get(calendarYearAtom),
      get(calendarMonthAtom),
      get(calendarWeekAtom),
    ],
    queryFn: async () => {
      const currentMonth = extendedDayjs()
        .set('year', get(calendarYearAtom))
        .set('month', get(calendarMonthAtom))

      const response = await getTasksByDate({
        dayStart: currentMonth
          .startOf('week')
          .week(get(calendarWeekAtom))
          .toDate(),
        dayEnd: currentMonth.endOf('week').week(get(calendarWeekAtom)).toDate(),
      })

      return response.data
    },
  }
})

export const tasksByMonthAtom = atomWithQuery(get => {
  return {
    queryKey: ['tasks-by-month', get(calendarYearAtom), get(calendarMonthAtom)],
    queryFn: async () => {
      const currentMonth = extendedDayjs()
        .set('year', get(calendarYearAtom))
        .set('month', get(calendarMonthAtom))

      const response = await getTasksByDate({
        dayStart: currentMonth.startOf('month').subtract(1, 'month').toDate(),
        dayEnd: currentMonth.endOf('month').add(1, 'month').toDate(),
      })

      return response.data
    },
  }
})
