import { OrganizationInterface } from '@hydra/interfaces'
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import React from 'react'

import { useRatingPeriodMask } from '../hooks/useRatingPeriodMask'
import { RatingTablePdf } from './RatingTablePdf'

Font.register({
  family: 'NotoSans',
  fonts: [
    {
      src: '/NotoSans-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: '/NotoSans-Bold.ttf',
      fontStyle: 'bold',
      fontWeight: 'bold',
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'bold',
    fontFamily: 'NotoSans',
    marginBottom: 20,
  },
})

interface Props {
  organizationsByGroups: Record<
    string,
    Array<
      OrganizationInterface & {
        totalPoints: {
          fact: number
          plan: number
          points: number
        }
      }
    >
  >
}

export const RatingByGroupPdf = ({ organizationsByGroups }: Props) => {
  const period = useRatingPeriodMask()

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.title}>
          <Text>Рейтинг</Text>
          <Text>информационного сопровождения деятельности</Text>
          <Text>ведомств и муниципалитетов</Text>
          <Text>за {period}</Text>
        </View>
        {Object.entries(organizationsByGroups)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([key, value]) => {
            return (
              <RatingTablePdf key={key} groupName={key} organizations={value} />
            )
          })}
      </Page>
    </Document>
  )
}
