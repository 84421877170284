import { MissedNewsbreakInterface } from '@hydra/interfaces'
import {
  Button,
  Chip,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'

import { excuseConst } from '../../../features/constants/excuse.const'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'

interface Props {
  missedNewsbreak: MissedNewsbreakInterface
}

export const MissedNewsbreakItem = ({ missedNewsbreak }: Props) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()

  return (
    <>
      <div
        className='hover:bg-default-100 transition-background cursor-pointer space-y-1 rounded-lg border p-2 text-xs'
        key={missedNewsbreak.id}
        onClick={onOpen}
      >
        <div className='flex justify-between'>
          <div>{getPrettyDate({ date: missedNewsbreak.createdAt })}</div>{' '}
          <div className='text-danger'>-1 балл</div>
        </div>
        <div>
          <Chip size='sm'>{excuseConst[missedNewsbreak.excuse]}</Chip>
        </div>
        <div className='line-clamp-3'>{missedNewsbreak.description}</div>
      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size='xl'>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Инфоповод вне темника
              </ModalHeader>
              <ModalBody>
                <div className='space-y-4'>
                  <div className='grid grid-cols-[150px_1fr] space-y-2 break-all text-sm'>
                    <div className='text-default-400'>Дата</div>
                    <div>
                      {getPrettyDate({ date: missedNewsbreak.createdAt })}
                    </div>
                    <div className='text-default-400'>Тип оправдания</div>
                    <Chip size='sm'>{excuseConst[missedNewsbreak.excuse]}</Chip>
                    <div className='text-default-400'>Ссылка</div>
                    <Link
                      isBlock
                      showAnchorIcon
                      href={missedNewsbreak.link}
                      isExternal
                    >
                      {missedNewsbreak.link}
                    </Link>
                  </div>
                  <div className='bg-default-50 break-all rounded-2xl p-4'>
                    {missedNewsbreak.description}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color='danger' variant='light' onPress={onClose}>
                  Закрыть
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
