import clsx from 'clsx'
import { useMemo } from 'react'

import { extendedDayjs } from '../../../utils/dayjsConfig'
import { generateRandomString } from '../../../utils/generateRandomString.util'
import { Items } from './types'
import { WeekViewColumn } from './WeekViewColumn'

interface Props {
  items: Items
  year: number
  month: number
  week: number
}

export const WeekView = ({ year, month, week, items }: Props) => {
  const currentDay = useMemo(
    () => extendedDayjs().year(year).month(month).week(week),
    [month, week, year],
  )

  return (
    <div className='bg-background grid h-[calc(100vh-152px)] grid-cols-7 grid-rows-[24px,1fr] gap-x-1 gap-y-2 rounded-xl p-2 shadow'>
      {Array.from({ length: 7 }, (_, index) => (
        <div
          key={generateRandomString(10)}
          className={clsx('flex justify-center capitalize', {
            'text-primary': currentDay.weekday(index).isToday(),
          })}
        >
          {currentDay.weekday(index).format('dd DD.MM')}
        </div>
      ))}
      {Array.from({ length: 7 }, (_, index) => {
        return (
          <WeekViewColumn
            index={index}
            items={items}
            currentDay={currentDay}
            key={generateRandomString(10)}
          />
        )
      })}
    </div>
  )
}
