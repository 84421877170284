export enum Grant {
  newsbreaksModerator = 'newsbreaksModerator',
  newsbreaksCategoryModerator = 'newsbreaksCategoryModerator',
  newsbreaksProvider = 'newsbreaksProvider',
  newsbreaksEditor = 'newsbreaksEditor',
  tasks = 'tasks',
  publics = 'publics',
  riskModerator = 'riskModerator',
  riskProvider = 'riskProvider',
  superadmin = 'superadmin',
}
