import { zodResolver } from '@hookform/resolvers/zod'
import { TagInterface } from '@hydra/interfaces'
import {
  Button,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TbEdit, TbTrash } from 'react-icons/tb'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { Empty, PageSpinner } from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { deleteTag, updateTag } from '../api/tags.api'
import { createTagSchema } from '../schemas/createTagSchema'
import { tagsAtom } from '../store/tags.store'

const columns = [
  {
    key: 'title',
    label: 'Тэг',
  },
  {
    key: 'createdAt',
    label: 'Создан',
  },
  {
    key: 'actions',
    label: '',
    width: 100,
  },
]

type Form = z.infer<typeof createTagSchema>

export const TagsList = () => {
  const [id, setId] = useState<string | undefined>(undefined)
  const [{ data, isPending, refetch }] = useAtom(tagsAtom)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const form = useForm<Form>({
    defaultValues: {
      title: '',
    },
    resolver: zodResolver(createTagSchema),
  })

  const onSubmit = useCallback(
    async (formData: Form) => {
      if (!id) return null
      setIsFormLoading(true)
      try {
        const response = await updateTag(id, formData)
        if (response.status && response.status === 200) {
          toast.success('Тэг успешно обновлен')
          form.reset()
          onClose()
          await refetch()
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
        setId(undefined)
      }
    },
    [form, id, onClose, refetch],
  )

  const renderCell = useCallback(
    (item: TagInterface, columnKey: Key) => {
      if (columnKey === 'title') {
        return (
          <Chip size='sm' color='secondary' variant='faded'>
            {item.title}
          </Chip>
        )
      }
      if (columnKey === 'createdAt') {
        return (
          <div>
            {getPrettyDate({
              date: item.createdAt,
            })}
          </div>
        )
      }
      if (columnKey === 'actions') {
        return (
          <div className='flex space-x-2'>
            <Button
              isIconOnly
              size='sm'
              color='primary'
              variant='light'
              onPress={() => {
                setId(item.id)
                onOpen()
              }}
            >
              <TbEdit />
            </Button>
            <Button
              isIconOnly
              size='sm'
              color='danger'
              variant='light'
              onPress={async () => {
                await deleteTag(item.id)
                toast.success('Тег успешно удален')
                await refetch()
              }}
            >
              <TbTrash />
            </Button>
          </div>
        )
      }
    },
    [onOpen, refetch],
  )

  if (isPending) return <PageSpinner />

  if (!data || data.length === 0) return <Empty />

  return (
    <>
      <Table
        aria-label='Тэги'
        isHeaderSticky
        isStriped
        removeWrapper
        classNames={{
          base: 'h-[calc(100vh-104px)] overflow-auto',
        }}
      >
        <TableHeader columns={columns}>
          {column => (
            <TableColumn width={column.width} key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={data}
          emptyContent={
            <div className='text-default-400 text-sm'>Нет данных</div>
          }
        >
          {item => (
            <TableRow key={item.id}>
              {columnKey => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Создать новую папку
              </ModalHeader>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <ModalBody>
                  <Controller
                    control={form.control}
                    name='title'
                    render={({ field: { onChange, value } }) => (
                      <Input
                        isDisabled={isFormLoading}
                        variant='bordered'
                        label='Название тэга'
                        color='primary'
                        onValueChange={onChange}
                        value={value}
                        isInvalid={!!form.formState.errors.title}
                        errorMessage={form.formState.errors.title?.message}
                      />
                    )}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color='danger'
                    type='button'
                    variant='light'
                    onPress={onClose}
                  >
                    Закрыть
                  </Button>
                  <Button color='primary' type='submit'>
                    Создать
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
