import { Button, Divider } from '@nextui-org/react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { ReactNode, useCallback } from 'react'
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb'

import { extendedDayjs } from '../../../utils/dayjsConfig'
import { DayReview } from './DayReview'
import { daysOfWeek } from './daysOfWeek.const'
import { SmallMonthItem } from './SmallMonthItem'
import { Items } from './types'

interface Props {
  year: number
  month: number
  day: number
  items?: Items
  months: Array<{
    id: number
    title: string
    daysCount: number
    firstWeekDay: number
  }>
  prevMonthDaysCount: number
  nextMonthDaysCount: number
  renderShowSmallCalendarButton: () => ReactNode
  onSmallCalendarDayButtonClick: (day: number) => void
  onPrevMonthButtonPress: () => void
  onNextMonthButtonPress: () => void
}

export const SmallMonth = ({
  year,
  month,
  day,
  months,
  items,
  prevMonthDaysCount,
  nextMonthDaysCount,
  renderShowSmallCalendarButton,
  onSmallCalendarDayButtonClick,
  onPrevMonthButtonPress,
  onNextMonthButtonPress,
}: Props) => {
  const renderSmallCalendar = useCallback(
    () => (
      <>
        {Array.from(
          {
            length: prevMonthDaysCount,
          },
          (_, index) => {
            const currentDay = extendedDayjs()
              .set('year', year)
              .set('month', month === 0 ? 11 : month - 1)
              .set(
                'date',
                months[month === 0 ? 11 : month - 1].daysCount - index,
              )

            return (
              <SmallMonthItem
                onSmallCalendarDayButtonClick={onSmallCalendarDayButtonClick}
                day={day}
                key={index}
                isNotCurrentMonth
                date={currentDay}
                items={items?.filter(item =>
                  dayjs(currentDay).isSame(dayjs(item.deadline), 'date'),
                )}
              />
            )
          },
        ).reverse()}

        {Array.from({ length: months[month].daysCount }, (_, index) => {
          const currentDay = extendedDayjs()
            .set('year', year)
            .set('month', month)
            .set('date', index + 1)

          return (
            <SmallMonthItem
              onSmallCalendarDayButtonClick={onSmallCalendarDayButtonClick}
              day={day}
              key={index}
              isNotCurrentMonth={false}
              date={currentDay}
              items={items?.filter(item =>
                dayjs(currentDay).isSame(dayjs(item.deadline), 'date'),
              )}
            />
          )
        })}

        {Array.from(
          {
            length: nextMonthDaysCount,
          },
          (_, index) => {
            const currentDay = extendedDayjs()
              .set('year', year)
              .set('month', month + 1)
              .set('date', index + 1)

            return (
              <SmallMonthItem
                onSmallCalendarDayButtonClick={onSmallCalendarDayButtonClick}
                day={day}
                key={index}
                isNotCurrentMonth
                date={currentDay}
                items={items?.filter(item =>
                  dayjs(currentDay).isSame(dayjs(item.deadline), 'date'),
                )}
              />
            )
          },
        )}
      </>
    ),
    [
      items,
      month,
      months,
      nextMonthDaysCount,
      onSmallCalendarDayButtonClick,
      prevMonthDaysCount,
      day,
      year,
    ],
  )

  const renderDayReview = useCallback(
    () => (
      <DayReview
        items={items?.filter(item => {
          return dayjs(
            extendedDayjs()
              .set('year', year)
              .set('month', month)
              .set('date', day),
          ).isSame(dayjs(item.deadline), 'date')
        })}
      />
    ),
    [items, month, day, year],
  )

  const renderSmallWeekDaysNavigator = useCallback(
    () =>
      daysOfWeek.map((item, index) => (
        <div className='text-primary px-2 text-xs' key={index}>
          {item}
        </div>
      )),
    [],
  )

  return (
    <div className='w-[256px] space-y-4'>
      <div className='flex justify-end'>
        <div className='flex items-center space-x-4'>
          {renderShowSmallCalendarButton()}
        </div>
      </div>
      <div className='bg-background space-y-4 rounded-lg p-4 shadow'>
        <div className='flex items-center justify-between'>
          <Button
            isIconOnly
            size='sm'
            variant='light'
            onPress={onPrevMonthButtonPress}
          >
            <TbChevronLeft className='text-primary text-2xl' />
          </Button>
          <div className='flex flex-1 justify-center'>
            {extendedDayjs().month(month).year(year).format('MMMM YYYY')}
          </div>
          <Button
            isIconOnly
            size='sm'
            variant='light'
            onPress={onNextMonthButtonPress}
          >
            <TbChevronRight className='text-primary text-2xl' />
          </Button>
        </div>
        <Divider />
        <div
          className={clsx(
            'grid grid-cols-7 grid-rows-[24px,repeat(5,32px)] gap-1',
            {
              '!grid-rows-[24px,repeat(6,32px)]':
                months[month].daysCount +
                  prevMonthDaysCount +
                  nextMonthDaysCount ===
                42,
            },
          )}
        >
          {renderSmallWeekDaysNavigator()}

          {renderSmallCalendar()}
        </div>
      </div>
      <div className='bg-background space-y-4 rounded-lg p-4 shadow'>
        <div>{extendedDayjs().date(day).month(month).format('DD MMMM')}</div>
        <Divider />
        {renderDayReview()}
      </div>
    </div>
  )
}
