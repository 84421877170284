import { CooperationEnum, LoyaltyEnum, PublicTypeEnum } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Checkbox,
  Input,
  Link,
} from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { TbEdit } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { allLocationsAtom } from '../../../features/store'
import { Empty, Header, Layout, PageSpinner } from '../../../features/ui'
import { getPrettyNumber } from '../../../features/utils/getPrettyNumber'
import { getSocialNetworkName } from '../../../features/utils/getSocialIcon'
import { updatePublic } from '../api/public.api'
import { cooperationConst } from '../constants/cooperation.const'
import { loyaltyConst } from '../constants/loyalty.const'
import { menuItems } from '../constants/menuItems'
import { publicTypeConst } from '../constants/publicType.const'
import { publicGroupsAtom } from '../store/group.store'
import { publicAtom, publicIdAtom } from '../store/public.store'

const pageTitle = 'Паблик'

export const Public = () => {
  const [{ data, isPending, refetch }] = useAtom(publicAtom)
  const [{ data: groups, isPending: isPendingGroups }] =
    useAtom(publicGroupsAtom)
  const [{ data: locations, isPending: isPendingLocations }] =
    useAtom(allLocationsAtom)
  const { id } = useParams()
  const [, setId] = useAtom(publicIdAtom)
  const [isEditBasicInformation, setIseditBasicInformation] = useState(false)
  const [isEditStatistics, setIseditStatistics] = useState(false)
  const [link, setLink] = useState<string | undefined>(undefined)
  const [title, setTitle] = useState<string | undefined>(undefined)
  const [organization, setOrganization] = useState<string | undefined>(
    undefined,
  )
  const [loyalty, setLoyalty] = useState<string | undefined>(undefined)
  const [cooperation, setCooperation] = useState<string | undefined>(undefined)
  const [type, setType] = useState<string | undefined>(undefined)
  const [group, setGroup] = useState<string | undefined>(undefined)
  const [cost, setCost] = useState<number | undefined>(undefined)
  const [location, setLocation] = useState<string | undefined>(undefined)
  const [contactName, setContactName] = useState<string | undefined>(undefined)
  const [contactPhone, setContactPhone] = useState<string | undefined>(
    undefined,
  )
  const [contactJobTitle, setContactJobTitle] = useState<string | undefined>(
    undefined,
  )
  const [contactLink, setContactLink] = useState<string | undefined>(undefined)
  const [subscribers, setSubscribers] = useState<number | undefined>(undefined)
  const [views, setViews] = useState<number | undefined>(undefined)
  const [subscriberDynamics, setSubscriberDynamics] = useState<
    number | undefined
  >(undefined)
  const [postingFrequency, setPostingFrequency] = useState<number | undefined>(
    undefined,
  )
  const [viewsAverage, setViewsAverage] = useState<number | undefined>(
    undefined,
  )
  const [viewsDynamic, setViewsDynamic] = useState<number | undefined>(
    undefined,
  )
  const [si, setSi] = useState<number | undefined>(undefined)
  const [er, setEr] = useState<number | undefined>(undefined)
  const [isCollectStatistic, setIsCollectStatistic] = useState(false)

  useEffect(() => {
    if (data) {
      setIsCollectStatistic(data?.isCollectStatistic)
    }
  }, [data])

  useEffect(() => {
    if (id) {
      setId(id)
    }
    return () => {
      setId(undefined)
    }
  }, [id, setId])

  const handleEditBasicInformation = useCallback(() => {
    setIseditBasicInformation(true)
    if (data) {
      setLink(data.link)
      data.title && setTitle(data.title)
      data.organization && setOrganization(data.organization)
      data.loyalty && setLoyalty(data.loyalty)
      data.cooperation && setCooperation(data.cooperation)
      data.type && setType(data.type)
      data.group?.id && setGroup(data.group.id)
      data.cost && setCost(data.cost)
      data.location?.id && setLocation(data.location.id)
      data.contactName && setContactName(data.contactName)
      data.contactPhone && setContactPhone(data.contactPhone)
      data.contactJobTitle && setContactJobTitle(data.contactJobTitle)
      data.contactLink && setContactLink(data.contactLink)
    }
  }, [data])

  const handleCancelEditBasicInformation = useCallback(() => {
    setIseditBasicInformation(false)
    setLink(undefined)
    setTitle(undefined)
    setOrganization(undefined)
    setLoyalty(undefined)
    setCooperation(undefined)
    setType(undefined)
    setGroup(undefined)
    setCost(0)
    setLocation(undefined)
    setContactName(undefined)
    setContactPhone(undefined)
    setContactJobTitle(undefined)
    setContactLink(undefined)
  }, [])

  const handleUpdateBasicInformation = useCallback(async () => {
    if (data) {
      try {
        const response = await updatePublic(data.id, {
          link,
          title,
          organization,
          loyalty: loyalty as LoyaltyEnum,
          cooperation: cooperation as CooperationEnum,
          type: type as PublicTypeEnum,
          group: group
            ? {
                id: group,
              }
            : undefined,
          cost: Number(cost),
          location: location
            ? {
                id: location,
              }
            : undefined,
          contactName,
          contactPhone,
          contactJobTitle,
          contactLink,
        })
        if (response.status && response.status === 200) {
          toast.success('Паблик успешно обновлен')
          setIseditBasicInformation(false)
          refetch()
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [
    contactJobTitle,
    contactLink,
    contactName,
    contactPhone,
    cooperation,
    cost,
    data,
    group,
    link,
    location,
    loyalty,
    organization,
    refetch,
    title,
    type,
  ])

  const handleEditStatistics = useCallback(() => {
    setIseditStatistics(true)
    if (data) {
      data.subscribers && setSubscribers(data.subscribers)
      data.views && setViews(data.views)
      data.subscriberDynamics && setSubscriberDynamics(data.subscriberDynamics)
      data.postingFrequency && setPostingFrequency(data.postingFrequency)
      data.viewsAverage && setViewsAverage(data.viewsAverage)
      data.viewsDynamic && setViewsDynamic(data.viewsDynamic)
      data.si && setSi(data.si)
      data.er && setEr(data.er)
    }
  }, [data])

  const handleCancelEditStatistics = useCallback(() => {
    setSubscribers(undefined)
    setViews(undefined)
    setSubscriberDynamics(undefined)
    setPostingFrequency(undefined)
    setViewsAverage(undefined)
    setViewsDynamic(undefined)
    setSi(undefined)
    setEr(undefined)
  }, [])

  const handleUpdateStatistics = useCallback(async () => {
    if (data) {
      try {
        const response = await updatePublic(data.id, {
          subscribers: Number(subscribers),
          subscriberDynamics: Number(subscriberDynamics),
          postingFrequency: Number(postingFrequency),
          views: Number(views),
          viewsAverage: Number(viewsAverage),
          viewsDynamic: Number(viewsDynamic),
          si: Number(si),
          er: Number(er),
          isCollectStatistic,
        })
        if (response.status && response.status === 200) {
          toast.success('Паблик успешно обновлен')
          setIseditStatistics(false)
          refetch()
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [
    data,
    er,
    isCollectStatistic,
    postingFrequency,
    refetch,
    si,
    subscriberDynamics,
    subscribers,
    views,
    viewsAverage,
    viewsDynamic,
  ])

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        {isPending ? (
          <PageSpinner />
        ) : !data ? (
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <Empty />
          </div>
        ) : (
          <div className='space-y-4 p-4'>
            <div className='flex items-center space-x-2'>
              <div className='h-6 w-6'>
                {data.link && (
                  <img
                    alt={getSocialNetworkName(data.link) || ''}
                    src={`/social-icons/${getSocialNetworkName(data.link)}.svg`}
                    className='w-full'
                  />
                )}
              </div>
              <Link
                showAnchorIcon
                isBlock
                href={data.link}
                className='text-foreground text-lg font-semibold'
              >
                {data.title || data.link}
              </Link>
            </div>
            <div className='flex space-x-4'>
              <div className='bg-background flex h-[calc(100vh-156px)] w-full flex-col space-y-4 overflow-y-auto rounded-xl p-4 shadow'>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='font-semibold'>Основная информация</div>
                  <Button
                    size='sm'
                    isIconOnly
                    isDisabled={isEditBasicInformation}
                    onPress={handleEditBasicInformation}
                    variant='bordered'
                  >
                    <TbEdit className='text-2xl' />
                  </Button>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>Ссылка</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Input
                        size='sm'
                        variant='underlined'
                        defaultValue={link}
                        onValueChange={setLink}
                      />
                    ) : (
                      <Link
                        href={data.link}
                        isExternal
                        isBlock
                        showAnchorIcon
                        className='overflow-hidden text-sm font-semibold'
                      >
                        {data.link}
                      </Link>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>Название</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Input
                        size='sm'
                        variant='underlined'
                        defaultValue={title}
                        onValueChange={setTitle}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.title}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>Организация</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Input
                        size='sm'
                        variant='underlined'
                        defaultValue={organization}
                        onValueChange={setOrganization}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.organization}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>Лояльность</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Autocomplete
                        aria-label='Лояльность'
                        variant='underlined'
                        defaultItems={Object.entries(loyaltyConst).map(
                          item => ({
                            id: item[0],
                            title: item[1],
                          }),
                        )}
                        size='sm'
                        onSelectionChange={value =>
                          setLoyalty(value as LoyaltyEnum)
                        }
                        defaultSelectedKey={loyalty}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        onKeyDown={e => e.continuePropagation()}
                      >
                        {item => (
                          <AutocompleteItem
                            key={item.id}
                            textValue={item.title}
                          >
                            {item.title}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    ) : (
                      data.loyalty && (
                        <div className='text-default-600 text-sm font-semibold'>
                          {loyaltyConst[data.loyalty]}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>Сотрудничество</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Autocomplete
                        aria-label='Сотрудничество'
                        variant='underlined'
                        defaultItems={Object.entries(cooperationConst).map(
                          item => ({
                            id: item[0],
                            title: item[1],
                          }),
                        )}
                        size='sm'
                        onSelectionChange={value =>
                          setCooperation(value as CooperationEnum)
                        }
                        defaultSelectedKey={cooperation}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        onKeyDown={e => e.continuePropagation()}
                      >
                        {item => (
                          <AutocompleteItem
                            key={item.id}
                            textValue={item.title}
                          >
                            {item.title}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    ) : (
                      data.cooperation && (
                        <div className='text-default-600 text-sm font-semibold'>
                          {cooperationConst[data.cooperation]}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>Тип паблика</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Autocomplete
                        aria-label='Тип'
                        variant='underlined'
                        defaultItems={Object.entries(publicTypeConst).map(
                          item => ({
                            id: item[0],
                            title: item[1],
                          }),
                        )}
                        size='sm'
                        onSelectionChange={value =>
                          setType(value as PublicTypeEnum)
                        }
                        defaultSelectedKey={type}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        onKeyDown={e => e.continuePropagation()}
                      >
                        {item => (
                          <AutocompleteItem
                            key={item.id}
                            textValue={item.title}
                          >
                            {item.title}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    ) : (
                      data.type && (
                        <div className='text-default-600 text-sm font-semibold'>
                          {publicTypeConst[data.type]}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>Группа</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Autocomplete
                        isLoading={isPendingGroups}
                        aria-label='Гурппы пабликов'
                        variant='underlined'
                        defaultItems={groups ? groups[0] : []}
                        size='sm'
                        onSelectionChange={value => setGroup(value as string)}
                        defaultSelectedKey={group}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        onKeyDown={e => e.continuePropagation()}
                      >
                        {item => (
                          <AutocompleteItem
                            key={item.id}
                            textValue={item.title}
                          >
                            {item.title}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    ) : (
                      data.group && (
                        <div className='text-default-600 text-sm font-semibold'>
                          {data.group.title}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    Стоиомсть размещения
                  </div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Input
                        type='number'
                        size='sm'
                        variant='underlined'
                        defaultValue={cost ? String(cost) : ''}
                        onValueChange={value => setCost(Number(value))}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.cost}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>Муниципалитет</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Autocomplete
                        aria-label='Муниципалитет'
                        isLoading={isPendingLocations}
                        variant='underlined'
                        defaultItems={locations ? locations[0] : []}
                        size='sm'
                        onSelectionChange={value =>
                          setLocation(value as string)
                        }
                        defaultSelectedKey={location}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        onKeyDown={e => e.continuePropagation()}
                      >
                        {item => (
                          <AutocompleteItem
                            key={item.id}
                            textValue={item.title}
                          >
                            {item.title}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    ) : (
                      data.location && (
                        <div className='text-default-600 text-sm font-semibold'>
                          {data.location.title}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    ФИО контактного лица
                  </div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Input
                        size='sm'
                        variant='underlined'
                        defaultValue={contactName}
                        onValueChange={setContactName}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.contactName}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    Телефон контактного лица
                  </div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Input
                        size='sm'
                        variant='underlined'
                        defaultValue={contactPhone}
                        onValueChange={setContactPhone}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.contactPhone}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    Должность контактного лица
                  </div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Input
                        size='sm'
                        variant='underlined'
                        defaultValue={contactJobTitle}
                        onValueChange={setContactJobTitle}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.contactJobTitle}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    Ссылка на контактное лицо
                  </div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditBasicInformation ? (
                      <Input
                        size='sm'
                        variant='underlined'
                        defaultValue={contactLink}
                        onValueChange={setContactLink}
                      />
                    ) : (
                      data.contactLink && (
                        <Link
                          href={data.contactLink}
                          isExternal
                          isBlock
                          showAnchorIcon
                          className='text-sm font-semibold'
                        >
                          {data.contactLink}
                        </Link>
                      )
                    )}
                  </div>
                </div>
                {isEditBasicInformation && (
                  <div className='flex flex-1 items-end justify-end space-x-4'>
                    <Button
                      variant='flat'
                      color='danger'
                      onPress={handleCancelEditBasicInformation}
                    >
                      Отменить
                    </Button>
                    <Button
                      color='primary'
                      onPress={handleUpdateBasicInformation}
                    >
                      Сохранить
                    </Button>
                  </div>
                )}
              </div>
              <div className='bg-background flex h-[calc(100vh-156px)] w-full flex-col space-y-4 overflow-y-auto rounded-xl p-4 shadow'>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='font-semibold'>Статистика</div>
                  <Button
                    size='sm'
                    isIconOnly
                    isDisabled={isEditStatistics}
                    onPress={handleEditStatistics}
                    variant='bordered'
                  >
                    <TbEdit className='text-2xl' />
                  </Button>
                </div>

                {/*Эти параметры парсятся если этот флажок включен, поэтому для корректной работы мы не можем руками менять если параметры собираются автоматически*/}
                {!data.isCollectStatistic && (
                  <>
                    <div className='flex h-8 w-full items-center justify-between space-x-4'>
                      <div className='text-default-600 text-sm'>
                        Число подписчиков
                      </div>
                      <div className='flex w-[450px] justify-end'>
                        {isEditStatistics ? (
                          <Input
                            type='number'
                            size='sm'
                            variant='underlined'
                            defaultValue={
                              subscribers ? String(subscribers) : ''
                            }
                            onValueChange={value =>
                              setSubscribers(Number(value))
                            }
                          />
                        ) : (
                          <div className='text-default-600 text-sm font-semibold'>
                            {data.subscribers
                              ? getPrettyNumber(data.subscribers)
                              : 0}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='flex h-8 w-full items-center justify-between space-x-4'>
                      <div className='text-default-600 text-sm'>
                        Динамика подписчиков
                      </div>
                      <div className='flex w-[450px] justify-end'>
                        {isEditStatistics ? (
                          <Input
                            type='number'
                            size='sm'
                            variant='underlined'
                            defaultValue={
                              subscriberDynamics
                                ? String(subscriberDynamics)
                                : ''
                            }
                            onValueChange={value =>
                              setSubscriberDynamics(Number(value))
                            }
                          />
                        ) : (
                          <div className='text-default-600 text-sm font-semibold'>
                            {data.subscriberDynamics
                              ? getPrettyNumber(data.subscriberDynamics)
                              : 0}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    Частота публикаций
                  </div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditStatistics ? (
                      <Input
                        type='number'
                        size='sm'
                        variant='underlined'
                        defaultValue={
                          postingFrequency ? String(postingFrequency) : ''
                        }
                        onValueChange={value =>
                          setPostingFrequency(Number(value))
                        }
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.postingFrequency
                          ? getPrettyNumber(data.postingFrequency)
                          : 0}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    Количество просмотров
                  </div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditStatistics ? (
                      <Input
                        type='number'
                        size='sm'
                        variant='underlined'
                        defaultValue={views ? String(views) : ''}
                        onValueChange={value => setViews(Number(value))}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.views ? getPrettyNumber(data.views) : 0}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    Среднее количество просмотров
                  </div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditStatistics ? (
                      <Input
                        type='number'
                        size='sm'
                        variant='underlined'
                        defaultValue={viewsAverage ? String(viewsAverage) : ''}
                        onValueChange={value => setViewsAverage(Number(value))}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.viewsAverage
                          ? getPrettyNumber(data.viewsAverage)
                          : 0}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    Динамика просмотров
                  </div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditStatistics ? (
                      <Input
                        type='number'
                        size='sm'
                        variant='underlined'
                        defaultValue={viewsDynamic ? String(viewsDynamic) : ''}
                        onValueChange={value => setViewsDynamic(Number(value))}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.viewsDynamic
                          ? getPrettyNumber(data.viewsDynamic)
                          : 0}
                      </div>
                    )}
                  </div>
                </div>

                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>SI</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditStatistics ? (
                      <Input
                        type='number'
                        size='sm'
                        variant='underlined'
                        defaultValue={si ? String(si) : ''}
                        onValueChange={value => setSi(Number(value))}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.si ? getPrettyNumber(data.si) : 0}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>ER</div>
                  <div className='flex w-[450px] justify-end'>
                    {isEditStatistics ? (
                      <Input
                        type='number'
                        size='sm'
                        variant='underlined'
                        defaultValue={er ? String(er) : ''}
                        onValueChange={value => setEr(Number(value))}
                      />
                    ) : (
                      <div className='text-default-600 text-sm font-semibold'>
                        {data.er ? getPrettyNumber(data.er) : 0}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex h-8 w-full items-center justify-between space-x-4'>
                  <div className='text-default-600 text-sm'>
                    Сбор статистики
                  </div>
                  <Checkbox
                    isSelected={isCollectStatistic}
                    isReadOnly={!isEditStatistics}
                    onValueChange={setIsCollectStatistic}
                  />
                </div>
                {isEditStatistics && (
                  <div className='flex flex-1 items-end justify-end space-x-4'>
                    <Button
                      variant='flat'
                      color='danger'
                      onPress={handleCancelEditStatistics}
                    >
                      Отменить
                    </Button>
                    <Button color='primary' onPress={handleUpdateStatistics}>
                      Сохранить
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  )
}
