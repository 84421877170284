import { OrganizationInterface } from '@hydra/interfaces'
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import { useAtom } from 'jotai/index'
import React, { useMemo } from 'react'

import { useMultiplier } from '../../../features/hooks/useMultiplier'
import { ratingColorfulAtom } from '../../../features/store'
import { useRatingPeriodMask } from '../hooks/useRatingPeriodMask'

Font.register({
  family: 'NotoSans',
  fonts: [
    {
      src: '/NotoSans-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: '/NotoSans-Bold.ttf',
      fontStyle: 'bold',
      fontWeight: 'bold',
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'bold',
    fontFamily: 'NotoSans',
    marginBottom: 20,
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e0e0e0',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeader: {
    backgroundColor: '#f5f5f5',
    borderBottomColor: '#e0e0e0',
    borderBottomWidth: 1,
    padding: 10,
  },
  tableCellHeader: {
    flex: 1,
    textAlign: 'center',
  },
  tableCell: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e0e0e0',
    padding: 10,
  },
  headerText: {
    fontFamily: 'NotoSans',
    fontStyle: 'bold',
    fontSize: 14,
  },
  cellText: {
    fontFamily: 'NotoSans',
    fontSize: 12,
  },
  highlightedRowGreen: {
    backgroundColor: '#deffcd',
  },
  highlightedRowYellow: {
    backgroundColor: '#fff3cd',
  },
  highlightedRowPink: {
    backgroundColor: '#f8d7da',
  },
  points: {
    textAlign: 'center',
  },
  groupName: {
    marginBottom: 5,
    fontStyle: 'bold',
    fontFamily: 'NotoSans',
    fontSize: 14,
  },
})

interface Props {
  groupName?: string
  organizations: Array<
    OrganizationInterface & {
      totalPoints: {
        fact: number
        plan: number
        points: number
      }
    }
  >
}

export const RatingTablePdf = ({ organizations, groupName }: Props) => {
  const period = useRatingPeriodMask()
  const [{ data: ratingColorful }] = useAtom(ratingColorfulAtom)
  const multiplier = useMultiplier()

  const content = useMemo(() => {
    return (
      <>
        {!groupName ? (
          <View style={styles.title}>
            <Text>Рейтинг</Text>
            <Text>информационного сопровождения деятельности</Text>
            <Text>ведомств и муниципалитетов</Text>
            <Text>за {period}</Text>
          </View>
        ) : (
          <View style={styles.groupName}>
            <Text>{groupName}</Text>
          </View>
        )}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableCellHeader}>
              <Text style={styles.headerText}>МО/ИОГВ</Text>
            </View>
            <View style={styles.tableCellHeader}>
              <Text style={styles.headerText}>Баллы</Text>
            </View>
          </View>
          {organizations
            .sort((a, b) => b.totalPoints.points - a.totalPoints.points)
            .map((item, index) => (
              <View
                style={[
                  styles.tableRow,
                  ratingColorful &&
                  item.totalPoints.points >= ratingColorful.green * multiplier
                    ? styles.highlightedRowGreen
                    : { ...styles.tableRow },
                  ratingColorful &&
                  item.totalPoints.points < ratingColorful.green * multiplier &&
                  item.totalPoints.points >= ratingColorful.orange * multiplier
                    ? styles.highlightedRowYellow
                    : { ...styles.tableRow },
                  ratingColorful &&
                  item.totalPoints.points < ratingColorful.orange * multiplier
                    ? styles.highlightedRowPink
                    : { ...styles.tableRow },
                ]}
                key={item.id}
              >
                <View style={styles.tableCell}>
                  <Text style={styles.cellText}>{item.name}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={[styles.cellText, styles.points]}>
                    {item.totalPoints.points}
                  </Text>
                </View>
              </View>
            ))}
        </View>
      </>
    )
  }, [groupName, multiplier, organizations, period, ratingColorful])

  return (
    <Document>
      {groupName ? content : <Page style={styles.page}>{content}</Page>}
    </Document>
  )
}
