import { CircularProgress, Divider, Tooltip } from '@nextui-org/react'
import clsx from 'clsx'
import plural from 'plural-ru'
import { ReactNode, useMemo } from 'react'
import { TbInfoCircle } from 'react-icons/tb'

interface Props {
  points: number
  title: string
  tooltipText: ReactNode
  fact: number
  plan: number
  progress: number
  color: 'primary' | 'success' | 'secondary' | 'warning' | 'danger'
  wrapperClasses?: string
  planText?: string
}

export const OrganizationRatingItem = ({
  points,
  title,
  fact,
  plan,
  progress,
  color,
  wrapperClasses,
  tooltipText,
  planText = 'по плану',
}: Props) => {
  const primaryStyles = useMemo(
    () => ({
      indicator: `stroke-primary`,
      track: `stroke-primary/10`,
    }),
    [],
  )
  const successStyles = useMemo(
    () => ({
      indicator: `stroke-success`,
      track: `stroke-success/10`,
    }),
    [],
  )
  const secondaryStyles = useMemo(
    () => ({
      indicator: `stroke-secondary`,
      track: `stroke-secondary/10`,
    }),
    [],
  )
  const warningStyles = useMemo(
    () => ({
      indicator: `stroke-warning`,
      track: `stroke-warning/10`,
    }),
    [],
  )
  const dangerStyles = useMemo(
    () => ({
      indicator: `stroke-danger`,
      track: `stroke-danger/10`,
    }),
    [],
  )

  return (
    <div
      className={clsx(
        'bg-background relative flex space-x-3 rounded-2xl p-4 shadow',
        wrapperClasses,
      )}
    >
      <Tooltip
        placement='top'
        content={<div className='text-sm'>{tooltipText}</div>}
        showArrow
      >
        <div className='absolute right-1 top-1'>
          <TbInfoCircle className='text-default-400 hover:text-default-600 text-lg transition-all' />
        </div>
      </Tooltip>
      <div className='flex flex-col items-center justify-center space-y-2'>
        <div className='text-6xl font-semibold'>{points}</div>
        <div className='text-default-400 text-xs'>
          {plural(points, 'балл', 'балла', 'баллов')}
        </div>
      </div>
      <Divider orientation='vertical' />
      <div className='flex flex-col justify-center space-y-2'>
        <div className='text-lg font-semibold'>{title}</div>
        <div className='text-default-400 text-sm'>
          ({fact} из {plan} {planText})
        </div>
      </div>
      <div className='flex flex-1 justify-end'>
        {color && (
          <CircularProgress
            aria-label={title}
            classNames={{
              svg: 'w-[100px] h-[100px]',
              value: 'text-2xl font-semibold text-default-400',
              ...(color === 'primary' && primaryStyles),
              ...(color === 'success' && successStyles),
              ...(color === 'secondary' && secondaryStyles),
              ...(color === 'warning' && warningStyles),
              ...(color === 'danger' && dangerStyles),
            }}
            value={progress}
            strokeWidth={3}
            showValueLabel={true}
          />
        )}
      </div>
    </div>
  )
}
