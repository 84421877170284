import {
  Navigate,
  Outlet,
  Routes as ReactRouterRoutes,
  Route,
} from 'react-router-dom'

import { routesConst } from './features/constants/routes.const'
import { AccessDenied } from './modules/accessDenied'
import { LoginPage } from './modules/auth'
import {
  CreateOrganization,
  CreateUser,
  Main,
  Organization,
  Organizations,
  Permissions,
  Profile,
  User,
  Users,
} from './modules/main'
import { Settings } from './modules/main/pages/Settings'
import {
  Chat,
  Chats,
  CreateChat,
  CreateRisk,
  Monitoring,
  Risk,
  Risks,
} from './modules/monitoring'
import { RisksCalendar } from './modules/monitoring/pages/RisksCalendar'
import {
  CreateGroup,
  CreateNewsbreak,
  CreateReport,
  Group,
  Log,
  Newsbreak,
  Newsbreaks,
  NewsbreaksAnalytics,
  Rating,
  Reports,
  ThemesCalendar,
} from './modules/newsbreaks'
import { NotFound } from './modules/notFound'
import {
  Public,
  PublicGroup,
  PublicGroups,
  Publics,
  PublicsDashboard,
} from './modules/publics'
import { CreateOrUpdatePublic } from './modules/publics/pages/CreatePublic'
import { CreatePublicGroup } from './modules/publics/pages/CreatePublicGroup'
import {
  Article,
  Articles,
  Collection,
  Collections,
  CreateArticle,
  CreateOrUpdateCollection,
  CreateOrUpdateTask,
  Folder,
  Folders,
  Tags,
  Task,
  Tasks,
  TasksCalendar,
} from './modules/tasks'
import { Transcription, Transcriptions } from './modules/transcriptions'

export const Routes = () => {
  return (
    <ReactRouterRoutes>
      <Route
        path={routesConst.main}
        element={
          !localStorage.getItem('token') ? (
            <Navigate to='/login' replace />
          ) : (
            <Outlet />
          )
        }
      >
        <Route index element={<Main />} />
        <Route path={routesConst.transcriptions}>
          <Route index element={<Transcriptions />} />
          <Route path=':id' element={<Transcription />} />
        </Route>
        {/*Модуль newsbreaks*/}
        <Route path={routesConst.newsbreaks}>
          <Route index element={<Newsbreaks />} />
          <Route path=':id' element={<Newsbreak />} />
        </Route>
        <Route path={routesConst.themeCalendar}>
          <Route index element={<ThemesCalendar />} />
        </Route>
        <Route path={routesConst.createNewsbreak}>
          <Route index element={<CreateNewsbreak />} />
        </Route>
        <Route path={routesConst.editNewsbreak}>
          <Route index element={<CreateNewsbreak isEdit />} />
        </Route>
        <Route path={routesConst.reports}>
          <Route index element={<Reports />} />
        </Route>
        <Route path={routesConst.createReport}>
          <Route index element={<CreateReport />} />
        </Route>
        <Route path={routesConst.editReport}>
          <Route index element={<CreateReport isEdit />} />
        </Route>
        <Route path={routesConst.newsbreaksAnalytics}>
          <Route index element={<NewsbreaksAnalytics />} />
        </Route>
        <Route path={routesConst.rating}>
          <Route index element={<Rating />} />
        </Route>
        <Route path={routesConst.log}>
          <Route index element={<Log />} />
          <Route path=':id' element={<Log />} />
        </Route>

        {/*Модуль main*/}
        <Route path={routesConst.permissions}>
          <Route index element={<Permissions />} />
        </Route>
        <Route path={routesConst.users}>
          <Route index element={<Users />} />
          <Route path=':id' element={<User />} />
        </Route>
        <Route path={routesConst.createUser}>
          <Route index element={<CreateUser />} />
        </Route>
        <Route path={routesConst.editUser}>
          <Route index element={<CreateUser isEdit />} />
        </Route>
        <Route path={routesConst.profile}>
          <Route index element={<Profile />} />
        </Route>
        <Route path={routesConst.organizations}>
          <Route index element={<Organizations />} />
          <Route path=':id' element={<Organization />} />
        </Route>
        <Route path={routesConst.createOrganization}>
          <Route index element={<CreateOrganization />} />
        </Route>
        <Route path={routesConst.editOrganization}>
          <Route index element={<CreateOrganization isEdit />} />
        </Route>
        <Route path={routesConst.settings}>
          <Route index element={<Settings />} />
        </Route>
        <Route path={routesConst.groups}>
          <Route path=':id' element={<Group />} />
        </Route>
        <Route path={routesConst.createGroup}>
          <Route index element={<CreateGroup />} />
        </Route>
        <Route path={routesConst.editGroup}>
          <Route index element={<CreateGroup isEdit />} />
        </Route>

        {/*Модуль tasks*/}
        <Route path={routesConst.tasks}>
          <Route index element={<Tasks />} />
          <Route path=':id' element={<Task />} />
        </Route>
        <Route path={routesConst.collections}>
          <Route index element={<Collections />} />
          <Route path=':id' element={<Collection />} />
        </Route>
        <Route path={routesConst.createTask}>
          <Route index element={<CreateOrUpdateTask />} />
        </Route>
        <Route path={routesConst.editTask}>
          <Route index element={<CreateOrUpdateTask isEdit />} />
        </Route>
        <Route path={routesConst.createCollection}>
          <Route index element={<CreateOrUpdateCollection />} />
        </Route>
        <Route path={routesConst.editCollection}>
          <Route index element={<CreateOrUpdateCollection isEdit />} />
        </Route>
        <Route path={routesConst.tasksCalendar}>
          <Route index element={<TasksCalendar />} />
        </Route>
        <Route path={routesConst.folders}>
          <Route index element={<Folders />} />
          <Route path=':id' element={<Folder />} />
        </Route>
        <Route path={routesConst.articles}>
          <Route index element={<Articles />} />
          <Route path=':id' element={<Article />} />
        </Route>
        <Route path={routesConst.createArticle}>
          <Route index element={<CreateArticle />} />
        </Route>
        <Route path={routesConst.tags}>
          <Route index element={<Tags />} />
        </Route>

        {/*Моудль publics*/}
        <Route path={routesConst.publics}>
          <Route index element={<Publics />} />
          <Route path=':id' element={<Public />} />
        </Route>
        <Route path={routesConst.publicGroups}>
          <Route index element={<PublicGroups />} />
          <Route path=':id' element={<PublicGroup />} />
        </Route>
        <Route path={routesConst.publicsDashboard}>
          <Route index element={<PublicsDashboard />} />
        </Route>
        <Route path={routesConst.createPublic}>
          <Route index element={<CreateOrUpdatePublic />} />
        </Route>
        <Route path={routesConst.editPublic}>
          <Route index element={<CreateOrUpdatePublic isEdit />} />
        </Route>
        <Route path={routesConst.createPublicGroup}>
          <Route index element={<CreatePublicGroup />} />
        </Route>
        <Route path={routesConst.editPublicGroup}>
          <Route index element={<CreatePublicGroup isEdit />} />
        </Route>
      </Route>

      {/*Моудль monitoring*/}
      <Route path={routesConst.monitoring}>
        <Route index element={<Monitoring />} />
      </Route>
      <Route path={routesConst.chats}>
        <Route index element={<Chats />} />
        <Route path=':id' element={<Chat />} />
      </Route>
      <Route path={routesConst.createChat}>
        <Route index element={<CreateChat />} />
      </Route>
      <Route path={routesConst.risks}>
        <Route index element={<Risks />} />
        <Route path=':id' element={<Risk />} />
      </Route>
      <Route path={routesConst.risksCalendar}>
        <Route index element={<RisksCalendar />} />
      </Route>
      <Route path={routesConst.createRisk}>
        <Route index element={<CreateRisk />} />
      </Route>
      <Route path='login' element={<LoginPage />} />
      <Route path='access-denied' element={<AccessDenied />} />
      <Route path='*' element={<NotFound />} />
    </ReactRouterRoutes>
  )
}
