import { PublicGroupInterface } from '@hydra/interfaces'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { publicGroupsAtom } from '../store/group.store'

export const PublicGroupsList = () => {
  const navigate = useNavigate()
  const [{ data, isPending }] = useAtom(publicGroupsAtom)

  const renderCell = useCallback(
    (item: PublicGroupInterface, columnKey: Key) => {
      if (columnKey === 'title') {
        return <div className='w-[250px]'>{item.title}</div>
      }
      if (columnKey === 'publics') {
        return <div className='w-[250px]'>{item.publics?.length}</div>
      }
    },
    [],
  )

  if (isPending) return <PageSpinner />

  if (!data || data[0].length <= 0) return <Empty />

  return (
    <Table
      aria-label='Задачи'
      isHeaderSticky
      isStriped
      removeWrapper
      onRowAction={key => navigate(routesConst.publicGroups + '/' + key)}
      classNames={{
        base: 'h-[calc(100vh-156px)] overflow-auto',
      }}
    >
      <TableHeader
        columns={[
          {
            key: 'title',
            label: 'Название',
          },
          {
            key: 'publics',
            label: 'Количество пабликов',
          },
        ]}
      >
        {column => (
          <TableColumn key={column.key} textValue={column.label}>
            {column.label}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        items={data[0]}
        emptyContent={
          <div className='text-default-400 text-sm'>Нет данных</div>
        }
      >
        {item => (
          <TableRow key={item.id} textValue={item.id}>
            {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
