import { cn } from '@udecode/cn'
import { PlateContent, PlateContentProps } from '@udecode/plate-common'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import React from 'react'

const editorVariants = cva(
  cn(
    'relative overflow-x-auto whitespace-pre-wrap break-words',
    'w-full rounded-small shadow-sm bg-background px-8 py-4 text-sm ring-offset-background placeholder:text-default-400 focus-visible:outline-none',
    '[&_[data-slate-placeholder]]:text-default-400 [&_[data-slate-placeholder]]:!opacity-100',
    '[&_[data-slate-placeholder]]:top-[auto_!important]',
    '[&_strong]:font-bold',
    'border-2 border-default-200 rounded-t-none',
  ),
  {
    variants: {
      variant: {
        outline: 'border border-primary',
        ghost: '',
      },
      focused: {
        true: 'ring-2 ring-ring ring-offset-2',
      },
      readOnly: {
        true: '!rounded-t-small border-0 shadow-none p-0 !h-auto',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-50',
      },
      focusRing: {
        true: 'focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        false: '',
      },
      size: {
        sm: 'text-sm',
        md: 'text-base',
      },
    },
    defaultVariants: {
      variant: 'ghost',
      focusRing: false,
      size: 'sm',
    },
  },
)

export type EditorProps = PlateContentProps &
  VariantProps<typeof editorVariants>

const Editor = React.forwardRef<HTMLDivElement, EditorProps>(
  (
    {
      className,
      disabled,
      focused,
      focusRing,
      readOnly,
      size,
      variant,
      ...props
    },
    ref,
  ) => {
    return (
      <div ref={ref} className='relative w-full font-[inherit]'>
        <PlateContent
          className={cn(
            editorVariants({
              disabled,
              focused,
              focusRing,
              size,
              variant,
              readOnly,
            }),
            className,
          )}
          disableDefaultStyles
          readOnly={disabled ?? readOnly}
          aria-disabled={disabled}
          {...props}
        />
      </div>
    )
  },
)
Editor.displayName = 'Editor'

export { Editor }
