import {
  Autocomplete,
  AutocompleteItem,
  Divider,
  Input,
} from '@nextui-org/react'
import { useAtom, useAtomValue } from 'jotai/index'
import SimpleBar from 'simplebar-react'

import { allLocationsAtom } from '../../../features/store'
import {
  addressFilterAtom,
  importanceFilterAtom,
  locationFilterAtom,
  riskImportancesAtom,
  riskThemesAtom,
  riskTypesAtom,
  themeFilterAtom,
  typeFilterAtom,
} from '../store/risk.store'

export const RiskFilters = () => {
  const [address, setAddress] = useAtom(addressFilterAtom)
  const [type, setType] = useAtom(typeFilterAtom)
  const [theme, setTheme] = useAtom(themeFilterAtom)
  const [importance, setImportance] = useAtom(importanceFilterAtom)
  const [location, setLocation] = useAtom(locationFilterAtom)
  const { data: types, isPending: isTypesPending } = useAtomValue(riskTypesAtom)

  const [{ data: locations, isPending: isPendingLocations }] =
    useAtom(allLocationsAtom)

  const { data: themes, isPending: isThemesPending } =
    useAtomValue(riskThemesAtom)

  const { data: importances, isPending: isImportancesPending } =
    useAtomValue(riskImportancesAtom)

  return (
    <SimpleBar className='bg-background mt-[52px] h-[calc(100vh-208px)] rounded-xl shadow'>
      <div className='space-y-4 p-4'>
        <div className='text-lg font-semibold'>Фильтры</div>
        <Divider />
        <div>
          <Input
            size='sm'
            variant='bordered'
            label='Найти по адресу ...'
            aria-label='Адрес'
            value={address}
            onValueChange={setAddress}
          />
        </div>
        <div>
          <Autocomplete
            isDisabled={isPendingLocations}
            aria-label='Локация'
            isLoading={isPendingLocations}
            variant='bordered'
            defaultItems={locations ? locations[0] : []}
            size='sm'
            label='Локация'
            onSelectionChange={key => setLocation(key as string)}
            selectedKey={location}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onKeyDown={e => e.continuePropagation()}
          >
            {item => (
              <AutocompleteItem key={item.id} textValue={item.title}>
                {item.title}
              </AutocompleteItem>
            )}
          </Autocomplete>
        </div>
        <div>
          <Autocomplete
            isDisabled={isTypesPending}
            aria-label='Тип'
            isLoading={isTypesPending}
            variant='bordered'
            defaultItems={types ? types : []}
            size='sm'
            label='Тип'
            onSelectionChange={key => setType(key as string)}
            selectedKey={type}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onKeyDown={e => e.continuePropagation()}
          >
            {item => (
              <AutocompleteItem key={item.id} textValue={item.title}>
                {item.title}
              </AutocompleteItem>
            )}
          </Autocomplete>
        </div>
        <div>
          <Autocomplete
            isDisabled={isThemesPending}
            aria-label='Тема'
            isLoading={isThemesPending}
            variant='bordered'
            defaultItems={themes ? themes : []}
            size='sm'
            label='Тема'
            onSelectionChange={key => setTheme(key as string)}
            selectedKey={theme}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onKeyDown={e => e.continuePropagation()}
          >
            {item => (
              <AutocompleteItem key={item.id} textValue={item.title}>
                {item.title}
              </AutocompleteItem>
            )}
          </Autocomplete>
        </div>
        <div>
          <Autocomplete
            isDisabled={isImportancesPending}
            aria-label='Важность'
            isLoading={isImportancesPending}
            variant='bordered'
            defaultItems={importances ? importances : []}
            size='sm'
            label='Важность'
            onSelectionChange={key => setImportance(key as string)}
            selectedKey={importance}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onKeyDown={e => e.continuePropagation()}
          >
            {item => (
              <AutocompleteItem key={item.id} textValue={item.title}>
                {item.title}
              </AutocompleteItem>
            )}
          </Autocomplete>
        </div>
      </div>
    </SimpleBar>
  )
}
