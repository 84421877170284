import { Button, Progress, useDisclosure } from '@nextui-org/react'
import { useAtom } from 'jotai/index'

import { newsbreakReportsAtom } from '../store/newsbreaks.store'
import { ReportsModal } from './ReportsModal'

export const ReportsComplete = () => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const [{ data }] = useAtom(newsbreakReportsAtom)

  if (!data) return null

  return (
    <div className='space-y-4'>
      <div className='flex items-center justify-between'>
        <div className='font-semibold'>Исполнение задачи</div>
        {data.reports.length && (
          <Button color='primary' variant='light' size='sm' onPress={onOpen}>
            Посмотреть все
          </Button>
        )}
        <ReportsModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          onClose={onClose}
          newsbreak={data}
        />
      </div>
      <Progress
        label='Загружено отчетов'
        value={data.reports.length}
        maxValue={data.organizationToNewsbreak.length}
        color='primary'
        showValueLabel={true}
        valueLabel={
          <div className='text-small'>
            {data.reports.length || 0}/{data.organizationToNewsbreak.length}
          </div>
        }
      />
    </div>
  )
}
