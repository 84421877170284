import { z } from 'zod'

export const createGroupSchema = z.object({
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  organizations: z.array(z.string(), {
    required_error: 'Обязательное поле',
  }),
})
