import { FolderInterface } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from '../../../features/api'

export const getFolders = async (params: {
  isPublic: boolean
}): Promise<AxiosResponse<Array<FolderInterface>>> => {
  return instance.get('/knowledge/all-folders', { params })
}

export const getFolderById = (
  id: string,
): Promise<AxiosResponse<FolderInterface>> => {
  return instance.get(`/knowledge/folder-by-id/${id}`, {})
}

export const createFolder = (data: { title: string; isPublic: boolean }) => {
  return instance.post('/knowledge/create-folder', data)
}

export const updateFolder = (
  id: string,
  data: {
    title?: string
  },
) => {
  return instance.patch(`/knowledge/update-folder/${id}`, data)
}

export const deleteFolder = (id: string) => {
  return instance.delete(`/knowledge/delete-folder/${id}`)
}
