import { Grant } from '@hydra/interfaces'
import { Autocomplete, AutocompleteItem } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { Key, useCallback, useMemo } from 'react'

import { useGrant } from '../../../features/grants'
import { allUsersAtom, profileAtom } from '../../../features/store'
import {
  organizationIdAtom,
  statusTabAtom,
  userIdAtom,
} from '../store/newsbreaks.store'

export const UserFilter = () => {
  const [{ data }] = useAtom(allUsersAtom)
  const { isValidGrant: isEditor } = useGrant(Grant.newsbreaksEditor)
  const [{ data: profile }] = useAtom(profileAtom)
  const [id, setId] = useAtom(userIdAtom)
  const [, setTab] = useAtom(statusTabAtom)
  const [organizationId] = useAtom(organizationIdAtom)

  const onSelectionChange = useCallback(
    (id: Key | null) => {
      setTab('all')
      setId(id ? String(id) : undefined)
    },
    [setTab, setId],
  )

  const item = useMemo(
    () =>
      id ? (data ? data[0].find(i => i.id === id) : undefined) : undefined,
    [id, data],
  )

  const itemValue = useMemo(
    () => (item ? `${item.name} ${item.surname}` : undefined),
    [item],
  )

  return (
    <div className='space-y-1'>
      <div className='flex w-full items-center justify-between'>
        <label
          className='text-small text-default-400'
          htmlFor='newsbreaks-author-filter'
        >
          Автор
        </label>
      </div>
      <Autocomplete
        aria-label='автор'
        variant='bordered'
        defaultItems={
          data
            ? isEditor
              ? data[0].filter(
                  u => u.organization.id === profile?.profile.organization.id,
                )
              : data[0].filter(u =>
                  organizationId ? u.organization?.id === organizationId : true,
                )
            : []
        }
        placeholder='Выберите автора'
        defaultInputValue={itemValue}
        onSelectionChange={onSelectionChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onKeyDown={e => e.continuePropagation()}
      >
        {user => (
          <AutocompleteItem
            key={user.id}
            value={user.id}
            textValue={`${user.name} ${user.surname}`}
          >
            {user.name} {user.surname}
          </AutocompleteItem>
        )}
      </Autocomplete>
    </div>
  )
}
