import { cn, withRef } from '@udecode/cn'
import { PlateLeaf } from '@udecode/plate-common'
import React from 'react'

export const HighlightLeaf = withRef<typeof PlateLeaf>(
  ({ className, children, ...props }, ref) => (
    <PlateLeaf
      ref={ref}
      asChild
      className={cn('bg-primary/20 dark:bg-primary/40 text-inherit', className)}
      {...props}
    >
      <mark>{children}</mark>
    </PlateLeaf>
  ),
)
