import { Link } from '@nextui-org/react'
import { useAtom } from 'jotai'
import plural from 'plural-ru'
import { IoDocumentText } from 'react-icons/io5'
import { TbArrowUpRight } from 'react-icons/tb'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { foldersAtom } from '../store/folders.store'

export const FoldersList = () => {
  const [{ data, isPending }] = useAtom(foldersAtom)

  if (isPending) return <PageSpinner />

  if (!data) return <Empty />

  return (
    <div className='flex flex-wrap gap-4'>
      {data.map(folder => (
        <Link
          href={routesConst.folders + '/' + folder.id}
          key={folder.id}
          className='bg-background text-foreground flex h-[150px] w-[300px] flex-col items-start justify-between space-y-4 rounded-2xl p-4 shadow'
        >
          <div className='line-clamp-2 font-semibold'>{folder.title}</div>
          <div className='flex w-full items-center justify-between'>
            <div className='flex items-center space-x-1'>
              <IoDocumentText className='text-primary' />
              <span className='text-default-600 flex text-xs'>
                {folder.articles?.length} {''}
                {plural(
                  folder.articles?.length || 0,
                  'статья',
                  'статьи',
                  'статей',
                )}
              </span>
            </div>
            <div className='bg-primary-50 rounded-xl p-1'>
              <TbArrowUpRight className='text-primary text-4xl' />
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}
