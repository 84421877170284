export function getMimeType(url: string): string {
  const extensionToMimeType: { [key: string]: string } = {
    mp3: 'audio/mpeg',
    wav: 'audio/wav',
    ogg: 'audio/ogg',
    aac: 'audio/aac',
    midi: 'audio/midi',

    // Видео расширения
    mp4: 'video/mp4',
    mov: 'video/quicktime',
    avi: 'video/x-msvideo',
    mpeg: 'video/mpeg',
    webm: 'video/webm',
    ogv: 'video/ogg',

    // Изображения
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    webp: 'image/webp',

    // Документы
    txt: 'text/plain',
    pdf: 'application/pdf',
    json: 'application/json',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    odt: 'application/vnd.oasis.opendocument.text',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    odp: 'application/vnd.oasis.opendocument.presentation',
    zip: 'application/zip',
  }

  const parsedUrl = new URL(url)
  const pathname = parsedUrl.pathname
  const lastDotIndex = pathname.lastIndexOf('.')

  const extension = pathname.substring(lastDotIndex + 1).toLowerCase()

  return extensionToMimeType[extension] || ''
}
