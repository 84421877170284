import { TagInterface } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from '../../../features/api'

export const getTags = async (): Promise<
  AxiosResponse<Array<TagInterface>>
> => {
  return instance.get('/knowledge/all-tags', {})
}

export const createTag = (data: { title: string }) => {
  return instance.post('/knowledge/create-tag', data)
}

export const updateTag = (id: string, data: { title: string }) => {
  return instance.patch(`/knowledge/update-tag/${id}`, data)
}

export const deleteTag = (id: string) => {
  return instance.delete(`/knowledge/delete-tag/${id}`)
}
