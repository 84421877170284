import { CollectionInterface } from '@hydra/interfaces'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'

import { getCollectionById, getTasksCollections } from '../api/collections.api'

export const skipAtom = atom(0)
export const takeAtom = atom(25)
export const orderAtom = atom<keyof CollectionInterface>('createdAt')
export const directionAtom = atom<'desc' | 'asc'>('desc')
export const collectionIdAtom = atom<string | undefined>(undefined)

export const collectionsAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'collections-list',
      get(skipAtom),
      get(takeAtom),
      get(orderAtom),
      get(directionAtom),
    ],
    queryFn: async () => {
      const response = await getTasksCollections({
        skip: get(skipAtom),
        take: get(takeAtom),
        order: get(orderAtom),
        direction: get(directionAtom),
      })
      return response.data
    },
  }
})

export const collectionAtom = atomWithQuery(get => {
  return {
    enabled: !!get(collectionIdAtom),
    queryKey: ['collection-by-id', get(collectionIdAtom)],
    queryFn: async () => {
      const response = await getCollectionById(get(collectionIdAtom) || '')
      return response.data
    },
  }
})
