import {
  Button,
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { TbSelect } from 'react-icons/tb'

import { organizationByIdAtom } from '../../../features/store'
import { Empty } from '../../../features/ui'
import { AddLocationsModal } from './AddLocationsModal'

export const OrganizationLocations = () => {
  const [{ data, refetch }] = useAtom(organizationByIdAtom)

  const {
    isOpen: isOpenAddLocationsModal,
    onOpen: onOpenAddLocationsModal,
    onOpenChange: onOpenChangeAddLocationsModal,
    onClose: onCloseAddLocationsModal,
  } = useDisclosure()

  if (!data) return <Empty />

  return (
    <>
      <AddLocationsModal
        defaultLocations={data.locations}
        organizationId={data.id}
        isOpen={isOpenAddLocationsModal}
        onOpenChange={onOpenChangeAddLocationsModal}
        onClose={onCloseAddLocationsModal}
        refetch={refetch}
      />
      <div className='space-y-4'>
        <div className='flex justify-end'>
          <Button
            size='sm'
            color='primary'
            startContent={<TbSelect className='text-lg' />}
            onPress={onOpenAddLocationsModal}
          >
            Изменить
          </Button>
        </div>
        <Table
          aria-label='Места публикации организации'
          isHeaderSticky
          isStriped
          removeWrapper
          classNames={{
            base: 'h-[calc(100vh-208px)] overflow-auto',
          }}
        >
          <TableHeader>
            <TableColumn key='title'>Название</TableColumn>
            <TableColumn key='actions'>
              <div />
            </TableColumn>
          </TableHeader>
          <TableBody
            items={data.locations}
            emptyContent={
              <div className='text-default-400 text-sm'>Не указаны</div>
            }
          >
            {item => (
              <TableRow key={item.id}>
                {columnKey => (
                  <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
