import { PublicInterface } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react'
import clsx from 'clsx'
import { useAtom, useAtomValue } from 'jotai/index'
import { Key, useCallback, useRef } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { RiDraggable } from 'react-icons/ri'
import {
  TbArrowDown,
  TbArrowUp,
  TbFilterFilled,
  TbSettingsFilled,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { useLocalStorage } from 'usehooks-ts'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { getPrettyNumber } from '../../../features/utils/getPrettyNumber'
import { getSocialNetworkName } from '../../../features/utils/getSocialIcon'
import { cooperationConst } from '../constants/cooperation.const'
import { loyaltyConst } from '../constants/loyalty.const'
import { publicTypeConst } from '../constants/publicType.const'
import {
  areaTypeAtom,
  columnsAtom,
  publicCountsAtom,
  publicsAtom,
} from '../store/public.store'

function DraggableItem({
  column,
  index,
  moveColumn,
}: {
  column: {
    key: string
    label: string
    isEnable: boolean
  }
  index: number
  moveColumn: (dragIndex: number, hoverIndex: number) => void
}) {
  const [columns, setColumns] = useAtom(columnsAtom)
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: 'COLUMN',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      moveColumn(dragIndex, hoverIndex)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN',
    item: () => {
      return { id: column.key, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <div
      ref={ref}
      className={clsx('flex items-center justify-between', {
        'opacity-50': isDragging,
      })}
    >
      <Checkbox
        isSelected={column.isEnable}
        onValueChange={value => {
          const newColumns = [...columns]
          newColumns[index] = { ...column, isEnable: value }
          if (newColumns.filter(c => c.isEnable).length < 1) {
            return toast.error(
              'В таблице должна быть выбрана хотя бы одна колонка',
            )
          }
          setColumns(newColumns)
        }}
      >
        {column.label}
      </Checkbox>
      <RiDraggable className='cursor-pointer text-lg' />
    </div>
  )
}

export const PublicsList = () => {
  const navigate = useNavigate()
  const [columns, setColumns] = useAtom(columnsAtom)
  const [areaType, setAreaType] = useAtom(areaTypeAtom)
  const { data, isPending } = useAtomValue(publicsAtom)
  const { data: counts } = useAtomValue(publicCountsAtom)

  const [isPublicsFilters, setIsPublicsFilters] = useLocalStorage(
    'publicsFilters',
    true,
  )

  const {
    isOpen: isOpenSettingsModal,
    onOpen: onOpenSettingsModal,
    onOpenChange: onOpenChangeSettingsModal,
  } = useDisclosure()

  const renderCell = useCallback((item: PublicInterface, columnKey: Key) => {
    if (columnKey === 'title') {
      return <div className='w-[250px]'>{item.title}</div>
    }
    if (columnKey === 'area') {
      const icon = getSocialNetworkName(item.link)
      return (
        <div className='w-[100px]'>
          <div className='h-5 w-5'>
            {icon && (
              <img
                alt={icon}
                src={`/social-icons/${icon}.svg`}
                className='w-full'
              />
            )}
          </div>
        </div>
      )
    }
    if (columnKey === 'link') {
      return <div className='w-[180px] overflow-hidden'>{item.link}</div>
    }
    if (columnKey === 'organization') {
      return <div className='w-[180px]'>{item.organization}</div>
    }
    if (columnKey === 'subscribers') {
      return (
        <div className='w-[200px]'>
          {item.subscribers ? getPrettyNumber(+item.subscribers) : 0}
        </div>
      )
    }
    if (columnKey === 'subscriberDynamics') {
      return (
        <div
          className={clsx('flex w-[220px] items-center space-x-0 ', {
            'text-success': item?.subscriberDynamics
              ? +item.subscriberDynamics > 0
              : '',
            'text-danger': item?.subscriberDynamics
              ? +item.subscriberDynamics < 0
              : '',
          })}
        >
          <span>
            {item.subscriberDynamics
              ? getPrettyNumber(+item.subscriberDynamics)
              : 0}
          </span>
          <span>
            {item?.subscriberDynamics && +item.subscriberDynamics > 0 && (
              <TbArrowUp />
            )}
            {item?.subscriberDynamics && +item.subscriberDynamics < 0 && (
              <TbArrowDown />
            )}
          </span>
        </div>
      )
    }
    if (columnKey === 'postingFrequency') {
      return (
        <div className='w-[200px]'>
          {item.postingFrequency ? getPrettyNumber(+item.postingFrequency) : 0}
        </div>
      )
    }
    if (columnKey === 'views') {
      return (
        <div className='w-[200px]'>
          {item.views ? getPrettyNumber(+item.views) : 0}
        </div>
      )
    }
    if (columnKey === 'viewsAverage') {
      return (
        <div className='w-[250px]'>
          {item.viewsAverage ? getPrettyNumber(+item.viewsAverage) : 0}
        </div>
      )
    }
    if (columnKey === 'viewsDynamic') {
      return (
        <div
          className={clsx('flex w-[220px] items-center space-x-0', {
            'text-success': item?.viewsDynamic ? +item.viewsDynamic > 0 : '',
            'text-danger': item?.viewsDynamic ? +item.viewsDynamic < 0 : '',
          })}
        >
          <span>
            {item.viewsDynamic ? getPrettyNumber(+item.viewsDynamic) : 0}
          </span>
          <span>
            {item?.viewsDynamic && +item.viewsDynamic > 0 && <TbArrowUp />}
            {item?.viewsDynamic && +item.viewsDynamic < 0 && <TbArrowDown />}
          </span>
        </div>
      )
    }
    if (columnKey === 'si') {
      return (
        <div className='w-[60px]'>
          {item.si ? getPrettyNumber(+item.si) : 0}
        </div>
      )
    }
    if (columnKey === 'er') {
      return (
        <div className='w-[60px]'>
          {item.er ? getPrettyNumber(+item.er) : 0}
        </div>
      )
    }
    if (columnKey === 'loyalty' && item.loyalty) {
      return <div className='w-[130px]'>{loyaltyConst[item.loyalty]}</div>
    }
    if (columnKey === 'cooperation' && item.cooperation) {
      return (
        <div className='w-[150px]'>{cooperationConst[item.cooperation]}</div>
      )
    }
    if (columnKey === 'cost') {
      return <div className='w-[200px]'>{item.cost}</div>
    }
    if (columnKey === 'contactName') {
      return <div className='w-[200px]'>{item.contactName}</div>
    }
    if (columnKey === 'type' && item.type) {
      return <div className='w-[135px]'>{publicTypeConst[item.type]}</div>
    }
    if (columnKey === 'location') {
      return <div className='w-[170px]'>{item.location?.title}</div>
    }
    if (columnKey === 'group') {
      return <div className='w-[170px]'>{item.group?.title}</div>
    }
  }, [])

  const moveColumn = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setColumns(prevColumns => {
        const newColumns = [...prevColumns]
        const dragged = newColumns.splice(dragIndex, 1)[0]
        newColumns.splice(hoverIndex, 0, dragged)
        return newColumns
      })
    },
    [setColumns],
  )

  if (isPending) return <PageSpinner />

  return (
    <>
      <div className='space-y-4'>
        <div className='flex justify-between'>
          <div className='flex space-x-4 text-sm font-semibold'>
            <div className='bg-background flex items-center justify-center space-x-1 rounded-lg px-2 shadow'>
              <span className='text-primary'>Количество сообществ:</span>
              <span>{data ? getPrettyNumber(data[1]) : 0}</span>
            </div>
            <div className='bg-background flex items-center justify-center space-x-1 rounded-lg px-2 shadow'>
              <span className='text-primary'>Количество подписчиков:</span>
              <span>
                {counts?.subscribers ? getPrettyNumber(+counts.subscribers) : 0}
              </span>
            </div>
            <div className='bg-background flex items-center justify-center space-x-1 rounded-lg px-2 shadow'>
              <span className='text-primary'>Средние просмотры:</span>
              <span>
                {counts?.viewsAverage
                  ? getPrettyNumber(counts.viewsAverage)
                  : 0}
              </span>
            </div>

            {counts?.lastUpdate && (
              <div className='bg-background flex items-center justify-center space-x-1 rounded-lg px-2 shadow'>
                <span className='text-primary'>Статистика обновлена:</span>
                <span>{counts?.lastUpdate}</span>
              </div>
            )}
          </div>
          <div className='flex space-x-4'>
            <Button isIconOnly variant='bordered' onPress={onOpenSettingsModal}>
              <TbSettingsFilled className='text-lg' />
            </Button>
            <Button
              isIconOnly
              color={isPublicsFilters ? 'primary' : 'default'}
              variant='bordered'
              onPress={() => setIsPublicsFilters(!isPublicsFilters)}
            >
              <TbFilterFilled className='text-lg' />
            </Button>
          </div>
        </div>
        <div
          className={clsx('grid gap-4', {
            'grid-cols-[1fr_350px]': isPublicsFilters,
            'grid-cols-1': !isPublicsFilters,
          })}
        >
          {!data || data[0].length <= 0 ? (
            <Empty />
          ) : (
            <SimpleBar className='bg-background h-[calc(100vh-212px)] overflow-auto rounded-xl p-4 shadow'>
              <Table
                aria-label='Задачи'
                isHeaderSticky
                isStriped
                removeWrapper
                onRowAction={key => navigate(routesConst.publics + '/' + key)}
              >
                <TableHeader columns={columns.filter(c => c.isEnable)}>
                  {column => (
                    <TableColumn key={column.key} textValue={column.label}>
                      {column.label}
                    </TableColumn>
                  )}
                </TableHeader>
                <TableBody
                  items={data[0]}
                  emptyContent={
                    <div className='text-default-400 text-sm'>Нет данных</div>
                  }
                >
                  {item => (
                    <TableRow key={item.id} textValue={item.id}>
                      {columnKey => (
                        <TableCell>{renderCell(item, columnKey)}</TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </SimpleBar>
          )}
          {isPublicsFilters && (
            <SimpleBar className='bg-background h-[calc(100vh-212px)] rounded-xl shadow'>
              <div className='space-y-4 p-4'>
                <div className='text-lg font-semibold'>Фильтры</div>
                <Divider />
                <div>
                  <div>
                    <Autocomplete
                      aria-label='Тип площадки'
                      isLoading={isPending}
                      variant='bordered'
                      defaultItems={[
                        {
                          id: 't.me',
                          title: 'Телеграм',
                        },
                        {
                          id: 'vk.com',
                          title: 'VK',
                        },
                        {
                          id: 'ok.ru',
                          title: 'Одноклассники',
                        },
                      ]}
                      size='sm'
                      label='Тип площадки'
                      onSelectionChange={key => setAreaType(key as string)}
                      selectedKey={areaType}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      onKeyDown={e => e.continuePropagation()}
                    >
                      {item => (
                        <AutocompleteItem key={item.id} textValue={item.title}>
                          {item.title}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                  </div>
                </div>
              </div>
            </SimpleBar>
          )}
        </div>
      </div>

      <Modal
        scrollBehavior='inside'
        isOpen={isOpenSettingsModal}
        onOpenChange={onOpenChangeSettingsModal}
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Настройки таблицы
              </ModalHeader>
              <ModalBody className=''>
                <DndProvider backend={HTML5Backend}>
                  {columns.map((c, i) => (
                    <DraggableItem
                      key={c.key}
                      column={c}
                      index={i}
                      moveColumn={moveColumn}
                    />
                  ))}
                </DndProvider>
              </ModalBody>
              <ModalFooter>
                <Button
                  fullWidth
                  onPress={() => {
                    const newColumns = columns.map(c => ({
                      ...c,
                      isEnable: true,
                    }))
                    setColumns(newColumns)
                  }}
                >
                  Показать все
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
