import { TaskInterface, TranscriptionsInterface } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'
import { SortDirection } from 'typeorm'

import { instance } from '../../../features/api'

export const create = async (file: File): Promise<AxiosResponse<string>> => {
  const formData = new FormData()
  formData.append('file', file)

  return instance.post('/transcriptions/create-transcription', formData)
}

export const getTranscriptions = async (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof TaskInterface
}): Promise<AxiosResponse<[Array<TranscriptionsInterface>, number]>> => {
  return instance.get('/transcriptions/all-transcriptions', {
    params,
  })
}

export const getTranscriptionById = (
  id: string,
): Promise<AxiosResponse<TranscriptionsInterface>> => {
  return instance.get(`/transcriptions/transcription-by-id/${id}`, {})
}
