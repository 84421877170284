import { PublicInterface } from '@hydra/interfaces'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'

import { getChatById, getChats } from '../api/chat.api'

export const skipAtom = atom(0)
export const takeAtom = atom(25)
export const chatIdAtom = atom<string | undefined>(undefined)
export const orderAtom = atom<keyof PublicInterface>('createdAt')
export const directionAtom = atom<'desc' | 'asc'>('desc')

export const chatsAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'chats-list',
      get(skipAtom),
      get(takeAtom),
      get(orderAtom),
      get(directionAtom),
    ],
    queryFn: async () => {
      const response = await getChats({
        skip: get(skipAtom),
        take: get(takeAtom),
        order: get(orderAtom),
        direction: get(directionAtom),
      })
      return response.data
    },
  }
})

export const chatAtom = atomWithQuery(get => {
  return {
    enabled: !!get(chatIdAtom),
    queryKey: ['chat-by-id', get(chatIdAtom)],
    queryFn: async () => {
      const response = await getChatById(get(chatIdAtom) || '')
      return response.data
    },
  }
})
