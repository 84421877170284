export function generateMailtoLink(
  email: string,
  subject?: string,
  body?: string,
): string {
  let link = `mailto:${email}`
  const params = []

  if (subject) {
    params.push(`subject=${encodeURIComponent(subject)}`)
  }
  if (body) {
    params.push(`body=${encodeURIComponent(body)}`)
  }

  if (params.length > 0) {
    link += '?' + params.join('&')
  }

  return link
}
