import {
  AreaInterface,
  AreaTypeEnum,
  OrganizationInterface,
} from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from './instance'

export const getAllAreas = (): Promise<
  AxiosResponse<[Array<AreaInterface>, number]>
> => {
  return instance.get('/areas/all')
}

export const createArea = (data: {
  title: string
  link: string
  type: AreaTypeEnum
  organization: Pick<OrganizationInterface, 'id'>
}) => {
  return instance.post('/areas/create', data)
}

export const deleteArea = (id: string) => {
  return instance.delete(`/areas/delete/${id}`)
}
