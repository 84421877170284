import { NewsbreakInterface } from '@hydra/interfaces'
import {
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback } from 'react'
import { Cell, Pie, PieChart, Tooltip as TooltipRecharts } from 'recharts'

import { routesConst } from '../../../features/constants/routes.const'
import { PageSpinner } from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { getPrettyNumber } from '../../../features/utils/getPrettyNumber'
import { newsbreaksAnalyticsAtom } from '../store/newsbreaks.store'

export const AnalyticsAdditionalInformation = () => {
  const [{ data, isPending }] = useAtom(newsbreaksAnalyticsAtom)

  const renderCell = useCallback((item: NewsbreakInterface, columnKey: Key) => {
    if (columnKey === 'title') {
      return (
        <div className='break-all'>
          <Link isBlock href={routesConst.newsbreaks + '/' + item.id}>
            {item.title}
          </Link>
        </div>
      )
    }

    if (columnKey === 'links') {
      const linksCount = item.reports.reduce((acc, r) => {
        return acc + r.links.length
      }, 0)
      return <div className='break-all'>{getPrettyNumber(linksCount)}</div>
    }

    if (columnKey === 'createdAt') {
      return (
        <div className='break-all'>
          {getPrettyDate({ date: item.createdAt })}
        </div>
      )
    }
  }, [])

  if (isPending) return <PageSpinner />

  return (
    <div className='grid grid-cols-[400px_1fr] grid-rows-[400px_400px] gap-4'>
      <div className='bg-background flex flex-col space-y-4 rounded-xl p-4 shadow'>
        <div className='flex flex-1 flex-col items-center space-y-4'>
          <h2 className='font-semibold'>Спродюсировано</h2>
          <div className='text-primary text-7xl font-semibold'>
            {getPrettyNumber(data?.producedCount || 0)}
          </div>
        </div>
        <Divider />
        <div className='flex flex-1 flex-col items-center space-y-4'>
          <h2 className='font-semibold'>Федеральная повестка</h2>
          <div className='text-primary text-7xl font-semibold'>
            {getPrettyNumber(data?.federalLevelCount || 0)}
          </div>
        </div>
      </div>
      <Table
        aria-label='Инфоповоды'
        isHeaderSticky
        isStriped
        removeWrapper
        classNames={{
          base: 'bg-background col-start-2 col-end-3 row-start-1 row-end-3 overflow-auto rounded-xl p-4 shadow',
        }}
      >
        <TableHeader>
          <TableColumn key='title'>Заголовок инфоповода</TableColumn>
          <TableColumn key='links'>Количество ссылок</TableColumn>
          <TableColumn key='createdAt'>Дата создания</TableColumn>
        </TableHeader>
        <TableBody
          emptyContent='За выбронный период данные отсутствуют'
          items={data?.newsbreaks}
        >
          {item => (
            <TableRow key={item.id}>
              {columnKey => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className='bg-background flex flex-col items-center justify-center rounded-xl p-4 shadow'>
        <div className='text-sm font-semibold'>Инфоповоды по важности</div>
        {data?.importances.length ? (
          <div className='flex-1'>
            <PieChart width={250} height={250}>
              <Pie
                data={data.importances}
                cx={125}
                cy={125}
                innerRadius={55}
                outerRadius={75}
                paddingAngle={5}
                dataKey='value'
              >
                {data.importances.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill='hsl(var(--nextui-primary-500))'
                  />
                ))}
              </Pie>
              <TooltipRecharts />
            </PieChart>
            <div className='h-[98px] w-full overflow-auto text-sm'>
              {data.importances.map(i => (
                <div key={i.name} className='flex justify-between px-4'>
                  <div className='flex items-center space-x-1'>
                    <div
                      style={{
                        background: 'hsl(var(--nextui-primary-500))',
                      }}
                      className='h-2 w-2 rounded-full'
                    />
                    <div className='line-clamp-1'>{i.name}</div>
                  </div>
                  <div>
                    {getPrettyNumber(i.value)}
                    {i.value
                      ? ` (${Math.floor(
                          (i.value * 100) /
                            data.importances.reduce((acc, item) => {
                              acc += item.value
                              return acc
                            }, 0),
                        )}%)`
                      : ''}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className='text-default-400 flex-1'>
            За выбронный период данные отсутствуют
          </div>
        )}
      </div>
    </div>
  )
}
