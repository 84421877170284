import { NewsbreakInterface } from '@hydra/interfaces'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback } from 'react'

import { linkFormatConst } from '../../../features/constants/linkFormat.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import {
  directionNewsbreaksWithReportsAtom,
  newsbreaksWithReportsAtom,
  orderNewsbreaksWithReportsAtom,
} from '../store/newsbreaks.store'
import { NewsbreaksWithReportsItem } from './NewsbreaksWithReportsItem'

export const NewsbreaksWithReportsList = () => {
  const [{ data, isPending }] = useAtom(newsbreaksWithReportsAtom)
  const [order, setOrder] = useAtom(orderNewsbreaksWithReportsAtom)
  const [direction, setDirection] = useAtom(directionNewsbreaksWithReportsAtom)

  const renderCell = useCallback((n: NewsbreakInterface, columnKey: Key) => {
    if (columnKey === 'title') {
      return (
        <NewsbreaksWithReportsItem newsbreak={n}>
          {n.title}
        </NewsbreaksWithReportsItem>
      )
    }

    if (columnKey === 'formOfWork') {
      const formats = n.reports.reduce((acc, report) => {
        report.links.forEach(link => {
          acc.add(linkFormatConst[link.format])
        })
        return acc
      }, new Set())
      const formatsString = Array.from(formats).join(', ')

      return (
        <NewsbreaksWithReportsItem newsbreak={n}>
          {formatsString}
        </NewsbreaksWithReportsItem>
      )
    }

    if (columnKey === 'theme') {
      const themes = n.reports.reduce((acc, report) => {
        acc.add(report.theme.title)
        return acc
      }, new Set())
      const themesString = Array.from(themes).join(', ')

      return (
        <NewsbreaksWithReportsItem newsbreak={n}>
          {themesString}
        </NewsbreaksWithReportsItem>
      )
    }

    if (columnKey === 'reports') {
      return (
        <NewsbreaksWithReportsItem newsbreak={n}>
          {n.reports.length}
        </NewsbreaksWithReportsItem>
      )
    }

    if (columnKey === 'createdAt') {
      return (
        <NewsbreaksWithReportsItem newsbreak={n}>
          {getPrettyDate({
            date: n.createdAt,
          })}
        </NewsbreaksWithReportsItem>
      )
    }

    if (columnKey === 'updatedAt') {
      return (
        <NewsbreaksWithReportsItem newsbreak={n}>
          {getPrettyDate({
            date: n.updatedAt,
          })}
        </NewsbreaksWithReportsItem>
      )
    }
  }, [])

  if (isPending) return <PageSpinner />

  if (!data || data[0].length === 0) return <Empty />

  return (
    <Table
      sortDescriptor={{
        column: order,
        direction: direction === 'asc' ? 'ascending' : 'descending',
      }}
      onSortChange={({ column, direction }) => {
        setOrder(column as keyof NewsbreakInterface)
        setDirection(direction === 'ascending' ? 'asc' : 'desc')
      }}
      aria-label='Площадки организации'
      isHeaderSticky
      isStriped
      removeWrapper
      classNames={{
        base: 'h-[calc(100vh-156px)] overflow-auto',
      }}
    >
      <TableHeader>
        <TableColumn width='500' key='title' allowsSorting>
          Название
        </TableColumn>
        <TableColumn width='200' key='formOfWork'>
          Формат контента
        </TableColumn>
        <TableColumn width='200' key='theme'>
          Тема
        </TableColumn>
        <TableColumn key='reports'>Количество отчетов</TableColumn>
        <TableColumn key='createdAt' allowsSorting>
          Создан
        </TableColumn>
        <TableColumn key='updatedAt' allowsSorting>
          Обновлен
        </TableColumn>
      </TableHeader>
      <TableBody
        items={data[0]}
        emptyContent={
          <div className='text-default-400 text-sm'>Нет данных</div>
        }
      >
        {item => (
          <TableRow key={item.id}>
            {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
