import {
  FileInterface,
  LinkInterface,
  LogInterface,
  NewsbreakEditorStatusEnum,
  NewsbreakInterface,
  NewsbreakStatusEnum,
  PriorityInterface,
  ProjectInterface,
  ThemeInterface,
  TypeInterface,
} from '@hydra/interfaces'
import { TComment } from '@udecode/plate-comments'
import { TElement } from '@udecode/plate-common'
import { AxiosResponse } from 'axios'
import { SortDirection } from 'typeorm'

import { instance } from '../../../features/api'
import { colorsConst } from '../../../features/constants/colors.const'

export const getNewsbreaks = (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof NewsbreakInterface
  status?: NewsbreakStatusEnum
  editorStatus?: NewsbreakEditorStatusEnum
  userId?: string
  startOfDay?: Date
  endOfDay?: Date
  type?: string
  theme?: string
  project?: string
  priority?: string
  importance?: string
  organization?: string
  isUrgent?: string
  searchString?: string
}): Promise<AxiosResponse<[Array<NewsbreakInterface>, number]>> => {
  return instance.get('/newsbreaks/all', {
    params,
  })
}

export const getNewsbreaksWithReports = (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof NewsbreakInterface
  startOfCreatedDay?: Date
  endOfCreatedDay?: Date
  startOfUpdatedDay?: Date
  endOfUpdatedDay?: Date
  theme?: string
}): Promise<AxiosResponse<[Array<NewsbreakInterface>, number]>> => {
  return instance.get('/newsbreaks/all-with-reports', {
    params,
  })
}

export const getAllStatusesCounts = (params: {
  userId?: string
  startOfDay?: Date
  endOfDay?: Date
  type?: string
  theme?: string
  project?: string
  priority?: string
  importance?: string
  organization?: string
  isUrgent?: string
  searchString?: string
}): Promise<
  AxiosResponse<{
    allCount: number
    draft: number
    rework: number
    agreement: number
    plan: number
    ok: number
    inWork: number
    canceled: number
    deleted: number
    done: number
    editorWait: number
    editorInWork: number
    editorDone: number
    editorCanceled: number
  }>
> => {
  return instance.get('/newsbreaks/get-all-statuses-counts', {
    params,
  })
}

export const getAllNewsbreaksByDate = (params: {
  dayStart: Date
  dayEnd: Date
}): Promise<AxiosResponse<Array<NewsbreakInterface>>> => {
  return instance.get('/newsbreaks/all-by-date', { params })
}

export const getAllTypes = (): Promise<
  AxiosResponse<[Array<TypeInterface>, number]>
> => {
  return instance.get('/newsbreaks/all-types')
}

export const getAllLogs = (
  newsbreakId: string,
): Promise<AxiosResponse<Array<LogInterface>>> => {
  return instance.get(`/newsbreaks/all-logs/${newsbreakId}`)
}

export const getAllThemes = (): Promise<
  AxiosResponse<[Array<ThemeInterface>, number]>
> => {
  return instance.get('/newsbreaks/all-themes')
}

export const getAllProjects = (): Promise<
  AxiosResponse<[Array<ProjectInterface>, number]>
> => {
  return instance.get('/newsbreaks/all-projects')
}

export const getAllPriorities = (): Promise<
  AxiosResponse<[Array<PriorityInterface>, number]>
> => {
  return instance.get('/newsbreaks/all-priorities')
}

export const getNewsbreakById = (
  id: string,
): Promise<
  AxiosResponse<{ current: NewsbreakInterface; next?: string; prev?: string }>
> => {
  return instance.get(`/newsbreaks/newsbreak-by-id/${id}`)
}

export const getNewsbreakUsers = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-users/${id}`)
}

export const getOrganizationToNewsbreak = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/organization-to-newsbreak/${id}`)
}

export const getNewsbreakComments = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-comments/${id}`)
}

export const getNewsbreakFiles = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-files/${id}`)
}

export const getNewsbreakReports = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-reports/${id}`)
}

export const getNewsbreakTheme = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-theme/${id}`)
}

export const getNewsbreakType = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-type/${id}`)
}

export const getNewsbreakProject = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-project/${id}`)
}

export const getNewsbreakPriority = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-priority/${id}`)
}

export const getNewsbreakImportance = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-importance/${id}`)
}

export const getNewsbreakLocations = (
  id: string,
): Promise<AxiosResponse<NewsbreakInterface>> => {
  return instance.get(`/newsbreaks/newsbreak-locations/${id}`)
}

export const getNewsbreaksAnalytics = (params: {
  dateStart: Date
  dateEnd: Date
  organizationIds?: Array<string>
  organizationType?: string
  status?: NewsbreakStatusEnum
  authorship?: string
}): Promise<
  AxiosResponse<{
    newsbreaks: Array<NewsbreakInterface>
    all: number
    draft: number
    rework: number
    agreement: number
    plan: number
    ok: number
    deleted: number
    inWork: number
    canceled: number
    done: number
    linksByAreas: Array<{
      name: string
      value: number
      key: keyof typeof colorsConst
    }>
    formatOfWork: Array<{ name: string; value: number }>
    themes: Array<{ name: string; publicationCount: number }>
    links: Array<LinkInterface>
    producedCount: number
    federalLevelCount: number
    importances: Array<{ name: string; value: number }>
  }>
> => {
  return instance.get('/newsbreaks/get-analytics', { params })
}

export const createNewsbreak = (data: {
  isUrgent: boolean
  isAllDay: boolean
  title: string
  plateDescription: Array<TElement>
  plateAccents?: Array<TElement>
  plateComments?: Record<string, TComment>
  address: string | null
  deadline?: Date
  status?: NewsbreakStatusEnum
  type: Pick<TypeInterface, 'id'> | null
  theme: Pick<ThemeInterface, 'id'>
  priority: Pick<PriorityInterface, 'id'> | null
  project: Pick<ProjectInterface, 'id'> | null
  files: Array<Pick<FileInterface, 'id'>> | null
}) => {
  return instance.post('/newsbreaks/create', data)
}

export const createComment = (data: { value: string; newsbreakId: string }) => {
  return instance.post('/newsbreaks/create-comment', data)
}

export const createNewsbreakFile = (data: {
  title: string
  link: string
  newsbreakId?: string
}): Promise<AxiosResponse<string>> => {
  return instance.post('/newsbreaks/create-file', data)
}

export const updateNewsbreak = (
  id: string,
  partialNewsbreak: Omit<
    Partial<NewsbreakInterface>,
    | 'theme'
    | 'type'
    | 'project'
    | 'priority'
    | 'author'
    | 'coAuthor'
    | 'locations'
    | 'importance'
  > & {
    importance?: { id: string; title: string }
    locations?: Array<{ id: string; title: string }>
    deadline?: Date
    author?: {
      id: string
    }
    coAuthor?: {
      id: string
    } | null
    theme?: {
      id: string
    }
    type?: {
      id: string
    } | null
    project?: {
      id: string
    } | null
    priority?: {
      id: string
    } | null
  },
): Promise<AxiosResponse<string>> => {
  return instance.patch(`/newsbreaks/update/${id}`, partialNewsbreak)
}

export const updateImplementOrganizations = (
  id: string,
  data: { status: NewsbreakEditorStatusEnum },
) => {
  return instance.patch(
    `/newsbreaks/update-implement-ogranizations/${id}`,
    data,
  )
}

export const deleteNewsbreakFile = (id: string) => {
  return instance.delete(`/newsbreaks/delete-file/${id}`)
}
