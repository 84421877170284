import { PostInterface } from '@hydra/interfaces'
import { Spinner } from '@nextui-org/react'
import { clsx } from 'clsx'
import { useAtomValue } from 'jotai/index'
import SimpleBar from 'simplebar-react'

import { Empty } from '../../../features/ui'
import { postsInfiniteAtom } from '../store/post.store'
import { PostItem } from './PostItem'

interface Props {
  posts?: Array<PostInterface>
}

export const PostsList = ({ posts }: Props) => {
  const { data: infinite, isPending } = useAtomValue(postsInfiniteAtom)

  return (
    <div className='space-y-2'>
      {isPending ? (
        <div className='flex h-full justify-center'>
          <Spinner />
        </div>
      ) : !infinite ? (
        <Empty />
      ) : (
        <div className='space-y-4'>
          <SimpleBar
            className={clsx('h-[calc(100vh-80px)]', {
              '!h-[calc(100vh-208px)]': posts,
            })}
          >
            <div className='space-y-2'>
              {!posts
                ? infinite?.pages.map(p =>
                    p[0].map((post, index) => (
                      <PostItem
                        key={post.id}
                        post={post}
                        index={index}
                        isShowRiskButton={true}
                      />
                    )),
                  )
                : posts.map((post, index) => (
                    <PostItem
                      key={post.id}
                      post={post}
                      index={index}
                      isShowRiskButton={false}
                    />
                  ))}
            </div>
          </SimpleBar>
        </div>
      )}
    </div>
  )
}
