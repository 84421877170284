import { TbTextRecognition } from 'react-icons/tb'

import { routesConst } from '../../../features/constants/routes.const'

export const menuItems = [
  {
    title: 'Транскрибации',
    link: routesConst.transcriptions,
    icon: <TbTextRecognition />,
    grant: undefined,
  },
]
