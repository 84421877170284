import { NextUIProvider } from '@nextui-org/react'
import { I18nProvider } from '@react-aria/i18n'
import { ThemeProvider as NextThemesProvider } from 'next-themes'
import { useNavigate } from 'react-router-dom'

import { ToastContainer } from './features/ui'
import { TooltipProvider } from './features/ui/components/PlateEditor/plate-ui/tooltip'
import { Routes } from './Routes'

export const Providers = () => {
  const navigate = useNavigate()

  return (
    <NextUIProvider navigate={navigate}>
      <NextThemesProvider attribute='class' defaultTheme='light'>
        <I18nProvider locale='ru-RU'>
          <TooltipProvider
            disableHoverableContent
            delayDuration={500}
            skipDelayDuration={0}
          >
            <Routes />
            <ToastContainer />
          </TooltipProvider>
        </I18nProvider>
      </NextThemesProvider>
    </NextUIProvider>
  )
}
