import { AreaTypeEnum } from '@hydra/interfaces'
import { z } from 'zod'

export const additionalInformationSchema = z.object({
  isUrgent: z.boolean().optional(),
  isProduced: z.boolean().optional(),
  isImportant: z.boolean().optional(),
  deadline: z.string().trim().optional().nullable(),
  locations: z.array(z.string(), {
    required_error: 'Обязательное поле',
  }),
  areas: z.array(z.nativeEnum(AreaTypeEnum), {
    required_error: 'Обязательное поле',
  }),
})
