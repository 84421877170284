import { Grant } from '@hydra/interfaces'
import {
  Chip,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useMemo } from 'react'
import {
  TbChevronDown,
  TbShieldCheckFilled,
  TbShieldLockFilled,
} from 'react-icons/tb'
import SimpleBar from 'simplebar-react'

import { areaConst } from '../../../features/constants/area.const'
import { editorStatusConst } from '../../../features/constants/editorStatus.const'
import { newsbreakFormOfWorkConst } from '../../../features/constants/newsbreakFormOfWork.const'
import { routesConst } from '../../../features/constants/routes.const'
import { statusConst } from '../../../features/constants/status.const'
import { useGrant } from '../../../features/grants'
import { useUserOrganizationId } from '../../../features/hooks/useUserOrganizationId'
import { allOrganizationsAtom } from '../../../features/store'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import {
  newsbreakAtom,
  newsbreakImportanceAtom,
  newsbreakLocationsAtom,
  newsbreakPriorityAtom,
  newsbreakProjectAtom,
  newsbreakThemeAtom,
  newsbreakTypeAtom,
  organizationToNewsbreakAtom,
} from '../store/newsbreaks.store'

interface Props {
  isTooltip?: boolean
}

export const MainInfo = ({ isTooltip = false }: Props) => {
  const { isValidGrant: isEditor } = useGrant(Grant.newsbreaksEditor)
  const [{ data: newsbreak }] = useAtom(newsbreakAtom)
  const [{ data: organizations }] = useAtom(allOrganizationsAtom)
  const [{ data: organizationToNewsbreak }] = useAtom(
    organizationToNewsbreakAtom,
  )
  const [{ data: theme }] = useAtom(newsbreakThemeAtom)
  const [{ data: type }] = useAtom(newsbreakTypeAtom)
  const [{ data: project }] = useAtom(newsbreakProjectAtom)
  const [{ data: priority }] = useAtom(newsbreakPriorityAtom)
  const [{ data: importance }] = useAtom(newsbreakImportanceAtom)
  const [{ data: locations }] = useAtom(newsbreakLocationsAtom)

  const orgId = useUserOrganizationId()

  const foundOrgStatus = useMemo(
    () =>
      organizationToNewsbreak?.organizationToNewsbreak.find(
        org => org.organizationId === orgId,
      )?.status,
    [organizationToNewsbreak, orgId],
  )

  if (!organizationToNewsbreak || !newsbreak?.current) return null

  return (
    <div className={isTooltip ? 'space-y-4 bg-white p-2' : 'space-y-4'}>
      <div className='font-semibold '>Основная информация</div>
      <div className='text-small grid grid-cols-[130px,1fr] items-center gap-4'>
        {!isEditor && !isTooltip && (
          <>
            <div className='text-default-400'>Статус</div>
            <div>
              <Popover placement='bottom-end'>
                <PopoverTrigger>
                  <Chip
                    variant='dot'
                    color={statusConst[newsbreak.current.status].color}
                    size='sm'
                    endContent={<TbChevronDown className='text-xl' />}
                  >
                    {statusConst[newsbreak.current.status].title}
                  </Chip>
                </PopoverTrigger>
                <PopoverContent>
                  <SimpleBar className='max-h-[300px] min-w-[350px] p-2'>
                    <div className='space-y-1'>
                      {organizationToNewsbreak.organizationToNewsbreak.map(
                        item => (
                          <div
                            key={item.id}
                            className='flex w-full items-center justify-between space-x-2'
                          >
                            <Link
                              size='sm'
                              color='foreground'
                              underline='hover'
                              href={
                                routesConst.organizations +
                                '/' +
                                item.organizationId
                              }
                            >
                              {organizations &&
                                organizations[0].find(
                                  org => org.id === item.organizationId,
                                )?.name}
                            </Link>
                            <Chip
                              variant='dot'
                              color={editorStatusConst[item.status].color}
                              size='sm'
                            >
                              {editorStatusConst[item.status].title}
                            </Chip>
                          </div>
                        ),
                      )}
                    </div>
                  </SimpleBar>
                </PopoverContent>
              </Popover>
            </div>
          </>
        )}
        {isEditor && foundOrgStatus && (
          <>
            <div className='text-default-400'>Статус редактора</div>
            <div>
              <Chip
                variant='dot'
                color={editorStatusConst[foundOrgStatus].color}
                size='sm'
              >
                {editorStatusConst[foundOrgStatus].title}
              </Chip>
            </div>
          </>
        )}
        {!isTooltip && (
          <>
            <div className='text-danger'>
              {newsbreak.current.isUrgent ? 'Срок исполнения' : 'Дата события'}
            </div>
            <div className='text-danger'>
              {getPrettyDate({
                date: newsbreak.current.deadline || undefined,
                template: newsbreak.current.isAllDay
                  ? 'DD MMMM YYYY'
                  : 'DD MMMM YYYY, HH:mm',
              })}
            </div>
          </>
        )}
        {!isEditor && !isTooltip && (
          <>
            <div className='text-default-400'>Дата создания</div>
            <div>
              {getPrettyDate({
                date: newsbreak.current.createdAt,
                template: 'DD MMMM YYYY HH:mm',
              })}
            </div>
          </>
        )}
        {newsbreak.current.address && (
          <>
            <div className='text-default-400'>Адрес</div>
            <div>{newsbreak.current.address}</div>
          </>
        )}
        <div className='text-default-400'>Категории</div>
        <div className='flex flex-wrap items-center gap-2'>
          {type?.type && (
            <Chip
              color='secondary'
              variant='flat'
              size='sm'
              classNames={{
                base: '!max-w-[250px] h-fit p-1 whitespace-break-spaces',
              }}
            >
              Тип: {type.type.title}
            </Chip>
          )}
          {theme?.theme && (
            <Chip
              color='secondary'
              variant='flat'
              size='sm'
              classNames={{
                base: '!max-w-[250px] h-fit p-1 whitespace-break-spaces',
              }}
            >
              Тема: {theme.theme.title}
            </Chip>
          )}
          {project?.project && (
            <Chip
              color='secondary'
              variant='flat'
              size='sm'
              classNames={{
                base: '!max-w-[250px] h-fit p-1 whitespace-break-spaces',
              }}
            >
              Нацпроект: {project.project.title}
            </Chip>
          )}
          {priority?.priority && (
            <Chip
              color='secondary'
              variant='flat'
              size='sm'
              classNames={{
                base: '!max-w-[250px] h-fit p-1 whitespace-break-spaces',
              }}
            >
              Приоритет: {priority.priority.title}
            </Chip>
          )}
          {importance?.importance && (
            <Chip
              color='danger'
              variant='flat'
              size='sm'
              classNames={{
                base: '!max-w-[250px] h-fit p-1 whitespace-break-spaces',
              }}
            >
              Важность: {importance.importance.title}
            </Chip>
          )}
        </div>
        {!isEditor && (
          <>
            <div className='text-default-400'>Срочность</div>
            <div>{newsbreak.current.isUrgent ? 'Текущий' : 'Плановый'}</div>

            <div className='text-default-400'>Продюсирование</div>
            <div>
              {newsbreak.current.isProduced ? (
                <TbShieldCheckFilled className='text-success text-xl' />
              ) : (
                <TbShieldLockFilled className='text-danger text-xl' />
              )}
            </div>
          </>
        )}
        {newsbreak.current.isImportant && (
          <>
            <div className='text-default-400'>Значимость</div>
            <div>
              {newsbreak.current.isImportant ? 'Значимый' : 'Не значимый'}
            </div>
          </>
        )}
        {locations?.locations ? (
          <>
            <div className='text-default-400'>Места публикаций</div>
            <div className='flex flex-wrap items-center gap-2'>
              {locations.locations.map(location => (
                <Chip
                  key={location.id}
                  color='primary'
                  variant='flat'
                  size='sm'
                  classNames={{
                    base: '!max-w-[250px] h-fit p-1 whitespace-break-spaces',
                  }}
                >
                  {location.title}
                </Chip>
              ))}
            </div>
          </>
        ) : null}
        {newsbreak.current.areas.length ? (
          <>
            <div className='text-default-400'>Площадки</div>
            <div className='flex flex-wrap items-center gap-2'>
              {newsbreak.current.areas.map(area => (
                <Chip key={area} color='primary' variant='flat' size='sm'>
                  {areaConst[area]}
                </Chip>
              ))}
            </div>
          </>
        ) : null}
        {newsbreak.current.formOfWork.length ? (
          <>
            <div className='text-default-400'>Формат отработки</div>
            <div className='flex flex-wrap items-center gap-2'>
              {newsbreak.current.formOfWork.map(item => (
                <Chip key={item} color='primary' variant='flat' size='sm'>
                  {newsbreakFormOfWorkConst[item]}
                </Chip>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}
