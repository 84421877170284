import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { TbMinus } from 'react-icons/tb'
import { toast } from 'react-toastify'

import { linkFormatConst } from '../../../features/constants/linkFormat.const'
import { extractUrls } from '../../../features/utils/extractUrls'
import { createReport, createReportLink } from '../api/reports.api'
import { newsbreakAtom, newsbreakThemeAtom } from '../store/newsbreaks.store'

interface Form {
  fields: Array<{
    value: {
      format: string
      link: string
    }
  }>
}

interface Props {
  isOpen: boolean
  onOpenChange: () => void
  onClose: () => void
  handleDoneClick: () => Promise<void>
}

export const DoneModal = ({
  isOpen,
  onOpenChange,
  onClose,
  handleDoneClick,
}: Props) => {
  const [{ refetch, data: newsbreak }] = useAtom(newsbreakAtom)
  const [{ data: theme }] = useAtom(newsbreakThemeAtom)
  const [isLoading, setIsLoading] = useState(false)
  const form = useForm<Form>({
    defaultValues: {
      fields: [
        {
          value: {
            format: '',
            link: '',
          },
        },
      ],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'fields',
  })

  const handleAddFiled = useCallback(() => {
    append({
      value: {
        format: '',
        link: '',
      },
    })
  }, [append])

  const onSubmit = async (formData: Form) => {
    if (!formData.fields?.length)
      return toast.error('Добавьте хотя бы одну ссылку')

    if (formData.fields.filter(i => i.value.format === '').length)
      return toast.error('Добавьте формат отработкти ко всем ссылкам')

    const linkChecker = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/,
    )
    const isNotEmpty = formData.fields?.find(
      item => item.value.link === '' && item.value.format === '',
    )
    const isValidLinks = formData.fields?.reduce(
      (_acc: boolean, item) => linkChecker.test(item.value.link),
      false,
    )

    if (isNotEmpty) return toast.error('Перед сохранением удалите пустые поля')

    if (!isValidLinks)
      return toast.error(
        'Ссылка не верного формата, корректный вид ссылки -  https://example.com',
      )

    try {
      setIsLoading(true)

      const createdLinks: Array<{ format: string; url: string }> = []

      formData.fields.forEach(field => {
        const links = extractUrls(field.value.link)

        links.forEach(link => {
          createdLinks.push({
            format: field.value.format,
            url: link,
          })
        })
      })

      const links = await Promise.allSettled(
        createdLinks.map(({ format, url }) => {
          return createReportLink({
            format,
            url,
          })
        }),
      )

      const response = await createReport({
        newsbreak: newsbreak?.current,
        title: `Отчет для инфоповода ${newsbreak?.current.title}`,
        theme: { id: theme?.theme?.id || '' },
        links: links
          .filter(link => link.status === 'fulfilled')
          .map(link => {
            return {
              id: link.status === 'fulfilled' ? link.value.data : '',
            }
          }),
      })
      if (response.status && response.status === 201) {
        await handleDoneClick()
        await refetch()
        toast.success('Отчет успешно добавлен')
        form.reset()
        fields.forEach((_field, index) => remove(index))
        onClose()
      }
    } catch (e) {
      toast.error('Что то пошло не так')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} size='5xl'>
      <ModalContent>
        <ModalHeader className='mr-2 flex items-center justify-between'>
          <div className='font-semibold'>Отчет</div>
          <Button
            color='primary'
            variant='light'
            size='sm'
            onPress={handleAddFiled}
          >
            Добавить ссылку
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className='space-y-4'>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className='flex flex-col space-y-4'
            >
              {fields.map((field, index) => (
                <div className='flex space-x-2' key={field.id}>
                  <div className='font-semibold'>{index + 1}.</div>
                  <div className='flex-1 space-y-2'>
                    <Controller
                      control={form.control}
                      name={`fields.${index}.value.format`}
                      render={({ field: { onChange } }) => (
                        <Select
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          fullWidth
                          aria-label='Формат контента'
                          placeholder='Выберите формат контента'
                          variant='bordered'
                          items={Object.entries(linkFormatConst).map(item => ({
                            label: item[1],
                            value: item[0],
                          }))}
                          onChange={onChange}
                        >
                          {item => (
                            <SelectItem key={item.value}>
                              {item.label}
                            </SelectItem>
                          )}
                        </Select>
                      )}
                    />
                    <Controller
                      control={form.control}
                      name={`fields.${index}.value.link`}
                      render={({ field: { onChange } }) => (
                        <Textarea
                          maxRows={50}
                          isDisabled={isLoading}
                          aria-label='Формат контента'
                          placeholder='Ссылка на публикацию'
                          variant='bordered'
                          fullWidth
                          onValueChange={onChange}
                        />
                      )}
                    />
                  </div>
                  <Button
                    variant='bordered'
                    size='sm'
                    isIconOnly
                    color='danger'
                    onClick={() => remove(index)}
                  >
                    <TbMinus className='text-xl' />
                  </Button>
                </div>
              ))}
              <ModalFooter>
                <Button
                  color='primary'
                  size='sm'
                  type='submit'
                  isDisabled={isLoading}
                  isLoading={isLoading}
                >
                  Сохранить
                </Button>
              </ModalFooter>
            </form>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
