import { withRef } from '@udecode/cn'
import { useOutdentButton } from '@udecode/plate-indent'
import React from 'react'

import { Icons } from '../icons'
import { ToolbarButton } from './toolbar'

export const OutdentToolbarButton = withRef<typeof ToolbarButton>(
  (rest, ref) => {
    const { props } = useOutdentButton()

    return (
      <ToolbarButton ref={ref} {...props} {...rest}>
        <Icons.outdent />
      </ToolbarButton>
    )
  },
)
