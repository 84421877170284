import { OrganizationInterface } from '@hydra/interfaces'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai'
import { Key, useCallback } from 'react'
import { TbTrash } from 'react-icons/tb'
import { toast } from 'react-toastify'

import { updateOrganization } from '../../../features/api'
import { groupAtom } from '../store/groups.store'

const columns = [
  {
    key: 'name',
    label: 'Организация',
  },
  {
    key: 'actions',
    label: '',
    width: 50,
  },
]

interface Props {
  organizations: Array<OrganizationInterface>
}

export const GroupOrganizationsTable = ({ organizations }: Props) => {
  const [{ refetch }] = useAtom(groupAtom)

  const removeOrganization = useCallback(
    async (id: string) => {
      try {
        const response = await updateOrganization(id, {
          group: null,
        })
        if (response.status && response.status === 200) {
          toast.success('Организация уалена')
          await refetch()
        }
      } catch (e) {
        console.error(e)
      }
    },
    [refetch],
  )

  const renderCell = useCallback(
    (org: OrganizationInterface, columnKey: Key) => {
      switch (columnKey) {
        case 'name':
          return org.name
        case 'actions':
          return (
            <div>
              <Button
                isIconOnly
                color='danger'
                variant='light'
                size='sm'
                onPress={() => removeOrganization(org.id)}
              >
                <TbTrash />
              </Button>
            </div>
          )
      }
    },
    [removeOrganization],
  )

  return (
    <div className='bg-background w-full rounded-2xl p-4 '>
      <Table
        aria-label='Список групп'
        isHeaderSticky
        isStriped
        removeWrapper
        classNames={{
          base: 'h-[calc(100vh-192px)] overflow-auto',
        }}
      >
        <TableHeader columns={columns}>
          {column => (
            <TableColumn width={column.width} key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={organizations} emptyContent='Нет данных'>
          {item => (
            <TableRow key={item.id}>
              {columnKey => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
