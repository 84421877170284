import { Select, SelectItem } from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { ChangeEvent, useCallback } from 'react'

import { organizationTypeConst } from '../../../features/constants/organizationType.const'
import { allOrganizationsAtom } from '../../../features/store'
import {
  analyticsOrganizationIds,
  analyticsOrganizationType,
  authorshipAnalyticsAtom,
  newsbreaksAnalyticsAtom,
} from '../store/newsbreaks.store'

export const AnalyticsOrganizationsFilters = () => {
  const [organizationIds, setOrganizationIds] = useAtom(
    analyticsOrganizationIds,
  )

  const [organizationType, setOrganizationType] = useAtom(
    analyticsOrganizationType,
  )

  const [authorship, setAuthorship] = useAtom(authorshipAnalyticsAtom)

  const [{ data, isPending }] = useAtom(allOrganizationsAtom)
  const [{ isPending: isPendingAnalytics }] = useAtom(newsbreaksAnalyticsAtom)

  const handleChangeOrganizations = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value) {
        setOrganizationIds(event.target.value.split(','))
      } else {
        setOrganizationIds([])
      }
    },
    [setOrganizationIds],
  )

  const handleChangeOrganizationsType = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value) {
        setOrganizationIds([])
        setOrganizationType(event.target.value)
      } else {
        setOrganizationIds([])
        setOrganizationType(undefined)
      }
    },
    [setOrganizationIds, setOrganizationType],
  )

  const handleChangeAuthorship = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value) {
        setAuthorship(event.target.value)
      }
    },
    [setAuthorship],
  )

  return (
    <>
      <Select
        isLoading={isPending}
        isDisabled={isPending || isPendingAnalytics}
        labelPlacement='outside'
        aria-label='Выберите тип организации'
        variant='bordered'
        selectionMode='single'
        placeholder='Выберите тип организации'
        defaultSelectedKeys={organizationType ? [organizationType] : []}
        onChange={handleChangeOrganizationsType}
        items={Object.entries(organizationTypeConst).map(item => ({
          label: item[1],
          value: item[0],
        }))}
      >
        {item => (
          <SelectItem
            key={item.value}
            value={item.value}
            textValue={item.label}
          >
            {item.label}
          </SelectItem>
        )}
      </Select>
      <Select
        isLoading={isPending}
        isDisabled={isPending || isPendingAnalytics}
        labelPlacement='outside'
        aria-label='Выберите организацию'
        variant='bordered'
        selectionMode='multiple'
        placeholder='Выберите организацию'
        selectedKeys={organizationIds}
        onChange={handleChangeOrganizations}
        items={
          data
            ? data[0].filter(org =>
                organizationType ? org.type === organizationType : true,
              )
            : []
        }
      >
        {item => (
          <SelectItem key={item.id} value={item.id} textValue={item.name}>
            {item.name}
          </SelectItem>
        )}
      </Select>

      <Select
        isLoading={isPending}
        isDisabled={isPending || isPendingAnalytics}
        labelPlacement='outside'
        aria-label='Выберите авторство'
        variant='bordered'
        selectionMode='single'
        placeholder='Выберите авторство'
        selectedKeys={[authorship]}
        onChange={handleChangeAuthorship}
      >
        <SelectItem key={'all'} value={'all'} textValue={'Автор и соавтор'}>
          Автор и соавтор
        </SelectItem>
        <SelectItem key={'author'} value={'author'} textValue={'Автор'}>
          Автор
        </SelectItem>
        <SelectItem key={'coAuthor'} value={'coAuthor'} textValue={'Соавтор'}>
          Соавтор
        </SelectItem>
      </Select>
    </>
  )
}
