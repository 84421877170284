import { zodResolver } from '@hookform/resolvers/zod'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Checkbox,
  Input,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { IoCloseCircle } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { routesConst } from '../../../features/constants/routes.const'
import { allLocationsAtom } from '../../../features/store'
import { Header, Layout } from '../../../features/ui'
import { createPublic } from '../api/public.api'
import { cooperationConst } from '../constants/cooperation.const'
import { loyaltyConst } from '../constants/loyalty.const'
import { menuItems } from '../constants/menuItems'
import { publicTypeConst } from '../constants/publicType.const'
import { createPublicSchema } from '../schemas/createPublic.schema'
import { publicGroupsAtom } from '../store/group.store'

type Form = z.infer<typeof createPublicSchema>

export const CreateOrUpdatePublic = ({ isEdit }: { isEdit?: boolean }) => {
  const navigate = useNavigate()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const form = useForm<Form>({
    defaultValues: {
      isCollectStatistic: false,
    },
    resolver: zodResolver(createPublicSchema),
  })
  const [{ data: locations, isPending: isPendingLocations }] =
    useAtom(allLocationsAtom)
  const [{ data: groups, isPending: isPendingGroups }] =
    useAtom(publicGroupsAtom)

  const pageTitle = useMemo(
    () => (isEdit ? 'Редактирование паблика' : 'Созадние паблика'),
    [isEdit],
  )

  const onSubmit = useCallback(
    async (formData: Form) => {
      setIsFormLoading(true)
      try {
        const response = await createPublic({
          ...formData,
          location: formData.location
            ? {
                id: formData.location,
              }
            : undefined,
          group: formData.group
            ? {
                id: formData.group,
              }
            : undefined,
        })

        if (response.status && response.status === 201) {
          toast.success('Паблик успешно создан')
          navigate(routesConst.publics)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [navigate],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        <form
          className='bg-background m-4 flex h-[calc(100vh-104px)] flex-col rounded-2xl p-4 shadow'
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className='flex-1 space-y-4'>
            <div className='flex justify-between'>
              <span className='text-lg font-semibold'>Основная информация</span>
              <Button
                onPress={() => {
                  form.reset()
                  navigate(-1)
                }}
                size='sm'
                variant='flat'
                color='primary'
                endContent={<IoCloseCircle className='text-xl' />}
              >
                Закрыть
              </Button>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <Controller
                control={form.control}
                name='link'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isRequired
                    isDisabled={isFormLoading}
                    variant='bordered'
                    size='sm'
                    label='Ссылка'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                    isInvalid={!!form.formState.errors.link}
                    errorMessage={form.formState.errors.link?.message}
                  />
                )}
              />
              <Controller
                control={form.control}
                name='title'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    size='sm'
                    label='Название'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                control={form.control}
                name='organization'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    size='sm'
                    label='Организация'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                control={form.control}
                name='cost'
                render={({ field: { onChange, value } }) => (
                  <Input
                    type='number'
                    isDisabled={isFormLoading}
                    variant='bordered'
                    size='sm'
                    label='Стоимость размещения'
                    color='primary'
                    onValueChange={value => onChange(parseInt(value, 10))}
                    value={value ? String(value) : ''}
                  />
                )}
              />
              <Controller
                control={form.control}
                name='contactName'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    size='sm'
                    label='ФИО контактного лица'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                control={form.control}
                name='contactJobTitle'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    size='sm'
                    label='Должность контактного лица'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                control={form.control}
                name='contactPhone'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    size='sm'
                    label='Телефон контактного лица'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                control={form.control}
                name='contactLink'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    size='sm'
                    label='Ссылка на контактное лицо'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                control={form.control}
                name='location'
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    isDisabled={isFormLoading}
                    aria-label='Муниципалитет'
                    isLoading={isPendingLocations || isFormLoading}
                    variant='bordered'
                    defaultItems={locations ? locations[0] : []}
                    size='sm'
                    label='Выберите муниципалитет'
                    onSelectionChange={onChange}
                    defaultSelectedKey={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id} textValue={item.title}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />

              <Controller
                control={form.control}
                name='type'
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    isDisabled={isFormLoading}
                    aria-label='Тип'
                    isLoading={isFormLoading}
                    variant='bordered'
                    defaultItems={Object.entries(publicTypeConst).map(item => ({
                      id: item[0],
                      title: item[1],
                    }))}
                    size='sm'
                    label='Выберите тип'
                    onSelectionChange={onChange}
                    defaultSelectedKey={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id} textValue={item.title}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />
              <Controller
                control={form.control}
                name='loyalty'
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    isDisabled={isFormLoading}
                    aria-label='Лояльность'
                    isLoading={isFormLoading}
                    variant='bordered'
                    defaultItems={Object.entries(loyaltyConst).map(item => ({
                      id: item[0],
                      title: item[1],
                    }))}
                    size='sm'
                    label='Выберите лояльность'
                    onSelectionChange={onChange}
                    defaultSelectedKey={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id} textValue={item.title}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />
              <Controller
                control={form.control}
                name='cooperation'
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    isDisabled={isFormLoading}
                    aria-label='Сотрудничество'
                    isLoading={isFormLoading}
                    variant='bordered'
                    defaultItems={Object.entries(cooperationConst).map(
                      item => ({
                        id: item[0],
                        title: item[1],
                      }),
                    )}
                    size='sm'
                    label='Выберите тип сотрудничеста'
                    onSelectionChange={onChange}
                    defaultSelectedKey={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id} textValue={item.title}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />

              <Controller
                control={form.control}
                name='group'
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    isDisabled={isFormLoading}
                    aria-label='Гурппы пабликов'
                    isLoading={isPendingGroups || isFormLoading}
                    variant='bordered'
                    defaultItems={groups ? groups[0] : []}
                    size='sm'
                    label='Выберите группу палбиков'
                    onSelectionChange={onChange}
                    defaultSelectedKey={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id} textValue={item.title}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />
              <Controller
                control={form.control}
                name='isCollectStatistic'
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    className='col-end-2'
                    isDisabled={isFormLoading}
                    color='primary'
                    onValueChange={onChange}
                    isSelected={value}
                  >
                    Собирать статистику
                  </Checkbox>
                )}
              />
            </div>
          </div>
          <div className='flex justify-end'>
            <Button color='primary' type='submit'>
              Сохранить
            </Button>
          </div>
        </form>
      </Layout>
    </>
  )
}
