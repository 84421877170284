import { Select, SelectItem } from '@nextui-org/react'
import { useAtom } from 'jotai/index'

import {
  availableRatingPeriodsAtom,
  ratingHalfYearAtom,
  ratingMonthAtom,
  ratingPeriodAtom,
  ratingQuarterAtom,
  ratingYearAtom,
} from '../../store'
import { extendedDayjs } from '../../utils/dayjsConfig'

export const PeriodSelects = ({ isDisabled = false }) => {
  const [ratingPeriod, setRatingPeriod] = useAtom(ratingPeriodAtom)
  const [year, setYear] = useAtom(ratingYearAtom)
  const [halfYear, setHalfYear] = useAtom(ratingHalfYearAtom)
  const [quarter, setQuarter] = useAtom(ratingQuarterAtom)
  const [month, setMonth] = useAtom(ratingMonthAtom)
  const [{ data, isPending: isPendingRatingYears }] = useAtom(
    availableRatingPeriodsAtom,
  )

  if (!data) return null

  return (
    <div className='flex w-1/3 space-x-4'>
      <Select
        isDisabled={isDisabled}
        aria-label='Период'
        variant='bordered'
        placeholder='Период'
        defaultSelectedKeys={[ratingPeriod]}
        value={[ratingPeriod]}
        onChange={e =>
          setRatingPeriod(e.target.value as 'month' | 'quarter' | 'year')
        }
      >
        <SelectItem key='year' value='year'>
          Год
        </SelectItem>
        <SelectItem key='halfYear' value='halfYear'>
          Полугодие
        </SelectItem>
        <SelectItem key='quarter' value='quarter'>
          Квартал
        </SelectItem>
        <SelectItem key='month' value='month'>
          Месяц
        </SelectItem>
      </Select>

      {ratingPeriod === 'month' && (
        <Select
          isDisabled={isDisabled}
          aria-label='Месяц'
          variant='bordered'
          placeholder='Месяц'
          defaultSelectedKeys={[month]}
          value={month}
          onChange={e => setMonth(e.target.value)}
          items={
            data.availablePeriod[+year]?.months.map(i => ({
              id: i,
              label: extendedDayjs().month(i).format('MMMM'),
            })) || []
          }
        >
          {item => (
            <SelectItem key={String(item.id)} value={String(item.id)}>
              {String(item.label)}
            </SelectItem>
          )}
        </Select>
      )}

      {ratingPeriod === 'quarter' && (
        <Select
          isDisabled={isDisabled}
          aria-label='Квартал'
          variant='bordered'
          placeholder='Квартал'
          defaultSelectedKeys={[quarter]}
          value={quarter}
          onChange={e => setQuarter(e.target.value)}
          items={
            data.availablePeriod[+year]?.quarters.map(i => ({
              id: i,
              label: i,
            })) || []
          }
        >
          {item => (
            <SelectItem key={String(item.id)} value={String(item.id)}>
              {String(item.label)}
            </SelectItem>
          )}
        </Select>
      )}

      {ratingPeriod === 'halfYear' && (
        <Select
          isDisabled={isDisabled}
          aria-label='Полугодие'
          variant='bordered'
          placeholder='Полугодие'
          defaultSelectedKeys={[halfYear]}
          value={halfYear}
          onChange={e => setHalfYear(e.target.value)}
          items={
            data.availablePeriod[+year]?.halfYears.map(i => ({
              id: i,
              label: i,
            })) || []
          }
        >
          {item => (
            <SelectItem key={String(item.id)} value={String(item.id)}>
              {String(item.label)}
            </SelectItem>
          )}
        </Select>
      )}

      <Select
        isLoading={isPendingRatingYears}
        isDisabled={isPendingRatingYears || isDisabled}
        aria-label='Год'
        variant='bordered'
        placeholder='Год'
        defaultSelectedKeys={[year]}
        value={year}
        onChange={e => setYear(e.target.value)}
        items={
          Object.keys(data.availablePeriod).map(i => ({
            id: i,
            label: i,
          })) || []
        }
      >
        {item => (
          <SelectItem key={String(item.id)} value={String(item.id)}>
            {String(item.label)}
          </SelectItem>
        )}
      </Select>
    </div>
  )
}
