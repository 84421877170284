import { Pagination as NextUIPagination } from '@nextui-org/react'
import { useCallback, useMemo } from 'react'

type Props = {
  count: number
  take: number
  skip: number
  setSkip: (value: number) => void
}

export const Pagination = ({ count, take, skip, setSkip }: Props) => {
  const pages = useMemo(() => {
    return count ? Math.ceil(count / take) : 0
  }, [count, take])

  const handlePageChange = useCallback(
    (page: number) => {
      setSkip((page - 1) * take)
    },
    [setSkip, take],
  )

  return (
    <div className='flex items-center justify-between'>
      {pages > 0 ? (
        <div className='flex w-full flex-1 justify-center'>
          <NextUIPagination
            showControls
            showShadow
            color='primary'
            page={skip ? Math.ceil(skip / take) + 1 : 1}
            total={pages}
            onChange={handlePageChange}
          />
        </div>
      ) : null}
    </div>
  )
}
