import { zodResolver } from '@hookform/resolvers/zod'
import { Grant, OrganizationType } from '@hydra/interfaces'
import { Button, Input, Select, SelectItem } from '@nextui-org/react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { createOrganization, updateOrganization } from '../../../features/api'
import { organizationTypeConst } from '../../../features/constants/organizationType.const'
import { routesConst } from '../../../features/constants/routes.const'
import { Grants } from '../../../features/grants'
import { Layout } from '../../../features/ui'
import { menuItems } from '../constants/menuItems'
import { createOrganizationSchema } from '../schemas/createOrganization.schema'

interface Props {
  isEdit?: boolean
}

type FormField = z.infer<typeof createOrganizationSchema>

export const CreateOrganization = ({ isEdit }: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const form = useForm<FormField>({
    defaultValues: {
      name: searchParams.get('name') || '',
      contactPerson: searchParams.get('contactPerson') || '',
      contactPersonPhone: searchParams.get('contactPersonPhone') || '',
      contactPersonEmail: searchParams.get('contactPersonEmail') || '',
      type: searchParams.get('type') || '',
      site: searchParams.get('site') || '',
      apiKey: searchParams.get('apiKey') || '',
    },
    resolver: zodResolver(createOrganizationSchema),
  })

  const onSubmit: SubmitHandler<FormField> = async formData => {
    if (isEdit) {
      const id = searchParams.get('id')
      if (!id) {
        return null
      }
      try {
        const response = await updateOrganization(id, formData)
        if (response.status && response.status === 200) {
          toast.success('Организация успешно обновлена')
          navigate(routesConst.organizations + '/' + id)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
      return
    }

    setIsFormLoading(true)
    try {
      const response = await createOrganization(formData)
      if (response.status && response.status === 201) {
        toast.success('Организация успешно созадна')
        form.reset()
        navigate(routesConst.organizations)
      }
    } catch (e) {
      toast.error('Что то пошло не так')
    } finally {
      setIsFormLoading(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>
          Гидра | {isEdit ? 'Редактирование' : 'Создание'} организации
        </title>
      </Helmet>
      <Grants grants={[Grant.newsbreaksModerator]}>
        <Layout
          menuItems={menuItems}
          headerContent={
            <div className='flex h-full items-center justify-between space-x-4 px-4'>
              <h1 className='flex-1 text-3xl font-semibold'>
                {isEdit ? 'Редактирование' : 'Создание'} организации
              </h1>
            </div>
          }
        >
          <div className='p-4'>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className='bg-background space-y-4 rounded-xl p-4 shadow'
            >
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='name'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Название'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.name}
                      errorMessage={form.formState.errors.name?.message}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='contactPerson'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Руководитель'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.contactPerson}
                      errorMessage={
                        form.formState.errors.contactPerson?.message
                      }
                    />
                  )}
                />
              </div>
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='contactPersonEmail'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      type='email'
                      variant='bordered'
                      label='Email руководителя'
                      placeholder='example@yandex.ru'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.contactPersonEmail}
                      errorMessage={
                        form.formState.errors.contactPersonEmail?.message
                      }
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='contactPersonPhone'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Телефон руководителя'
                      placeholder='79999999999'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.contactPersonPhone}
                      errorMessage={
                        form.formState.errors.contactPersonPhone?.message
                      }
                    />
                  )}
                />
              </div>
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='type'
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isLoading={isFormLoading}
                      color='primary'
                      variant='bordered'
                      label='Тип организации'
                      selectedKeys={value ? [value] : []}
                      onChange={onChange}
                    >
                      {[
                        {
                          value: OrganizationType.other,
                          label: organizationTypeConst[OrganizationType.other],
                        },
                        {
                          value: OrganizationType.department,
                          label:
                            organizationTypeConst[OrganizationType.department],
                        },
                        {
                          value: OrganizationType.municipality,
                          label:
                            organizationTypeConst[
                              OrganizationType.municipality
                            ],
                        },
                        {
                          value: OrganizationType.smi,
                          label: organizationTypeConst[OrganizationType.smi],
                        },
                      ].map(item => (
                        <SelectItem key={item.value} value={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </Select>
                  )}
                />
                <Controller
                  control={form.control}
                  name='site'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Сайт организации'
                      placeholder='https://example.com'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.site}
                      errorMessage={form.formState.errors.site?.message}
                    />
                  )}
                />
              </div>
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='apiKey'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Api ключ'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.apiKey}
                      errorMessage={form.formState.errors.apiKey?.message}
                    />
                  )}
                />
              </div>
              <div className='flex justify-end'>
                <Button
                  color='primary'
                  type='submit'
                  isDisabled={isFormLoading}
                  isLoading={isFormLoading}
                >
                  {isEdit ? 'Сохранить' : 'Создать'}
                </Button>
              </div>
            </form>
          </div>
        </Layout>
      </Grants>
    </>
  )
}
