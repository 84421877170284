import { Grant } from '@hydra/interfaces'
import { Button, Input } from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { TbSearch, TbSquareRoundedPlus, TbX } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants } from '../../../features/grants'
import { Header, Layout } from '../../../features/ui'
import { RiskFilters } from '../components/RiskFilters'
import { RisksList } from '../components/RisksList'
import { RisksPagination } from '../components/RisksPagination'
import { menuItems } from '../constants/menuItems'
import { searchStringAtom, skipAtom, takeAtom } from '../store/risk.store'

const pageTitle = 'Риски'

export const Risks = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState('')
  const [, setSearchString] = useAtom(searchStringAtom)
  const [, setSkip] = useAtom(skipAtom)
  const [, setTake] = useAtom(takeAtom)

  const handleSearch = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setSkip(0)
      setTake(25)
      if (value !== '') {
        setSearchString(value)
      } else {
        setSearchString(undefined)
      }
    },
    [setSearchString, setSkip, setTake, value],
  )

  useEffect(() => {
    if (value === '') {
      setSearchString(undefined)
    }
  }, [setSearchString, value])

  return (
    <Grants grants={[Grant.riskModerator, Grant.riskProvider]}>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <form onSubmit={handleSearch} className='flex space-x-2'>
                <Input
                  className='w-[450px]'
                  value={value}
                  onValueChange={setValue}
                  placeholder='Найти по заголовку, описанию...'
                  variant='bordered'
                  endContent={
                    <div className='flex space-x-1'>
                      {value && (
                        <Button
                          type='button'
                          isIconOnly
                          size='sm'
                          variant='light'
                          color='danger'
                          onPress={() => setValue('')}
                        >
                          <TbX />
                        </Button>
                      )}
                      <Button
                        type='submit'
                        isIconOnly
                        size='sm'
                        variant='light'
                      >
                        <TbSearch />
                      </Button>
                    </div>
                  }
                />
                <Button
                  color='primary'
                  startContent={<TbSquareRoundedPlus className='text-2xl' />}
                  onPress={() => navigate(routesConst.createRisk)}
                >
                  Создать риск
                </Button>
              </form>
            }
          />
        }
      >
        <div className='space-y-4 p-4'>
          <div className='grid grid-cols-[1fr_350px] gap-4'>
            <RisksList />
            <RiskFilters />
          </div>
          <RisksPagination />
        </div>
      </Layout>
    </Grants>
  )
}
