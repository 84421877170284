import { TComment, useCommentsSelectors } from '@udecode/plate-comments'
import { Plate, TElement, Value } from '@udecode/plate-common'
import { useAtom } from 'jotai'
import React, { useEffect, useRef } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import { allUsersAtom } from '../../../store'
import { plugins } from './lib/plate-plugins'
import { CommentsPopover } from './plate-ui/comments-popover'
import { CursorOverlay } from './plate-ui/cursor-overlay'
import { Editor } from './plate-ui/editor'
import { FixedToolbar } from './plate-ui/fixed-toolbar'
import { FixedToolbarButtons } from './plate-ui/fixed-toolbar-buttons'
import { FloatingToolbar } from './plate-ui/floating-toolbar'
import { FloatingToolbarButtons } from './plate-ui/floating-toolbar-buttons'
import { MentionCombobox } from './plate-ui/mention-combobox'

interface Props {
  onChange: (value: Value) => void
  setComments?: (comments: Record<string, TComment>) => void
  initialValue?: Array<TElement>
  placeholder?: string
  isReadOnly?: boolean
  isDisabled?: boolean
  withComments: boolean
}

export const PlateEditorComponent = ({
  onChange,
  initialValue,
  placeholder,
  isReadOnly,
  isDisabled,
  setComments,
  withComments,
}: Props) => {
  const [{ data: users }] = useAtom(allUsersAtom)
  const containerRef = useRef(null)
  const comments = useCommentsSelectors().comments()

  const debounced = useDebounceCallback(value => {
    onChange(value)
  }, 300)

  useEffect(() => {
    setComments && setComments(comments)
  }, [comments, setComments])

  return (
    <Plate plugins={plugins} onChange={debounced} initialValue={initialValue}>
      <div ref={containerRef}>
        {!isReadOnly && (
          <FixedToolbar>
            <FixedToolbarButtons />
          </FixedToolbar>
        )}

        <Editor
          disabled={isDisabled}
          readOnly={isReadOnly}
          placeholder={placeholder}
        />

        {!isReadOnly && (
          <FloatingToolbar>
            <FloatingToolbarButtons withComments={withComments} />
          </FloatingToolbar>
        )}

        {users && (
          <MentionCombobox
            items={users[0].map(user => ({
              key: user.id,
              text: user.name + ' ' + user.surname,
            }))}
          />
        )}

        <CommentsPopover />

        <CursorOverlay containerRef={containerRef} />
      </div>
    </Plate>
  )
}
