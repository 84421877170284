import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { toast } from 'react-toastify'

import { routesConst } from '../constants/routes.const'

export const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL_V1,
})

const reqInterceptor = (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

const resInterceptor = async (response: AxiosResponse) => {
  return response
}

const errInterceptor = async (
  error: AxiosError<{
    error: string
    message: string | Array<string>
    statusCode: number
  }>,
) => {
  if (error.response) {
    if (error.response.status === 400) {
      toast.error(error.response.data.message)
      return
    }
    if (error.response.status === 401) {
      toast.error('Необходимо авторизоваться')
      localStorage.removeItem('token')
      window.location.replace(routesConst.login)
      return
    }
    if (error.response.status === 403) {
      toast.error('Недостаточно прав')
      window.location.replace(routesConst.main)
      return
    }
    if (error.response.status === 500) {
      return toast.error(
        'Что-то пошло не так на сервере. ' +
          error.response.data.message +
          '. ' +
          error.response.config.url,
      )
    }
    return toast.error(error.response.data.message)
  }

  return null
}

instance.interceptors.request.use(reqInterceptor)
instance.interceptors.response.use(resInterceptor, errInterceptor)
