import dayjs from 'dayjs'

import 'dayjs/locale/ru'

import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.locale('ru')
dayjs.extend(isToday)
dayjs.extend(weekOfYear)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(quarterOfYear)
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault()
dayjs.Ls.ru.weekStart = 1

export { dayjs as extendedDayjs }
