import { Button, Select, SelectItem } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { ChangeEvent, useCallback } from 'react'

import { urgentAtom } from '../store/newsbreaks.store'

export const UrgentFilter = () => {
  const [isUrgent, setIsUrgent] = useAtom(urgentAtom)

  const onSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setIsUrgent(event.target.value)
    },
    [setIsUrgent],
  )

  return (
    <div className='space-y-1'>
      <div className='flex w-full items-center justify-between'>
        <label
          className='text-small text-default-400'
          htmlFor='newsbreaks-priority-filter'
        >
          Срочность
        </label>
        <Button
          variant='light'
          size='sm'
          color='primary'
          onPress={() => setIsUrgent(undefined)}
        >
          Сбросить
        </Button>
      </div>
      <Select
        aria-labelledby='Приоритет'
        id='newsbreaks-priority-filter'
        items={[
          {
            id: 'true',
            title: 'Текущий',
          },
          {
            id: 'false',
            title: 'Плановый',
          },
        ]}
        labelPlacement='outside'
        variant='bordered'
        onChange={onSelectChange}
        classNames={{
          base: '!ml-0 h-10',
        }}
        selectedKeys={[isUrgent || '']}
      >
        {item => (
          <SelectItem key={item.id} textValue={item.title}>
            {item.title}
          </SelectItem>
        )}
      </Select>
    </div>
  )
}
