import Highlighter from 'react-highlight-words'

interface Props {
  search: Array<string>
  value: string
}

export const HighLightSearch = ({ search, value }: Props) => {
  return (
    <Highlighter
      highlightStyle={{
        background: 'hsl(var(--nextui-success))',
      }}
      searchWords={search}
      autoEscape={true}
      textToHighlight={value}
    />
  )
}
