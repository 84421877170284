import { OrganizationInterface } from '@hydra/interfaces'
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Key } from 'react'
import SimpleBar from 'simplebar-react'

import { useRatingPeriodMask } from '../hooks/useRatingPeriodMask'
import { RatingTablePdf } from './RatingTablePdf'

interface Props {
  groupName: string
  value: Array<
    OrganizationInterface & {
      totalPoints: {
        fact: number
        plan: number
        points: number
      }
    }
  >
  onTableRowClick: (id: string) => void
  renderCell: (
    item: OrganizationInterface & {
      totalPoints: {
        fact: number
        plan: number
        points: number
      }
    },
    columnKey: Key,
  ) => JSX.Element | undefined
}

export const GroupRatingTable = ({
  groupName,
  value,
  onTableRowClick,
  renderCell,
}: Props) => {
  const period = useRatingPeriodMask()

  return (
    <div className=' space-y-4 rounded-2xl p-4 shadow'>
      <div className='flex items-center justify-between'>
        <Chip variant='flat' color='secondary'>
          {groupName}
        </Chip>
        <PDFDownloadLink
          fileName={'Медиарейтинг ЯНАО ' + groupName + ' за ' + period + '.pdf'}
          document={<RatingTablePdf organizations={value} />}
        >
          {({ loading }) => (
            <Button
              isDisabled={loading}
              variant='flat'
              color='primary'
              size='sm'
            >
              Скачать рейтинг группы
            </Button>
          )}
        </PDFDownloadLink>
      </div>
      <SimpleBar className='max-h-[300px] overflow-auto'>
        <Table
          aria-label={groupName}
          removeWrapper
          classNames={{
            td: 'first:rounded-l-md last:rounded-r-md p-0',
          }}
        >
          <TableHeader>
            <TableColumn width={750} maxWidth={750} minWidth={750} key='name'>
              МО/ИОГВ
            </TableColumn>
            <TableColumn width={250} maxWidth={250} minWidth={250} key='points'>
              Баллы
            </TableColumn>
          </TableHeader>
          <TableBody
            items={
              value
                .sort((a, b) => b.totalPoints.points - a.totalPoints.points)
                .map((o, index) => ({
                  ...o,
                  index,
                })) || []
            }
            emptyContent={
              <div className='text-default-400 text-sm'>Нет данных</div>
            }
          >
            {item => (
              <TableRow key={item.id} onClick={() => onTableRowClick(item.id)}>
                {columnKey => (
                  <TableCell>{renderCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </SimpleBar>
    </div>
  )
}
