import { GroupInterface, PerformanceInterface } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'
import { SortDirection } from 'typeorm'

import { instance } from '../../../features/api'

export const getAllGroups = (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof GroupInterface
}): Promise<AxiosResponse<[Array<GroupInterface>, number]>> => {
  return instance.get('/organizations/all-groups', { params })
}

export const getGroupById = (
  id: string,
): Promise<AxiosResponse<GroupInterface>> => {
  return instance.get(`/organizations/group-by-id/${id}`)
}

export const createGroup = (data: {
  title: string
  organizations: Array<{ id: string }>
}) => {
  return instance.post('/organizations/create-group', data)
}

export const createPerformance = (data: {
  month: number
  year: number
  occasionsCount: number
  occasionsCountForSocialNetwork: number
  themeHit: number
  themeHitForSocialNetwork: number
  qualityRelease: number
  producing: number
  graphic: number
  group: { id: string }
}) => {
  return instance.post('/organizations/create-performance', data)
}

export const updatePerformance = (
  id: string,
  data: Partial<PerformanceInterface>,
) => {
  return instance.patch(`/organizations/update-performance/${id}`, data)
}

export const updateGroup = (
  id: string,
  data: {
    title?: string
    organizations?: Array<{ id: string }>
  },
) => {
  return instance.patch(`/organizations/update-group/${id}`, data)
}
