import { useAtom } from 'jotai'
import { Helmet } from 'react-helmet'

import { profileAtom } from '../../../features/store'
import { Empty, Layout, PageSpinner } from '../../../features/ui'
import { UserInfo } from '../components/UserInfo'
import { menuItems } from '../constants/menuItems'

export const Profile = () => {
  const [{ data, isPending }] = useAtom(profileAtom)

  return (
    <>
      <Helmet>
        <title>Гидра | Профиль</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <div className='flex h-full items-center justify-between space-x-4 px-4'>
            <h1 className='flex-1 text-3xl font-semibold'>Профиль</h1>
          </div>
        }
      >
        {isPending ? (
          <PageSpinner />
        ) : !data ? (
          <Empty />
        ) : (
          <UserInfo profile={data.profile} isMyProfile />
        )}
      </Layout>
    </>
  )
}
