import { PerformanceInterface } from '@hydra/interfaces'
import { ScrollShadow } from '@nextui-org/react'

import { Empty } from '../../../features/ui'
import { PerformanceItem } from './PerformanceItem'

interface Props {
  performances: Array<PerformanceInterface>
}

export const GroupPerformancesList = ({ performances }: Props) => {
  if (performances.length < 1) {
    return <Empty />
  }

  return (
    <ScrollShadow
      size={0}
      className='bg-default-50 h-[calc(100vh-160px)] w-full space-y-4'
    >
      {performances.map(perf => (
        <PerformanceItem performance={perf} key={perf.id} />
      ))}
    </ScrollShadow>
  )
}
