import { FileInterface } from '@hydra/interfaces'

import { createLinkAndDownloadFile } from './createLinkAndDownloadFile'

export const downloadAllFiles = (files: Array<FileInterface>) => {
  files.forEach((file, index) => {
    setTimeout(() => {
      createLinkAndDownloadFile(file.link, file.title)
    }, index * 1000) // Задержка 100 мс между каждым скачиванием
  })
}
