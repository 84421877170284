import { UserInterface } from '@hydra/interfaces'
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback } from 'react'

import { organizationByIdAtom } from '../../../features/store'
import { Empty } from '../../../features/ui'
import { CopyToClipboardButton } from '../../../features/ui/components/CopyToClipboardButton'
import { formatRussianPhoneNumber } from '../../../features/utils/formatRussianPhoneNumber.util'
import { generateMailtoLink } from '../../../features/utils/generateMailtoLink.util'
import { generateTelegramLink } from '../../../features/utils/generateTelegramLink.util'

export const OrganizationUsers = () => {
  const [{ data }] = useAtom(organizationByIdAtom)

  const renderUserCell = useCallback((user: UserInterface, columnKey: Key) => {
    switch (columnKey) {
      case 'name':
        return user.name + ' ' + user.surname
      case 'phone':
        return (
          <div className='flex items-center space-x-1'>
            <span>{formatRussianPhoneNumber(user.phone)}</span>
            <CopyToClipboardButton
              value={user.phone}
              messageText={`${user.phone}\nскопированно в буфер обмена`}
            />
          </div>
        )
      case 'telegram':
        return (
          <div className='flex items-center space-x-1'>
            <Link
              isExternal
              isBlock
              href={generateTelegramLink(user.telegram)}
              size='sm'
            >
              {user.telegram}
            </Link>
            <CopyToClipboardButton
              messageText={`${generateTelegramLink(
                user.telegram,
              )}\nскопированно в буфер обмена`}
              value={generateTelegramLink(user.telegram)}
            />
          </div>
        )
      case 'email':
        return (
          <div className='flex items-center space-x-1'>
            <Link isExternal isBlock href={generateMailtoLink(user.email)}>
              {user.email}
            </Link>
            <CopyToClipboardButton
              value={user.email}
              messageText={`${user.email}\nскопированно в буфер обмена`}
            />
          </div>
        )
      default:
        return
    }
  }, [])

  if (!data) return <Empty />

  return (
    <div className='space-y-4'>
      <Table
        aria-label='Площадки организации'
        isHeaderSticky
        isStriped
        removeWrapper
        classNames={{
          base: 'h-[calc(100vh-208px)] overflow-auto',
        }}
      >
        <TableHeader>
          <TableColumn key='name'>Сотрудник</TableColumn>
          <TableColumn key='phone'>Телефон</TableColumn>
          <TableColumn key='telegram'>Telegram</TableColumn>
          <TableColumn key='email'>Электронная почта</TableColumn>
        </TableHeader>
        <TableBody
          items={data.users}
          emptyContent={
            <div className='text-default-400 text-sm'>Нет данных</div>
          }
        >
          {item => (
            <TableRow key={item.id}>
              {columnKey => (
                <TableCell>{renderUserCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
