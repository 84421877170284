import {
  GroupInterface,
  MissedNewsbreakInterface,
  OrganizationInterface,
  RatingColorfulInterface,
  RatingInterface,
} from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from './instance'

export const getAllOrganizations = (): Promise<
  AxiosResponse<[Array<OrganizationInterface>, number]>
> => {
  return instance.get('/organizations/all')
}
export const getAllOrganizationsWithoutGroup = (): Promise<
  AxiosResponse<[Array<OrganizationInterface>, number]>
> => {
  return instance.get('/organizations/all-without-group')
}

export const getOrganizationById = (
  id: string,
): Promise<AxiosResponse<OrganizationInterface>> => {
  return instance.get(`/organizations/organization-by-id/${id}`)
}

export const createOrganization = (data: {
  name: string
  contactPerson: string
  contactPersonPhone: string
  contactPersonEmail: string
  type: string
  site?: string
  apiKey?: string
}) => {
  return instance.post('organizations/create', data)
}

export const updateOrganization = (
  id: string,
  data: {
    name?: string
    contactPerson?: string
    contactPersonPhone?: string
    contactPersonEmail?: string
    type?: string
    site?: string
    apiKey?: string
    locations?: Array<string>
    group?: GroupInterface | null
  },
) => {
  return instance.patch(`organizations/update/${id}`, data)
}

export const creteMissedNewsbreak = (data: {
  link: string
  excuse: string
  description: string
  organization: { id: string }
}) => {
  return instance.post('/organizations/create-missed-newsbreak', data)
}

export const getAvailableRatingPeriods = (params: {
  organizationId: string
}): Promise<
  AxiosResponse<{
    availablePeriod: {
      [year: number]: {
        months: Array<number>
        quarters: Array<number>
        halfYears: Array<number>
      }
    }
  }>
> => {
  return instance.get('/newsbreaks/get-rating-years', { params })
}

export const getOrgRating = (params: {
  organizationId: string
  year: string
  months?: Array<string>
}): Promise<AxiosResponse<RatingInterface>> => {
  return instance.get('/newsbreaks/get-organization-rating', { params })
}

export const getOrgsRating = (params: {
  year: string
  months?: Array<string>
  organizationType?: string
}): Promise<
  AxiosResponse<{
    organizationsByGroups: Record<
      string,
      Array<
        OrganizationInterface & {
          totalPoints: {
            fact: number
            plan: number
            points: number
          }
        }
      >
    >
    organizations: Array<
      OrganizationInterface & {
        totalPoints: {
          fact: number
          plan: number
          points: number
        }
      }
    >
  }>
> => {
  return instance.get('/newsbreaks/get-organizations-rating', { params })
}

export const getRatingColorful = (): Promise<
  AxiosResponse<RatingColorfulInterface>
> => {
  return instance.get('/newsbreaks/get-rating-colorful')
}

export const updateRatingColorful = (
  id: string,
  data: Pick<RatingColorfulInterface, 'green' | 'orange'>,
): Promise<AxiosResponse<string>> => {
  return instance.patch(`/newsbreaks/update-rating-colorful/${id}`, data)
}

export const getMissedNewsbreaks = (
  organizationId: string,
): Promise<AxiosResponse<Array<MissedNewsbreakInterface>>> => {
  return instance.get(`/newsbreaks/get-missed-newsbreaks/${organizationId}`)
}
