import { Grant } from '@hydra/interfaces'

import { routesConst } from '../../../features/constants/routes.const'

export const items = [
  {
    id: 0,
    path: routesConst.newsbreaks,
    icon: 'newsbreaks-main-card',
    title: 'Дистрибуция',
    description:
      'Модуль для работы с инфоповодами, календарем тем, отчетами, аналитикой и рейтингом',
    grants: [
      Grant.newsbreaksProvider,
      Grant.newsbreaksModerator,
      Grant.newsbreaksCategoryModerator,
      Grant.newsbreaksEditor,
    ],
  },
  {
    id: 1,
    path: routesConst.tasks,
    icon: 'tasks-main-card',
    title: 'Задачи',
    description: 'Модуль для работы с задачами и проектами',
    grants: [Grant.tasks],
  },
  {
    id: 2,
    path: routesConst.publics,
    icon: 'publics-main-card',
    title: 'Инфокарта',
    description: 'Модуль для работы с пабликами и группами пабликов',
    grants: [Grant.publics],
  },
  {
    id: 3,
    path: routesConst.monitoring,
    icon: 'monitoring-main-card',
    title: 'Мониторинг',
    description: '',
    grants: [Grant.publics],
  },
  {
    id: 4,
    path: '#',
    icon: 'sociology-main-card',
    title: 'Социология',
    description: '',
    grants: [Grant.superadmin],
  },
  {
    id: 5,
    path: '#',
    icon: 'comments-main-card',
    title: 'Комментарии',
    description: '',
    grants: [Grant.superadmin],
  },
  {
    id: 6,
    path: '#',
    icon: 'dashboard-main-card',
    title: 'Дашборд',
    description: '',
    grants: [Grant.superadmin],
  },
  {
    id: 7,
    path: '#',
    icon: 'documentation-main-card',
    title: 'Документация',
    description: '',
    grants: [Grant.superadmin],
  },
  {
    id: 8,
    path: routesConst.transcriptions,
    icon: 'transcription-main-card',
    title: 'Транскрибация',
    description: '',
    grants: undefined,
  },
]
