import clsx from 'clsx'
import { useCallback } from 'react'

import { extendedDayjs } from '../../../utils/dayjsConfig'
import { daysOfWeek } from './daysOfWeek.const'
import { MonthItem } from './MonthItem'
import { Items } from './types'

interface Props {
  year: number
  month: number
  items?: Items
  months: Array<{
    id: number
    title: string
    daysCount: number
    firstWeekDay: number
  }>
  prevMonthDaysCount: number
  nextMonthDaysCount: number
}

export const MonthView = ({
  year,
  month,
  items,
  months,
  prevMonthDaysCount,
  nextMonthDaysCount,
}: Props) => {
  const renderWeekDaysNavigation = useCallback(
    () =>
      daysOfWeek.map((item, index) => (
        <div className='px-2' key={index}>
          {item}
        </div>
      )),
    [],
  )

  const renderCalendar = useCallback(
    () => (
      <>
        {Array.from(
          {
            length: prevMonthDaysCount,
          },
          (_, index) => {
            const currentDay = extendedDayjs()
              .set('year', year)
              .set('month', month === 0 ? 11 : month - 1)
              .set(
                'date',
                months[month === 0 ? 11 : month - 1].daysCount - index,
              )

            return (
              <MonthItem
                key={index}
                isNotCurrentMonth
                date={currentDay}
                items={items?.filter(item =>
                  extendedDayjs(currentDay).isSame(
                    extendedDayjs(item.deadline),
                    'date',
                  ),
                )}
              />
            )
          },
        ).reverse()}

        {Array.from({ length: months[month].daysCount }, (_, index) => {
          const currentDay = extendedDayjs()
            .set('year', year)
            .set('month', month)
            .set('date', index + 1)

          return (
            <MonthItem
              key={index}
              isNotCurrentMonth={false}
              date={currentDay}
              items={items?.filter(item =>
                extendedDayjs(currentDay).isSame(
                  extendedDayjs(item.deadline),
                  'date',
                ),
              )}
            />
          )
        })}

        {Array.from(
          {
            length: nextMonthDaysCount,
          },
          (_, index) => {
            const currentDay = extendedDayjs()
              .set('year', year)
              .set('month', month + 1)
              .set('date', index + 1)

            return (
              <MonthItem
                key={index}
                isNotCurrentMonth
                date={currentDay}
                items={items?.filter(item =>
                  extendedDayjs(currentDay).isSame(
                    extendedDayjs(item.deadline),
                    'date',
                  ),
                )}
              />
            )
          },
        )}
      </>
    ),
    [items, month, months, nextMonthDaysCount, prevMonthDaysCount, year],
  )
  return (
    <div
      className={clsx(
        'bg-background grid h-[calc(100vh-152px)] grid-cols-7 grid-rows-[32px,repeat(5,1fr)] gap-[1px] rounded-xl p-4 shadow',
        {
          '!grid-rows-[32px,repeat(6,1fr)]':
            months[month].daysCount +
              prevMonthDaysCount +
              nextMonthDaysCount ===
            42,
        },
      )}
    >
      {renderWeekDaysNavigation()}

      {renderCalendar()}
    </div>
  )
}
