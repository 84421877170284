import { Link } from '@nextui-org/react'
import SimpleBar from 'simplebar-react'

import { generateRandomString } from '../../../utils/generateRandomString.util'
import { Items } from './types'

interface Props {
  items?: Items
}

export const DayReview = ({ items }: Props) => {
  if (!items?.length)
    return (
      <div className='text-small text-default-400 flex justify-center'>
        Данные отсутствуют
      </div>
    )

  return (
    <SimpleBar className='flex max-h-[calc(100vh-594px)] flex-col space-y-2'>
      {items?.map(item => (
        <div className='flex items-center'>
          <Link
            size='sm'
            key={generateRandomString(10)}
            isBlock
            href={item.link}
            className='line-clamp-1'
          >
            {item.title}
          </Link>
        </div>
      ))}
    </SimpleBar>
  )
}
