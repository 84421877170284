import { Button, useDisclosure } from '@nextui-org/react'
import { useAtomValue } from 'jotai/index'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { DeleteModal } from '../../../features/ui'
import { removeExternalUser } from '../api/chat.api'
import { chatAtom } from '../store/chat.store'

interface Props {
  item: {
    id: number
    username: string
    phone: string
    firstName: string
  }
}

export const RemoveExternalUserBtn = ({ item }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { data: chat, refetch } = useAtomValue(chatAtom)
  const { isOpen, onOpenChange, onClose, onOpen } = useDisclosure()

  const handleRemoveExternalUser = useCallback(async () => {
    if (!chat) return
    try {
      setIsLoading(true)
      await removeExternalUser(chat.id, {
        user: {
          telegram: item.username,
          chatId: item.id,
        },
      })
    } catch (error) {
      toast.error('Не удалось удалить пользователя из чата, попробуйте позже')
      console.error(error)
    } finally {
      setIsLoading(false)
      await refetch()
    }
  }, [chat, item.id, item.username, refetch])

  return (
    <>
      <Button
        size='sm'
        color='danger'
        variant='bordered'
        onPress={onOpen}
        isLoading={isLoading}
        isDisabled={isLoading}
      >
        Удалить
      </Button>

      <DeleteModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        deleteAction={handleRemoveExternalUser}
        message='Вы действительно хотите удалить внешнего пользователя из чата?'
        description='Это действие нельзя отменить из системы'
        onClose={onClose}
      />
    </>
  )
}
