import { PublicInterface } from '@hydra/interfaces'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'
import { atomWithStorage } from 'jotai/vanilla/utils'

import {
  getDashboard,
  getPublicById,
  getPublics,
  getPublicsCounts,
} from '../api/public.api'

export const skipAtom = atom(0)
export const takeAtom = atom(25)
export const publicIdAtom = atom<string | undefined>(undefined)
export const orderAtom = atom<keyof PublicInterface>('createdAt')
export const directionAtom = atom<'desc' | 'asc'>('desc')
export const searchStringAtom = atom<string | undefined>(undefined)
export const areaTypeAtom = atom<string | undefined>(undefined)

export const columnsAtom = atomWithStorage('public-table-columns', [
  {
    key: 'title',
    label: 'Название',
    isEnable: true,
  },
  {
    key: 'area',
    label: 'Площадка',
    isEnable: true,
  },
  {
    key: 'link',
    label: 'Ссылка',
    isEnable: true,
  },
  {
    key: 'organization',
    label: 'Организация',
    isEnable: true,
  },
  {
    key: 'subscribers',
    label: 'Число подписчиков',
    isEnable: true,
  },
  {
    key: 'subscriberDynamics',
    label: 'Динамика подписчиков',
    isEnable: true,
  },
  {
    key: 'postingFrequency',
    label: 'Частота публикаций',
    isEnable: true,
  },
  {
    key: 'views',
    label: 'Количество просмотров',
    isEnable: true,
  },
  {
    key: 'viewsAverage',
    label: 'Ср.количество просмотров',
    isEnable: true,
  },
  {
    key: 'viewsDynamic',
    label: 'Динамика просмотров',
    isEnable: true,
  },
  {
    key: 'si',
    label: 'SI',
    isEnable: true,
  },
  {
    key: 'er',
    label: 'ER',
    isEnable: true,
  },
  {
    key: 'loyalty',
    label: 'Лояльность',
    isEnable: true,
  },
  {
    key: 'cooperation',
    label: 'Сотрудничество',
    isEnable: true,
  },
  {
    key: 'cost',
    label: 'Стоимость размещений',
    isEnable: true,
  },
  {
    key: 'contactName',
    label: 'Контакты',
    isEnable: true,
  },
  {
    key: 'type',
    label: 'Тип паблика',
    isEnable: true,
  },
  {
    key: 'location',
    label: 'Муниципалитет',
    isEnable: true,
  },
  {
    key: 'group',
    label: 'Группа',
    isEnable: true,
  },
])

export const publicsAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'publics-list',
      get(skipAtom),
      get(takeAtom),
      get(orderAtom),
      get(directionAtom),
      get(searchStringAtom),
      get(areaTypeAtom),
    ],
    queryFn: async () => {
      const response = await getPublics({
        skip: get(skipAtom),
        take: get(takeAtom),
        order: get(orderAtom),
        direction: get(directionAtom),
        searchString: get(searchStringAtom),
        areaType: get(areaTypeAtom),
      })
      return response.data
    },
  }
})

export const publicAtom = atomWithQuery(get => {
  return {
    enabled: !!get(publicIdAtom),
    queryKey: ['public-by-id', get(publicIdAtom)],
    queryFn: async () => {
      const response = await getPublicById(get(publicIdAtom) || '')
      return response.data
    },
  }
})

export const publicCountsAtom = atomWithQuery(get => {
  return {
    queryKey: ['publics-counts'],
    queryFn: async () => {
      const response = await getPublicsCounts()
      return response.data
    },
  }
})

export const publicsDashboardAtom = atomWithQuery(get => {
  return {
    queryKey: ['publics-dashboard'],
    queryFn: async () => {
      const response = await getDashboard()
      return response.data
    },
  }
})
