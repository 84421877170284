import { cn, withRef } from '@udecode/cn'
import { PortalBody, useComposedRef } from '@udecode/plate-common'
import {
  flip,
  FloatingToolbarState,
  offset,
  useFloatingToolbar,
  useFloatingToolbarState,
} from '@udecode/plate-floating'
import React from 'react'

import { Toolbar } from './toolbar'

export const FloatingToolbar = withRef<
  typeof Toolbar,
  {
    state?: FloatingToolbarState
  }
>(({ state, children, ...props }, componentRef) => {
  const floatingToolbarState = useFloatingToolbarState({
    ...state,
    floatingOptions: {
      placement: 'right-end',
      middleware: [
        offset(12),
        flip({
          padding: 12,
          fallbackPlacements: [
            'bottom-end',
            'bottom-start',
            'top-start',
            'top-end',
          ],
        }),
      ],
      ...state?.floatingOptions,
    },
  })

  const {
    ref: floatingRef,
    props: rootProps,
    hidden,
  } = useFloatingToolbar(floatingToolbarState)

  const ref = useComposedRef<HTMLDivElement>(componentRef, floatingRef)

  if (hidden) return null

  return (
    <PortalBody>
      <Toolbar
        ref={ref}
        className={cn(
          'bg-background absolute z-50 whitespace-nowrap border px-1 opacity-100 shadow-md print:hidden',
        )}
        {...rootProps}
        {...props}
      >
        {children}
      </Toolbar>
    </PortalBody>
  )
})
