import { NewsbreakEditorStatusEnum } from '@hydra/interfaces'
import { Chip, Tab, Tabs } from '@nextui-org/react'
import { useAtom } from 'jotai'

import { editorStatusConst } from '../../../features/constants/editorStatus.const'
import {
  editorStatusTabAtom,
  newsbreaksStatusesCountsAtom,
} from '../store/newsbreaks.store'

export const NewsbreakEditorStatusTabs = () => {
  const [{ data: counts }] = useAtom(newsbreaksStatusesCountsAtom)
  const [tab, setTab] = useAtom(editorStatusTabAtom)

  return (
    <Tabs
      size='sm'
      aria-label='Статусы'
      selectedKey={tab}
      onSelectionChange={event => setTab(event as NewsbreakEditorStatusEnum)}
      classNames={{
        tabList: 'gap-0',
        tab: 'px-0 m-0 ',
      }}
    >
      <Tab
        key={NewsbreakEditorStatusEnum.wait}
        title={
          <Chip variant='light' color={editorStatusConst.wait.color}>
            <div className='flex space-x-1'>
              <span>{editorStatusConst.wait.title}</span>
              <span
                className={`text-${editorStatusConst.wait.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.editorWait > 999
                  ? '999+'
                  : counts?.editorWait}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key={NewsbreakEditorStatusEnum.inWork}
        title={
          <Chip variant='light' color={editorStatusConst.inWork.color}>
            <div className='flex space-x-1'>
              <span>{editorStatusConst.inWork.title}</span>
              <span
                className={`text-${editorStatusConst.inWork.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.editorInWork > 999
                  ? '999+'
                  : counts?.editorInWork}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key={NewsbreakEditorStatusEnum.done}
        title={
          <Chip variant='light' color={editorStatusConst.done.color}>
            <div className='flex space-x-1'>
              <span>{editorStatusConst.done.title}</span>
              <span
                className={`text-${editorStatusConst.done.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.editorDone > 999
                  ? '999+'
                  : counts?.editorDone}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key={NewsbreakEditorStatusEnum.canceled}
        title={
          <Chip variant='light' color={editorStatusConst.canceled.color}>
            <div className='flex space-x-1'>
              <span>{editorStatusConst.canceled.title}</span>
              <span
                className={`text-${editorStatusConst.canceled.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.editorCanceled > 999
                  ? '999+'
                  : counts?.editorCanceled}
              </span>
            </div>
          </Chip>
        }
      />
    </Tabs>
  )
}
