import {
  CooperationEnum,
  LoyaltyEnum,
  PublicInterface,
  PublicTypeEnum,
} from '@hydra/interfaces'
import { AxiosResponse } from 'axios'
import { SortDirection } from 'typeorm'

import { instance } from '../../../features/api'

export const updateStatistics = () => {
  return instance.get('/publics/update-statistics')
}

export const getDashboard = (): Promise<
  AxiosResponse<{
    topSubscribers: Array<PublicInterface>
    topViewsAverage: Array<PublicInterface>
    vkCount: number
    okCount: number
    tgCount: number
    otherCount: number
    vkSubscribers: number
    okSubscribers: number
    tgSubscribers: number
    otherSubscribers: number
    vkViewsAverage: number
    okViewsAverage: number
    tgViewsAverage: number
    otherViewsAverage: number
    totalPublics: number
    totalSubscribers: number
    totalViewsAverage: number
  }>
> => {
  return instance.get('/publics/dashboard')
}

export const getPublicsCounts = (): Promise<
  AxiosResponse<{
    subscribers: number
    viewsAverage: number
    lastUpdate: string | null
  }>
> => {
  return instance.get('/publics/all-publics-counts')
}

export const getPublics = async (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof PublicInterface
  searchString?: string
  areaType?: string
}): Promise<AxiosResponse<[Array<PublicInterface>, number]>> => {
  return instance.get('/publics/all-publics', { params })
}

export const createPublic = (data: {
  link: string
  title?: string
  location?: {
    id: string
  }
  organization?: string
  type?: PublicTypeEnum
  loyalty?: LoyaltyEnum
  cooperation?: CooperationEnum
  cost?: number
  contactName?: string
  contactPhone?: string
  contactLink?: string
  contactJobTitle?: string
  group?: {
    id: string
  }
  isCollectStatistic: boolean
}): Promise<AxiosResponse<string>> => {
  return instance.post('/publics/create-public', data)
}

export const getPublicById = (
  id: string,
): Promise<AxiosResponse<PublicInterface>> => {
  return instance.get(`/publics/public-by-id/${id}`, {})
}

export const updatePublic = (
  id: string,
  data: Partial<Omit<PublicInterface, 'id' | 'location' | 'group'>> & {
    location?: {
      id: string
    }
    group?: {
      id: string
    } | null
  },
) => {
  return instance.patch(`/publics/update-public/${id}`, data)
}
