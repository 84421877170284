import {
  Badge,
  Button,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { TbBell } from 'react-icons/tb'

import { updateAllNotifications } from '../../../api'
import { notificationsCountAtom } from '../../../store'
import { NotificationsList } from './NotificationsList'

export const NotificationsButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [{ data: count, isPending, refetch }] = useAtom(notificationsCountAtom)

  const onAllCheckButtonPress = useCallback(async () => {
    await updateAllNotifications()
    setIsOpen(false)
    await refetch()
  }, [refetch])

  return (
    <Badge
      content={count && count > 99 ? '99+' : count}
      color='danger'
      isInvisible={isPending || !count}
    >
      <Popover
        disableAnimation
        key='notifications'
        placement='bottom-end'
        isOpen={isOpen}
        onOpenChange={open => setIsOpen(open)}
      >
        <PopoverTrigger>
          <Button isIconOnly variant='bordered' isDisabled={!count}>
            <TbBell className='text-xl' />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <div className='flex flex-col space-y-4 p-4'>
            <div className='flex items-center text-lg font-semibold'>
              Уведомления
            </div>
            <Divider />
            <NotificationsList />
            <Divider />
            <div>
              <Button size='sm' variant='light' onPress={onAllCheckButtonPress}>
                Прочитать все
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </Badge>
  )
}
