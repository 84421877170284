import { Spinner } from '@nextui-org/react'
import React from 'react'

export const PageSpinner = () => {
  return (
    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
      <Spinner size='lg' />
    </div>
  )
}
