import { ScrollShadow } from '@nextui-org/react'
import { useAtom } from 'jotai'

import { notificationsAtom } from '../../../store'
import { PageSpinner } from '../PageSpinner'
import { NotificationItem } from './NotificationItem'

export const NotificationsList = () => {
  const [{ data: notifications, isPending }] = useAtom(notificationsAtom)

  return (
    <ScrollShadow size={0} className='h-[450px] w-[600px] space-y-2'>
      {isPending ? (
        <PageSpinner />
      ) : (
        notifications?.map(n => (
          <NotificationItem notification={n} key={n.id} />
        ))
      )}
    </ScrollShadow>
  )
}
