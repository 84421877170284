import { RiskStatusEnum } from '@hydra/interfaces'
import { z } from 'zod'

export const createRiskSchema = z.object({
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  status: z.nativeEnum(RiskStatusEnum).optional(),
  isPlan: z.boolean().optional(),
  date: z.string().trim().optional(),
  chat: z.string().trim().optional(),
  address: z.string().trim().optional(),
  theme: z.string().trim().optional(),
  importance: z.string().trim().optional(),
  type: z.string().trim().optional(),
})
