import { PublicGroupInterface } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'
import { SortDirection } from 'typeorm'

import { instance } from '../../../features/api'

export const getPublicGroups = async (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof PublicGroupInterface
}): Promise<AxiosResponse<[Array<PublicGroupInterface>, number]>> => {
  return instance.get('/publics/all-groups', { params })
}

export const getPublicGroupById = (
  id: string,
): Promise<AxiosResponse<PublicGroupInterface>> => {
  return instance.get(`/publics/group-by-id/${id}`, {})
}

export const createGroupPublic = (data: {
  title: string
}): Promise<AxiosResponse<string>> => {
  return instance.post('/publics/create-group', data)
}

export const updatePublicGroup = (
  id: string,
  data: Partial<Omit<PublicGroupInterface, 'id'>>,
) => {
  return instance.patch(`/publics/update-group/${id}`, data)
}
