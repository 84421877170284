import { useAtom } from 'jotai/index'
import { useMemo } from 'react'

import { Pagination } from '../../../features/ui'
import { publicGroupsAtom, skipAtom, takeAtom } from '../store/group.store'

export const PublicGroupsPagination = () => {
  const [{ data }] = useAtom(publicGroupsAtom)
  const [skip, setSkip] = useAtom(skipAtom)
  const [take] = useAtom(takeAtom)
  const count = useMemo(() => (data ? data[1] : 0), [data])

  return <Pagination count={count} take={take} skip={skip} setSkip={setSkip} />
}
