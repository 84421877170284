import { zodResolver } from '@hookform/resolvers/zod'
import { Grant } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Switch,
} from '@nextui-org/react'
import { useAsyncList } from '@react-stately/data'
import { TElement } from '@udecode/plate-common'
import axios from 'axios'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai/index'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import slug from 'slug'
import { z } from 'zod'

import { getFileUrlFromS3, uploadFileToS3 } from '../../../features/api'
import {
  audioExtensions,
  docExtensions,
  imageExtensions,
  videoExtensions,
} from '../../../features/constants/fileExtensions.const'
import { ONE_HUNDRED_MEGABYTES_IN_BYTES } from '../../../features/constants/fileSizes.const'
import { routesConst } from '../../../features/constants/routes.const'
import { Grants, useGrant } from '../../../features/grants'
import {
  FileDnD,
  FileItem,
  Header,
  Layout,
  PlateEditorWrapper,
} from '../../../features/ui'
import { DateAndTimePicker } from '../../../features/ui/components/DateAndTimePicker'
import { getFileExtension } from '../../../features/utils/getFileExtension'
import { createNewsbreakFile } from '../../newsbreaks/api/newsbreaks.api'
import { createRisk } from '../api/risk.api'
import { menuItems } from '../constants/menuItems'
import { createRiskSchema } from '../schemas/createRisk.schema'
import { chatsAtom } from '../store/chat.store'
import {
  riskImportancesAtom,
  riskThemesAtom,
  riskTypesAtom,
} from '../store/risk.store'

const pageTitle = 'Создание чата'

type Form = z.infer<typeof createRiskSchema>

export const CreateRisk = () => {
  const { isValidGrant: isModerator } = useGrant([Grant.riskModerator])
  const navigate = useNavigate()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [editorValue, setEditorValue] = useState<Array<TElement>>([])
  const [files, setFiles] = useState<File[] | null>()
  const { data: chats, isPending: isChatsPending } = useAtomValue(chatsAtom)

  const { data: themes, isPending: isThemesPending } =
    useAtomValue(riskThemesAtom)

  const { data: types, isPending: isTypesPending } = useAtomValue(riskTypesAtom)

  const { data: importances, isPending: isImportncesPending } =
    useAtomValue(riskImportancesAtom)

  const form = useForm<Form>({
    defaultValues: {
      isPlan: true,
    },
    resolver: zodResolver(createRiskSchema),
  })

  const onSubmit = useCallback(
    async (formData: Form) => {
      setIsFormLoading(true)

      try {
        let createdFiles
        if (files) {
          const filesResponses = await Promise.allSettled(
            files.map(file => {
              return uploadFileToS3(
                new File(
                  [file],
                  slug(file.name.replace(/\.[^/.]+$/, '')) +
                    '.' +
                    getFileExtension(file.name),
                  {
                    type: file.type,
                  },
                ),
              )
            }),
          )
          const filesUrlResponses = await Promise.allSettled(
            filesResponses.map(file => {
              if (file.status === 'fulfilled' && file.value) {
                return getFileUrlFromS3(
                  file.value.data,
                  import.meta.env.VITE_BUCKET_NAME,
                )
              }

              return null
            }),
          )

          createdFiles = await Promise.allSettled(
            filesUrlResponses.map(file => {
              if (file.status === 'fulfilled' && file.value) {
                return createNewsbreakFile(file.value.data)
              }
              return null
            }),
          )
        }

        const response = await createRisk({
          ...formData,
          description: editorValue,
          chat: formData.chat ? { id: formData.chat } : undefined,
          theme: formData.theme ? { id: formData.theme } : undefined,
          type: formData.type ? { id: formData.type } : undefined,
          files: createdFiles
            ? createdFiles.map(file => {
                if (file.status === 'fulfilled' && file.value) {
                  return { id: file.value.data }
                }
                return { id: '' }
              })
            : null,
        })

        if (response.status && response.status === 201) {
          toast.success('Риск успешно создан')
          navigate(routesConst.risks)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [editorValue, files, navigate],
  )

  const onDropAccepted = useCallback(
    (newFiles: Array<File>) => {
      setFiles(files ? [...files, ...newFiles] : newFiles)
    },
    [files],
  )

  const list = useAsyncList({
    load: async ({ signal, filterText, items }) => {
      if (filterText && filterText.length % 3 !== 0) return { items }

      const { data } = await axios.post(
        `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
        { query: filterText },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token ' + import.meta.env.VITE_DADATA_TOKEN,
          },
          signal: signal,
        },
      )

      return {
        items: data.suggestions.map((el: { value: string }) => el.value),
      }
    },
  })

  return (
    <Grants grants={[Grant.publics]}>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className='bg-background m-4 flex h-[calc(100vh-104px)] flex-col space-y-4 rounded-2xl p-4 shadow'
        >
          <div className='space-y-4'>
            <Controller
              control={form.control}
              name='isPlan'
              render={({ field: { onChange, value } }) => (
                <Switch size='sm' onValueChange={onChange} isSelected={value}>
                  {value ? 'Плановый риск' : 'Срочный риск'}
                </Switch>
              )}
            />
            <Controller
              control={form.control}
              name='date'
              render={({ field: { onChange, value } }) => (
                <div className='flex flex-col space-y-2'>
                  <DateAndTimePicker
                    onChange={onChange}
                    label='Дата наступления риска'
                    isTimeShow={true}
                    value={value && dayjs(value).isValid() ? value : ''}
                  />
                </div>
              )}
            />
            <Controller
              control={form.control}
              name='title'
              render={({ field: { onChange, value } }) => (
                <Input
                  isRequired
                  isDisabled={isFormLoading}
                  variant='bordered'
                  size='sm'
                  label='Название'
                  color='primary'
                  onValueChange={onChange}
                  value={value}
                  isInvalid={!!form.formState.errors.title}
                  errorMessage={form.formState.errors.title?.message}
                />
              )}
            />
            <div>
              <PlateEditorWrapper
                withComments={false}
                placeholder='Введите текст риска'
                onChange={setEditorValue}
              />
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <Controller
                control={form.control}
                name='address'
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    aria-label='адрес'
                    inputValue={list.filterText}
                    isLoading={list.isLoading}
                    className='flex-1'
                    items={
                      list.items
                        ? Object.entries(list.items).map(([key, value]) => ({
                            label: key,
                            value,
                          }))
                        : []
                    }
                    placeholder='Введите адрес риска'
                    variant='bordered'
                    onSelectionChange={onChange}
                    onInputChange={list.setFilterText}
                  >
                    {item => (
                      <AutocompleteItem
                        key={String(item.value)}
                        className='capitalize'
                      >
                        {String(item.value)}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />
              <Controller
                control={form.control}
                name='importance'
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    aria-label='Важность риска'
                    isLoading={isImportncesPending || isFormLoading}
                    variant='bordered'
                    defaultItems={importances ? importances : []}
                    placeholder='Выберите важность риска'
                    onSelectionChange={onChange}
                    defaultSelectedKey={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <Controller
                control={form.control}
                name='theme'
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    aria-label='Тема риска'
                    isLoading={isThemesPending || isFormLoading}
                    variant='bordered'
                    defaultItems={themes ? themes : []}
                    placeholder='Выберите тему риска'
                    onSelectionChange={onChange}
                    defaultSelectedKey={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />
              <Controller
                control={form.control}
                name='type'
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    aria-label='Тип риска'
                    isLoading={isTypesPending || isFormLoading}
                    variant='bordered'
                    defaultItems={types ? types : []}
                    placeholder='Выберите тип риска'
                    onSelectionChange={onChange}
                    defaultSelectedKey={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />
            </div>
            {isModerator && (
              <Controller
                control={form.control}
                name='chat'
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    aria-label='чат'
                    isLoading={isChatsPending || isFormLoading}
                    variant='bordered'
                    defaultItems={chats ? chats[0] : []}
                    placeholder='Выберите чат'
                    onSelectionChange={onChange}
                    defaultSelectedKey={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    onKeyDown={e => e.continuePropagation()}
                  >
                    {item => (
                      <AutocompleteItem key={item.id}>
                        {item.title}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                )}
              />
            )}
            <div className='space-y-4'>
              <div className='flex space-x-4'>
                <div className='w-1/3 space-y-3'>
                  <div className='text-lg font-semibold '>
                    Материалы публикации
                  </div>
                  <div className='text-default-600'>
                    Вы можете загрузить изображения, видео, аудиозапись,
                    текстовый документ.
                  </div>
                </div>
                <div className='flex-1'>
                  <FileDnD
                    disabled={isFormLoading}
                    maxFiles={100}
                    maxSize={ONE_HUNDRED_MEGABYTES_IN_BYTES}
                    multiple
                    onDropAccepted={onDropAccepted}
                    accept={{
                      ...audioExtensions,
                      ...videoExtensions,
                      ...imageExtensions,
                      ...docExtensions,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-3 gap-4'>
              {files?.map((file, index) => (
                <FileItem
                  key={Math.random()}
                  file={file}
                  setFiles={setFiles}
                  index={index}
                />
              ))}
            </div>
          </div>

          <div className='flex flex-1 items-end justify-end'>
            <Button
              color='primary'
              type='submit'
              isDisabled={isFormLoading}
              isLoading={isFormLoading}
            >
              Сохранить
            </Button>
          </div>
        </form>
      </Layout>
    </Grants>
  )
}
