import { Dayjs } from 'dayjs'

import { extendedDayjs } from './dayjsConfig'

export const getPrettyDate = ({
  date,
  template,
  fallback,
}: {
  date: string | Date | Dayjs | undefined
  template?: string
  fallback?: string
}): string => {
  if (!date || !extendedDayjs(date).isValid())
    return fallback || 'Дата отсутсвует'
  return extendedDayjs(date)
    .tz(extendedDayjs.tz.guess())
    .format(template || 'DD.MM.YYYY')
}
