import {
  Chip,
  DateRangePicker,
  DateValue,
  Divider,
  Image,
  Input,
  RangeValue,
} from '@nextui-org/react'
import { clsx } from 'clsx'
import { useAtomValue } from 'jotai'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import SimpleBar from 'simplebar-react'

import { getSocialNetworkName } from '../../../features/utils/getSocialIcon'
import {
  activePublicAtom,
  platformAtom,
  postsPubDatesAtom,
  postsPublicsAtom,
  searchStringAtom,
} from '../store/post.store'

export const PublicFilter = () => {
  const { data } = useAtomValue(postsPublicsAtom)
  const [activePublic, setActivePublic] = useAtom(activePublicAtom)
  const [platform, setPlatform] = useAtom(platformAtom)
  const [searchString, setSearchString] = useAtom(searchStringAtom)
  const [dateValue, setDateValue] = useAtom(postsPubDatesAtom)

  const handlePublicClick = useCallback(
    (id: string) => {
      if (activePublic === id) {
        setActivePublic(undefined)
      } else {
        setActivePublic(id)
      }
    },
    [activePublic, setActivePublic],
  )

  const handlePlatformClick = useCallback(
    (id: string) => {
      setActivePublic(undefined)
      if (platform === id) {
        setPlatform(undefined)
      } else {
        setPlatform(id)
      }
    },
    [platform, setActivePublic, setPlatform],
  )

  const handleChangeDateRange = useCallback(
    (period: RangeValue<DateValue>) => {
      setDateValue(period)
    },
    [setDateValue],
  )

  return (
    <div className='space-y-4 p-4'>
      <div className='space-y-2'>
        <Input
          variant='bordered'
          placeholder='Найти...'
          aria-label='Найти'
          value={searchString}
          onValueChange={setSearchString}
        />
        <DateRangePicker
          value={dateValue}
          onChange={handleChangeDateRange}
          variant='bordered'
          aria-label='Период'
        />
      </div>
      <div className='flex flex-wrap items-center gap-1'>
        <Chip
          size='sm'
          variant='bordered'
          color={platform === 'vk.com' ? 'primary' : 'default'}
          onClick={() => handlePlatformClick('vk.com')}
        >
          ВК
        </Chip>
        <Chip
          size='sm'
          variant='bordered'
          color={platform === 't.me' ? 'primary' : 'default'}
          onClick={() => handlePlatformClick('t.me')}
        >
          Телеграм
        </Chip>
        <Chip
          size='sm'
          variant='bordered'
          color={platform === 'ok.ru' ? 'primary' : 'default'}
          onClick={() => handlePlatformClick('ok.ru')}
        >
          Одноклассники
        </Chip>
      </div>
      <Divider />
      <SimpleBar className='-mx-4 h-[calc(100vh-265px)] overflow-x-hidden'>
        <div>
          {data?.map(p => (
            <div
              key={p.id}
              onClick={() => handlePublicClick(p.id)}
              className={clsx(
                'hover:bg-default-200  flex cursor-pointer items-center space-x-1 p-4 font-semibold transition-colors',
                {
                  'bg-default-200 ': activePublic === p.id,
                },
              )}
            >
              <Image
                width={16}
                height={16}
                alt={p.link}
                src={`/social-icons/${getSocialNetworkName(p.link)}.svg`}
              />
              <span>{p.title || p.link}</span>
            </div>
          ))}
        </div>
      </SimpleBar>
    </div>
  )
}
