import { z } from 'zod'

export const planActionModalSchema = z.object({
  importance: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  deadline: z.string().trim().optional().nullable(),
})
