import {
  Button,
  Divider,
  ScrollShadow,
  Textarea,
  useDisclosure,
} from '@nextui-org/react'
import { Value } from '@udecode/plate-common'
import { useAtom } from 'jotai/index'
import { equals } from 'ramda'
import { useCallback, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { IoCloseCircle } from 'react-icons/io5'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { routesConst } from '../../../features/constants/routes.const'
import {
  DeleteModal,
  Empty,
  Header,
  Layout,
  PageSpinner,
  PlateEditorWrapper,
} from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { deleteTask, updateTask } from '../api/tasks.api'
import { AuthorChip } from '../components/AuthorChip'
import { CollectionChip } from '../components/CollectionChip'
import { Comments } from '../components/Comments'
import { DateChip } from '../components/DateChip'
import { ExecutorChip } from '../components/ExecutorChip'
import { ParticipantsChip } from '../components/ParticipantsChip'
import { PriorityChip } from '../components/PriorityChip'
import { StatusChip } from '../components/StatusChip'
import { TaskFiles } from '../components/TaskFiles'
import { menuItems } from '../constants/menuItems'
import { taskAtom, taskIdAtom } from '../store/tasks.store'

export const Task = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [, setId] = useAtom(taskIdAtom)
  const [{ data, isPending, refetch }] = useAtom(taskAtom)
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
    onClose: onDeleteClose,
  } = useDisclosure()

  const pageTitle = useMemo(
    () => `Задача: ${data?.current.title || ''}`,
    [data],
  )

  const onPrevTask = useCallback(() => {
    if (data?.prev) navigate(routesConst.tasks + '/' + data.prev)
  }, [data?.prev, navigate])

  const onNextTask = useCallback(() => {
    if (data?.next) navigate(routesConst.tasks + '/' + data.next)
  }, [data?.next, navigate])

  const handleChangeDescription = useCallback(
    async (value: Value) => {
      if (data && !equals(value)) {
        await updateTask(data.current.id, {
          description: value,
        })
        await refetch()
      }
    },
    [data, refetch],
  )

  const handleChangeTitle = useCallback(
    async (value: string) => {
      if (data?.current) {
        await updateTask(data.current.id, {
          title: value,
        })
      }
    },
    [data],
  )

  const handleChangeAuthor = useCallback(
    async (value: string) => {
      if (data?.current) {
        await updateTask(data.current.id, {
          author: { id: value },
        })
        await refetch()
      }
    },
    [data, refetch],
  )

  useEffect(() => {
    if (id) {
      setId(id)
    }
    return () => {
      setId(undefined)
    }
  }, [id, setId])

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        {isPending ? (
          <PageSpinner />
        ) : !data ? (
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <Empty />
          </div>
        ) : (
          <div className='p-4'>
            <div className='bg-background h-[calc(100vh-104px)] overflow-hidden rounded-xl  shadow'>
              <div className='space-y-4 p-4'>
                <div className='flex items-center justify-between'>
                  <div className='flex space-x-2'>
                    <Button
                      isIconOnly
                      variant='bordered'
                      color='default'
                      size='sm'
                      isDisabled={!data.next || data.next === data.current.id}
                      onPress={onNextTask}
                    >
                      <TbChevronUp className='text-primary text-lg' />
                    </Button>
                    <Button
                      isIconOnly
                      variant='bordered'
                      color='default'
                      size='sm'
                      isDisabled={!data.prev || data.prev === data.current.id}
                      onPress={onPrevTask}
                    >
                      <TbChevronDown className='text-primary text-lg' />
                    </Button>
                  </div>
                  <div className='flex space-x-2'>
                    <Button
                      onPress={onDeleteOpen}
                      color='danger'
                      variant='flat'
                      endContent={<IoCloseCircle className='text-xl' />}
                    >
                      Удалить
                    </Button>
                    <Button
                      onPress={() => {
                        navigate(-1)
                      }}
                      color='primary'
                      variant='flat'
                      endContent={<IoCloseCircle className='text-xl' />}
                    >
                      Закрыть
                    </Button>
                  </div>
                </div>

                <Divider />

                <div className='flex space-x-4'>
                  <div className='flex-1 space-y-4'>
                    <div className='flex space-x-4'>
                      <div className='flex-1 items-start text-lg font-semibold'>
                        <Textarea
                          minRows={1}
                          color='primary'
                          variant='underlined'
                          defaultValue={data.current.title}
                          onValueChange={handleChangeTitle}
                        />
                      </div>
                    </div>
                    <ScrollShadow
                      size={0}
                      className='tiptap h-[calc(100vh-265px)] space-y-2 overflow-auto'
                    >
                      <PlateEditorWrapper
                        withComments={false}
                        initialValue={data.current.description}
                        onChange={handleChangeDescription}
                      />
                    </ScrollShadow>
                  </div>

                  <Divider orientation='vertical' className='h-[initial]' />

                  <ScrollShadow
                    size={0}
                    className='h-[calc(100vh-210px)] w-[500px] space-y-6 overflow-auto'
                  >
                    <div className='space-y-4'>
                      <div className='font-semibold '>Пользователи</div>
                      <div className='text-small grid grid-cols-[130px,1fr] items-center gap-4'>
                        <div className='text-default-400'>Автор:</div>
                        <AuthorChip
                          author={data.current.author}
                          refetch={refetch}
                          updateFn={handleChangeAuthor}
                        />
                        <div className='text-default-400'>Исполнитель:</div>
                        <ExecutorChip
                          id={data.current.id}
                          executor={data.current.executor}
                          refetch={refetch}
                        />
                        <div className='text-default-400'>Участники:</div>
                        <ParticipantsChip
                          id={data.current.id}
                          participants={data.current.participants}
                          refetch={refetch}
                        />
                      </div>

                      <Divider />

                      <div className='font-semibold'>Основная информация</div>
                      <div className='text-small grid grid-cols-[130px,1fr] items-center gap-4'>
                        <div className='text-default-400'>Создана:</div>
                        {getPrettyDate({
                          date: data.current.createdAt,
                        })}
                        <div className='text-default-400'>Срок:</div>
                        <DateChip
                          id={data.current.id}
                          date={data.current.date}
                          time={data.current.time}
                          refetch={refetch}
                        />

                        <div className='text-default-400'>Статус:</div>
                        <StatusChip
                          withChangeAction
                          id={data.current.id}
                          status={data.current.status}
                          refetch={refetch}
                        />
                        <div className='text-default-400'>Приоритет:</div>
                        <PriorityChip
                          id={data.current.id}
                          priority={data.current.priority}
                          refetch={refetch}
                        />
                        <div className='text-default-400'>Проект:</div>
                        <CollectionChip
                          id={data.current.id}
                          collection={data.current.collection}
                          refetch={refetch}
                        />
                      </div>

                      <Divider />

                      <div className='font-semibold'>Материалы</div>
                      <TaskFiles task={data.current} />

                      <Divider />

                      <div className='font-semibold'>Комментарии</div>
                      <Comments task={data.current} />
                    </div>
                  </ScrollShadow>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        onClose={onDeleteClose}
        message='Удалить задачу?'
        deleteAction={async () => {
          await deleteTask(id || '')
          navigate(-1)
          toast.success('Задача удалена')
        }}
      />
    </>
  )
}
