import { GroupInterface } from '@hydra/interfaces'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'
import { atomWithStorage } from 'jotai/vanilla/utils'

import { getAllGroups, getGroupById } from '../api/groups.api'

export const skipAtom = atom(0)
export const takeAtom = atom(50)
export const orderAtom = atomWithStorage<keyof GroupInterface>(
  'newsbreaks-groups-order',
  'createdAt',
)
export const directionAtom = atomWithStorage<'desc' | 'asc'>(
  'newsbreaks-groups-direction',
  'desc',
)
export const groupIdAtom = atom<string | undefined>(undefined)

export const groupsAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'groups-list',
      get(skipAtom),
      get(takeAtom),
      get(orderAtom),
      get(directionAtom),
    ],
    queryFn: async () => {
      const response = await getAllGroups({
        skip: get(skipAtom),
        take: get(takeAtom),
        order: get(orderAtom),
        direction: get(directionAtom),
      })
      return response.data
    },
  }
})

export const groupAtom = atomWithQuery(get => {
  return {
    enabled: !!get(groupIdAtom),

    queryKey: ['group-by-id', get(groupIdAtom)],
    queryFn: async () => {
      const response = await getGroupById(get(groupIdAtom) || '')
      return response.data
    },
  }
})
