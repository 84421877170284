import { atomWithQuery } from 'jotai-tanstack-query'

import { getAllAreas } from '../api'

export const allAreasAtom = atomWithQuery(() => {
  return {
    queryKey: ['areas'],
    queryFn: async () => {
      const response = await getAllAreas()
      return response.data
    },
  }
})
