import { ChatInterface } from '@hydra/interfaces'
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtomValue } from 'jotai'
import { Key, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { chatsAtom } from '../store/chat.store'
import { LevelChip } from './LevelChip'

export const ChatsList = () => {
  const navigate = useNavigate()
  const { data, isPending, refetch } = useAtomValue(chatsAtom)

  const renderCell = useCallback(
    (item: ChatInterface, columnKey: Key) => {
      if (columnKey === 'title') {
        return <div className='w-[250px]'>{item.title}</div>
      }

      if (columnKey === 'link') {
        return (
          <div className='w-[250px] overflow-hidden'>
            <Link
              size='sm'
              isExternal
              href={item.link}
              isBlock
              showAnchorIcon={!!item.link}
            >
              {item.link}
            </Link>
          </div>
        )
      }

      if (columnKey === 'level') {
        return (
          <div>
            <LevelChip id={item.id} level={item.level} refetch={refetch} />
          </div>
        )
      }

      if (columnKey === 'users') {
        return <span>{item.users?.length || 0}</span>
      }

      if (columnKey === 'externalUsers') {
        return <span>{item.externalUsers?.length || 0}</span>
      }

      if (columnKey === 'risks') {
        return <span>{item.risks?.length || 0}</span>
      }
    },
    [refetch],
  )

  if (isPending) return <PageSpinner />

  if (!data || data[0].length <= 0) return <Empty />

  return (
    <Table
      aria-label='Задачи'
      isHeaderSticky
      isStriped
      removeWrapper
      onRowAction={key => navigate(routesConst.chats + '/' + key)}
      classNames={{
        base: 'h-[calc(100vh-140px)] overflow-auto',
      }}
    >
      <TableHeader
        columns={[
          {
            key: 'title',
            label: 'Название',
          },
          {
            key: 'link',
            label: 'Пригласительная ссылка',
          },
          {
            key: 'level',
            label: 'Уровень риска',
          },
          {
            key: 'users',
            label: 'Внутренние',
          },
          {
            key: 'externalUsers',
            label: 'Внешние',
          },
          {
            key: 'risks',
            label: 'Риски',
          },
        ]}
      >
        {column => (
          <TableColumn key={column.key} textValue={column.label}>
            {column.label}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        items={data[0]}
        emptyContent={
          <div className='text-default-400 text-sm'>Нет данных</div>
        }
      >
        {item => (
          <TableRow key={item.id} textValue={item.id}>
            {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
