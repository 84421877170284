import { Helmet } from 'react-helmet'

import { Layout } from '../../../features/ui'
import { MainCard } from '../components/MainCard'
import { items } from '../constants/mainItems'
import { menuItems } from '../constants/menuItems'

export const Main = () => (
  <>
    <Helmet>
      <title>Гидра | Главная</title>
    </Helmet>
    <Layout
      menuItems={menuItems}
      headerContent={
        <div className='flex h-full items-center justify-between space-x-4 px-4'>
          <h1 className='flex-1 text-3xl font-semibold'>Модули</h1>
        </div>
      }
    >
      <div className='p-4'>
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3'>
          {items.map(item => (
            <MainCard
              key={item.id}
              title={item.title}
              icon={item.icon}
              description={item.description}
              grants={item.grants}
              path={item.path}
            />
          ))}
        </div>
      </div>
    </Layout>
  </>
)
