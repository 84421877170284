import { NotificationInterface } from '@hydra/interfaces'
import { Button } from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { TbChecks } from 'react-icons/tb'

import { updateNotification } from '../../../api'
import { notificationsAtom, notificationsCountAtom } from '../../../store'
import { getPrettyDate } from '../../../utils/getPrettyDate'

interface Props {
  notification: NotificationInterface
}

export const NotificationItem = ({ notification }: Props) => {
  const [{ refetch }] = useAtom(notificationsAtom)
  const [{ refetch: refetchCount }] = useAtom(notificationsCountAtom)

  const onCheckButtonPress = useCallback(async () => {
    await updateNotification(notification.id, { isRead: true })
    await refetch()
    await refetchCount()
  }, [notification.id, refetch, refetchCount])

  return (
    <div className='flex items-start space-x-2 p-2'>
      <Button
        isIconOnly
        size='sm'
        variant='light'
        color='success'
        onPress={onCheckButtonPress}
      >
        <TbChecks className='text-xl' />
      </Button>
      <div className='space-y-1'>
        <div
          className='text-sm'
          dangerouslySetInnerHTML={{
            __html: notification.message,
          }}
        />
        <div className='text-default-400 text-xs'>
          {getPrettyDate({
            date: notification.createdAt,
            template: 'DD MMMM YYYY HH:mm',
          })}
        </div>
      </div>
    </div>
  )
}
