import { Grant } from '@hydra/interfaces'
import { CgDanger } from 'react-icons/cg'
import {
  TbCalendar,
  TbHeartRateMonitor,
  TbMessageChatbot,
} from 'react-icons/tb'

import { routesConst } from '../../../features/constants/routes.const'

export const menuItems = [
  {
    title: 'Лента новостей',
    link: routesConst.monitoring,
    icon: <TbHeartRateMonitor />,
    grant: [Grant.publics],
  },
  {
    title: 'Чаты',
    link: routesConst.chats,
    icon: <TbMessageChatbot />,
    grant: [Grant.publics],
  },
  {
    title: 'Риски',
    link: routesConst.risks,
    icon: <CgDanger />,
    grant: [Grant.riskModerator, Grant.riskProvider],
  },
  {
    title: 'Календарь рисков',
    link: routesConst.risksCalendar,
    icon: <TbCalendar />,
    grant: [Grant.riskModerator, Grant.riskProvider],
  },
]
