import { setUser } from '@sentry/react'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'

import {
  getAllUsers,
  getNotifications,
  getNotificationsCount,
  getProfile,
  getUserById,
} from '../api'

export const userIdAtom = atom<string | undefined>(undefined)

export const userByIdAtom = atomWithQuery(get => {
  return {
    enabled: !!get(userIdAtom),
    queryKey: ['user', get(userIdAtom)],
    queryFn: async () => {
      const response = await getUserById(get(userIdAtom) || '')
      return response.data
    },
  }
})

export const allUsersAtom = atomWithQuery(() => {
  return {
    queryKey: ['users'],
    queryFn: async () => {
      const response = await getAllUsers()
      return response.data
    },
  }
})

export const profileAtom = atomWithQuery(() => {
  return {
    queryKey: ['profile'],
    queryFn: async () => {
      const response = await getProfile()
      setUser({
        username:
          response.data.profile.name + ' ' + response.data.profile.surname,
      })
      return response.data
    },
  }
})

export const notificationsCountAtom = atomWithQuery(() => {
  return {
    queryKey: ['notifications-count'],
    queryFn: async () => {
      const response = await getNotificationsCount()
      return response.data
    },
    refetchInterval: 60000, // обновление запроса раз в 60 секунд
  }
})

export const notificationsAtom = atomWithQuery(get => {
  return {
    queryKey: ['notifications', get(notificationsCountAtom)],
    queryFn: async () => {
      const response = await getNotifications()
      return response.data
    },
  }
})
