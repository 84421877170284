import { ReactNode } from 'react'

interface Props {
  text?: string
  actionButton?: ReactNode
}

export const Header = ({ text, actionButton }: Props) => {
  return (
    <div className='flex h-full items-center justify-between space-x-4 px-4'>
      <h1 className='line-clamp-1 flex-1 text-3xl font-semibold'>{text}</h1>
      {actionButton}
    </div>
  )
}
