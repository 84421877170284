import { Grant } from '@hydra/interfaces'
import { Button, Input } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { TbSearch, TbSquareRoundedPlus, TbX } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants, useGrant } from '../../../features/grants'
import { Header, Layout } from '../../../features/ui'
import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import { NewsbreakEditorStatusTabs } from '../components/NewsbreakEditorStatusTabs'
import { NewsbreaksFilters } from '../components/NewsbreaksFilters'
import { NewsbreaksList } from '../components/NewsbreaksList'
import { NewsbreaksPagination } from '../components/NewsbreaksPagination'
import { NewsbreaksSort } from '../components/NewsbreaksSort'
import { NewsbreakStatusTabs } from '../components/NewsbreakStatusTabs'
import { menuItems } from '../constants/menuItems'
import {
  newsbreaksSearchStringAtom,
  skipAtom,
  takeAtom,
} from '../store/newsbreaks.store'

const CreateNewsbreakButton = () => {
  const navigate = useNavigate()
  const { isValidGrant: isModerator } = useGrant([
    Grant.newsbreaksModerator,
    Grant.newsbreaksCategoryModerator,
  ])

  const { isValidGrant: isProvider } = useGrant([Grant.newsbreaksProvider])

  const isCanCreateNewsbreak =
    extendedDayjs().day() !== 5 ||
    extendedDayjs().day() !== 6 ||
    extendedDayjs().day() !== 7

  const onCreateButtonClick = useCallback(() => {
    navigate(routesConst.createNewsbreak)
  }, [navigate])

  if (isModerator || (isCanCreateNewsbreak && isProvider))
    return (
      <Button
        color='primary'
        startContent={<TbSquareRoundedPlus className='text-2xl' />}
        onPress={onCreateButtonClick}
      >
        Создать
      </Button>
    )

  return null
}

export const Newsbreaks = () => {
  const { isValidGrant: isEditor } = useGrant([Grant.newsbreaksEditor])
  const [value, setValue] = useState('')
  const [, setSearchString] = useAtom(newsbreaksSearchStringAtom)
  const [, setSkip] = useAtom(skipAtom)
  const [, setTake] = useAtom(takeAtom)

  const handleSearch = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setSkip(0)
      setTake(10)
      if (value !== '') {
        setSearchString(value)
      } else {
        setSearchString(undefined)
      }
    },
    [setSearchString, setSkip, setTake, value],
  )

  useEffect(() => {
    if (value === '') {
      setSearchString(undefined)
    }
  }, [setSearchString, value])

  return (
    <>
      <Helmet>
        <title>Гидра | Инфоповоды</title>
      </Helmet>
      <Grants
        grants={[
          Grant.newsbreaksModerator,
          Grant.newsbreaksProvider,
          Grant.newsbreaksEditor,
          Grant.newsbreaksCategoryModerator,
        ]}
      >
        <Layout
          menuItems={menuItems}
          headerContent={
            <Header
              text='Инфоповоды'
              actionButton={
                <form onSubmit={handleSearch} className='flex space-x-2'>
                  <Input
                    className='w-[450px]'
                    value={value}
                    onValueChange={setValue}
                    placeholder='Найти по заголовку, описанию, акцентам...'
                    variant='bordered'
                    endContent={
                      <div className='flex space-x-1'>
                        {value && (
                          <Button
                            type='button'
                            isIconOnly
                            size='sm'
                            variant='light'
                            color='danger'
                            onPress={() => setValue('')}
                          >
                            <TbX />
                          </Button>
                        )}
                        <Button
                          type='submit'
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <TbSearch />
                        </Button>
                      </div>
                    }
                  />
                  <CreateNewsbreakButton />
                </form>
              }
            />
          }
        >
          <div className='space-y-4 overflow-hidden p-4'>
            {isEditor ? <NewsbreakEditorStatusTabs /> : <NewsbreakStatusTabs />}
            <NewsbreaksSort />
            <div className='flex space-x-4'>
              <SimpleBar className='h-[calc(100vh-256px)] flex-1'>
                <NewsbreaksList />
              </SimpleBar>
              <NewsbreaksFilters />
            </div>
            <NewsbreaksPagination />
          </div>
        </Layout>
      </Grants>
    </>
  )
}
