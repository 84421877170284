import { Grant } from '@hydra/interfaces'
import { Button } from '@nextui-org/react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

import { useGrant } from '../../../grants'

interface Props {
  title: string
  link: string
  icon: ReactNode
  grant: Grant | Grant[] | undefined
}

export const MenuItem = ({ link, icon, title, grant }: Props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isMenuCollapsed] = useLocalStorage('isMenuCollapsed', false)
  const { isValidGrant } = useGrant(grant)

  if (!isValidGrant) return null

  return (
    <Button
      fullWidth
      size='sm'
      color='primary'
      variant={pathname === link ? 'flat' : 'light'}
      onClick={() => navigate(link)}
      isIconOnly={isMenuCollapsed}
      startContent={
        isMenuCollapsed ? null : <div className='text-lg'>{icon}</div>
      }
      className={clsx('justify-start space-x-2', {
        'justify-center': isMenuCollapsed,
      })}
    >
      {isMenuCollapsed ? (
        <span className='text-lg'>{icon}</span>
      ) : (
        <span>{title}</span>
      )}
    </Button>
  )
}
