import { Grant, RiskStatusEnum } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Chip,
  Divider,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom, useAtomValue } from 'jotai'
import { equals } from 'ramda'
import { Key, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { IoCloseCircle } from 'react-icons/io5'
import { TbArchive, TbEdit } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants, useGrant } from '../../../features/grants'
import {
  Empty,
  Header,
  Layout,
  PageSpinner,
  PlateEditorWrapper,
} from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { createRiskComment, updateRisk } from '../api/risk.api'
import { Comments } from '../components/Comments'
import { EditRiskModal } from '../components/EditRiskModal'
import { PostsList } from '../components/PostsList'
import { RiskFiles } from '../components/RiskFiles'
import { menuItems } from '../constants/menuItems'
import { statusConst } from '../constants/status.const'
import { chatsAtom } from '../store/chat.store'
import { riskAtom, riskIdAtom } from '../store/risk.store'

export const Risk = () => {
  const { isValidGrant: isModerator } = useGrant([Grant.riskModerator])
  const { isValidGrant: isProvider } = useGrant([Grant.riskProvider])
  const navigate = useNavigate()
  const { id } = useParams()
  const { data: risk, isPending, refetch } = useAtomValue(riskAtom)
  const [, setId] = useAtom(riskIdAtom)
  const [newComment, setNewComment] = useState('')
  const [isCreateCommentLoading, setIsCreateCommentLoading] = useState(false)
  const [selected, setSelected] = useState<Key | null | undefined>('info')
  const { data: chats, isPending: isChatsPending } = useAtomValue(chatsAtom)
  const [chat, setChat] = useState<Key | null | undefined>(null)

  const {
    isOpen: isOpenArchiveModal,
    onOpen: onOpenArchiveModal,
    onOpenChange: onOpenChangeArchiveModal,
    onClose: onCloseArchiveModal,
  } = useDisclosure()

  const {
    isOpen: isOpenNoAgreementModal,
    onOpen: onOpenNoAgreementModal,
    onOpenChange: onOpenChangeNoAgreementModal,
    onClose: onCloseNoAgreementModal,
  } = useDisclosure()

  const {
    isOpen: isOpenAgreementModal,
    onOpen: onOpenAgreementModal,
    onOpenChange: onOpenChangeAgreementModal,
    onClose: onCloseAgreementModal,
  } = useDisclosure()

  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onOpenChange: onOpenChangeEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure()

  const {
    isOpen: isOpenCurrentModal,
    onOpen: onOpenCurrentModal,
    onOpenChange: onOpenChangeCurrentModal,
    onClose: onCloseCurrentModal,
  } = useDisclosure()

  const pageTitle = useMemo(() => `Риск: ${risk?.title || ''}`, [risk])

  const onCurrentButtonPress = useCallback(async () => {
    if (!risk) return null

    if (newComment === '') {
      return toast.error('Введите комменатрий')
    }

    setIsCreateCommentLoading(true)

    await createRiskComment({
      value: newComment,
      riskId: risk.id,
    })

    setNewComment('')
    await updateRisk(risk.id, { status: RiskStatusEnum.current })
    await refetch()
    onCloseCurrentModal()
    setIsCreateCommentLoading(false)
  }, [newComment, onCloseCurrentModal, refetch, risk])

  const onArchiveButtonPress = useCallback(async () => {
    if (!risk) return null

    if (newComment === '') {
      return toast.error('Введите комменатрий')
    }

    setIsCreateCommentLoading(true)

    await createRiskComment({
      value: newComment,
      riskId: risk.id,
    })

    setNewComment('')
    await updateRisk(risk.id, { status: RiskStatusEnum.archive })
    await refetch()
    onCloseArchiveModal()
    setIsCreateCommentLoading(false)
  }, [newComment, onCloseArchiveModal, refetch, risk])

  const onNoAgreementButtonPress = useCallback(async () => {
    if (!risk) return null

    if (newComment === '') {
      return toast.error('Введите комменатрий')
    }

    setIsCreateCommentLoading(true)

    await createRiskComment({
      value: newComment,
      riskId: risk.id,
    })

    setNewComment('')
    await updateRisk(risk.id, { status: RiskStatusEnum.draft })
    await refetch()
    onCloseNoAgreementModal()
    setIsCreateCommentLoading(false)
  }, [newComment, onCloseNoAgreementModal, refetch, risk])

  const onAgreementButtonPress = useCallback(async () => {
    if (!risk) return null

    await updateRisk(risk.id, {
      status: RiskStatusEnum.current,
      chat: chat ? { id: chat as string } : undefined,
    })

    await refetch()
    onCloseAgreementModal()
  }, [chat, onCloseAgreementModal, refetch, risk])

  const handleAgreementRisk = useCallback(async () => {
    if (!risk) return null
    await updateRisk(risk.id, { status: RiskStatusEnum.agreement })
    await refetch()
  }, [refetch, risk])

  useEffect(() => {
    if (id) {
      setId(id)
    }
    return () => {
      setId(undefined)
    }
  }, [id, setId])

  return (
    <Grants grants={[Grant.riskModerator, Grant.riskProvider]}>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>

      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        {isPending ? (
          <PageSpinner />
        ) : !risk ? (
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <Empty />
          </div>
        ) : (
          <div className='p-4'>
            <div className='bg-background h-[calc(100vh-104px)] overflow-hidden rounded-xl shadow'>
              <div className='space-y-4 p-4'>
                <div className='flex items-center justify-between'>
                  <div>
                    <Tabs
                      aria-label='Отображение'
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      selectedKey={selected}
                      onSelectionChange={setSelected}
                      size='sm'
                      color='primary'
                    >
                      <Tab key='info' title='Описание'></Tab>
                      <Tab key='posts' title='Пикрепленные посты'></Tab>
                    </Tabs>
                  </div>
                  <div className='flex items-center justify-end'>
                    <Button
                      onPress={() => {
                        navigate(-1)
                      }}
                      color='primary'
                      endContent={<IoCloseCircle className='text-xl' />}
                    >
                      Закрыть
                    </Button>
                  </div>
                </div>

                <Divider />

                <div className='grid grid-cols-[60%_1px_1fr] gap-4'>
                  {selected === 'info' && (
                    <div className='space-y-4'>
                      <div className='flex space-x-4'>
                        <div className='flex-1 items-start text-lg font-semibold'>
                          {risk.title}
                        </div>
                      </div>
                      <SimpleBar className='h-[calc(100vh-265px)]'>
                        <div className='tiptap space-y-2'>
                          <PlateEditorWrapper
                            withComments={false}
                            initialValue={
                              risk.description?.length
                                ? risk.description
                                : undefined
                            }
                            isReadOnly={[
                              RiskStatusEnum.current,
                              RiskStatusEnum.archive,
                            ].includes(risk.status)}
                            onChange={async value => {
                              if (!equals(value, risk.description)) {
                                await updateRisk(risk.id, {
                                  description: value,
                                })
                                await refetch()
                              }
                            }}
                          />
                        </div>
                      </SimpleBar>
                    </div>
                  )}

                  {selected === 'posts' && <PostsList posts={risk.posts} />}

                  <Divider orientation='vertical' className='h-[initial]' />

                  <div className='space-y-4 overflow-auto'>
                    <SimpleBar className='h-[calc(100vh-294px)]'>
                      <div className='space-y-4'>
                        <div className='space-y-4'>
                          <div className='text-lg font-semibold'>
                            Основная информация
                          </div>
                          <div className='grid grid-cols-2 gap-y-4'>
                            <div className='text-default-400 text-sm'>
                              Статус
                            </div>
                            <div>
                              <Chip
                                variant='dot'
                                size='sm'
                                color={statusConst[risk.status].color}
                              >
                                {statusConst[risk.status].title}
                              </Chip>
                            </div>
                            <div className='text-default-400 col-start-1 col-end-3 text-sm'>
                              {risk.isPlan ? (
                                <span className='text-success'>
                                  Плановый риск
                                </span>
                              ) : (
                                <span className='text-danger'>
                                  Срочный риск
                                </span>
                              )}
                            </div>
                            <div className='text-default-400 text-sm'>
                              Дата создания
                            </div>
                            <div className='text-sm'>
                              {getPrettyDate({
                                date: risk.createdAt,
                              })}
                            </div>
                            <div className='text-default-400 text-sm'>
                              Дата натсупления риска
                            </div>
                            <div className='text-sm'>
                              {getPrettyDate({
                                date: risk.date,
                                template: 'DD MM YYYY HH:mm',
                              })}
                            </div>
                            <div className='text-default-400 text-sm'>
                              Автор риска
                            </div>
                            <div>
                              <Chip
                                size='sm'
                                color='primary'
                                variant='bordered'
                              >
                                {risk.author?.name} {risk.author?.surname}
                              </Chip>
                            </div>
                            <div className='text-default-400 text-sm'>
                              Организация
                            </div>
                            <div className='text-sm'>
                              {risk.author?.organization.name}
                            </div>
                            <div className='text-default-400 text-sm'>
                              Локация
                            </div>
                            <div className='text-sm'>
                              {risk.location?.title}
                            </div>
                            <div className='text-default-400 text-sm'>
                              Адрес
                            </div>
                            <div className='text-sm'>{risk.address}</div>
                            <div className='text-default-400 text-sm'>Тема</div>
                            <div className='text-sm'>{risk.theme?.title}</div>
                            <div className='text-default-400 text-sm'>Тип</div>
                            <div className='text-sm'>{risk.type?.title}</div>
                            <div className='text-default-400 text-sm'>
                              Важность
                            </div>
                            <div className='text-sm'>
                              {risk.importance?.title}
                            </div>
                            <div className='text-default-400 text-sm'>Чат</div>
                            <div className='text-sm'>
                              <Link
                                size='sm'
                                href={routesConst.chats + '/' + risk.chat?.id}
                              >
                                {risk.chat?.title}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <Divider />
                        <div className='space-y-4'>
                          <div className='text-lg font-semibold'>
                            Материалы к риску
                          </div>
                          <RiskFiles
                            files={risk.files || []}
                            riskId={risk.id}
                          />
                        </div>
                        <Divider />
                        <div className='space-y-4'>
                          <div className='text-lg font-semibold'>
                            Комментарии
                          </div>
                          <Comments />
                        </div>
                      </div>
                    </SimpleBar>

                    <Divider />

                    <div className='flex flex-wrap items-center justify-end gap-4'>
                      {risk.status === RiskStatusEnum.draft && (
                        <Button
                          color='primary'
                          variant='bordered'
                          onPress={handleAgreementRisk}
                        >
                          Отправить на согласование
                        </Button>
                      )}
                      {isModerator &&
                        risk.status === RiskStatusEnum.agreement && (
                          <>
                            <Button
                              color='primary'
                              onPress={onOpenAgreementModal}
                            >
                              Согласовать
                            </Button>
                            <Button
                              color='danger'
                              onPress={onOpenNoAgreementModal}
                            >
                              Не согласовать
                            </Button>
                          </>
                        )}
                      {((isProvider &&
                        [
                          RiskStatusEnum.draft,
                          RiskStatusEnum.agreement,
                        ].includes(risk.status)) ||
                        isModerator) && (
                        <Button
                          color='primary'
                          variant='bordered'
                          isIconOnly
                          onPress={onOpenEditModal}
                        >
                          <TbEdit className='text-2xl' />
                        </Button>
                      )}
                      {isModerator &&
                        risk.status === RiskStatusEnum.archive && (
                          <Button
                            variant='bordered'
                            color='success'
                            onPress={onOpenCurrentModal}
                          >
                            Актуализировать
                          </Button>
                        )}
                      {isModerator &&
                        risk.status !== RiskStatusEnum.archive && (
                          <Button
                            variant='bordered'
                            color='danger'
                            isIconOnly
                            onPress={onOpenArchiveModal}
                          >
                            <TbArchive className='text-2xl' />
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>

      <Modal
        isOpen={isOpenCurrentModal}
        onOpenChange={onOpenChangeCurrentModal}
      >
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Укажите причину актуализации
              </ModalHeader>
              <ModalBody>
                <Input
                  variant='bordered'
                  placeholder='Комментировать'
                  value={newComment}
                  onValueChange={setNewComment}
                />
              </ModalBody>
              <ModalFooter>
                <Button variant='light' onPress={onClose}>
                  Закрыть
                </Button>
                <Button
                  isLoading={isCreateCommentLoading}
                  color='success'
                  isDisabled={isCreateCommentLoading}
                  onPress={onCurrentButtonPress}
                >
                  Актуализировать
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenArchiveModal}
        onOpenChange={onOpenChangeArchiveModal}
      >
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Укажите причину архивирования
              </ModalHeader>
              <ModalBody>
                <Input
                  variant='bordered'
                  placeholder='Комментировать'
                  value={newComment}
                  onValueChange={setNewComment}
                />
              </ModalBody>
              <ModalFooter>
                <Button variant='light' onPress={onClose}>
                  Закрыть
                </Button>
                <Button
                  isLoading={isCreateCommentLoading}
                  color='primary'
                  isDisabled={isCreateCommentLoading}
                  onPress={onArchiveButtonPress}
                >
                  Архивировать
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenNoAgreementModal}
        onOpenChange={onOpenChangeNoAgreementModal}
      >
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Укажите по какой причине не согласовали риск
              </ModalHeader>
              <ModalBody>
                <Input
                  variant='bordered'
                  placeholder='Комментировать'
                  value={newComment}
                  onValueChange={setNewComment}
                />
              </ModalBody>
              <ModalFooter>
                <Button variant='light' onPress={onClose}>
                  Закрыть
                </Button>
                <Button
                  isLoading={isCreateCommentLoading}
                  color='danger'
                  isDisabled={isCreateCommentLoading}
                  onPress={onNoAgreementButtonPress}
                >
                  Не согласовать
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenAgreementModal}
        onOpenChange={onOpenChangeAgreementModal}
      >
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Необязательный комментарий при согласовании
              </ModalHeader>
              <ModalBody>
                <Input
                  variant='bordered'
                  placeholder='Комментировать'
                  value={newComment}
                  onValueChange={setNewComment}
                />

                <Autocomplete
                  aria-label='Чат риска'
                  isLoading={isChatsPending}
                  variant='bordered'
                  defaultItems={chats ? chats[0] : []}
                  label='Чат риска'
                  onSelectionChange={setChat}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  selectedKey={chat} // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  onKeyDown={e => e.continuePropagation()}
                >
                  {item => (
                    <AutocompleteItem key={item.id}>
                      {item.title}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
                <p className='text-default-400 text-xs'>
                  Если в списке нету нужного вам чата, вы можете создать его
                </p>
                <Button
                  onPress={() => navigate(routesConst.createChat)}
                  size='sm'
                  variant='bordered'
                >
                  Создать чат
                </Button>
              </ModalBody>
              <ModalFooter>
                <Button variant='light' onPress={onClose}>
                  Закрыть
                </Button>
                <Button
                  isLoading={isCreateCommentLoading}
                  color='primary'
                  isDisabled={isCreateCommentLoading}
                  onPress={onAgreementButtonPress}
                >
                  Согласовать
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <EditRiskModal
        isOpen={isOpenEditModal}
        onOpenChange={onOpenChangeEditModal}
        onClose={onCloseEditModal}
      />
    </Grants>
  )
}
