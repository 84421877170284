import { Grant } from '@hydra/interfaces'
import { Button } from '@nextui-org/react'
import { useAtomValue } from 'jotai/index'
import { useState } from 'react'
import { toast } from 'react-toastify'
import slug from 'slug'

import { getFileUrlFromS3, uploadFileToS3 } from '../../../features/api'
import {
  audioExtensions,
  docExtensions,
  imageExtensions,
  videoExtensions,
} from '../../../features/constants/fileExtensions.const'
import { ONE_HUNDRED_MEGABYTES_IN_BYTES } from '../../../features/constants/fileSizes.const'
import { useGrant } from '../../../features/grants'
import { FileDnD, FileItemPreview } from '../../../features/ui'
import { downloadAllFiles } from '../../../features/utils/downloadAllFiles'
import { getFileExtension } from '../../../features/utils/getFileExtension'
import { createNewsbreakFile } from '../api/newsbreaks.api'
import { newsbreakFilesAtom } from '../store/newsbreaks.store'

export const NewsbreakFiles = () => {
  const { isValidGrant: isEditor } = useGrant(Grant.newsbreaksEditor)
  const [visibleFilesCount, setVisibleFilesCount] = useState(2)
  const { refetch, data } = useAtomValue(newsbreakFilesAtom)

  const loadFunction = async (files: Array<File>) => {
    if (!data) return null

    try {
      if (files) {
        const filesResponses = await Promise.allSettled(
          files.map(file => {
            return uploadFileToS3(
              new File(
                [file],
                slug(file.name.replace(/\.[^/.]+$/, '')) +
                  '.' +
                  getFileExtension(file.name),
                {
                  type: file.type,
                },
              ),
            )
          }),
        )
        const filesUrlResponses = await Promise.allSettled(
          filesResponses.map(file => {
            if (file.status === 'fulfilled' && file.value) {
              return getFileUrlFromS3(
                file.value.data,
                import.meta.env.VITE_BUCKET_NAME,
              )
            }

            return null
          }),
        )

        filesUrlResponses.forEach(file => {
          if (file.status === 'fulfilled' && file.value) {
            return createNewsbreakFile({
              ...file.value.data,
              newsbreakId: data.id,
            })
          }
          return null
        })
      }

      await refetch()
    } catch (e) {
      console.error(e)
    }
  }

  const onDropAccepted = async (files: Array<File>) => {
    await toast.promise(loadFunction(files), {
      pending: 'Загрузка файлов...',
      success: 'Файлы успешно загружены',
      error: 'Не удалось загрузить файлы',
    })
  }

  if (!data) return null

  return (
    <div className='space-y-4'>
      <div className='flex items-center justify-between space-y-1'>
        <div className='flex-1  font-semibold'>Материалы к публикации</div>
        {data.files.length ? (
          <div>
            <Button
              size='sm'
              variant='light'
              color='primary'
              onPress={() => {
                downloadAllFiles(data.files)
              }}
            >
              Скачать все
            </Button>
          </div>
        ) : null}
      </div>
      <div className='space-y-2'>
        {data.files.slice(0, visibleFilesCount).map(file => (
          <FileItemPreview file={file} key={file.id} refetch={refetch} />
        ))}

        {!isEditor && (
          <FileDnD
            isShowCount={false}
            size='sm'
            maxFiles={100}
            maxSize={ONE_HUNDRED_MEGABYTES_IN_BYTES}
            multiple
            onDropAccepted={onDropAccepted}
            accept={{
              ...audioExtensions,
              ...videoExtensions,
              ...imageExtensions,
              ...docExtensions,
            }}
          />
        )}
        {data.files.length > 2 && (
          <Button
            size='sm'
            variant='light'
            onPress={() => {
              if (visibleFilesCount === data.files.length) {
                setVisibleFilesCount(2)
              } else {
                setVisibleFilesCount(data.files.length)
              }
            }}
          >
            <div className='text-default-400'>
              {visibleFilesCount === data.files.length
                ? 'Скрыть'
                : `Показать все (${data.files.length})`}
            </div>
          </Button>
        )}
      </div>
    </div>
  )
}
