import { Grant } from '@hydra/interfaces'
import { Button, Tab, Tabs, useDisclosure } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { TbEdit, TbSquareRoundedPlus } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants } from '../../../features/grants'
import { Empty, Header, Layout, PageSpinner } from '../../../features/ui'
import { CreateOrUpdatePerformanceModal } from '../components/CreateOrUpdatePerformanceModal'
import { GroupOrganizationsTable } from '../components/GroupOrganizationsTable'
import { GroupPerformancesList } from '../components/GroupPerformancesList'
import { menuItems } from '../constants/menuItems'
import { groupAtom, groupIdAtom } from '../store/groups.store'

type TabType = 'organizations' | 'performances'

export const Group = () => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const [{ data, isPending, refetch }] = useAtom(groupAtom)
  const { id } = useParams()
  const [, setGroupId] = useAtom(groupIdAtom)
  const [selectedTab, setselectedTab] = useState<TabType>('organizations')

  const tabs: Array<{ id: TabType; label: string; content: ReactNode }> = [
    {
      id: 'organizations',
      label: 'Организации',
      content: (
        <GroupOrganizationsTable
          organizations={data ? data.organizations : []}
        />
      ),
    },
    {
      id: 'performances',
      label: 'КПЭ',
      content: (
        <GroupPerformancesList performances={data ? data.performances : []} />
      ),
    },
  ]

  useEffect(() => {
    if (id) {
      setGroupId(id)
    }
    return () => {
      setGroupId(undefined)
    }
  }, [id, setGroupId])

  return (
    <>
      <Helmet>
        <title>Гидра | Группа</title>
      </Helmet>
      <Grants grants={[Grant.newsbreaksModerator]}>
        <Layout
          menuItems={menuItems}
          headerContent={
            <Header
              text={`Группа: ${data ? data.title : ''}`}
              actionButton={
                selectedTab === 'organizations' ? (
                  <div>
                    <Button
                      color='primary'
                      onPress={() => {
                        const params = new URLSearchParams({
                          id: data ? data.id : '',
                          title: data ? data.title : '',
                          organizations: JSON.stringify(
                            data
                              ? data.organizations.map(org => org.id)
                              : undefined,
                          ),
                        })
                        navigate(routesConst.editGroup + '?' + params)
                      }}
                      startContent={<TbEdit className='text-2xl' />}
                    >
                      Редактировать
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      color='primary'
                      onPress={onOpen}
                      startContent={
                        <TbSquareRoundedPlus className='text-2xl' />
                      }
                    >
                      Добавить КПЭ
                    </Button>
                  </div>
                )
              }
            />
          }
        >
          {isPending ? (
            <PageSpinner />
          ) : !data ? (
            <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
              <Empty />
            </div>
          ) : (
            <div className='p-4'>
              <Tabs
                aria-label='Табы группы'
                items={tabs}
                selectedKey={selectedTab}
                onSelectionChange={key => setselectedTab(key as TabType)}
                classNames={{
                  panel: '!pt-4 !px-0 !pb-0',
                }}
              >
                {item => (
                  <Tab key={item.id} title={item.label}>
                    {item.content}
                  </Tab>
                )}
              </Tabs>
              <CreateOrUpdatePerformanceModal
                refetch={refetch}
                groupId={data.id}
                isOpen={isOpen}
                onClose={onClose}
                onOpenChange={onOpenChange}
              />
            </div>
          )}
        </Layout>
      </Grants>
    </>
  )
}
