import { PublicGroupInterface } from '@hydra/interfaces'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'

import { getPublicGroupById, getPublicGroups } from '../api/group.api'

export const skipAtom = atom(0)
export const takeAtom = atom(25)
export const orderAtom = atom<keyof PublicGroupInterface>('createdAt')
export const directionAtom = atom<'desc' | 'asc'>('desc')
export const publicGroupIdAtom = atom<string | undefined>(undefined)

export const publicGroupsAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'public-groups-list',
      get(skipAtom),
      get(takeAtom),
      get(orderAtom),
      get(directionAtom),
    ],
    queryFn: async () => {
      const response = await getPublicGroups({
        skip: get(skipAtom),
        take: get(takeAtom),
        order: get(orderAtom),
        direction: get(directionAtom),
      })
      return response.data
    },
  }
})

export const publicGroupAtom = atomWithQuery(get => {
  return {
    enabled: !!get(publicGroupIdAtom),
    queryKey: ['public-group-by-id', get(publicGroupIdAtom)],
    queryFn: async () => {
      const response = await getPublicGroupById(get(publicGroupIdAtom) || '')
      return response.data
    },
  }
})
