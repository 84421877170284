import { Grant } from '@hydra/interfaces'
import { Button, Link } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { useIsClient, useLocalStorage } from 'usehooks-ts'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @nx/enforce-module-boundaries
import { version } from '../../../../../../../package.json'
import { routesConst } from '../../../constants/routes.const'
import { useMobileWidth } from '../../../hooks/useMobileWidth'
import { MenuLogo } from '../../icons/MenuLogo'
import { Menu } from './Menu'
import { NotificationsButton } from './NotificationsButton'

interface Props extends PropsWithChildren {
  menuItems: Array<{
    title: string
    link: string
    icon: ReactNode
    grant: Grant | Grant[] | undefined
  }>
  headerContent: ReactNode
}

export const Layout = ({ children, menuItems, headerContent }: Props) => {
  const isMobile = useMobileWidth()
  const isClient = useIsClient()
  const [isMenuCollapsed] = useLocalStorage('isMenuCollapsed', false)

  const animationValue = useMemo(() => {
    if (isMobile && isClient) {
      return '0px'
    }
    return isMenuCollapsed ? '64px' : '180px'
  }, [isClient, isMenuCollapsed, isMobile])

  return (
    <div className='bg-default-50 min-h-screen'>
      <motion.header
        initial={{
          left: animationValue,
        }}
        animate={{
          left: animationValue,
        }}
        className='border-b-divider bg-background fixed right-0 top-0 z-50 h-[72px] border-b'
      >
        <div className='flex h-full max-w-[1920px] items-center justify-between'>
          <div className='flex-1'>{headerContent}</div>
          <div className='pr-4'>
            <NotificationsButton />
          </div>
        </div>
      </motion.header>
      <motion.aside
        initial={{
          width: animationValue,
        }}
        animate={{
          width: animationValue,
        }}
        className='border-r-divider bg-background fixed inset-y-0 left-0 hidden flex-col gap-6 border-r sm:flex '
      >
        <div className='border-b-divider bg-background flex h-[72px] items-center justify-center border-b '>
          <Link href={routesConst.main}>
            <MenuLogo isFull={!isMenuCollapsed} />
          </Link>
        </div>
        <Menu items={menuItems} />
        <div className='text-small text-default-400 flex justify-end px-2'>
          {version}
        </div>
      </motion.aside>
      <motion.main
        initial={{
          marginLeft: animationValue,
        }}
        animate={{
          marginLeft: animationValue,
        }}
        className='bg-default-50 max-w-[1920px] pt-[72px]'
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        {window.isShowErrorButton && (
          <Button
            onPress={() => {
              throw new Error('Sentry Test Error')
            }}
          >
            Тест sentry
          </Button>
        )}
        {children}
      </motion.main>
    </div>
  )
}
