import clsx from 'clsx'
import plural from 'plural-ru'
import React, { useCallback } from 'react'
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { TbUpload } from 'react-icons/tb'
import { toast } from 'react-toastify'

import { bytesToMegabytes } from '../../utils/bytesToMegabytes'

export const FILE_INVALID_TYPE = 'file-invalid-type'
export const FILE_TOO_LARGE = 'file-too-large'
export const FILE_TOO_SMALL = 'file-too-small'
export const TOO_MANY_FILES = 'too-many-files'

type Props = {
  isShowCount?: boolean
  size?: 'sm' | 'md'
  accept: Accept
  maxFiles: number
  maxSize: number
  minSize?: number
  multiple: boolean
  disabled?: boolean
  onDropAccepted: (files: Array<File>, event: DropEvent) => void
}
export const FileDnD = ({
  size = 'md',
  isShowCount = true,
  accept,
  maxFiles,
  maxSize,
  minSize,
  multiple,
  onDropAccepted,
  disabled,
}: Props) => {
  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      fileRejections.forEach(({ errors, file }) => {
        errors.forEach(error => {
          if (error.code === FILE_INVALID_TYPE) {
            toast.error(`Файл ${file.name} неверного формата`)
          }
          if (error.code === FILE_TOO_LARGE) {
            toast.error(
              `Файл ${
                file.name
              } слишком большой. Разрешено загрузать файлы до ${bytesToMegabytes(
                maxSize,
              )} мегабайт`,
            )
          }
          if (error.code === FILE_TOO_SMALL) {
            toast.error(
              `Файл ${
                file.name
              } слишком маленький. Разрешено загрузать файлы от ${bytesToMegabytes(
                minSize || 0,
              )} мегабайт`,
            )
          }
          if (error.code === TOO_MANY_FILES) {
            toast.error(
              `Слишком много файлов. Максимальное количество файлов ${maxFiles}`,
            )
          }
        })
      })
    },
    [maxFiles, maxSize, minSize],
  )
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    disabled,
    onDropAccepted,
    onDropRejected,
    maxFiles,
    maxSize,
    minSize,
    multiple,
    accept,
  })
  return (
    <div
      {...getRootProps()}
      className={clsx(
        'border-default-200 bg-default-50 hover:bg-default-100 cursor-pointer rounded-lg border border-dashed px-2 py-8 transition-colors',
        {
          '!py-5': size === 'sm',
        },
      )}
    >
      <input {...getInputProps()} />
      <div className='text-default-500 flex items-center justify-center space-x-2'>
        {isShowCount && acceptedFiles.length > 0 ? (
          <p className='text-small'>
            {plural(acceptedFiles.length, 'Добавлен', 'Добавлено', 'Добавлено')}{' '}
            <span className='font-bold'>{acceptedFiles.length}</span>{' '}
            {plural(acceptedFiles.length, 'файл', 'файла', 'файлов')}
          </p>
        ) : (
          <>
            <TbUpload className='text-2xl' />
            <p className='text-small'>Добавьте файлы</p>
          </>
        )}
      </div>
    </div>
  )
}
