import { TaskPriorityEnum } from '@hydra/interfaces'
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import { useCallback, useEffect, useState } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { updateTask } from '../api/tasks.api'
import { taskPriorityConst } from '../constants/taskPriority.const'

interface Props {
  id: string
  priority: TaskPriorityEnum
  refetch: () => void
}

export const PriorityChip = ({ id, priority, refetch }: Props) => {
  const [color, setColor] = useState<
    'primary' | 'danger' | 'default' | 'success'
  >('default')

  const handleUpdatePriority = useCallback(
    async (priority: TaskPriorityEnum) => {
      await updateTask(id, { priority })
      refetch()
    },
    [id, refetch],
  )

  useEffect(() => {
    if (priority === TaskPriorityEnum.high) {
      setColor('danger')
    }
    if (priority === TaskPriorityEnum.normal) {
      setColor('primary')
    }
    if (priority === TaskPriorityEnum.low) {
      setColor('success')
    }
  }, [priority])

  return (
    <Dropdown backdrop='blur'>
      <DropdownTrigger>
        <Chip
          size='sm'
          variant='bordered'
          color={color}
          className='cursor-pointer'
          endContent={<TbChevronDown className='text-xl' />}
          as='span'
        >
          {taskPriorityConst[priority]}
        </Chip>
      </DropdownTrigger>
      <DropdownMenu
        variant='faded'
        aria-label={`Приоритет задачи ${id}`}
        onAction={key => handleUpdatePriority(key as TaskPriorityEnum)}
      >
        <DropdownItem key={TaskPriorityEnum.high}>
          {taskPriorityConst[TaskPriorityEnum.high]}
        </DropdownItem>
        <DropdownItem key={TaskPriorityEnum.normal}>
          {taskPriorityConst[TaskPriorityEnum.normal]}
        </DropdownItem>
        <DropdownItem key={TaskPriorityEnum.low}>
          {taskPriorityConst[TaskPriorityEnum.low]}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
