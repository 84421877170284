import { Button } from '@nextui-org/react'
import { useCallback } from 'react'
import { TbClipboardCopy } from 'react-icons/tb'
import { toast } from 'react-toastify'
import { useCopyToClipboard } from 'usehooks-ts'

export const CopyToClipboardButton = ({
  value,
  messageText,
}: {
  value: string
  messageText?: string
}) => {
  const [, setCopy] = useCopyToClipboard()

  const onButtonPress = useCallback(async () => {
    try {
      await setCopy(value)
      toast.success(messageText || 'Скопированно в буфер обмена')
    } catch (e) {
      toast.error('Что то пошло не так при копировании')
    }
  }, [value, messageText])

  return (
    <Button isIconOnly onPress={onButtonPress} size='sm' variant='light'>
      <TbClipboardCopy className='text-base' />
    </Button>
  )
}
