import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import { TbTrashFilled } from 'react-icons/tb'

interface Props {
  isOpen: boolean
  onOpenChange: () => void
  deleteAction: () => void
  onClose: () => void
  message?: string
  description?: string
}

export const DeleteModal = ({
  isOpen,
  onOpenChange,
  deleteAction,
  message,
  description = 'Вы уверены, что хотите удалить? Это действие невозможно отменить.',
  onClose,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {() => (
          <>
            <ModalHeader />
            <ModalBody>
              <div className='flex flex-col items-center justify-center space-y-4'>
                <div className='bg-danger text-background flex h-[60px] w-[60px] items-center justify-center rounded-2xl'>
                  <TbTrashFilled className='text-4xl' />
                </div>
                <p className='text-center text-lg  font-semibold'>{message}</p>
                <p className='text-small text-default-600 text-center'>
                  {description}
                </p>
              </div>
            </ModalBody>
            <ModalFooter className='flex justify-center'>
              <Button variant='bordered' onPress={onClose}>
                Отменить
              </Button>
              <Button color='danger' onPress={deleteAction}>
                Удалить
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
