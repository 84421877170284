import { zodResolver } from '@hookform/resolvers/zod'
import { PerformanceInterface } from '@hydra/interfaces'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { monthsConst } from '../../../features/constants/months.const'
import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import { createPerformance, updatePerformance } from '../api/groups.api'
import { createPerformanceSchema } from '../schemas/createPerformance.schema'

interface Props {
  isOpen: boolean
  onOpenChange: () => void
  onClose: () => void
  defaultValues?: PerformanceInterface
  groupId?: string
  refetch?: () => void
}

export const CreateOrUpdatePerformanceModal = ({
  groupId,
  isOpen,
  onClose,
  onOpenChange,
  refetch,
  defaultValues,
}: Props) => {
  const [isFormLoading, setIsFormLoading] = useState(false)
  const form = useForm<z.infer<typeof createPerformanceSchema>>({
    defaultValues: {
      month: defaultValues?.month ? String(defaultValues.month) : '',
      year: defaultValues?.year ? String(defaultValues.year) : '',
      occasionsCount: defaultValues?.occasionsCount
        ? String(defaultValues.occasionsCount)
        : '',
      occasionsCountForSocialNetwork:
        defaultValues?.occasionsCountForSocialNetwork
          ? String(defaultValues.occasionsCountForSocialNetwork)
          : '',
      themeHit: defaultValues?.themeHit ? String(defaultValues.themeHit) : '',
      themeHitForSocialNetwork: defaultValues?.themeHitForSocialNetwork
        ? String(defaultValues.themeHitForSocialNetwork)
        : '',
      qualityRelease: defaultValues?.qualityRelease
        ? String(defaultValues.qualityRelease)
        : '',
      producing: defaultValues?.producing
        ? String(defaultValues.producing)
        : '',
      graphic: defaultValues?.graphic ? String(defaultValues.graphic) : '',
    },
    resolver: zodResolver(createPerformanceSchema),
  })

  const onSubmit = useCallback(
    async (data: z.infer<typeof createPerformanceSchema>) => {
      if (defaultValues) {
        try {
          setIsFormLoading(true)
          const response = await updatePerformance(defaultValues.id, {
            month: parseInt(data.month, 10),
            year: parseInt(data.year, 10),
            occasionsCount: parseInt(data.occasionsCount, 10),
            occasionsCountForSocialNetwork: 0,
            themeHit: 0,
            themeHitForSocialNetwork: 0,
            qualityRelease: 0,
            producing: 0,
            graphic: 0,
            // occasionsCountForSocialNetwork: parseInt(
            //   data.occasionsCountForSocialNetwork,
            //   10,
            // ),
            // themeHit: parseInt(data.themeHit, 10),
            // themeHitForSocialNetwork: parseInt(
            //   data.themeHitForSocialNetwork,
            //   10,
            // ),
            // qualityRelease: parseInt(data.qualityRelease, 10),
            // producing: parseInt(data.producing, 10),
            // graphic: parseInt(data.graphic, 10),
          })

          if (response.status && response.status === 200) {
            toast.success('КПЭ успешно обновлен')
            form.reset()
            onClose()
            if (refetch) {
              refetch()
            }
          }
        } catch (e) {
          console.error(e)
        } finally {
          setIsFormLoading(false)
        }
      } else {
        try {
          setIsFormLoading(true)
          const response = await createPerformance({
            month: parseInt(data.month, 10),
            year: parseInt(data.year, 10),
            occasionsCount: parseInt(data.occasionsCount, 10),
            occasionsCountForSocialNetwork: 0,
            themeHit: 0,
            themeHitForSocialNetwork: 0,
            qualityRelease: 0,
            producing: 0,
            graphic: 0,
            // occasionsCountForSocialNetwork: parseInt(
            //   data.occasionsCountForSocialNetwork,
            //   10,
            // ),
            // themeHit: parseInt(data.themeHit, 10),

            // themeHitForSocialNetwork: parseInt(
            //   data.themeHitForSocialNetwork,
            //   10,
            // ),
            // qualityRelease: parseInt(data.qualityRelease, 10),
            // producing: parseInt(data.producing, 10),
            // graphic: parseInt(data.graphic, 10),
            group: { id: groupId || '' },
          })

          if (response.status && response.status === 201) {
            toast.success('КПЭ успешно созадн')
            form.reset()
            onClose()
            if (refetch) {
              refetch()
            }
          }
        } catch (e) {
          console.error(e)
        } finally {
          setIsFormLoading(false)
        }
      }
    },
    [defaultValues, form, groupId, onClose, refetch],
  )

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              Добавление КПЭ
            </ModalHeader>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <ModalBody>
                <div className='space-y-4'>
                  <div className='font-bold'>Период</div>
                  <div className='flex space-x-4'>
                    <Controller
                      control={form.control}
                      name='month'
                      render={({ field: { onChange, value } }) => (
                        <Select
                          isDisabled={isFormLoading}
                          selectedKeys={[value]}
                          isLoading={isFormLoading}
                          items={monthsConst}
                          aria-label='Месяц'
                          placeholder='Месяц'
                          variant='bordered'
                          className='flex-1'
                          onChange={event => {
                            onChange(event.target.value)
                          }}
                          isInvalid={!!form.formState.errors.month}
                          errorMessage={form.formState.errors.month?.message}
                        >
                          {item => (
                            <SelectItem key={item.id}>{item.title}</SelectItem>
                          )}
                        </Select>
                      )}
                    />
                    <Controller
                      control={form.control}
                      name='year'
                      render={({ field: { onChange, value } }) => (
                        <Select
                          isDisabled={isFormLoading}
                          selectedKeys={[value]}
                          isLoading={isFormLoading}
                          items={[
                            {
                              id: extendedDayjs().year(),
                              title: String(extendedDayjs().year()),
                            },
                            {
                              id: extendedDayjs().year() + 1,
                              title: String(extendedDayjs().year() + 1),
                            },
                          ]}
                          aria-label='Год'
                          placeholder='Год'
                          variant='bordered'
                          className='flex-1'
                          onChange={event => {
                            onChange(event.target.value)
                          }}
                          isInvalid={!!form.formState.errors.year}
                          errorMessage={form.formState.errors.year?.message}
                        >
                          {item => (
                            <SelectItem key={item.id}>{item.title}</SelectItem>
                          )}
                        </Select>
                      )}
                    />
                  </div>
                  <div className='font-bold'>Значение КПЭ</div>
                  <Controller
                    control={form.control}
                    name='occasionsCount'
                    render={({ field: { onChange, value } }) => (
                      <Input
                        type='number'
                        value={value}
                        isDisabled={isFormLoading}
                        variant='bordered'
                        label='Количество поводов'
                        labelPlacement='inside'
                        color='primary'
                        onValueChange={onChange}
                        isInvalid={!!form.formState.errors.occasionsCount}
                        errorMessage={
                          form.formState.errors.occasionsCount?.message
                        }
                      />
                    )}
                  />
                  {/*<Controller*/}
                  {/*  control={form.control}*/}
                  {/*  name='occasionsCountForSocialNetwork'*/}
                  {/*  render={({ field: { onChange, value } }) => (*/}
                  {/*    <Input*/}
                  {/*      type='number'*/}
                  {/*      value={value}*/}
                  {/*      isDisabled={isFormLoading}*/}
                  {/*      variant='bordered'*/}
                  {/*      label='Количество поводов для соц.сетей'*/}
                  {/*labelPlacement='inside*/}
                  {/*      color='primary'*/}
                  {/*      onValueChange={onChange}*/}
                  {/*      isInvalid={!!form.formState.errors.occasionsCount}*/}
                  {/*      errorMessage={*/}
                  {/*        form.formState.errors.occasionsCount?.message*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                  {/*<Controller*/}
                  {/*  control={form.control}*/}
                  {/*  name='themeHit'*/}
                  {/*  render={({ field: { onChange, value } }) => (*/}
                  {/*    <Input*/}
                  {/*      type='number'*/}
                  {/*      value={value}*/}
                  {/*      isDisabled={isFormLoading}*/}
                  {/*      variant='bordered'*/}
                  {/*      label='Попадание в темник'*/}
                  {/*      labelPlacement='inside'*/}
                  {/*      color='primary'*/}
                  {/*      onValueChange={onChange}*/}
                  {/*      isInvalid={!!form.formState.errors.occasionsCount}*/}
                  {/*      errorMessage={*/}
                  {/*        form.formState.errors.occasionsCount?.message*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                  {/*<Controller*/}
                  {/*  control={form.control}*/}
                  {/*  name='themeHitForSocialNetwork'*/}
                  {/*  render={({ field: { onChange, value } }) => (*/}
                  {/*    <Input*/}
                  {/*      type='number'*/}
                  {/*      value={value}*/}
                  {/*      isDisabled={isFormLoading}*/}
                  {/*      variant='bordered'*/}
                  {/*      label='Попадание в темник для соц.сетей'*/}
                  {/*labelPlacement='inside*/}
                  {/*      color='primary'*/}
                  {/*      onValueChange={onChange}*/}
                  {/*      isInvalid={!!form.formState.errors.occasionsCount}*/}
                  {/*      errorMessage={*/}
                  {/*        form.formState.errors.occasionsCount?.message*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                  {/*<Controller*/}
                  {/*  control={form.control}*/}
                  {/*  name='qualityRelease'*/}
                  {/*  render={({ field: { onChange, value } }) => (*/}
                  {/*    <Input*/}
                  {/*      type='number'*/}
                  {/*      value={value}*/}
                  {/*      isDisabled={isFormLoading}*/}
                  {/*      variant='bordered'*/}
                  {/*      label='Пресс-релиз'*/}
                  {/*      labelPlacement='inside'*/}
                  {/*      color='primary'*/}
                  {/*      onValueChange={onChange}*/}
                  {/*      isInvalid={!!form.formState.errors.occasionsCount}*/}
                  {/*      errorMessage={*/}
                  {/*        form.formState.errors.occasionsCount?.message*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                  {/*<Controller*/}
                  {/*  control={form.control}*/}
                  {/*  name='producing'*/}
                  {/*  render={({ field: { onChange, value } }) => (*/}
                  {/*    <Input*/}
                  {/*      type='number'*/}
                  {/*      value={value}*/}
                  {/*      isDisabled={isFormLoading}*/}
                  {/*      variant='bordered'*/}
                  {/*      label='Продюсирование'*/}
                  {/*      labelPlacement='inside'*/}
                  {/*      color='primary'*/}
                  {/*      onValueChange={onChange}*/}
                  {/*      isInvalid={!!form.formState.errors.occasionsCount}*/}
                  {/*      errorMessage={*/}
                  {/*        form.formState.errors.occasionsCount?.message*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                  {/*<Controller*/}
                  {/*  control={form.control}*/}
                  {/*  name='graphic'*/}
                  {/*  render={({ field: { onChange, value } }) => (*/}
                  {/*    <Input*/}
                  {/*      type='number'*/}
                  {/*      value={value}*/}
                  {/*      isDisabled={isFormLoading}*/}
                  {/*      variant='bordered'*/}
                  {/*      label='Выходы на федеральном уровне'*/}
                  {/*      labelPlacement='inside'*/}
                  {/*      color='primary'*/}
                  {/*      onValueChange={onChange}*/}
                  {/*      isInvalid={!!form.formState.errors.occasionsCount}*/}
                  {/*      errorMessage={*/}
                  {/*        form.formState.errors.occasionsCount?.message*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type='button'
                  color='danger'
                  variant='light'
                  onPress={onClose}
                >
                  Закрыть
                </Button>
                <Button type='submit' color='primary'>
                  Сохранить
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
