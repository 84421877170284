import { Grant } from '@hydra/interfaces'
import { TbLayoutDashboard, TbSocial } from 'react-icons/tb'
import { VscGroupByRefType } from 'react-icons/vsc'

import { routesConst } from '../../../features/constants/routes.const'

export const menuItems = [
  {
    title: 'Паблики',
    link: routesConst.publics,
    icon: <TbSocial />,
    grant: [Grant.publics],
  },
  {
    title: 'Группы',
    link: routesConst.publicGroups,
    icon: <VscGroupByRefType />,
    grant: [Grant.publics],
  },

  {
    title: 'Дашборд',
    link: routesConst.publicsDashboard,
    icon: <TbLayoutDashboard />,
    grant: [Grant.publics],
  },
]
