import { useAtom } from 'jotai/index'
import { useMemo } from 'react'

import { Pagination } from '../../../features/ui'
import {
  skipAtom,
  takeAtom,
  tasksAtom,
  tasksByCollectionsAtom,
  tasksByExecutorsAtom,
  tasksViewAtom,
} from '../store/tasks.store'

export const TasksPagination = () => {
  const [{ data: tasks }] = useAtom(tasksAtom)
  const [{ data: tasksByExecutors }] = useAtom(tasksByExecutorsAtom)
  const [{ data: tasksByCollections }] = useAtom(tasksByCollectionsAtom)
  const [tasksView] = useAtom(tasksViewAtom)
  const [skip, setSkip] = useAtom(skipAtom)
  const [take] = useAtom(takeAtom)
  const count = useMemo(() => {
    if (tasksView === 'table') {
      return tasks ? tasks[1] : 0
    }

    if (tasksView === 'byExecutor') {
      return tasksByExecutors ? tasksByExecutors[1] : 0
    }

    if (tasksView === 'byCollection') {
      return tasksByCollections ? tasksByCollections[1] : 0
    }

    return 0
  }, [tasks, tasksByCollections, tasksByExecutors, tasksView])

  return <Pagination count={count} take={take} skip={skip} setSkip={setSkip} />
}
