import { z } from 'zod'

export const createCollectionSchema = z.object({
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, {
      message: 'Минимальная длина 2 символов',
    })
    .max(1000, {
      message: 'Максимальная длина 1000 символов',
    })
    .trim(),
  responsible: z.string().trim().optional(),
})
