import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'

import { getFolderById, getFolders } from '../api/folders.api'

export const folderTabAtom = atom<'personal' | 'public'>('public')
export const folderIdAtom = atom<string | undefined>(undefined)

export const foldersAtom = atomWithQuery(get => {
  return {
    queryKey: ['folders-list', get(folderTabAtom)],
    queryFn: async () => {
      const response = await getFolders({
        isPublic: get(folderTabAtom) === 'public',
      })
      return response.data
    },
  }
})

export const folderAtom = atomWithQuery(get => {
  return {
    enabled: !!get(folderIdAtom),
    queryKey: ['folder-by-id', get(folderIdAtom)],
    queryFn: async () => {
      const response = await getFolderById(get(folderIdAtom) || '')
      return response.data
    },
  }
})
