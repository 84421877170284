import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import { Dispatch, SetStateAction, useState } from 'react'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  defaultValue: string
  onSaveAction: (newName: string) => void
  onClose: () => void
}

export const RenameFileModal = ({
  isOpen,
  setIsOpen,
  defaultValue,
  onSaveAction,
  onClose,
}: Props) => {
  const [value, setValue] = useState('')

  return (
    <Modal isOpen={isOpen} onOpenChange={setIsOpen} isDismissable={false}>
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              Переименовать файл
            </ModalHeader>
            <ModalBody>
              <div className='text-small'>
                Старое имя файла:{' '}
                <span className='font-bold'>{defaultValue}</span>
              </div>
              <Input
                value={value}
                onValueChange={setValue}
                variant='bordered'
                label='Новое имя файла'
                labelPlacement='outside'
              />
            </ModalBody>
            <ModalFooter>
              <Button color='danger' variant='light' onPress={onClose}>
                Отменить
              </Button>
              <Button
                color='primary'
                onPress={() => {
                  onSaveAction(value)
                  onClose()
                }}
              >
                Сохранить
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
