import { GroupInterface } from '@hydra/interfaces'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { PageSpinner } from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { directionAtom, groupsAtom, orderAtom } from '../store/groups.store'

const columns = [
  {
    key: 'title',
    label: 'Название группы',
    isSorting: true,
  },
  {
    key: 'createdAt',
    label: 'Дата созадния',
    isSorting: true,
  },
  {
    key: 'organizations',
    label: 'Количество организаций',
    isSorting: false,
  },
]

export const GroupsList = () => {
  const navigate = useNavigate()
  const [{ data, isPending }] = useAtom(groupsAtom)
  const [order, setOrder] = useAtom(orderAtom)
  const [direction, setDirection] = useAtom(directionAtom)

  const renderCell = useCallback((group: GroupInterface, columnKey: Key) => {
    switch (columnKey) {
      case 'createdAt':
        return getPrettyDate({
          date: group.createdAt,
        })
      case 'title':
        return group.title
      case 'organizations':
        return group.organizations.length
    }
  }, [])

  if (isPending) return <PageSpinner />

  return (
    <div className='bg-background w-full rounded-2xl'>
      <Table
        sortDescriptor={{
          column: order,
          direction: direction === 'asc' ? 'ascending' : 'descending',
        }}
        onSortChange={({ column, direction }) => {
          setOrder(column as keyof GroupInterface)
          setDirection(direction === 'ascending' ? 'asc' : 'desc')
        }}
        aria-label='Список групп'
        isHeaderSticky
        isStriped
        removeWrapper
        classNames={{
          base: 'h-[calc(100vh-220px)] overflow-auto',
        }}
        onRowAction={key => {
          navigate(routesConst.groups + '/' + key)
        }}
      >
        <TableHeader columns={columns}>
          {column => (
            <TableColumn key={column.key} allowsSorting={column.isSorting}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={data ? data[0] : []}
          emptyContent='Нет данных'
          isLoading={isPending}
        >
          {item => (
            <TableRow key={item.id}>
              {columnKey => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
