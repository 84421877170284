import { Grant } from '@hydra/interfaces'
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Selection,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { allUsersAtom } from '../../../features/store'
import { updateTask } from '../api/tasks.api'

interface Props {
  id: string
  participants: Array<string> | null
  refetch: () => void
}

export const ParticipantsChip = ({ id, participants, refetch }: Props) => {
  const [{ data: users, isPending }] = useAtom(allUsersAtom)

  const handleChangeUsers = useCallback(
    async (values: Selection) => {
      if (values !== 'all') {
        await updateTask(id, {
          participants: Array.from(values) as Array<string>,
        })
      }
      refetch()
    },
    [id, refetch],
  )

  return (
    <Dropdown
      backdrop='blur'
      placement='bottom-end'
      showArrow
      isDisabled={isPending}
    >
      <DropdownTrigger>
        {participants && users && participants.length > 0 ? (
          <div className='flex flex-wrap gap-2'>
            {participants.map(p => {
              const user = users[0].find(u => u.id === p)
              return (
                <Chip
                  as='span'
                  color='default'
                  variant='bordered'
                  size='sm'
                  className='cursor-pointer'
                  endContent={<TbChevronDown className='text-xl' />}
                >
                  {user?.name} {user?.surname}
                </Chip>
              )
            })}
          </div>
        ) : (
          <Chip
            as='span'
            color='default'
            variant='bordered'
            size='sm'
            className='cursor-pointer'
            endContent={<TbChevronDown className='text-xl' />}
          >
            Не выбраны
          </Chip>
        )}
      </DropdownTrigger>
      <DropdownMenu
        closeOnSelect={false}
        selectionMode='multiple'
        variant='faded'
        aria-label={`Участники задачи ${id}`}
        selectedKeys={participants || []}
        onSelectionChange={keys => handleChangeUsers(keys)}
        items={
          users
            ? users[0].filter(user => user.grants.includes(Grant.tasks))
            : []
        }
        className='max-h-[300px] overflow-auto'
      >
        {user => (
          <DropdownItem key={user.id}>
            {user.name} {user.surname}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}
