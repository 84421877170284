import { Accordion, AccordionItem, Link } from '@nextui-org/react'
import { useAtom } from 'jotai/index'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { tasksByExecutorsAtom } from '../store/tasks.store'
import { TasksTable } from './TasksTable'

export const TasksByExecutors = () => {
  const [{ data, isPending, refetch }] = useAtom(tasksByExecutorsAtom)

  if (isPending) return <PageSpinner />

  if (!data || data[0].length === 0) return <Empty />

  return (
    <div className='h-[calc(100vh-220px)] overflow-auto'>
      <Accordion
        variant='shadow'
        selectionMode='multiple'
        className='bg-background shadow'
        defaultExpandedKeys={data[0].map(user => user.id)}
      >
        {data[0].map(user => (
          <AccordionItem
            textValue={user.name + ' ' + user.surname}
            key={user.id}
            title={
              <Link
                className='bg-primary-100 rounded-2xl'
                href={routesConst.users + '/' + user.id}
                size='sm'
                isBlock
                showAnchorIcon
              >
                {user.name} {user.surname}
              </Link>
            }
            className='bg-background'
          >
            <TasksTable
              items={user.executableTasks}
              refetch={refetch}
              withCollection
            />
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
