import { ArticleInterface } from '@hydra/interfaces'
import {
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { articlesAtom } from '../store/articles.store'
import { tagsAtom } from '../store/tags.store'

const columns = [
  {
    key: 'title',
    label: 'Статья',
  },
  {
    key: 'createdAt',
    label: 'Создана',
  },
  {
    key: 'tags',
    label: 'Тэги',
  },
  {
    key: 'author',
    label: 'Автор',
  },
  {
    key: 'folder',
    label: 'Папка',
    width: 300,
  },
]

export const ArticlesList = () => {
  const navigate = useNavigate()
  const [{ data, isPending }] = useAtom(articlesAtom)
  const [{ data: tags }] = useAtom(tagsAtom)

  const renderCell = useCallback(
    (item: ArticleInterface, columnKey: Key) => {
      if (columnKey === 'title') {
        return <div className='line-clamp-3'>{item.title}</div>
      }
      if (columnKey === 'createdAt') {
        return (
          <div>
            {getPrettyDate({
              date: item.createdAt,
            })}
          </div>
        )
      }
      if (columnKey === 'tags') {
        return item.tags && item.tags.length > 0 ? (
          <div className='flex flex-wrap gap-1'>
            {item.tags.map(tag => (
              <Chip key={tag} size='sm' color='secondary' variant='faded'>
                {tags?.find(t => t.id === tag)?.title}
              </Chip>
            ))}
          </div>
        ) : null
      }
      if (columnKey === 'author') {
        return (
          <Link
            size='sm'
            isBlock
            href={routesConst.users + '/' + item.author.id}
          >
            {item.author.name} {item.author.surname}
          </Link>
        )
      }
      if (columnKey === 'folder') {
        return item.folder ? (
          <Link
            className='line-clamp-1'
            size='sm'
            isBlock
            href={routesConst.folders + '/' + item.folder.id}
          >
            {item.folder.title}
          </Link>
        ) : (
          <div />
        )
      }
    },
    [tags],
  )

  if (isPending) return <PageSpinner />

  if (!data || data[0].length === 0) return <Empty />

  return (
    <Table
      aria-label='Задачи'
      isHeaderSticky
      isStriped
      removeWrapper
      onRowAction={key => navigate(routesConst.articles + '/' + key)}
      classNames={{
        base: 'h-[calc(100vh-156px)] overflow-auto',
      }}
    >
      <TableHeader columns={columns}>
        {column => (
          <TableColumn width={column.width} key={column.key}>
            {column.label}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        items={data[0]}
        emptyContent={
          <div className='text-default-400 text-sm'>Нет данных</div>
        }
      >
        {item => (
          <TableRow key={item.id}>
            {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
