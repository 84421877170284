import { RiskStatusEnum } from '@hydra/interfaces'
import { Chip, Tab, Tabs } from '@nextui-org/react'
import { useAtomValue } from 'jotai'
import { useAtom } from 'jotai/index'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { Empty, PageSpinner } from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { statusConst } from '../constants/status.const'
import { risksAtom, statusTabAtom } from '../store/risk.store'

export const RisksList = () => {
  const navigate = useNavigate()
  const { data, isPending } = useAtomValue(risksAtom)
  const [tab, setTab] = useAtom(statusTabAtom)

  return (
    <div className='space-y-4'>
      <div>
        <Tabs
          size='sm'
          aria-label='Статусы'
          selectedKey={tab}
          onSelectionChange={event => setTab(event as RiskStatusEnum)}
          classNames={{
            tabList: 'gap-0',
            tab: 'px-0 m-0 ',
          }}
        >
          <Tab
            key='draft'
            title={
              <Chip variant='light' color={statusConst.draft.color}>
                <div className='flex space-x-1'>
                  <span>{statusConst.draft.title}</span>
                </div>
              </Chip>
            }
          />
          <Tab
            key='agreement'
            title={
              <Chip variant='light' color={statusConst.agreement.color}>
                <div className='flex space-x-1'>
                  <span>{statusConst.agreement.title}</span>
                </div>
              </Chip>
            }
          />
          <Tab
            key='current'
            title={
              <Chip variant='light' color={statusConst.current.color}>
                <div className='flex space-x-1'>
                  <span>{statusConst.current.title}</span>
                </div>
              </Chip>
            }
          />
          <Tab
            key='archive'
            title={
              <Chip variant='light' color={statusConst.archive.color}>
                <div className='flex space-x-1'>
                  <span>{statusConst.archive.title}</span>
                </div>
              </Chip>
            }
          />
        </Tabs>
      </div>
      {isPending ? (
        <PageSpinner />
      ) : !data || data[0].length <= 0 ? (
        <Empty />
      ) : (
        <SimpleBar className='h-[calc(100vh-208px)] overflow-auto'>
          <div className='space-y-4'>
            {data[0].map(risk => (
              <div
                key={risk.id}
                className='bg-background cursor-pointer space-y-3 rounded-xl p-4 shadow transition-shadow hover:shadow-lg'
                onClick={() => navigate(`/risks/${risk.id}`)}
              >
                <div>
                  <Chip color='secondary' variant='flat'>
                    {getPrettyDate({
                      date: risk.createdAt,
                      template: 'DD MMMM YYYY',
                    })}
                  </Chip>
                </div>
                <div className='space-y-2'>
                  <div className='font-semibold'>{risk.title}</div>
                  <div className='flex flex-wrap gap-2'>
                    {risk.author && (
                      <div>
                        <Chip color='primary' variant='faded'>
                          {risk.author?.organization.name}
                        </Chip>
                      </div>
                    )}
                    {risk.theme && (
                      <div>
                        <Chip color='primary' variant='faded'>
                          Тема: {risk.theme?.title}
                        </Chip>
                      </div>
                    )}
                    {risk.type && (
                      <div>
                        <Chip color='primary' variant='faded'>
                          Тип: {risk.type?.title}
                        </Chip>
                      </div>
                    )}
                    {risk.importance && (
                      <div>
                        <Chip color='primary' variant='faded'>
                          Важность: {risk.importance?.title}
                        </Chip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </SimpleBar>
      )}
    </div>
  )
}
