import { Autocomplete, AutocompleteItem } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { Key, useCallback, useMemo } from 'react'

import { allThemesAtom, themeIdAtom } from '../store/newsbreaks.store'

export const ThemeFilter = () => {
  const [{ data }] = useAtom(allThemesAtom)
  const [id, setIdId] = useAtom(themeIdAtom)

  const onSelectionChange = useCallback(
    (id: Key | null) => {
      setIdId(id ? String(id) : undefined)
    },
    [setIdId],
  )

  const item = useMemo(
    () =>
      id ? (data ? data[0].find(i => i.id === id) : undefined) : undefined,
    [id, data],
  )

  const itemValue = useMemo(() => (item ? `${item.title}` : undefined), [item])

  return (
    <div className='space-y-1'>
      <div className='flex w-full items-center justify-between'>
        <label
          className='text-small text-default-400'
          htmlFor='newsbreaks-theme-filter'
        >
          Тема
        </label>
      </div>
      <Autocomplete
        aria-label='тема'
        variant='bordered'
        defaultItems={data ? data[0] : []}
        placeholder='Выберите тему'
        defaultInputValue={itemValue}
        onSelectionChange={onSelectionChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onKeyDown={e => e.continuePropagation()}
      >
        {item => (
          <AutocompleteItem
            key={item.id}
            value={item.id}
            textValue={item.title}
          >
            {item.title}
          </AutocompleteItem>
        )}
      </Autocomplete>
    </div>
  )
}
