import { Button, Input } from '@nextui-org/react'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { toast } from 'react-toastify'

import {
  endOfUpdatedDayAtom,
  startOfUpdatedDayAtom,
} from '../store/newsbreaks.store'

export const UpdatedDateFilter = () => {
  const [startOfUpdatedDay, setStartOfUpdatedDay] = useAtom(
    startOfUpdatedDayAtom,
  )
  const [endOfUpdatedDay, setEndOfUpdatedDay] = useAtom(endOfUpdatedDayAtom)

  return (
    <div className='space-y-1'>
      <div className='flex w-full items-center'>
        <label
          className='text-small text-default-400 flex-1'
          htmlFor='newsbreaks-date-filter-start'
        >
          Период обновления
        </label>
        <Button
          variant='light'
          size='sm'
          color='primary'
          onPress={() => {
            setStartOfUpdatedDay('')
            setEndOfUpdatedDay('')
          }}
        >
          Сбросить
        </Button>
      </div>
      <div className='flex flex-col space-y-2'>
        <Input
          aria-labelledby='Начало периода'
          id='newsbreaks-date-filter-start'
          type='date'
          variant='bordered'
          value={
            startOfUpdatedDay
              ? dayjs(startOfUpdatedDay).format('YYYY-MM-DD').toString()
              : ''
          }
          onValueChange={value => setStartOfUpdatedDay(dayjs(value))}
          classNames={{
            inputWrapper: 'h-10',
          }}
        />
        <Input
          aria-labelledby='Конец периода'
          id='newsbreaks-date-filter-end'
          type='date'
          variant='bordered'
          value={
            endOfUpdatedDay
              ? dayjs(endOfUpdatedDay).format('YYYY-MM-DD').toString()
              : ''
          }
          onValueChange={value => {
            if (startOfUpdatedDay > dayjs(value)) {
              toast.error('Конец периода должен быть после его начала')
            } else {
              setEndOfUpdatedDay(dayjs(value))
            }
          }}
          classNames={{
            inputWrapper: 'h-10',
          }}
        />
      </div>
    </div>
  )
}
