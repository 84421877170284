import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'

import {
  getAllOrganizations,
  getAllOrganizationsWithoutGroup,
  getAvailableRatingPeriods,
  getMissedNewsbreaks,
  getOrganizationById,
  getOrgRating,
  getOrgsRating,
  getRatingColorful,
} from '../api'
import { extendedDayjs } from '../utils/dayjsConfig'

export const organizationIdAtom = atom<string | undefined>(undefined)

export const ratingPeriodAtom = atom<'month' | 'quarter' | 'halfYear' | 'year'>(
  'month',
)
export const ratingYearAtom = atom<string>(String(extendedDayjs().year()))

export const ratingHalfYearAtom = atom<string>(
  String(extendedDayjs().month() - 1 > 5 ? 2 : 1),
)

export const ratingQuarterAtom = atom<string>(
  String(
    extendedDayjs()
      .month(extendedDayjs().month() - 1)
      .quarter(),
  ),
)

export const ratingMonthAtom = atom<string>(
  String(extendedDayjs().month() === 0 ? 11 : extendedDayjs().month() - 1),
)

export const organizationTypeAtom = atom<string | undefined>(undefined)

export const organizationByIdAtom = atomWithQuery(get => {
  return {
    enabled: !!get(organizationIdAtom),
    queryKey: ['organization', !get(organizationIdAtom)],
    queryFn: async () => {
      const response = await getOrganizationById(get(organizationIdAtom) || '')
      return response.data
    },
  }
})

export const allOrganizationsAtom = atomWithQuery(() => {
  return {
    queryKey: ['organizations'],
    queryFn: async () => {
      const response = await getAllOrganizations()
      return response.data
    },
  }
})

export const allOrganizationsWithoutGroupAtom = atomWithQuery(() => {
  return {
    queryKey: ['organizations-without-group'],
    queryFn: async () => {
      const response = await getAllOrganizationsWithoutGroup()
      return response.data
    },
  }
})

export const availableRatingPeriodsAtom = atomWithQuery(get => {
  return {
    queryKey: ['available-rating-periods', get(organizationIdAtom)],
    queryFn: async () => {
      const response = await getAvailableRatingPeriods({
        organizationId: get(organizationIdAtom) || '',
      })
      return response.data
    },
  }
})

export const ratingColorfulAtom = atomWithQuery(get => {
  return {
    queryKey: ['rating-colorful'],
    queryFn: async () => {
      const response = await getRatingColorful()
      return response.data
    },
  }
})

export const organizationRatingAtom = atomWithQuery(get => {
  return {
    enabled: !!get(organizationIdAtom),
    queryKey: [
      'organization-rating',
      get(organizationIdAtom),
      get(ratingPeriodAtom),
      get(ratingYearAtom),
      get(ratingHalfYearAtom),
      get(ratingQuarterAtom),
      get(ratingMonthAtom),
    ],
    queryFn: async () => {
      const isMonth = get(ratingPeriodAtom) === 'month'
      const isQuarter = get(ratingPeriodAtom) === 'quarter'
      const isHalfYear = get(ratingPeriodAtom) === 'halfYear'
      const isYear = get(ratingPeriodAtom) === 'year'
      let months: Array<string> = []

      if (isMonth) {
        months = [...months, get(ratingMonthAtom)]
      }

      if (isHalfYear) {
        if (get(ratingHalfYearAtom) === '1') {
          months = [...months, ...['0', '1', '2', '3', '4', '5']]
        }
        if (get(ratingHalfYearAtom) === '2') {
          months = [...months, ...['6', '7', '8', '9', '10', '11']]
        }
      }

      if (isQuarter) {
        if (get(ratingQuarterAtom) === '1') {
          months = [...months, ...['0', '1', '2']]
        }

        if (get(ratingQuarterAtom) === '2') {
          months = [...months, ...['3', '4', '5']]
        }
        if (get(ratingQuarterAtom) === '3') {
          months = [...months, ...['6', '7', '8']]
        }

        if (get(ratingQuarterAtom) === '4') {
          months = [...months, ...['9', '10', '11']]
        }
      }

      if (isYear) {
        months = [
          ...months,
          ...Array.from({ length: 12 }, (_, i) => i.toString()),
        ]
      }

      const response = await getOrgRating({
        organizationId: get(organizationIdAtom) || '',
        year: get(ratingYearAtom),
        months,
      })
      return response.data
    },
  }
})

export const organizationsRatingAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'organizations-rating',
      get(ratingPeriodAtom),
      get(ratingYearAtom),
      get(ratingHalfYearAtom),
      get(ratingQuarterAtom),
      get(ratingMonthAtom),
      get(organizationTypeAtom),
    ],
    queryFn: async () => {
      const isMonth = get(ratingPeriodAtom) === 'month'
      const isQuarter = get(ratingPeriodAtom) === 'quarter'
      const isHalfYear = get(ratingPeriodAtom) === 'halfYear'
      const isYear = get(ratingPeriodAtom) === 'year'
      let months: Array<string> = []

      if (isMonth) {
        months = [...months, get(ratingMonthAtom)]
      }

      if (isHalfYear) {
        if (get(ratingHalfYearAtom) === '1') {
          months = [...months, ...['0', '1', '2', '3', '4', '5']]
        }
        if (get(ratingHalfYearAtom) === '2') {
          months = [...months, ...['6', '7', '8', '9', '10', '11']]
        }
      }

      if (isQuarter) {
        if (get(ratingQuarterAtom) === '1') {
          months = [...months, ...['0', '1', '2']]
        }

        if (get(ratingQuarterAtom) === '2') {
          months = [...months, ...['3', '4', '5']]
        }
        if (get(ratingQuarterAtom) === '3') {
          months = [...months, ...['6', '7', '8']]
        }

        if (get(ratingQuarterAtom) === '4') {
          months = [...months, ...['9', '10', '11']]
        }
      }

      if (isYear) {
        months = [
          ...months,
          ...Array.from({ length: 12 }, (_, i) => i.toString()),
        ]
      }

      const response = await getOrgsRating({
        organizationType: get(organizationTypeAtom),
        year: get(ratingYearAtom),
        months,
      })
      return response.data
    },
  }
})

export const missedNewsbreaksAtom = atomWithQuery(get => {
  return {
    enabled: !!get(organizationIdAtom),
    queryKey: ['missed-newsbreaks', get(organizationIdAtom)],
    queryFn: async () => {
      const response = await getMissedNewsbreaks(get(organizationIdAtom) || '')
      return response.data
    },
  }
})
