import { CooperationEnum, LoyaltyEnum, PublicTypeEnum } from '@hydra/interfaces'
import { z } from 'zod'

export const createPublicSchema = z.object({
  link: z
    .string({
      required_error: 'Обязательное поле',
    })
    .url({
      message: 'Неккоретный адрес паблика',
    })
    .trim(),
  title: z.string().trim().optional(),
  location: z.string().trim().optional(),
  organization: z.string().trim().optional(),
  type: z.nativeEnum(PublicTypeEnum).optional(),
  loyalty: z.nativeEnum(LoyaltyEnum).optional(),
  cooperation: z.nativeEnum(CooperationEnum).optional(),
  cost: z.number().optional(),
  contactName: z.string().trim().optional(),
  contactPhone: z.string().trim().optional(),
  contactLink: z.string().trim().optional(),
  contactJobTitle: z.string().trim().optional(),
  group: z.string().trim().optional(),
  isCollectStatistic: z.boolean(),
})
