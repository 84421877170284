import { Grant } from '@hydra/interfaces'
import { Chip } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants } from '../../../features/grants'
import { Calendar, Header, Layout } from '../../../features/ui'
import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import { menuItems } from '../constants/menuItems'
import { statusConst } from '../constants/status.const'
import {
  calendarDayAtom,
  calendarMonthAtom,
  calendarViewAtom,
  calendarWeekAtom,
  calendarYearAtom,
  risksByMonthAtom,
  risksByWeekAtom,
} from '../store/risk.store'

export const RisksCalendar = () => {
  const [year, setYear] = useAtom(calendarYearAtom)
  const [month, setMonth] = useAtom(calendarMonthAtom)
  const [week, setWeek] = useAtom(calendarWeekAtom)
  const [day, setDay] = useAtom(calendarDayAtom)
  const [view, setView] = useAtom(calendarViewAtom)
  const [{ data: monthItems, isPending: isPendigMonthItems }] =
    useAtom(risksByMonthAtom)
  const [{ data: weekItems, isPending: isPendingWeekItems }] =
    useAtom(risksByWeekAtom)

  const onPrevWeekButtonPress = useCallback(() => {
    if (week === 1) {
      setWeek(52)
      setMonth(11)
      setYear(year - 1)
    } else {
      setWeek(week - 1)
      setMonth(
        extendedDayjs()
          .year(year)
          .week(week - 1)
          .month(),
      )
    }
  }, [setMonth, setWeek, setYear, week, year])

  const onNextWeekButtonPress = useCallback(() => {
    if (week === 52) {
      setWeek(1)
      setMonth(0)
      setYear(year + 1)
    } else {
      setWeek(week + 1)
      setMonth(
        extendedDayjs()
          .year(year)
          .week(week + 1)
          .month(),
      )
    }
  }, [setMonth, setWeek, setYear, week, year])

  const onPrevMonthButtonPress = useCallback(() => {
    setDay(1)
    if (month === 0) {
      setMonth(11)
      setYear(year - 1)
      setWeek(52)
    } else {
      setWeek(
        extendedDayjs()
          .month(month - 1)
          .date(1)
          .week(),
      )
      setMonth(month - 1)
    }
  }, [month, setDay, setMonth, setWeek, setYear, year])

  const onNextMonthButtonPress = useCallback(() => {
    setDay(1)
    if (month === 11) {
      setMonth(0)
      setYear(year + 1)
      setWeek(1)
    } else {
      setWeek(
        extendedDayjs()
          .month(month + 1)
          .date(1)
          .week(),
      )
      setMonth(month + 1)
    }
  }, [month, setDay, setMonth, setWeek, setYear, year])

  const onSmallCalendarDayButtonClick = useCallback(
    (day: number) => {
      setDay(day)
      setWeek(extendedDayjs().year(year).month(month).date(day).week())
    },
    [month, setDay, setWeek, year],
  )

  const onTodayButtonPress = useCallback(() => {
    setYear(extendedDayjs().year())
    setMonth(extendedDayjs().month())
    setWeek(extendedDayjs().week())
    setDay(extendedDayjs().date())
  }, [setDay, setMonth, setWeek, setYear])

  return (
    <>
      <Helmet>
        <title>Гидра | События</title>
      </Helmet>
      <Grants grants={[Grant.publics]}>
        <Layout headerContent={<Header text='События' />} menuItems={menuItems}>
          <Calendar
            isLoading={
              view === 'month' ? isPendigMonthItems : isPendingWeekItems
            }
            calendarView={view}
            setCalendarView={setView}
            year={year}
            month={month}
            week={week}
            day={day}
            onSmallCalendarDayButtonClick={onSmallCalendarDayButtonClick}
            onPrevMonthButtonPress={onPrevMonthButtonPress}
            onNextMonthButtonPress={onNextMonthButtonPress}
            onTodayButtonPress={onTodayButtonPress}
            onPrevWeekButtonPress={onPrevWeekButtonPress}
            onNextWeekButtonPress={onNextWeekButtonPress}
            monthItems={monthItems?.map(item => ({
              id: item.id,
              title: item.title,
              link: routesConst.risks + '/' + item.id,
              deadline: item.date || null,
            }))}
            weekItems={weekItems?.map(item => ({
              id: item.id,
              title: item.title,
              link: routesConst.risks + '/' + item.id,
              deadline: item.date || null,
              status: (
                <Chip
                  variant='bordered'
                  color={statusConst[item.status].color}
                  size='sm'
                >
                  {statusConst[item.status].title}
                </Chip>
              ),
            }))}
          />
        </Layout>
      </Grants>
    </>
  )
}
