import { Grant } from '@hydra/interfaces'
import {
  TbBuildingBank,
  TbLockOpen,
  TbSettings,
  TbUsersGroup,
} from 'react-icons/tb'

import { routesConst } from '../../../features/constants/routes.const'

export const menuItems = [
  {
    title: 'Доступы',
    link: routesConst.permissions,
    icon: <TbLockOpen />,
    grant: Grant.superadmin,
  },
  {
    title: 'Пользователи',
    link: routesConst.users,
    icon: <TbUsersGroup />,
    grant: Grant.newsbreaksModerator,
  },
  {
    title: 'Организации',
    link: routesConst.organizations,
    icon: <TbBuildingBank />,
    grant: Grant.newsbreaksModerator,
  },
  {
    title: 'Настройки',
    link: routesConst.settings,
    icon: <TbSettings />,
    grant: Grant.newsbreaksModerator,
  },
]
