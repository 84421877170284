import { Grant } from '@hydra/interfaces'
import { Image } from '@nextui-org/react'
import { clsx } from 'clsx'
import { useTheme } from 'next-themes'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGrant } from '../../../features/grants'

interface Props {
  title: string
  icon?: string
  description: string
  grants: Array<Grant> | undefined
  path: string
}

export const MainCard = ({ title, icon, description, grants, path }: Props) => {
  const navigate = useNavigate()
  const { isValidGrant } = useGrant(grants)
  const [isHover, setIsHover] = useState(false)
  const { theme } = useTheme()

  const handleIsFocus = useCallback(() => {
    setIsHover(true)
  }, [])

  const handleIsBlur = useCallback(() => {
    setIsHover(false)
  }, [])

  if (!isValidGrant) return null

  return (
    <div
      className='bg-background cursor-pointer rounded-xl shadow'
      onFocus={handleIsFocus}
      onBlur={handleIsBlur}
      onMouseEnter={handleIsFocus}
      onMouseLeave={handleIsBlur}
      onClick={() => navigate(path)}
    >
      <div
        className={clsx('relative flex justify-center rounded-t-xl', {
          'bg-default-100': theme === 'light',
          'bg-default-200': theme === 'dark',
        })}
      >
        {icon && (
          <>
            <Image
              disableSkeleton
              loading='eager'
              width={392}
              height={154}
              alt={title}
              src={`/main-icons/${icon}-${theme}.svg`}
              className={clsx(
                isHover ? 'opacity-1 hidden' : 'visible opacity-0',
              )}
            />
            <Image
              disableSkeleton
              width={392}
              height={154}
              alt={title}
              src={`/main-icons/${icon}-${theme}.gif`}
              className={clsx(
                isHover ? 'visible opacity-0' : 'opacity-1 hidden',
              )}
            />
          </>
        )}
      </div>
      <div className='space-y-1 px-6 py-4'>
        <div className='font-semibold uppercase'>{title}</div>
        <p className='text-default-600 text-sm'>{description}</p>
      </div>
    </div>
  )
}
