import { Grant } from '@hydra/interfaces'
import { Button } from '@nextui-org/react'
import { Helmet } from 'react-helmet'
import { TbSquareRoundedPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants } from '../../../features/grants'
import { Header, Layout } from '../../../features/ui'
import { ChatsList } from '../components/ChatsList'
import { ChatsPagination } from '../components/ChatsPagination'
import { menuItems } from '../constants/menuItems'

const pageTitle = 'Чаты'

export const Chats = () => {
  const navigate = useNavigate()

  return (
    <Grants grants={[Grant.publics]}>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <div>
                <Button
                  color='primary'
                  startContent={<TbSquareRoundedPlus className='text-2xl' />}
                  onPress={() => navigate(routesConst.createChat)}
                >
                  Создать чат
                </Button>
              </div>
            }
          />
        }
      >
        <div className='p-4'>
          <ChatsList />
          <ChatsPagination />
        </div>
      </Layout>
    </Grants>
  )
}
