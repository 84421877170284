import { LinkInterface } from '@hydra/interfaces'
import {
  getKeyValue,
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  Pie,
  PieChart,
  Rectangle,
  Tooltip as TooltipRecharts,
  XAxis,
  YAxis,
} from 'recharts'

import { colorsConst } from '../../../features/constants/colors.const'
import { linkFormatConst } from '../../../features/constants/linkFormat.const'
import { PageSpinner } from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { getPrettyNumber } from '../../../features/utils/getPrettyNumber'
import { newsbreaksAnalyticsAtom } from '../store/newsbreaks.store'

export const AnalyticsIndicators = () => {
  const [{ data, isPending }] = useAtom(newsbreaksAnalyticsAtom)

  const renderCell = useCallback((item: LinkInterface, columnKey: Key) => {
    if (columnKey === 'url') {
      return (
        <Link href={item.url} isExternal isBlock className='break-all'>
          {item.url}
        </Link>
      )
    }
    if (columnKey === 'createdAt') {
      return (
        <div className='break-all'>
          {getPrettyDate({ date: item.createdAt })}
        </div>
      )
    }
  }, [])

  if (isPending) return <PageSpinner />

  return (
    <div className='flex flex-col space-y-4'>
      {data && (
        <div className='grid grid-cols-[400px_1fr] grid-rows-[400px_400px] gap-4'>
          <div className='space-y-4'>
            <div className='bg-background flex h-[400px] flex-col items-center justify-center rounded-xl p-4 shadow'>
              <div className='text-sm font-semibold'>
                Публикации на площадкам
              </div>
              {data.linksByAreas.length ? (
                <div className='flex-1'>
                  <PieChart width={250} height={250}>
                    <Pie
                      data={data.linksByAreas}
                      cx={125}
                      cy={125}
                      innerRadius={55}
                      outerRadius={75}
                      paddingAngle={5}
                      dataKey='value'
                    >
                      {data.linksByAreas
                        .sort((a, b) => b.value - a.value)
                        .map((entry, index) => (
                          <Cell
                            key={`cell-${entry.name}`}
                            fill={colorsConst[entry.key]}
                          />
                        ))}
                    </Pie>
                    <TooltipRecharts />
                  </PieChart>
                  <div className='w-full text-sm'>
                    {data.linksByAreas.map(i => (
                      <div key={i.name} className='flex justify-between px-4'>
                        <div className='flex items-center space-x-1'>
                          <div
                            style={{
                              background: colorsConst[i.key],
                            }}
                            className='h-2 w-2 rounded-full'
                          />
                          <div className='line-clamp-1'>{i.name}</div>
                        </div>
                        <div>
                          {getPrettyNumber(i.value)}
                          {i.value
                            ? ` (${Math.floor(
                                (i.value * 100) /
                                  data.linksByAreas.reduce((acc, item) => {
                                    acc += item.value
                                    return acc
                                  }, 0),
                              )}%)`
                            : ''}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className='text-default-400 flex-1'>
                  За выбронный период данные отсутствуют
                </div>
              )}
            </div>
            <div className='bg-background flex h-[400px] flex-col items-center justify-center rounded-xl p-4 shadow'>
              <div className='text-sm font-semibold'>Формат публикаций</div>
              {data.formatOfWork.length ? (
                <div className='flex-1'>
                  <BarChart
                    width={400}
                    height={348}
                    data={data.formatOfWork}
                    margin={{
                      top: 0,
                      right: 16,
                      left: 0,
                      bottom: 50,
                    }}
                  >
                    <XAxis
                      angle={-45}
                      tick={{
                        fontSize: 12,
                        textAnchor: 'end',
                      }}
                      dataKey={({ name }) =>
                        linkFormatConst[name as keyof typeof linkFormatConst]
                      }
                    />
                    <YAxis />
                    <TooltipRecharts />
                    <Bar
                      dataKey='value'
                      fill='hsl(var(--nextui-primary-500))'
                      activeBar={
                        <Rectangle fill='hsl(var(--nextui-success-500))' />
                      }
                    />
                  </BarChart>
                </div>
              ) : (
                <div className='text-danger flex-1'>
                  За выбронный период данные отсутствуют
                </div>
              )}
            </div>
          </div>
          <div className='space-y-4'>
            <div className='bg-background flex h-[400px] flex-col space-y-2 rounded-xl p-4 shadow'>
              <div className='text-sm font-semibold'>Тематические линии</div>
              <Table
                aria-label='Тематические линии'
                isHeaderSticky
                isStriped
                removeWrapper
                classNames={{
                  base: 'overflow-auto',
                }}
              >
                <TableHeader>
                  <TableColumn key='name'>Тема</TableColumn>
                  <TableColumn key='publicationCount'>Публикации</TableColumn>
                </TableHeader>
                <TableBody
                  emptyContent='За выбронный период данные отсутствуют'
                  items={data.themes}
                >
                  {item => (
                    <TableRow key={item.name}>
                      {columnKey => (
                        <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <div className='bg-background flex h-[400px] flex-col space-y-2 rounded-xl p-4 shadow'>
              <div className='text-sm font-semibold'>
                Ссылки на публикации {`(${data.links.length})`}
              </div>
              <Table
                aria-label='Ссылки на публикации'
                isHeaderSticky
                isStriped
                removeWrapper
                classNames={{
                  base: 'overflow-auto',
                }}
              >
                <TableHeader>
                  <TableColumn key='url'>Ссылка</TableColumn>
                  <TableColumn key='createdAt'>Дата создания</TableColumn>
                </TableHeader>
                <TableBody
                  emptyContent='За выбронный период данные отсутствуют'
                  items={data.links}
                >
                  {item => (
                    <TableRow key={item.id}>
                      {columnKey => (
                        <TableCell>{renderCell(item, columnKey)}</TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
