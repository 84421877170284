import { UserInterface } from '@hydra/interfaces'
import {
  Input,
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { routesConst } from '../../../features/constants/routes.const'
import { allUsersAtom } from '../../../features/store'
import { Empty, PageSpinner } from '../../../features/ui'
import { CopyToClipboardButton } from '../../../features/ui/components/CopyToClipboardButton'
import { formatRussianPhoneNumber } from '../../../features/utils/formatRussianPhoneNumber.util'
import { generateMailtoLink } from '../../../features/utils/generateMailtoLink.util'
import { generateTelegramLink } from '../../../features/utils/generateTelegramLink.util'

const columns = [
  {
    id: 'name',
    label: 'Имя',
  },
  {
    id: 'surname',
    label: 'Фамилия',
  },
  {
    id: 'login',
    label: 'Логин',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'telegram',
    label: 'Телеграм',
  },
  {
    id: 'phone',
    label: 'Телефон',
  },
]

type ColumnType = Pick<
  UserInterface,
  'id' | 'name' | 'surname' | 'login' | 'email' | 'phone' | 'telegram'
>

export const UsersList = () => {
  const [{ data, isPending }] = useAtom(allUsersAtom)
  const navigate = useNavigate()
  const [filter, setFilter] = useState('')

  const renderCell = useCallback((user: ColumnType, columnKey: Key) => {
    const cellValue = user[columnKey as keyof ColumnType]
    switch (columnKey) {
      case 'email':
        return (
          <div className='flex items-center space-x-1'>
            <Link isExternal isBlock href={generateMailtoLink(user.email)}>
              {user.email}
            </Link>
            <CopyToClipboardButton
              value={user.email}
              messageText={`${user.email}\nскопированно в буфер обмена`}
            />
          </div>
        )
      case 'telegram':
        return (
          <div className='flex items-center space-x-1'>
            <Link
              isExternal
              isBlock
              href={generateTelegramLink(user.telegram)}
              size='sm'
            >
              {user.telegram}
            </Link>
            <CopyToClipboardButton
              messageText={`${generateTelegramLink(
                user.telegram,
              )}\nскопированно в буфер обмена`}
              value={generateTelegramLink(user.telegram)}
            />
          </div>
        )
      case 'phone':
        return (
          <div className='flex items-center space-x-1'>
            <span>{formatRussianPhoneNumber(user.phone)}</span>
            <CopyToClipboardButton
              value={user.phone}
              messageText={`${user.phone}\nскопированно в буфер обмена`}
            />
          </div>
        )
      default:
        return cellValue
    }
  }, [])

  const onTableRowClick = useCallback(
    (id: string) => {
      navigate(routesConst.users + '/' + id)
    },
    [navigate],
  )

  if (isPending) return <PageSpinner />

  if (!data) return <Empty />

  return (
    <div className='space-y-4'>
      <div>
        <Input
          size='sm'
          label='Поиск по имени или фамилии'
          variant='bordered'
          value={filter}
          onValueChange={setFilter}
        />
      </div>
      <SimpleBar className='bg-background h-[calc(100vh-168px)] overflow-auto rounded-xl p-4 shadow'>
        <Table aria-label='Пользователи' isHeaderSticky isStriped removeWrapper>
          <TableHeader columns={columns}>
            {column => (
              <TableColumn key={column.id}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody
            items={data[0].filter(
              user =>
                user.name.toLowerCase().includes(filter.toLowerCase()) ||
                user.surname.toLowerCase().includes(filter.toLowerCase()),
            )}
          >
            {item => (
              <TableRow key={item.id} onClick={() => onTableRowClick(item.id)}>
                {columnKey => (
                  <TableCell>{renderCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </SimpleBar>
    </div>
  )
}
