import { ChatInterface, UserInterface } from '@hydra/interfaces'
import { Checkbox } from '@nextui-org/react'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

interface Props {
  user: UserInterface
  chat: ChatInterface
  refetch: () => void
  updateHandler: (
    id: string,
    data: { user: UserInterface; value: boolean },
  ) => void
}

export const InChatUserCheckbox = ({
  user,
  chat,
  refetch,
  updateHandler,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const userInChat = useMemo(
    () => chat?.users?.find(u => u.id === user.id),
    [user.id, chat?.users],
  )

  const handleUserInChat = useCallback(
    async (value: boolean, item: UserInterface) => {
      try {
        setIsLoading(true)

        await updateHandler(chat?.id || '', {
          user: item,
          value,
        })
      } catch (error) {
        toast.error('Не удалось добавить пользователя в чат, попробуйте позже')
        console.error(error)
      } finally {
        await refetch()
        setIsLoading(false)
      }
    },
    [chat?.id, updateHandler, refetch],
  )

  return (
    <Checkbox
      isSelected={!!userInChat}
      isDisabled={isLoading || (!user.telegram && !user.chatId)}
      size='lg'
      onValueChange={value => handleUserInChat(value, user)}
    />
  )
}
