import { z } from 'zod'

export const createPerformanceSchema = z.object({
  month: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  year: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  occasionsCount: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  occasionsCountForSocialNetwork: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  themeHit: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  themeHitForSocialNetwork: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  qualityRelease: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  producing: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  graphic: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
})
