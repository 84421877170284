import { Divider, Link } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Cell, Pie, PieChart } from 'recharts'

import { colorsConst } from '../../../features/constants/colors.const'
import { routesConst } from '../../../features/constants/routes.const'
import { Empty, Header, Layout, PageSpinner } from '../../../features/ui'
import { getPrettyNumber } from '../../../features/utils/getPrettyNumber'
import { menuItems } from '../constants/menuItems'
import { publicsDashboardAtom } from '../store/public.store'

const pageTitle = 'Дашборад'

export const PublicsDashboard = () => {
  const [{ data, isPending }] = useAtom(publicsDashboardAtom)

  const dashboardChartData = useMemo(
    () =>
      data
        ? [
            {
              name: 'ВКонтакте',
              subscribers: data.vkSubscribers,
              viewsAverage: data.vkViewsAverage,
              count: data.vkCount,
              color: colorsConst.vk,
            },
            {
              name: 'Одноклассники',
              subscribers: data.okSubscribers,
              viewsAverage: data.okViewsAverage,
              count: data.okCount,
              color: colorsConst.ok,
            },
            {
              name: 'Telegram',
              subscribers: data.tgSubscribers,
              viewsAverage: data.tgViewsAverage,
              count: data.tgCount,
              color: colorsConst.tg,
            },
            {
              name: 'Другие',
              subscribers: data.otherSubscribers,
              viewsAverage: data.otherViewsAverage,
              count: data.otherCount,
              color: colorsConst.other,
            },
          ]
        : [],
    [data],
  )

  if (isPending) return <PageSpinner />

  if (!data) return <Empty />

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        <div className='h-[calc(100vh-86px)] space-y-4 p-4'>
          <div className='grid h-1/2 grid-cols-2 gap-4'>
            <div className='bg-background flex flex-col space-y-2 overflow-hidden rounded-2xl p-4 shadow'>
              <div className='text-sm font-semibold'>
                ТОП пабликов по подписчикам
              </div>
              <Divider />
              <div className='flex flex-col space-y-2 overflow-y-auto overflow-x-hidden'>
                {data.topSubscribers.map((p, index) => (
                  <div
                    key={p.id}
                    className='flex justify-between space-x-4 px-2 text-xs'
                  >
                    <div className='line-clamp-1 whitespace-nowrap'>
                      <Link
                        href={routesConst.publics + '/' + p.id}
                        className='text-foreground text-xs hover:underline'
                      >
                        {index + 1}. {p.title || p.link}
                      </Link>
                    </div>
                    <div>
                      {p.subscribers ? getPrettyNumber(p.subscribers) : 0}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='bg-background flex flex-col space-y-2 overflow-hidden rounded-2xl p-4 shadow'>
              <div className='text-sm font-semibold'>
                ТОП пабликов средним просмотрам
              </div>
              <Divider />
              <div className='flex flex-col space-y-2 overflow-y-auto overflow-x-hidden'>
                {data.topViewsAverage.map((p, index) => (
                  <div
                    key={p.id}
                    className='flex justify-between space-x-4 px-2 text-xs'
                  >
                    <div className='line-clamp-1 whitespace-nowrap'>
                      <Link
                        href={routesConst.publics + '/' + p.id}
                        className='text-foreground text-xs hover:underline'
                      >
                        {index + 1}. {p.title || p.link}
                      </Link>
                    </div>
                    <div>
                      {p.viewsAverage ? getPrettyNumber(p.viewsAverage) : 0}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='grid h-1/2 grid-cols-3 gap-4'>
            <div className='bg-background flex flex-col space-y-2 rounded-2xl p-4 shadow'>
              <div className='text-sm font-semibold'>Количество пабликов</div>
              <Divider />
              <div className='flex w-full flex-col space-y-4'>
                <div className='flex justify-center'>
                  <PieChart width={200} height={200}>
                    <Pie
                      data={dashboardChartData}
                      cx={100}
                      cy={100}
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey='count'
                    >
                      {dashboardChartData
                        .sort((a, b) => b.count - a.count)
                        .map((entry, index) => (
                          <Cell key={`cell-${entry.name}`} fill={entry.color} />
                        ))}
                    </Pie>
                  </PieChart>
                </div>
                <div className='w-full text-sm'>
                  {dashboardChartData
                    .sort((a, b) => b.count - a.count)
                    .map(i => (
                      <div key={i.name} className='flex justify-between px-4'>
                        <div className='flex items-center space-x-1'>
                          <div
                            style={{
                              background: i.color,
                            }}
                            className='h-2 w-2 rounded-full'
                          />
                          <div className='line-clamp-1'>{i.name}</div>
                        </div>
                        <div>
                          {getPrettyNumber(i.count)}
                          {i.count
                            ? ` (${Math.floor((i.count * 100) / data.totalPublics)}%)`
                            : ''}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className='bg-background flex flex-col space-y-2 rounded-2xl p-4 shadow'>
              <div className='text-sm font-semibold'>
                Количество подписчиков
              </div>
              <Divider />
              <div className='flex w-full flex-col space-y-4'>
                <div className='flex justify-center'>
                  <PieChart width={200} height={200}>
                    <Pie
                      data={dashboardChartData}
                      cx={100}
                      cy={100}
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey='subscribers'
                    >
                      {dashboardChartData
                        .sort((a, b) => b.subscribers - a.subscribers)
                        .map((entry, index) => (
                          <Cell key={`cell-${entry.name}`} fill={entry.color} />
                        ))}
                    </Pie>
                  </PieChart>
                </div>
                <div className='w-full text-sm'>
                  {dashboardChartData
                    .sort((a, b) => b.subscribers - a.subscribers)
                    .map(i => (
                      <div key={i.name} className='flex justify-between px-4'>
                        <div className='flex items-center space-x-1'>
                          <div
                            style={{
                              background: i.color,
                            }}
                            className='h-2 w-2 rounded-full'
                          />
                          <div>{i.name}</div>
                        </div>
                        <div>
                          {getPrettyNumber(i.subscribers)}
                          {i.subscribers
                            ? ` (${Math.floor((i.subscribers * 100) / data.totalSubscribers)}%)`
                            : ''}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className='bg-background flex flex-col space-y-2 rounded-2xl p-4 shadow'>
              <div className='text-sm font-semibold'>
                Ср. количество просмотров
              </div>
              <Divider />
              <div className='flex w-full flex-col space-y-4'>
                <div className='flex justify-center'>
                  <PieChart width={200} height={200}>
                    <Pie
                      data={dashboardChartData}
                      cx={100}
                      cy={100}
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey='viewsAverage'
                    >
                      {dashboardChartData
                        .sort((a, b) => b.viewsAverage - a.viewsAverage)
                        .map((entry, index) => (
                          <Cell key={`cell-${entry.name}`} fill={entry.color} />
                        ))}
                    </Pie>
                  </PieChart>
                </div>
                <div className='w-full text-sm'>
                  {dashboardChartData
                    .sort((a, b) => b.viewsAverage - a.viewsAverage)
                    .map(i => (
                      <div key={i.name} className='flex justify-between px-4'>
                        <div className='flex items-center space-x-1'>
                          <div
                            style={{
                              background: i.color,
                            }}
                            className='h-2 w-2 rounded-full'
                          />
                          <div>{i.name}</div>
                        </div>
                        <div>
                          {getPrettyNumber(i.viewsAverage)}
                          {i.viewsAverage
                            ? ` (${Math.floor((i.viewsAverage * 100) / data.totalViewsAverage)}%)`
                            : ''}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
