import { Switch } from '@nextui-org/react'
import { useTheme } from 'next-themes'
import { useEffect, useState } from 'react'
import { MdDarkMode, MdLightMode } from 'react-icons/md'
import { useLocalStorage } from 'usehooks-ts'

export function ThemeSwitcher() {
  const [mounted, setMounted] = useState(false)
  const { theme, setTheme } = useTheme()
  const [isMenuCollapsed] = useLocalStorage('isMenuCollapsed', false)
  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return null

  return (
    <Switch
      size='sm'
      isSelected={theme === 'light'}
      onValueChange={value => setTheme(value ? 'light' : 'dark')}
      color='success'
      startContent={<MdLightMode />}
      endContent={<MdDarkMode />}
    >
      {!isMenuCollapsed && (
        <span className='text-small'>
          {theme === 'light' ? 'Светлая' : 'Темная'} тема
        </span>
      )}
    </Switch>
  )
}
