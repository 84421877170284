import {
  Checkbox,
  Chip,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/react'
import { useCallback, useMemo, useState } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { updateTask } from '../api/tasks.api'

interface Props {
  id: string
  date: string | null
  time: string | null
  refetch: () => void
}

export const DateChip = ({ id, date, time, refetch }: Props) => {
  const [withTime, setWithTime] = useState(Boolean(time))

  const overdue = useMemo(
    () => extendedDayjs().diff(extendedDayjs(date), 'days'),
    [date],
  )

  const handleChangeTime = useCallback(
    async (value: string) => {
      if (withTime) {
        const splitValue = value.split('T')
        await updateTask(id, {
          date: splitValue[0],
          time: splitValue[1],
        })
      } else {
        await updateTask(id, {
          date: value,
          time: '',
        })
      }
      refetch()
    },
    [id, refetch, withTime],
  )

  return (
    <Popover backdrop='blur' placement='bottom-end' showArrow>
      <PopoverTrigger>
        <Chip
          size='sm'
          color={
            overdue <= 0 && overdue >= -2
              ? 'danger'
              : overdue > 0
                ? 'default'
                : 'success'
          }
          variant='bordered'
          className='cursor-pointer'
          endContent={<TbChevronDown className='text-xl' />}
        >
          {getPrettyDate({
            date: time ? extendedDayjs(date + ' ' + time) : extendedDayjs(date),
            template: time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY',
          })}
        </Chip>
      </PopoverTrigger>
      <PopoverContent
        aria-label='Проект задачи'
        className='flex w-[200px] flex-col items-start space-y-4 p-4'
      >
        <Checkbox isSelected={withTime} onValueChange={setWithTime}>
          Добавить время
        </Checkbox>
        <Input
          defaultValue={date ? (time ? date + 'T' + time : date) : ''}
          variant='underlined'
          type={withTime ? 'datetime-local' : 'date'}
          onValueChange={handleChangeTime}
        />
      </PopoverContent>
    </Popover>
  )
}
