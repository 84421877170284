import { instance } from '../../../features/api'

export const updatePriority = (
  id: string,
  data: {
    title?: string
  },
) => {
  return instance.patch(`/newsbreaks/update-priority/${id}`, data)
}

export const createPriority = (data: { title: string }) => {
  return instance.post('/newsbreaks/create-priority', data)
}
