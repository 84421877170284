import { instance } from '../../../features/api'

export const updateProject = (
  id: string,
  data: {
    title?: string
  },
) => {
  return instance.patch(`/newsbreaks/update-project/${id}`, data)
}

export const createProject = (data: { title: string }) => {
  return instance.post('/newsbreaks/create-project', data)
}
