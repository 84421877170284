import { Grant } from '@hydra/interfaces'
import { Button, Divider } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback } from 'react'
import SimpleBar from 'simplebar-react'

import { useGrant } from '../../../features/grants'
import {
  endOfDayAtom,
  importanceIdAtom,
  organizationIdAtom,
  priorityIdAtom,
  projectIdAtom,
  startOfDayAtom,
  themeIdAtom,
  typeIdAtom,
  userIdAtom,
} from '../store/newsbreaks.store'
import { DateFilter } from './DateFilter'
import { ImportanceFilter } from './ImportanceFilter'
import { OrganizationFilter } from './OrganizationFilter'
import { PriorityFilter } from './PriorityFilter'
import { ProjectFilter } from './ProjectFilter'
import { ThemeFilter } from './ThemeFilter'
import { TypeFilter } from './TypeFilter'
import { UrgentFilter } from './UrgentFilter'
import { UserFilter } from './UserFilter'

export const NewsbreaksFilters = () => {
  const { isValidGrant: isModerator } = useGrant([Grant.newsbreaksModerator])
  const [, setStartOfDay] = useAtom(startOfDayAtom)
  const [, setEndOfDay] = useAtom(endOfDayAtom)
  const [, setUserId] = useAtom(userIdAtom)
  const [, setTypeId] = useAtom(typeIdAtom)
  const [, setThemeId] = useAtom(themeIdAtom)
  const [, setProjectId] = useAtom(projectIdAtom)
  const [, setPriorityId] = useAtom(priorityIdAtom)
  const [, setImportanceId] = useAtom(importanceIdAtom)
  const [, setOrganization] = useAtom(organizationIdAtom)

  const onClearButtonPress = useCallback(() => {
    setStartOfDay('')
    setEndOfDay('')
    setUserId(undefined)
    setTypeId(undefined)
    setThemeId(undefined)
    setProjectId(undefined)
    setPriorityId(undefined)
    setImportanceId(undefined)
    setOrganization(undefined)
  }, [
    setEndOfDay,
    setImportanceId,
    setOrganization,
    setPriorityId,
    setProjectId,
    setStartOfDay,
    setThemeId,
    setTypeId,
    setUserId,
  ])

  return (
    <div className='bg-background space-y-4 rounded-xl p-4 shadow'>
      <div className='text-lg font-semibold'>Фильтры</div>
      <Divider />
      <SimpleBar className='h-[calc(100vh-422px)] w-[300px] '>
        <div className='space-y-4'>
          <UrgentFilter />
          <DateFilter />
          {isModerator && (
            <>
              <UserFilter />
              <OrganizationFilter />
            </>
          )}
          <TypeFilter />
          <ThemeFilter />
          <ProjectFilter />
          <PriorityFilter />
          {isModerator && <ImportanceFilter />}
        </div>
      </SimpleBar>
      <Divider />
      <div className='flex flex-col items-center space-y-4'>
        <Button fullWidth variant='light' onPress={onClearButtonPress}>
          Сбросить все
        </Button>
      </div>
    </div>
  )
}
