import {
  Grant,
  NewsbreakEditorStatusEnum,
  NewsbreakInterface,
  NewsbreakStatusEnum,
} from '@hydra/interfaces'
import { parseDate } from '@internationalized/date'
import { DateValue, RangeValue } from '@nextui-org/react'
import { Dayjs } from 'dayjs'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'
import { atomWithStorage } from 'jotai/vanilla/utils'

import { profileAtom } from '../../../features/store'
import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import { getAllImportances } from '../api/importances.api'
import {
  getAllLogs,
  getAllNewsbreaksByDate,
  getAllPriorities,
  getAllProjects,
  getAllStatusesCounts,
  getAllThemes,
  getAllTypes,
  getNewsbreakById,
  getNewsbreakComments,
  getNewsbreakFiles,
  getNewsbreakImportance,
  getNewsbreakLocations,
  getNewsbreakPriority,
  getNewsbreakProject,
  getNewsbreakReports,
  getNewsbreaks,
  getNewsbreaksAnalytics,
  getNewsbreaksWithReports,
  getNewsbreakTheme,
  getNewsbreakType,
  getNewsbreakUsers,
  getOrganizationToNewsbreak,
} from '../api/newsbreaks.api'

export const statusTabAtom = atom<NewsbreakStatusEnum | 'all'>('all')
export const editorStatusTabAtom = atom<NewsbreakEditorStatusEnum>(
  NewsbreakEditorStatusEnum.wait,
)

export const skipAtom = atom(0)
export const takeAtom = atom(10)
export const orderAtom = atomWithStorage<keyof NewsbreakInterface>(
  'newsbreaks-order',
  'createdAt',
)

export const directionAtom = atomWithStorage<'desc' | 'asc'>(
  'newsbreaks-direction',
  'desc',
)

export const skipNewsbreatWithReportsAtom = atom(0)
export const takeNewsbreatWithReportsAtom = atom(25)
export const orderNewsbreaksWithReportsAtom = atomWithStorage<
  keyof NewsbreakInterface
>('order-newsbreaks-wit-reports', 'createdAt')

export const directionNewsbreaksWithReportsAtom = atomWithStorage<
  'desc' | 'asc'
>('direction-newsbreak-wit-reports', 'desc')

export const startOfDayAtom = atom<Dayjs | ''>('')
export const endOfDayAtom = atom<Dayjs | ''>('')
export const startOfCreatedDayAtom = atom<Dayjs | ''>('')
export const endOfCreatedDayAtom = atom<Dayjs | ''>('')
export const startOfUpdatedDayAtom = atom<Dayjs | ''>('')
export const endOfUpdatedDayAtom = atom<Dayjs | ''>('')

export const userIdAtom = atom<string | undefined>(undefined)
export const organizationIdAtom = atom<string | undefined>(undefined)
export const typeIdAtom = atom<string | undefined>(undefined)
export const themeIdAtom = atom<string | undefined>(undefined)
export const formOfWorkAtom = atom<string | undefined>(undefined)
export const projectIdAtom = atom<string | undefined>(undefined)
export const priorityIdAtom = atom<string | undefined>(undefined)
export const importanceIdAtom = atom<string | undefined>(undefined)
export const urgentAtom = atom<string | undefined>(undefined)
export const newsbreakIdAtom = atom<string | undefined>(undefined)
export const calendarYearAtom = atom(extendedDayjs().year())
export const calendarMonthAtom = atom(extendedDayjs().month())
export const calendarWeekAtom = atom(extendedDayjs().week())
export const calendarDayAtom = atom(extendedDayjs().date())
export const calendarViewAtom = atomWithStorage<'month' | 'week'>(
  'calendarView',
  'week',
)

export const newsbreaksSearchStringAtom = atom<string | undefined>(undefined)

export const newsbreaksAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'newsbreaks-list',
      get(skipAtom),
      get(takeAtom),
      get(orderAtom),
      get(directionAtom),
      get(statusTabAtom),
      get(editorStatusTabAtom),
      get(startOfDayAtom),
      get(endOfDayAtom),
      get(userIdAtom),
      get(organizationIdAtom),
      get(typeIdAtom),
      get(themeIdAtom),
      get(projectIdAtom),
      get(priorityIdAtom),
      get(importanceIdAtom),
      get(urgentAtom),
      get(newsbreaksSearchStringAtom),
    ],
    queryFn: async () => {
      const response = await getNewsbreaks({
        skip: get(skipAtom),
        take: get(takeAtom),
        order: get(orderAtom),
        direction: get(directionAtom),
        status:
          get(statusTabAtom) === 'all'
            ? undefined
            : (get(statusTabAtom) as NewsbreakStatusEnum),
        editorStatus: get(editorStatusTabAtom) as NewsbreakEditorStatusEnum,
        startOfDay: get(startOfDayAtom)
          ? extendedDayjs(get(startOfDayAtom)).utc(true).toDate()
          : undefined,
        endOfDay: get(endOfDayAtom)
          ? extendedDayjs(get(endOfDayAtom)).utc(true).toDate()
          : undefined,
        userId: get(userIdAtom),
        organization: get(organizationIdAtom),
        type: get(typeIdAtom),
        theme: get(themeIdAtom),
        project: get(projectIdAtom),
        priority: get(priorityIdAtom),
        importance: get(importanceIdAtom),
        isUrgent: get(urgentAtom),
        searchString: get(newsbreaksSearchStringAtom),
      })
      return response.data
    },
  }
})

export const newsbreaksStatusesCountsAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'newsbreaks-statuses-counts',
      get(startOfDayAtom),
      get(endOfDayAtom),
      get(userIdAtom),
      get(organizationIdAtom),
      get(typeIdAtom),
      get(themeIdAtom),
      get(projectIdAtom),
      get(priorityIdAtom),
      get(importanceIdAtom),
      get(urgentAtom),
      get(newsbreaksSearchStringAtom),
    ],
    queryFn: async () => {
      const response = await getAllStatusesCounts({
        startOfDay: get(startOfDayAtom)
          ? extendedDayjs(get(startOfDayAtom)).utc(true).toDate()
          : undefined,
        endOfDay: get(endOfDayAtom)
          ? extendedDayjs(get(endOfDayAtom)).utc(true).toDate()
          : undefined,
        userId: get(userIdAtom),
        organization: get(organizationIdAtom),
        type: get(typeIdAtom),
        theme: get(themeIdAtom),
        project: get(projectIdAtom),
        priority: get(priorityIdAtom),
        importance: get(importanceIdAtom),
        isUrgent: get(urgentAtom),
        searchString: get(newsbreaksSearchStringAtom),
      })
      return response.data
    },
  }
})

export const newsbreakAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-by-id', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakById(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const newsbreakUsersAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-users', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakUsers(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const organizationToNewsbreakAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['organization-to-newsbreak', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getOrganizationToNewsbreak(
        get(newsbreakIdAtom) || '',
      )
      return response.data
    },
  }
})

export const newsbreakCommentsAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-comments', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakComments(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})
export const newsbreakFilesAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-files', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakFiles(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const newsbreakReportsAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-reports', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakReports(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const newsbreakThemeAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-theme', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakTheme(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const newsbreakTypeAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-type', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakType(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const newsbreakProjectAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-project', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakProject(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const newsbreakPriorityAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-priority', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakPriority(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const newsbreakImportanceAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-importance', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakImportance(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const newsbreakLocationsAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: ['newsbreak-locations', get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getNewsbreakLocations(get(newsbreakIdAtom) || '')
      return response.data
    },
  }
})

export const newsbreaksByWeekAtom = atomWithQuery(get => {
  return {
    enabled: get(calendarViewAtom) === 'week',
    queryKey: [
      'newsbreaks-by-week',
      get(calendarYearAtom),
      get(calendarMonthAtom),
      get(calendarWeekAtom),
    ],
    queryFn: async () => {
      const currentMonth = extendedDayjs()
        .set('year', get(calendarYearAtom))
        .set('month', get(calendarMonthAtom))

      const response = await getAllNewsbreaksByDate({
        dayStart: currentMonth
          .startOf('week')
          .week(get(calendarWeekAtom))
          .toDate(),
        dayEnd: currentMonth.endOf('week').week(get(calendarWeekAtom)).toDate(),
      })

      return response.data
    },
  }
})

export const newsbreaksByMonthAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'newsbreaks-by-month',
      get(calendarYearAtom),
      get(calendarMonthAtom),
    ],
    queryFn: async () => {
      const currentMonth = extendedDayjs()
        .set('year', get(calendarYearAtom))
        .set('month', get(calendarMonthAtom))

      const response = await getAllNewsbreaksByDate({
        dayStart: currentMonth.startOf('month').subtract(1, 'month').toDate(),
        dayEnd: currentMonth.endOf('month').add(1, 'month').toDate(),
      })

      return response.data
    },
  }
})

export const newsbreaksWithReportsAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'newsbreaks-with-reports-list',
      get(skipNewsbreatWithReportsAtom),
      get(takeNewsbreatWithReportsAtom),
      get(orderNewsbreaksWithReportsAtom),
      get(directionNewsbreaksWithReportsAtom),
      get(startOfCreatedDayAtom),
      get(endOfCreatedDayAtom),
      get(startOfUpdatedDayAtom),
      get(endOfUpdatedDayAtom),
      get(themeIdAtom),
    ],
    queryFn: async () => {
      const response = await getNewsbreaksWithReports({
        skip: get(skipNewsbreatWithReportsAtom),
        take: get(takeNewsbreatWithReportsAtom),
        order: get(orderNewsbreaksWithReportsAtom),
        direction: get(directionNewsbreaksWithReportsAtom),
        startOfCreatedDay: get(startOfCreatedDayAtom)
          ? extendedDayjs(get(startOfCreatedDayAtom)).utc(true).toDate()
          : undefined,
        endOfCreatedDay: get(endOfCreatedDayAtom)
          ? extendedDayjs(get(endOfCreatedDayAtom)).utc(true).toDate()
          : undefined,
        startOfUpdatedDay: get(startOfUpdatedDayAtom)
          ? extendedDayjs(get(startOfUpdatedDayAtom)).utc(true).toDate()
          : undefined,
        endOfUpdatedDay: get(endOfUpdatedDayAtom)
          ? extendedDayjs(get(endOfUpdatedDayAtom)).utc(true).toDate()
          : undefined,
        theme: get(themeIdAtom),
      })
      return response.data
    },
  }
})

export const allImportancesAtom = atomWithQuery(() => {
  return {
    queryKey: ['newsbreaks-importances'],
    queryFn: async () => {
      const response = await getAllImportances()
      return response.data
    },
  }
})

export const allPrioritiesAtom = atomWithQuery(() => {
  return {
    queryKey: ['newsbreak-priorities'],
    queryFn: async () => {
      const response = await getAllPriorities()
      return response.data
    },
  }
})

export const allProjectsAtom = atomWithQuery(() => {
  return {
    queryKey: ['newsbreaks-projects'],
    queryFn: async () => {
      const response = await getAllProjects()
      return response.data
    },
  }
})

export const allThemesAtom = atomWithQuery(() => {
  return {
    queryKey: ['newsbreaks-themes'],
    queryFn: async () => {
      const response = await getAllThemes()
      return response.data
    },
  }
})

export const allTypesAtom = atomWithQuery(() => {
  return {
    queryKey: ['newsbreaks-types'],
    queryFn: async () => {
      const response = await getAllTypes()
      return response.data
    },
  }
})

export const allLogsAtom = atomWithQuery(get => {
  return {
    enabled: !!get(newsbreakIdAtom),
    queryKey: [get(newsbreakIdAtom)],
    queryFn: async () => {
      const response = await getAllLogs(get(newsbreakIdAtom) ?? '')
      return response.data
    },
  }
})

export const analyticsPeriodAtom = atom<RangeValue<DateValue>>({
  start: parseDate(extendedDayjs().subtract(1, 'month').format('YYYY-MM-DD')),
  end: parseDate(extendedDayjs().format('YYYY-MM-DD')),
})

export const analyticsOrganizationIds = atom<Array<string>>([])
export const analyticsOrganizationType = atom<string | undefined>(undefined)
export const analyticsNewsbreaksStatus = atom<NewsbreakStatusEnum | undefined>(
  undefined,
)

export const authorshipAnalyticsAtom = atom<string>('all')

export const newsbreaksAnalyticsAtom = atomWithQuery(get => {
  return {
    enabled: !!get(profileAtom).data,
    queryKey: [
      'newsbreaks-analytics',
      get(analyticsPeriodAtom),
      get(analyticsOrganizationIds),
      get(analyticsOrganizationType),
      get(analyticsNewsbreaksStatus),
      get(authorshipAnalyticsAtom),
      get(profileAtom),
    ],
    queryFn: async () => {
      const dateStart = get(analyticsPeriodAtom).start.toString()
      const dateEnd = get(analyticsPeriodAtom).end.toString()
      const profile = get(profileAtom)
      const organizationIds = profile.data?.profile.grants.includes(
        Grant.newsbreaksCategoryModerator,
      )
        ? [profile.data.profile.organization.id]
        : get(analyticsOrganizationIds)

      const response = await getNewsbreaksAnalytics({
        dateStart: extendedDayjs(dateStart).utc(true).toDate(),
        dateEnd: extendedDayjs(dateEnd).utc(true).toDate(),
        organizationIds,
        organizationType: get(analyticsOrganizationType),
        status: get(analyticsNewsbreaksStatus),
        authorship:
          get(authorshipAnalyticsAtom) === 'all'
            ? undefined
            : get(authorshipAnalyticsAtom),
      })

      return response.data
    },
  }
})
