import { Grant, OrganizationInterface } from '@hydra/interfaces'
import {
  Button,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  useDisclosure,
} from '@nextui-org/react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { clsx } from 'clsx'
import { useAtom } from 'jotai'
import { ChangeEvent, FormEvent, Key, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { PiMedalFill } from 'react-icons/pi'
import { TbSettings, TbSquareRoundedPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { useLocalStorage } from 'usehooks-ts'

import { instance, updateRatingColorful } from '../../../features/api'
import { organizationTypeConst } from '../../../features/constants/organizationType.const'
import { routesConst } from '../../../features/constants/routes.const'
import { Grants, useGrant } from '../../../features/grants'
import { useMultiplier } from '../../../features/hooks/useMultiplier'
import {
  availableRatingPeriodsAtom,
  organizationsRatingAtom,
  organizationTypeAtom,
  ratingColorfulAtom,
} from '../../../features/store'
import { Empty, Header, Layout, PageSpinner } from '../../../features/ui'
import { PeriodSelects } from '../../../features/ui/components/PeriodSelects'
import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import { GroupRatingTable } from '../components/GroupRatingTable'
import { GroupsList } from '../components/GroupsList'
import { GroupsPagination } from '../components/GroupsPagination'
import { RatingByGroupPdf } from '../components/RatingByGoupPdf'
import { RatingTablePdf } from '../components/RatingTablePdf'
import { menuItems } from '../constants/menuItems'
import { useRatingPeriodMask } from '../hooks/useRatingPeriodMask'

export const Rating = () => {
  const [selected, setSelected] = useLocalStorage('rating-tabs', 'indicators')

  const [isSelectedByGroup, setIsSelectedByGroup] = useLocalStorage(
    'rating-organizations-by-groups',
    false,
  )

  const [{ data: availableRatingPeriods }] = useAtom(availableRatingPeriodsAtom)
  const { isValidGrant: isSuperadmin } = useGrant([Grant.superadmin])
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const navigate = useNavigate()
  const multiplier = useMultiplier()
  const [{ data, isPending, refetch }] = useAtom(organizationsRatingAtom)
  const [organizationType, setOrganizationType] = useAtom(organizationTypeAtom)

  const [
    {
      data: ratingColorful,
      refetch: ratingColorfulRefetch,
      isPending: ratingColorfulIsPending,
    },
  ] = useAtom(ratingColorfulAtom)

  const period = useRatingPeriodMask()

  const renderCell = useCallback(
    (
      item: OrganizationInterface & {
        totalPoints: {
          fact: number
          plan: number
          points: number
        }
      },
      columnKey: Key,
    ) => {
      const classes = clsx('', {
        'bg-success-50 px-3 py-2':
          ratingColorful &&
          isSelectedByGroup &&
          item.totalPoints.points >= ratingColorful.green * multiplier,
        'bg-warning-50 px-3 py-2':
          ratingColorful &&
          isSelectedByGroup &&
          item.totalPoints.points < ratingColorful.green * multiplier &&
          item.totalPoints.points >= ratingColorful.orange * multiplier,
        'bg-danger-50 px-3 py-2':
          ratingColorful &&
          isSelectedByGroup &&
          item.totalPoints.points < ratingColorful.orange * multiplier,
      })

      if (columnKey === 'name') {
        return (
          <div
            className={clsx(
              'flex items-center space-x-1 rounded-l-md',
              classes,
            )}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-expect-error*/}
            {item.index <= 2 && (
              <div>
                <PiMedalFill
                  className={clsx('text-lg', {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    'text-yellow-400': item.index === 0,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    'text-gray-500': item.index === 1,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    'text-yellow-900': item.index === 2,
                  })}
                />
              </div>
            )}
            <div> {item.name}</div>
          </div>
        )
      }

      if (columnKey === 'points') {
        return (
          <div className={clsx('rounded-r-md', classes)}>
            {item.totalPoints.points || 0}
          </div>
        )
      }
    },
    [isSelectedByGroup, multiplier, ratingColorful],
  )

  const onTableRowClick = useCallback(
    (id: string) => {
      navigate(`${routesConst.organizations}/${id}?tab=rating`, {
        replace: true,
      })
    },
    [navigate],
  )

  const handleChangeOrganizationsType = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value) {
        setOrganizationType(event.target.value)
      } else {
        setOrganizationType(undefined)
      }
    },
    [setOrganizationType],
  )

  const handleSaveRatingColorful = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      if (ratingColorful) {
        const target = event.target as typeof event.target & {
          green: { value: string }
          orange: { value: string }
        }
        try {
          const response = await updateRatingColorful(ratingColorful.id, {
            green: Number(target.green.value),
            orange: Number(target.orange.value),
          })

          console.log(response)

          if (response.status && response.status === 200) {
            toast.success('Границы рейтинга обновлены')
            ratingColorfulRefetch()
            onClose()
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    [onClose, ratingColorful, ratingColorfulRefetch],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | Рейтинг</title>
      </Helmet>
      <Grants grants={[Grant.newsbreaksModerator]}>
        <Layout
          menuItems={menuItems}
          headerContent={
            <Header
              text='Рейтинг'
              actionButton={
                <div>
                  <Button
                    color='primary'
                    startContent={<TbSquareRoundedPlus className='text-2xl' />}
                    onPress={() => navigate(routesConst.createGroup)}
                  >
                    Создать группу
                  </Button>
                </div>
              }
            />
          }
        >
          <div className='p-4'>
            <Tabs
              color='primary'
              selectedKey={selected}
              onSelectionChange={tab => setSelected(tab as string)}
              aria-label='Табы рейтинга'
            >
              <Tab title='Показатели' key='indicators'>
                <div className='mb-4 flex items-center space-x-4'>
                  <PeriodSelects isDisabled={isPending} />
                  <div className='w-[250px]'>
                    <Select
                      isLoading={isPending}
                      isDisabled={isPending}
                      labelPlacement='outside'
                      aria-label='Выберите тип организации'
                      variant='bordered'
                      selectionMode='single'
                      placeholder='Выберите тип организации'
                      defaultSelectedKeys={
                        organizationType ? [organizationType] : []
                      }
                      onChange={handleChangeOrganizationsType}
                      items={Object.entries(organizationTypeConst).map(
                        item => ({
                          label: item[1],
                          value: item[0],
                        }),
                      )}
                    >
                      {item => (
                        <SelectItem
                          key={item.value}
                          value={item.value}
                          textValue={item.label}
                        >
                          {item.label}
                        </SelectItem>
                      )}
                    </Select>
                  </div>
                  <div>
                    <Switch
                      isSelected={isSelectedByGroup}
                      onValueChange={setIsSelectedByGroup}
                    >
                      Группировка
                    </Switch>
                  </div>
                  <div className='flex flex-1 justify-end space-x-2'>
                    {isSuperadmin && (
                      <Button
                        onPress={async () => {
                          await instance.get(
                            'newsbreaks/create-rating-by-month',
                            {
                              params: {
                                month:
                                  extendedDayjs().month() === 0
                                    ? 12
                                    : extendedDayjs().month() - 1,
                                year:
                                  extendedDayjs().month() === 0
                                    ? extendedDayjs().year() - 1
                                    : extendedDayjs().year(),
                              },
                            },
                          )
                          refetch()
                        }}
                      >
                        Создать рейтинг (прошлый месяц)
                      </Button>
                    )}

                    {data && isSelectedByGroup ? (
                      <div className='flex justify-end space-x-2'>
                        <PDFDownloadLink
                          fileName={'Медиарейтинг ЯНАО за ' + period + '.pdf'}
                          document={
                            <RatingByGroupPdf
                              organizationsByGroups={data.organizationsByGroups}
                            />
                          }
                        >
                          {({ loading }) => (
                            <Button
                              isDisabled={loading}
                              variant='flat'
                              color='primary'
                            >
                              Скачать полный рейтинг
                            </Button>
                          )}
                        </PDFDownloadLink>

                        <Button
                          isDisabled={ratingColorfulIsPending}
                          isIconOnly
                          variant='flat'
                          color='primary'
                          onPress={onOpen}
                        >
                          <TbSettings className='text-xl' />
                        </Button>
                      </div>
                    ) : (
                      <div className='flex justify-end'>
                        <PDFDownloadLink
                          fileName={'Медиарейтинг ЯНАО за ' + period + '.pdf'}
                          document={
                            <RatingTablePdf
                              organizations={data?.organizations || []}
                            />
                          }
                        >
                          {({ loading }) => (
                            <Button
                              isDisabled={loading}
                              variant='flat'
                              color='primary'
                            >
                              Скачать рейтинг
                            </Button>
                          )}
                        </PDFDownloadLink>
                      </div>
                    )}
                  </div>
                </div>

                <SimpleBar className='h-[calc(100vh-224px)] overflow-auto'>
                  {isPending ? (
                    <PageSpinner />
                  ) : !data ? (
                    <Empty />
                  ) : !isSelectedByGroup ? (
                    <div className='space-y-4'>
                      <Table
                        aria-label='Рейтинг организаций'
                        isHeaderSticky
                        isStriped
                      >
                        <TableHeader>
                          <TableColumn
                            width={750}
                            maxWidth={750}
                            minWidth={750}
                            key='name'
                          >
                            МО/ИОГВ
                          </TableColumn>
                          <TableColumn
                            width={250}
                            maxWidth={250}
                            minWidth={250}
                            key='points'
                          >
                            Баллы
                          </TableColumn>
                        </TableHeader>
                        <TableBody
                          items={
                            data.organizations.map((o, index) => ({
                              ...o,
                              index,
                            })) || []
                          }
                          emptyContent={
                            <div className='text-default-400 text-sm'>
                              Нет данных
                            </div>
                          }
                        >
                          {item => (
                            <TableRow
                              key={item.id}
                              onClick={() => onTableRowClick(item.id)}
                            >
                              {columnKey => (
                                <TableCell>
                                  {renderCell(item, columnKey)}
                                </TableCell>
                              )}
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    <div className='space-y-4'>
                      {Object.entries(data.organizationsByGroups)
                        .sort((a, b) => a[0].localeCompare(b[0]))
                        .map(([key, value]) => (
                          <GroupRatingTable
                            key={key}
                            groupName={key}
                            value={value}
                            onTableRowClick={onTableRowClick}
                            renderCell={renderCell}
                          />
                        ))}
                    </div>
                  )}
                </SimpleBar>
              </Tab>
              <Tab title='Группы' key='groups'>
                <div className='space-y-4 overflow-hidden'>
                  <div className='flex space-x-4'>
                    <GroupsList />
                  </div>
                  <GroupsPagination />
                </div>
              </Tab>
            </Tabs>
          </div>
        </Layout>
      </Grants>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <form onSubmit={handleSaveRatingColorful}>
              <ModalHeader className='flex flex-col gap-1'>
                Изменение границ рейтинга
              </ModalHeader>
              <ModalBody>
                <div className='text-sm'>
                  <p>
                    В <span className='text-success-500'>зеленую</span> зону
                    попадут организации которые имеют количество балов равное
                    или выше верхнего порога
                  </p>
                  <p>
                    В <span className='text-warning-500'>желтую</span> зону
                    попадут организации которые имеют количество балов ниже
                    верхнего порога и равное или больше нижнего порога
                  </p>
                  <p>
                    В <span className='text-danger-500'>красную</span> зону
                    попадут организации которые имеют количество балов ниже
                    нижнего порога
                  </p>
                </div>
                <Divider />
                {ratingColorful && (
                  <>
                    <Input
                      name='green'
                      color='success'
                      variant='bordered'
                      labelPlacement='outside'
                      label='Граница верхней зоны'
                      type='number'
                      defaultValue={String(ratingColorful.green)}
                    />
                    <Input
                      name='orange'
                      color='danger'
                      variant='bordered'
                      labelPlacement='outside'
                      label='Граница нижней зоны'
                      type='number'
                      defaultValue={String(ratingColorful.orange)}
                    />
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color='danger' variant='light' onPress={onClose}>
                  Закрыть
                </Button>
                <Button type='submit' color='primary'>
                  Сохранить
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
