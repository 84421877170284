import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtomValue } from 'jotai/index'
import { Key, useCallback, useState } from 'react'
import { TbCopy } from 'react-icons/tb'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { useCopyToClipboard } from 'usehooks-ts'

import { formatRussianPhoneNumber } from '../../../features/utils/formatRussianPhoneNumber.util'
import { chatAtom } from '../store/chat.store'
import { HighLightSearch } from './HighLightSearch'
import { RemoveExternalUserBtn } from './RemoveExternalUserBtn'

export const ExternalChatUsers = () => {
  const [, copy] = useCopyToClipboard()
  const { data: chat, isPending } = useAtomValue(chatAtom)
  const [search, setSearch] = useState('')

  const renderUserCell = useCallback(
    (
      item: { id: number; username: string; phone: string; firstName: string },
      columnKey: Key,
    ) => {
      if (columnKey === 'firstName' + search) {
        return (
          <HighLightSearch search={search.split(' ')} value={item.firstName} />
        )
      }

      if (columnKey === 'username' + search) {
        return (
          <div className='flex items-center space-x-2'>
            <Button
              color='primary'
              size='sm'
              variant='light'
              isIconOnly
              onPress={async () => {
                try {
                  await copy(item.username || '')
                  toast.success('Telegram username скопирован в буфер обмена')
                } catch (error) {
                  toast.error('Не удалось скопировать telegram username')
                }
              }}
            >
              <TbCopy className='text-lg' />
            </Button>
            <HighLightSearch search={search.split(' ')} value={item.username} />
          </div>
        )
      }

      if (columnKey === 'id' + search) {
        return (
          <div className='flex items-center space-x-2'>
            {item.id && (
              <Button
                color='primary'
                size='sm'
                variant='light'
                isIconOnly
                onPress={async () => {
                  try {
                    await copy(item.id.toString() || '')
                    toast.success('Chat ID скопирован в буфер обмена')
                  } catch (error) {
                    toast.error('Не удалось скопировать Chat ID')
                  }
                }}
              >
                <TbCopy className='text-lg' />
              </Button>
            )}
            <div>{item.id}</div>
          </div>
        )
      }

      if (columnKey === 'phone' + search) {
        return (
          <div className='flex items-center space-x-2'>
            <Button
              color='primary'
              size='sm'
              variant='light'
              isIconOnly
              onPress={async () => {
                try {
                  await copy(item.phone)
                  toast.success('Номер телефона скопирован в буфер обмена')
                } catch (error) {
                  toast.error('Не удалось скопировать номер телефона')
                }
              }}
            >
              <TbCopy className='text-lg' />
            </Button>
            <div>{formatRussianPhoneNumber(item.phone)}</div>
          </div>
        )
      }

      if (columnKey === 'remove' + search) {
        return <RemoveExternalUserBtn item={item} />
      }
    },
    [copy, search],
  )

  const usersForList = chat
    ? chat.externalUsers?.filter(
        u =>
          u.username.toLowerCase().includes(search.toLowerCase()) ||
          u.firstName.toLowerCase().includes(search.toLowerCase()),
      )
    : []

  return (
    <div className='space-y-4'>
      <Input
        variant='bordered'
        size='sm'
        color='primary'
        aria-label='Найти по имени, фамилии, организации'
        placeholder='Найти по имени, фамилии, организации'
        value={search}
        onValueChange={setSearch}
      />
      <SimpleBar className='h-[calc(100vh-356px)] overflow-auto'>
        <Table
          aria-label='Пользователи чата'
          isHeaderSticky
          isStriped
          removeWrapper
        >
          <TableHeader
            columns={[
              {
                key: 'firstName' + search,
                label: 'ФИО',
              },
              {
                key: 'username' + search,
                label: 'Telegram username',
              },
              {
                key: 'id' + search,
                label: 'Chat ID',
              },
              {
                key: 'phone' + search,
                label: 'Номер телефона',
              },
              {
                key: 'remove' + search,
                label: '',
              },
            ]}
          >
            {column => (
              <TableColumn key={column.key} textValue={column.label}>
                {column.label}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody
            items={usersForList}
            isLoading={isPending}
            emptyContent={
              <div className='text-default-400 text-sm'>Нет данных</div>
            }
          >
            {item => (
              <TableRow key={item.id + search} textValue={item.id.toString()}>
                {columnKey => (
                  <TableCell>{renderUserCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </SimpleBar>
    </div>
  )
}
