export const monthsConst = [
  { id: 0, title: 'Январь' },
  { id: 1, title: 'Февраль' },
  { id: 2, title: 'Март' },
  { id: 3, title: 'Апрель' },
  { id: 4, title: 'Май' },
  { id: 5, title: 'Июнь' },
  { id: 6, title: 'Июль' },
  { id: 7, title: 'Август' },
  { id: 8, title: 'Сентрябрь' },
  { id: 9, title: 'Октябрь' },
  { id: 10, title: 'Ноябрь' },
  { id: 11, title: 'Декабрь' },
]
