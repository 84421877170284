import { zodResolver } from '@hookform/resolvers/zod'
import { Grant } from '@hydra/interfaces'
import { Button, Input, Select, SelectItem } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants } from '../../../features/grants'
import { allOrganizationsWithoutGroupAtom } from '../../../features/store'
import { Header, Layout } from '../../../features/ui'
import { createGroup, updateGroup } from '../api/groups.api'
import { menuItems } from '../constants/menuItems'
import { createGroupSchema } from '../schemas/createGroup.schema'

interface Props {
  isEdit?: boolean
}
export const CreateGroup = ({ isEdit }: Props) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [{ data: organizations, isPending: isPendingOrganizations }] = useAtom(
    allOrganizationsWithoutGroupAtom,
  )

  const form = useForm<z.infer<typeof createGroupSchema>>({
    defaultValues: {
      title: searchParams.get('title') || '',
      organizations: searchParams.get('organizations')
        ? JSON.parse(searchParams.get('organizations') || '')
        : undefined,
    },
    resolver: zodResolver(createGroupSchema),
  })

  const onSubmit = useCallback(
    async (data: z.infer<typeof createGroupSchema>) => {
      if (isEdit) {
        const id = searchParams.get('id')
        if (!id) {
          return null
        }
        try {
          setIsFormLoading(true)
          await updateGroup(id, {
            title: data.title,
            organizations: data.organizations.map(i => ({ id: i })),
          })
          toast.success('Группа успешно обновлена')
          navigate(routesConst.groups + '/' + id)
        } catch (e) {
          console.error(e)
        } finally {
          setIsFormLoading(false)
        }
        return
      }

      try {
        setIsFormLoading(true)
        const response = await createGroup({
          ...data,
          organizations: data.organizations.map(org => ({ id: org })),
        })
        if (response.status && response.status === 201) {
          toast.success('Группа успешно создана')
          navigate(routesConst.groups)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [isEdit, navigate, searchParams],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | Создание группы</title>
      </Helmet>
      <Grants grants={[Grant.newsbreaksModerator]}>
        <Layout
          menuItems={menuItems}
          headerContent={<Header text='Создать группы' />}
        >
          <form
            className='space-y-4 p-4'
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Controller
              control={form.control}
              name='title'
              render={({ field: { onChange, value } }) => (
                <Input
                  isDisabled={isFormLoading}
                  variant='bordered'
                  placeholder='Название'
                  color='primary'
                  onValueChange={onChange}
                  defaultValue={value}
                  isInvalid={!!form.formState.errors.title}
                  errorMessage={form.formState.errors.title?.message}
                />
              )}
            />
            <Controller
              control={form.control}
              name='organizations'
              render={({ field: { onChange, value } }) => (
                <Select
                  isDisabled={isFormLoading}
                  isLoading={isFormLoading || isPendingOrganizations}
                  defaultSelectedKeys={value}
                  items={
                    organizations
                      ? organizations[0].filter(o => o.type !== 'smi')
                      : []
                  }
                  aria-label='Организации'
                  placeholder='Выберите организации'
                  variant='bordered'
                  className='flex-1'
                  selectionMode='multiple'
                  onChange={event => {
                    onChange(event.target.value.split(','))
                  }}
                >
                  {item => <SelectItem key={item.id}>{item.name}</SelectItem>}
                </Select>
              )}
            />
            <div className='flex justify-end'>
              <Button
                color='primary'
                type='submit'
                isDisabled={isFormLoading}
                isLoading={isFormLoading}
              >
                Сохранить
              </Button>
            </div>
          </form>
        </Layout>
      </Grants>
    </>
  )
}
