import { Button } from '@nextui-org/react'
import { useCallback } from 'react'
import { TbLogout2 } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../constants/routes.const'

export const ExitButton = () => {
  const navigate = useNavigate()

  const handleSignOut = useCallback(() => {
    localStorage.removeItem('token')
    navigate(routesConst.login)
  }, [navigate])

  return (
    <Button
      size='sm'
      color='primary'
      onClick={handleSignOut}
      startContent={<TbLogout2 className='text-lg' />}
      className={'justify-start gap-2'}
    >
      <span>Выйти</span>
    </Button>
  )
}
