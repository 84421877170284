import { ArticleInterface } from '@hydra/interfaces'
import { TElement } from '@udecode/plate-common'
import { AxiosResponse } from 'axios'

import { instance } from '../../../features/api'

export const getArticles = async (params: {
  skip: number
  take: number
}): Promise<AxiosResponse<[Array<ArticleInterface>, number]>> => {
  return instance.get('/knowledge/all-articles', {
    params,
  })
}

export const getArticleById = (
  id: string,
): Promise<AxiosResponse<ArticleInterface>> => {
  return instance.get(`/knowledge/article-by-id/${id}`, {})
}
export const createArticle = (data: {
  title: string
  description?: Array<TElement>
  folder?: {
    id: string
  }
  tags?: Array<string>
}) => {
  return instance.post('/knowledge/create-article', data)
}

export const updateArticle = (
  id: string,
  data: {
    title?: string
    folder?: {
      id: string
    } | null
    description?: Array<TElement>
    tags?: Array<string>
  },
) => {
  return instance.patch(`/knowledge/update-article/${id}`, data)
}

export const deleteArticle = (id: string) => {
  return instance.delete(`/knowledge/delete-article/${id}`)
}
