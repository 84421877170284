import { Grant } from '@hydra/interfaces'
import { Helmet } from 'react-helmet'

import { Grants } from '../../../features/grants'
import { Layout } from '../../../features/ui'
import { PermissionsList } from '../components/PermissionsList'
import { menuItems } from '../constants/menuItems'

export const Permissions = () => {
  return (
    <>
      <Helmet>
        <title>Гидра | Доступы</title>
      </Helmet>
      <Grants grants={[Grant.superadmin]}>
        <Layout
          menuItems={menuItems}
          headerContent={
            <div className='flex h-full items-center justify-between space-x-4 px-4'>
              <h1 className='flex-1 text-3xl font-semibold'>Доступы</h1>
            </div>
          }
        >
          <div className='p-4'>
            <PermissionsList />
          </div>
        </Layout>
      </Grants>
    </>
  )
}
