import { Grant, NotificationInterface, UserInterface } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from './index'

export const getProfile = (): Promise<
  AxiosResponse<{ profile: UserInterface }>
> => {
  return instance.get('/users/profile')
}

export const getAllUsers = (): Promise<
  AxiosResponse<[Array<UserInterface>, number]>
> => {
  return instance.get('/users/all')
}

export const getUserById = (
  id: string,
): Promise<AxiosResponse<UserInterface>> => {
  return instance.get(`/users/user-by-id/${id}`)
}

export const createUser = (data: {
  login: string
  password?: string
  name: string
  surname: string
  email: string
  phone: string
  telegram?: string
  chatId?: number
  grants?: Array<Grant>
  organization?: {
    id: string
  }
}) => {
  return instance.post('users/create', data)
}

export const updateUser = (
  id: string,
  data: {
    login?: string
    name?: string
    surname?: string
    email?: string
    phone?: string
    telegram?: string
    chatId?: number
    receiveNotificationsInTelegram?: boolean
    grants?: Array<Grant>
    organization?: {
      id: string
    }
  },
) => {
  return instance.patch(`/users/update/${id}`, data)
}

export const updateUserPassword = (data: { id?: string; password: string }) => {
  return instance.patch('users/update-password', data)
}

export const getNotifications = (): Promise<
  AxiosResponse<Array<NotificationInterface>>
> => {
  return instance.get('/users/notifications')
}

export const getNotificationsCount = (): Promise<AxiosResponse<number>> => {
  return instance.get('/users/notifications-count')
}

export const updateNotification = (
  id: string,
  data: {
    isRead: boolean
  },
) => {
  return instance.patch(`/users/update-notification/${id}`, data)
}

export const updateAllNotifications = () => {
  return instance.get(`/users/update-all-notification`)
}
