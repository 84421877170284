import { LevelEnum } from '@hydra/interfaces'
import { z } from 'zod'

export const createChatSchema = z.object({
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  link: z
    .string({})
    .url({
      message: 'Неккоретный адрес паблика',
    })
    .trim()
    .optional(),
  level: z.nativeEnum(LevelEnum).optional(),
})
