import { zodResolver } from '@hookform/resolvers/zod'
import { Grant } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { createUser, updateUser } from '../../../features/api'
import { routesConst } from '../../../features/constants/routes.const'
import { Grants } from '../../../features/grants'
import { allOrganizationsAtom } from '../../../features/store'
import { Layout } from '../../../features/ui'
import { CopyToClipboardButton } from '../../../features/ui/components/CopyToClipboardButton'
import { generateRandomString } from '../../../features/utils/generateRandomString.util'
import { menuItems } from '../constants/menuItems'
import { createUserSchema, editUserSchema } from '../schemas/createUser.schema'

interface Props {
  isEdit?: boolean
}

type FormField = z.infer<typeof createUserSchema>

export const CreateUser = ({ isEdit }: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isShowUserCred, setIsShowUserCred] = useState(false)
  const [password, setPassword] = useState('')
  const [login, setLogin] = useState('')
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [{ data: organizations, isPending: isPendingOrganizations }] =
    useAtom(allOrganizationsAtom)
  const form = useForm<FormField>({
    defaultValues: {
      login: searchParams.get('login') || '',
      name: searchParams.get('name') || '',
      surname: searchParams.get('surname') || '',
      email: searchParams.get('email') || '',
      phone: searchParams.get('phone') || '',
      telegram: searchParams.get('telegram') || '',
      chatId: searchParams.get('chatId') || '',
      organization: searchParams.get('organization') || '',
      newsbreakRole: '',
    },
    resolver: zodResolver(isEdit ? editUserSchema : createUserSchema),
  })

  const onSubmit: SubmitHandler<FormField> = async formData => {
    if (isEdit) {
      const id = searchParams.get('id')
      if (!id) {
        return null
      }
      try {
        const { newsbreakRole, ...rest } = formData
        const response = await updateUser(id, {
          ...rest,
          organization: formData.organization
            ? {
                id: formData.organization,
              }
            : undefined,
          chatId: formData.chatId ? +formData.chatId : undefined,
          telegram: formData.telegram ? formData.telegram : undefined,
        })
        if (response.status && response.status === 200) {
          toast.success('Пользователь успешно обновлен')
          navigate(routesConst.users + '/' + id)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
        setIsShowUserCred(true)
      }
      return
    }

    const randomPassword = generateRandomString(10)

    try {
      const response = await createUser({
        ...formData,
        password: randomPassword,
        grants: [formData.newsbreakRole as Grant],
        chatId: formData.chatId ? +formData.chatId : undefined,
        telegram: formData.telegram ? formData.telegram : undefined,
        organization: formData.organization
          ? {
              id: formData.organization,
            }
          : undefined,
      })
      if (response.status && response.status === 201) {
        toast.success('Пользователь успешно созадн')
        form.reset()
        setIsFormLoading(true)
        setLogin(formData.login)
        setPassword(randomPassword)
      }
    } catch (e) {
      toast.error('Что то пошло не так')
    } finally {
      setIsFormLoading(false)
      setIsShowUserCred(true)
    }
  }

  return (
    <>
      <Helmet>
        <title>
          Гидра | {isEdit ? 'Редактирование' : 'Создание'} пользователя
        </title>
      </Helmet>
      <Grants grants={[Grant.newsbreaksModerator]}>
        <Layout
          menuItems={menuItems}
          headerContent={
            <div className='flex h-full items-center justify-between space-x-4 px-4'>
              <h1 className='flex-1 text-3xl font-semibold'>
                {isEdit ? 'Редактирование' : 'Создание'} пользователя
              </h1>
            </div>
          }
        >
          <div className='p-4'>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className='bg-background space-y-4 rounded-xl p-4 shadow'
            >
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='name'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Имя'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.name}
                      errorMessage={form.formState.errors.name?.message}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='surname'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Фамилия'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.surname}
                      errorMessage={form.formState.errors.surname?.message}
                    />
                  )}
                />
              </div>
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='login'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Логин'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.login}
                      errorMessage={form.formState.errors.login?.message}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='phone'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Телефон'
                      placeholder='79999999999'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.phone}
                      errorMessage={form.formState.errors.phone?.message}
                    />
                  )}
                />
              </div>
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='email'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      type='email'
                      variant='bordered'
                      label='Email'
                      placeholder='example@yandex.ru'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.email}
                      errorMessage={form.formState.errors.email?.message}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='telegram'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Телеграм'
                      placeholder='nikcname'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.telegram}
                      errorMessage={form.formState.errors.telegram?.message}
                    />
                  )}
                />
              </div>
              <div className='flex space-x-4'>
                <Controller
                  control={form.control}
                  name='chatId'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Chat ID'
                      placeholder='Chat ID'
                      color='primary'
                      onValueChange={onChange}
                      value={String(value)}
                      isInvalid={!!form.formState.errors.telegram}
                      errorMessage={form.formState.errors.telegram?.message}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='organization'
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      isLoading={isPendingOrganizations || isFormLoading}
                      color='primary'
                      variant='bordered'
                      defaultItems={organizations ? organizations[0] : []}
                      label='Организация'
                      onSelectionChange={onChange}
                      selectedKey={value}
                      isInvalid={!!form.formState.errors.organization}
                      errorMessage={form.formState.errors.organization?.message}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      onKeyDown={e => e.continuePropagation()}
                    >
                      {item => (
                        <AutocompleteItem key={item.id}>
                          {item.name}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                  )}
                />
              </div>
              <div className='flex space-x-4'>
                {!isEdit && (
                  <Controller
                    control={form.control}
                    name='newsbreakRole'
                    render={({ field: { onChange, value } }) => (
                      <Select
                        isInvalid={!!form.formState.errors.newsbreakRole}
                        errorMessage={
                          form.formState.errors.newsbreakRole?.message
                        }
                        isLoading={isFormLoading}
                        color='primary'
                        variant='bordered'
                        label='Роль в модуле инфоповодов'
                        selectedKeys={value ? [value] : []}
                        onChange={onChange}
                      >
                        {[
                          {
                            value: Grant.newsbreaksModerator,
                            label: 'Модератор',
                          },
                          {
                            value: Grant.newsbreaksCategoryModerator,
                            label: 'Модератор категории инфоповодов',
                          },
                          {
                            value: Grant.newsbreaksProvider,
                            label: 'Поставщик',
                          },
                          {
                            value: Grant.newsbreaksEditor,
                            label: 'Выпускающий редактор',
                          },
                        ].map(item => (
                          <SelectItem key={item.value} value={item.value}>
                            {item.label}
                          </SelectItem>
                        ))}
                      </Select>
                    )}
                  />
                )}
              </div>
              <div className='flex justify-end'>
                <Button
                  color='primary'
                  type='submit'
                  isDisabled={isFormLoading}
                  isLoading={isFormLoading}
                >
                  {isEdit ? 'Сохранить' : 'Создать'}
                </Button>
              </div>
              {!isEdit && isShowUserCred && (
                <>
                  <div>
                    <span>Данные созданного пользователя</span>{' '}
                    <CopyToClipboardButton
                      value={`Логин: ${login}\nПароль: ${password}`}
                    />
                  </div>
                  <div>Логин: {login}</div>
                  <div>Пароль: {password}</div>
                </>
              )}
            </form>
          </div>
        </Layout>
      </Grants>
    </>
  )
}
