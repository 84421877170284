import { getLocalTimeZone, parseDate, today } from '@internationalized/date'
import { DatePicker, Select, SelectItem } from '@nextui-org/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { extendedDayjs } from '../../utils/dayjsConfig'

type Props = {
  onChange: (value: string) => void
  label: string
  isTimeShow: boolean
  value: string
}

const TIME_HOURS = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]

const TIME_MINUTES = ['00', '15', '30', '45']

export const DateAndTimePicker = ({
  onChange,
  label,
  isTimeShow,
  value,
}: Props) => {
  const [selectedTime, setSelectedTime] = useState<string>('12:00')

  useEffect(() => {
    value &&
      onChange(
        extendedDayjs(value)
          .set('hours', +selectedTime.split(':')[0])
          .set('minutes', +selectedTime.split(':')[1])
          .toISOString(),
      )
  }, [onChange, selectedTime, value])

  return (
    <div className='flex space-x-4'>
      <DatePicker
        minValue={today(getLocalTimeZone())}
        showMonthAndYearPickers
        variant='bordered'
        className='max-w-md'
        hideTimeZone
        aria-label={label}
        defaultValue={
          value ? parseDate(dayjs(value).format('YYYY-MM-DD')) : null
        }
        onChange={value =>
          onChange(extendedDayjs(value.toString()).toISOString())
        }
      />
      {isTimeShow && (
        <Select
          aria-label='Время срока исполнения'
          variant='bordered'
          className='flex w-40'
          items={TIME_HOURS.map(hour =>
            TIME_MINUTES.map(minutes => ({
              label: `${hour}:${minutes}`,
              value: `${hour}:${minutes}`,
            })),
          ).flat()}
          selectedKeys={[value ? dayjs(value).format('HH:mm') : selectedTime]}
          onChange={e => setSelectedTime(e.target.value)}
        >
          {item => (
            <SelectItem key={String(item.label)}>{item.value}</SelectItem>
          )}
        </Select>
      )}
    </div>
  )
}
