import { Grant } from '@hydra/interfaces'
import { FaRankingStar } from 'react-icons/fa6'
import {
  TbCalendarFilled,
  TbEditCircle,
  TbListCheck,
  TbPresentationAnalytics,
} from 'react-icons/tb'

import { routesConst } from '../../../features/constants/routes.const'

export const menuItems = [
  {
    title: 'Инфоповоды',
    link: routesConst.newsbreaks,
    icon: <TbEditCircle />,
    grant: [
      Grant.newsbreaksModerator,
      Grant.newsbreaksCategoryModerator,
      Grant.newsbreaksProvider,
      Grant.newsbreaksEditor,
    ],
  },
  {
    title: 'События',
    link: routesConst.themeCalendar,
    icon: <TbCalendarFilled />,
    grant: [
      Grant.newsbreaksModerator,
      Grant.newsbreaksCategoryModerator,
      Grant.newsbreaksProvider,
      Grant.newsbreaksEditor,
    ],
  },
  {
    title: 'Отчетность',
    link: routesConst.reports,
    icon: <TbListCheck />,
    grant: [
      Grant.newsbreaksModerator,
      Grant.newsbreaksCategoryModerator,
      Grant.newsbreaksProvider,
      Grant.newsbreaksEditor,
    ],
  },
  {
    title: 'Аналитика',
    link: routesConst.newsbreaksAnalytics,
    icon: <TbPresentationAnalytics />,
    grant: [Grant.newsbreaksModerator, Grant.newsbreaksCategoryModerator],
  },
  {
    title: 'Рейтинг',
    link: routesConst.rating,
    icon: <FaRankingStar />,
    grant: [Grant.newsbreaksModerator],
  },
]
