import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  useDisclosure,
} from '@nextui-org/react'
import { Dispatch, SetStateAction } from 'react'
import { BsImageFill } from 'react-icons/bs'
import { HiDocument, HiVideoCamera } from 'react-icons/hi2'
import { TbDotsVertical, TbHeadphonesFilled } from 'react-icons/tb'

import { RenameFileModal } from '../../../modules/newsbreaks/components/RenameFileModal'
import {
  audioExtensions,
  docExtensions,
  imageExtensions,
  videoExtensions,
} from '../../constants/fileExtensions.const'
import { createLinkAndDownloadFile } from '../../utils/createLinkAndDownloadFile'
import { getFileExtension } from '../../utils/getFileExtension'
import { DeleteModal } from '../index'

interface Props {
  file: File
  index: number
  setFiles: Dispatch<SetStateAction<File[] | null | undefined>>
}

export const FileItem = ({ file, index, setFiles }: Props) => {
  const {
    isOpen: isOpenRenameFileModal,
    onOpen: setIsOpenRenameFileModal,
    onClose: onCloseRenameFileModal,
  } = useDisclosure()
  const {
    isOpen: isOpenDeleteFileModal,
    onOpen: onOpenDeleteFileModal,
    onClose: onCloseDeleteFileModal,
  } = useDisclosure()

  return (
    <>
      <div className='bg-background border-default-200 flex items-center space-x-4 rounded-xl border p-3'>
        <div className='flex-0 bg-primary-50 text-primary flex h-10 w-10 items-center justify-center rounded-lg'>
          {Object.keys(audioExtensions).includes(file.type) && (
            <TbHeadphonesFilled className='text-2xl' />
          )}
          {Object.keys(videoExtensions).includes(file.type) && (
            <HiVideoCamera className='text-2xl' />
          )}
          {Object.keys(imageExtensions).includes(file.type) && (
            <BsImageFill className='text-2xl' />
          )}
          {Object.keys(docExtensions).includes(file.type) && (
            <HiDocument className='text-2xl' />
          )}
        </div>
        <div className='line-clamp-1 flex-1'>{file.name}</div>
        <div>
          <Dropdown>
            <DropdownTrigger>
              <Button size='sm' isIconOnly variant='light'>
                <TbDotsVertical />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label='Меню выбора действий с файлом'>
              <DropdownItem
                key='download'
                onPress={() => {
                  const fileURL = URL.createObjectURL(file)
                  createLinkAndDownloadFile(fileURL, file.name)
                }}
              >
                Скачать
              </DropdownItem>
              <DropdownItem
                key='rename'
                onPress={() => {
                  setIsOpenRenameFileModal()
                }}
              >
                Переименовать
              </DropdownItem>
              <DropdownItem
                key='delete'
                onPress={() => {
                  onOpenDeleteFileModal()
                }}
              >
                Удалить
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <RenameFileModal
        onClose={onCloseRenameFileModal}
        isOpen={isOpenRenameFileModal}
        setIsOpen={setIsOpenRenameFileModal}
        defaultValue={file.name}
        onSaveAction={newName => {
          setFiles(prev =>
            prev
              ? prev.map((f, i) => {
                  if (index === i) {
                    return new File(
                      [f],
                      newName + '.' + getFileExtension(f.name),
                      {
                        type: f.type,
                      },
                    )
                  }
                  return f
                })
              : [],
          )
        }}
      />
      <DeleteModal
        onClose={onCloseDeleteFileModal}
        isOpen={isOpenDeleteFileModal}
        onOpenChange={onOpenDeleteFileModal}
        deleteAction={() => {
          setFiles(prev => (prev ? prev.filter((f, i) => i !== index) : []))
          onCloseDeleteFileModal()
        }}
        message=' Удалить файл?'
      />
    </>
  )
}
