import { useCommentAddButton } from '@udecode/plate-comments'
import React from 'react'

import { Icons } from '../icons'
import { ToolbarButton } from './toolbar'

export function CommentToolbarButton() {
  const { hidden, props } = useCommentAddButton()

  if (hidden) return null

  return (
    <ToolbarButton {...props}>
      <Icons.commentAdd />
    </ToolbarButton>
  )
}
