export function getSocialNetworkName(url: string): string | null {
  // Проверка на соответствие доменам различных социальных сетей
  if (/ok\.ru/.test(url)) {
    return 'ok'
  } else if (/vk\.com/.test(url)) {
    return 'vk'
  } else if (/t\.me/.test(url) || /telegram\.org/.test(url)) {
    return 'tg'
  } else if (/instagram\.com/.test(url)) {
    return 'instagram'
  } else if (/facebook\.com/.test(url)) {
    return 'fb'
  } else if (/youtube\.com/.test(url)) {
    return 'yt'
  } else if (/rutube\.ru/.test(url)) {
    return 'rt'
  } else if (/dzen\.ru/.test(url)) {
    return 'dzen'
  } else if (/twitter\.com/.test(url)) {
    return 'tw'
  } else {
    return null
  }
}
