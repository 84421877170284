import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getPrettyDate } from '../../../utils/getPrettyDate'
import { Items } from './types'

interface Props extends PropsWithChildren {
  date: Dayjs
  isNotCurrentMonth: boolean
  items?: Items
}

export const MonthItem = ({ date, isNotCurrentMonth, items }: Props) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const navigate = useNavigate()
  const itemList = useRef<HTMLDivElement | null>(null)
  const container = useRef<HTMLDivElement | null>(null)
  const [isShowMoreButton, setIsShowMoreButton] = useState(false)
  const [sliceCount, setSliceCount] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (itemList.current && container.current) {
      const listHeight = itemList.current.getBoundingClientRect().height
      const containerHeight = container.current.getBoundingClientRect().height
      if (listHeight > 0) {
        if (listHeight > containerHeight - 24 - 8) {
          setSliceCount(Math.floor((containerHeight - 24 - 8 - 20) / 24))
          setIsShowMoreButton(true)
        }
      }
    }
  }, [items])

  return (
    <div
      ref={container}
      onClick={onOpen}
      className={clsx(
        'bg-background border-default-200 cursor-pointer overflow-hidden rounded-lg border p-1',
        {
          'bg-default-50': isNotCurrentMonth,
          'bg-primary-50 border-primary-100':
            dayjs(date).day() === 0 || dayjs(date).day() === 6,
        },
      )}
    >
      <div>
        <div
          className={clsx(
            'flex h-6 w-6 items-center justify-center rounded-lg p-2',
            {
              'text-background bg-primary text-small': dayjs(date).isToday(),
            },
          )}
        >
          {dayjs(date).date()}
        </div>
        <div className='space-y-1' ref={itemList}>
          {items?.slice(0, sliceCount).map(item => (
            <div
              key={item.id}
              className='text-small bg-default-200 hover:bg-default-300 line-clamp-1 cursor-pointer rounded-lg px-1 transition-all hover:shadow'
              onClick={() => navigate(item.link)}
            >
              {item.title}
            </div>
          ))}
        </div>
        {isShowMoreButton && sliceCount && (
          <div
            className='text-default-400 hover:text-default-600 cursor-pointer text-xs transition-all'
            onClick={onOpen}
          >
            Еще {items && items.length - sliceCount}
          </div>
        )}
      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                {getPrettyDate({
                  date,
                  template: 'DD MMMM YYYY',
                })}
              </ModalHeader>
              <ModalBody>
                <div className='flex flex-col space-y-2'>
                  {items?.map((item, index) => (
                    <div className='flex  items-center'>
                      {index + 1}.
                      <Link key={item.id} isBlock href={item.link}>
                        {item.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color='danger' variant='light' onPress={onClose}>
                  Закрыть
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}
