import {
  CollectionInterface,
  FileInterface,
  NotifyMomentEnum,
  TaskInterface,
  TaskPriorityEnum,
  TaskStatusEnum,
  UserInterface,
} from '@hydra/interfaces'
import { TElement } from '@udecode/plate-common'
import { AxiosResponse } from 'axios'
import { SortDirection } from 'typeorm'

import { instance } from '../../../features/api'

export const getTasks = async (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof TaskInterface
}): Promise<AxiosResponse<[Array<TaskInterface>, number]>> => {
  return instance.get('/tasks/all-tasks', {
    params,
  })
}

export const getTasksByExecutors = async (params: {
  skip: number
  take: number
}): Promise<AxiosResponse<[Array<UserInterface>, number]>> => {
  return instance.get('/tasks/all-tasks-by-executors', {
    params,
  })
}

export const getTasksByCollections = async (params: {
  skip: number
  take: number
}): Promise<AxiosResponse<[Array<CollectionInterface>, number]>> => {
  return instance.get('/tasks/all-tasks-by-collections', {
    params,
  })
}

export const getTaskById = (
  id: string,
): Promise<
  AxiosResponse<{ current: TaskInterface; next: string; prev: string }>
> => {
  return instance.get(`/tasks/task-by-id/${id}`, {})
}

export const getTasksByDate = (params: {
  dayStart: Date
  dayEnd: Date
}): Promise<AxiosResponse<Array<TaskInterface>>> => {
  return instance.get('/tasks/all-tasks-by-date', { params })
}

export const createComment = (data: { value: string; taskId: string }) => {
  return instance.post('/tasks/create-task-comment', data)
}

export const createTaskFile = (data: {
  title: string
  link: string
  taskId?: string
}): Promise<AxiosResponse<string>> => {
  return instance.post('/tasks/create-task-file', data)
}

export const createTask = (data: {
  title: string
  description?: Array<TElement>
  collection?: { id: string }
  executor?: { id: string }
  status?: TaskStatusEnum
  priority?: TaskPriorityEnum
  date?: string
  time?: string
  isNotify: boolean
  notifyMoment?: NotifyMomentEnum
  participants?: Array<string>
  files: Array<Pick<FileInterface, 'id'>> | null
}) => {
  return instance.post('/tasks/create-task', data)
}

export const updateTask = (
  id: string,
  data: {
    title?: string
    description?: Array<TElement>
    collection?: { id: string }
    author?: { id: string }
    executor?: { id: string }
    status?: TaskStatusEnum
    priority?: TaskPriorityEnum
    date?: string
    time?: string
    isNotify?: boolean
    notifyMoment?: NotifyMomentEnum
    participants?: Array<string>
    files?: Array<Pick<FileInterface, 'id'>> | null
  },
) => {
  return instance.patch(`/tasks/update-task/${id}`, data)
}

export const deleteTaskFile = (id: string) => {
  return instance.delete(`/tasks/delete-task-file/${id}`)
}

export const deleteTask = (id: string) => {
  return instance.delete(`/tasks/delete-task/${id}`)
}
