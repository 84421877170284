import {
  Button,
  Divider,
  Progress,
  Tooltip,
  useDisclosure,
} from '@nextui-org/react'
import { clsx } from 'clsx'
import { useAtom } from 'jotai/index'
import plural from 'plural-ru'
import { useCallback } from 'react'
import {
  TbCircleArrowDownFilled,
  TbCircleArrowUpFilled,
  TbPlus,
  TbX,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { routesConst } from '../../../features/constants/routes.const'
import { useMultiplier } from '../../../features/hooks/useMultiplier'
import {
  missedNewsbreaksAtom,
  organizationIdAtom,
  organizationRatingAtom,
} from '../../../features/store'
import { Empty, PageSpinner } from '../../../features/ui'
import { PeriodSelects } from '../../../features/ui/components/PeriodSelects'
import { CreateMissedNewsbreakModal } from './CreateMissedNewsbreakModal'
import { MissedNewsbreakItem } from './MissedNewsbreakItem'
import { OrganizationRatingItem } from './OrganizationRatingItem'

export const OrganizationRating = () => {
  const navigate = useNavigate()
  const [organizationId] = useAtom(organizationIdAtom)
  const multiplier = useMultiplier()

  const [{ data: rating, isPending: isRatingPending }] = useAtom(
    organizationRatingAtom,
  )

  const [{ data: missedNewsbreaks, refetch: refetchMissedNewsbreaks }] =
    useAtom(missedNewsbreaksAtom)

  const {
    isOpen: isOpenCreateMissedNewsbreakModal,
    onOpen: onOpenCreateMissedNewsbreakModal,
    onOpenChange: onOpenChangeCreateMissedNewsbreakModal,
    onClose: onCloseCreateMissedNewsbreakModal,
  } = useDisclosure()

  const getChangesFromLastPeriod = useCallback(
    (current: number, last: number): { isMore: boolean; value: string } => {
      if (last === 0) {
        if (current === 0) {
          // Оба значения равны нулю
          return {
            isMore: false,
            value: '0', // или любое другое значение, которое имеет смысл в вашем контексте
          }
        }
        // Предыдущее значение равно нулю, а текущее - нет
        return {
          isMore: true,
          value: '∞', // Индикатор бесконечного увеличения или любое другое значение
        }
      }

      const value = (current * 100) / last - 100
      return {
        isMore: value >= 0,
        value: value.toFixed(0),
      }
    },
    [],
  )

  if (isRatingPending) return <PageSpinner />

  if (!rating) return <Empty />

  return (
    <>
      {organizationId && (
        <CreateMissedNewsbreakModal
          organizationId={organizationId}
          isOpen={isOpenCreateMissedNewsbreakModal}
          onOpenChange={onOpenChangeCreateMissedNewsbreakModal}
          onClose={onCloseCreateMissedNewsbreakModal}
          refetch={refetchMissedNewsbreaks}
        />
      )}

      <div className='space-y-4'>
        <div className='flex items-center justify-between'>
          <PeriodSelects isDisabled={isRatingPending} />
          <Button
            onPress={() => navigate(routesConst.rating)}
            color='primary'
            endContent={<TbX className='text-2xl' />}
          >
            Закрыть
          </Button>
        </div>

        <div className='grid grid-cols-[1fr_332px] gap-x-4'>
          <div className='space-y-4'>
            <div className='space-y-4'>
              <div className='text-lg font-semibold'>Планирование</div>
              <div className='grid grid-cols-2 gap-4'>
                <OrganizationRatingItem
                  points={+rating.performance.occasionsCount.points || 0}
                  fact={rating.performance.occasionsCount.fact || 0}
                  plan={rating.performance.occasionsCount.plan || 0}
                  progress={
                    (rating.performance.occasionsCount.fact * 100) /
                    rating.performance.occasionsCount.plan
                  }
                  title='Количество инфоповодов'
                  color='primary'
                  tooltipText={
                    <div className='flex max-w-[450px] flex-col space-y-2'>
                      <div className='font-semibold'>
                        Описание расчета баллов по показателю
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Вес показателя в общем рейтинге:
                        </span>{' '}
                        15%
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Оценка показателя:
                        </span>{' '}
                        фактическое количество информационных поводов,
                        направленных на согласование организацией, умноженное на
                        100, и деленное на плановый показатель по количеству
                        информационных поводов, установленный для организации
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Расчет баллов по показателю:
                        </span>{' '}
                        оценка показателя умноженная на 0,15
                      </div>
                    </div>
                  }
                />
                <OrganizationRatingItem
                  points={+rating.performance.themeHit.points || 0}
                  fact={rating.performance.themeHit.fact || 0}
                  plan={rating.performance.themeHit.plan || 0}
                  progress={
                    (rating.performance.themeHit.fact * 100) /
                    rating.performance.themeHit.plan
                  }
                  title='Попадание в план событий'
                  color='primary'
                  planText='направленных на согласование инфоповодов'
                  tooltipText={
                    <div className='flex max-w-[450px] flex-col space-y-2'>
                      <div className='font-semibold'>
                        Описание расчета баллов по показателю
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Вес показателя в общем рейтинге:
                        </span>{' '}
                        20%
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Оценка показателя:
                        </span>{' '}
                        фактическое количество информационных поводов,
                        согласованных модератором в план событий, деленное на
                        фактическое количество информационных поводов,
                        направленных организацией на согласование в план
                        событий, умноженное на 100
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Расчет баллов по показателю:
                        </span>{' '}
                        оценка показателя умноженная на 0,2
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <div className='space-y-4'>
              <div className='text-lg font-semibold'>Отработка</div>
              <div className='grid grid-cols-2 gap-4'>
                <OrganizationRatingItem
                  points={+rating.performance.qualityRelease.points || 0}
                  fact={rating.performance.qualityRelease.fact || 0}
                  plan={rating.performance.qualityRelease.plan || 0}
                  progress={
                    (rating.performance.qualityRelease.fact * 100) /
                    rating.performance.qualityRelease.plan
                  }
                  title='Подготовка пресс-релиза'
                  color='secondary'
                  planText='инфоповодов в плане событий'
                  tooltipText={
                    <div className='flex max-w-[450px] flex-col space-y-2'>
                      <div className='font-semibold'>
                        Описание расчета баллов по показателю
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Вес показателя в общем рейтинге:
                        </span>{' '}
                        25%
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Оценка показателя:
                        </span>{' '}
                        фактическое количество информационных поводов,
                        направленных ранее в план событий, у которых в
                        последствии заполнено поле «Описание», умноженное на
                        100, деленное на фактическое количество информационных
                        поводов, согласованных в план событий
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Расчет баллов по показателю:
                        </span>{' '}
                        оценка показателя умноженная на 0,25
                      </div>
                    </div>
                  }
                />
                <OrganizationRatingItem
                  points={rating.performance.socialNetworksThemes.points || 0}
                  fact={rating.performance.socialNetworksThemes.fact || 0}
                  plan={rating.performance.socialNetworksThemes.plan || 0}
                  progress={
                    (rating.performance.socialNetworksThemes.fact * 100) /
                    rating.performance.socialNetworksThemes.plan
                  }
                  title='Темы для социальных сетей'
                  color='success'
                  planText='инфоповодов в плане событий'
                  tooltipText={
                    <div className='flex max-w-[450px] flex-col space-y-2'>
                      <div className='font-semibold'>
                        Описание расчета баллов по показателю
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Расчет баллов по показателю:
                        </span>{' '}
                        каждый информационный повод с признаком важности
                        «Фоновые события» в плане событий равен 1 баллу
                      </div>
                    </div>
                  }
                />

                <OrganizationRatingItem
                  points={+rating.performance.graphic.points || 0}
                  fact={rating.performance.graphic.fact || 0}
                  plan={rating.performance.graphic.plan || 0}
                  progress={
                    (rating.performance.graphic.fact * 100) /
                    rating.performance.graphic.plan
                  }
                  title='Федеральная повестка'
                  color='warning'
                  planText='полностью отработанных инфоповодов'
                  tooltipText={
                    <div className='flex max-w-[450px] flex-col space-y-2'>
                      <div className='font-semibold'>
                        Описание расчета баллов по показателю
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Расчет баллов по показателю:
                        </span>{' '}
                        каждый информационный повод с признаком локации
                        «Россия», направленный для отработки СМИ, равен 1 баллу
                      </div>
                    </div>
                  }
                />
                <OrganizationRatingItem
                  points={+rating.performance.producing.points || 0}
                  fact={rating.performance.producing.fact || 0}
                  plan={rating.performance.producing.plan || 0}
                  progress={
                    (rating.performance.producing.fact * 100) /
                    rating.performance.producing.plan
                  }
                  title='Продюсирование'
                  color='danger'
                  planText='полностью отработанных инфоповодов'
                  tooltipText={
                    <div className='flex max-w-[450px] flex-col space-y-2'>
                      <div className='font-semibold'>
                        Описание расчета баллов по показателю
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Вес показателя в общем рейтинге:
                        </span>{' '}
                        40%
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Оценка показателя:
                        </span>{' '}
                        количество инфоповодов направленных в работу СМИ, по
                        которым модераторами установлен признак
                        «Продюсирование», умноженный на 100, и деленный на общее
                        количество полностью отработанных инфоповодов
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Критерий установки признака «Продюсирование»:
                        </span>{' '}
                        полностью отработанный информационный повод содержит
                        пресс-релиз, фотографию(и) и (или) видео комментарий
                        спикера, контакт героя для СМИ и (или) в рамках
                        отработки организован и проведен пресс-тура,
                        пресс-конференции и т.д.
                      </div>
                      <div>
                        <span className='font-semibold'>
                          Расчет баллов по показателю:
                        </span>{' '}
                        оценка показателя умноженная на 0,4
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <div className='relative space-y-4'>
              <Tooltip
                showArrow
                placement='bottom-end'
                content={
                  <div className='space-y-2'>
                    <div className='font-semibold'>
                      Прирост баллов в процентах по сравнению с прошлым
                      периодом.
                    </div>
                    <div>
                      <div>Текущий период: {rating.totalPoints.points}</div>
                      <div>Прошлый период: {rating.totalPoints.last}</div>
                    </div>
                  </div>
                }
              >
                <div
                  className={clsx(
                    'text-primary items-centers absolute right-4 top-[75%]  flex space-x-1 text-lg font-semibold',
                    {
                      '!text-danger': !getChangesFromLastPeriod(
                        rating.totalPoints.points,
                        100,
                      ).isMore,
                    },
                  )}
                >
                  <span className='flex items-center justify-center'>
                    {getChangesFromLastPeriod(
                      rating.totalPoints.points,
                      rating.totalPoints.last,
                    ).isMore ? (
                      <TbCircleArrowUpFilled className='text-2xl' />
                    ) : (
                      <TbCircleArrowDownFilled className='text-2xl' />
                    )}
                  </span>
                  <span>
                    {
                      getChangesFromLastPeriod(
                        rating.totalPoints.points,
                        rating.totalPoints.last,
                      ).value
                    }
                    %
                  </span>
                </div>
              </Tooltip>
              <div className='text-lg font-semibold'>Итого</div>
              <div className='bg-background flex space-x-3 rounded-2xl px-4 py-8 shadow'>
                <div className='flex flex-col items-center justify-center space-y-2'>
                  <div className='text-6xl font-semibold'>
                    {rating.totalPoints.points}
                  </div>
                  <div className='text-default-400 text-xs'>
                    {plural(
                      rating.totalPoints.points,
                      'балл',
                      'балла',
                      'баллов',
                    )}
                  </div>
                </div>
                <Divider orientation='vertical' className='h-[initial]' />
                <Progress
                  aria-label='Итого'
                  value={(rating.totalPoints.points * 100) / (100 * multiplier)}
                  className='w-full justify-center'
                />
              </div>
            </div>
          </div>
          <div className='bg-background mt-[44px] space-y-4 overflow-hidden rounded-2xl p-4 shadow '>
            <div className='flex items-center justify-between'>
              <span className='font-semibold'>
                Инфоповоды вне темника ({missedNewsbreaks?.length || '0'})
              </span>
              <Button
                isIconOnly
                color='primary'
                size='sm'
                onPress={onOpenCreateMissedNewsbreakModal}
              >
                <TbPlus className='text-xl' />
              </Button>
            </div>
            <Divider />
            <SimpleBar className='h-[583px]'>
              {missedNewsbreaks && (
                <div className='space-y-1'>
                  {missedNewsbreaks.map(n => (
                    <MissedNewsbreakItem key={n.id} missedNewsbreak={n} />
                  ))}
                </div>
              )}
            </SimpleBar>
          </div>
        </div>
      </div>
    </>
  )
}
