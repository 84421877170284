import { LocationInterface } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from '../../../features/api'

export const getAllImportances = (): Promise<
  AxiosResponse<[Array<LocationInterface>, number]>
> => {
  return instance.get('/importances/all')
}

export const updateImportance = (
  id: string,
  data: {
    title?: string
  },
) => {
  return instance.patch(`/importances/update/${id}`, data)
}

export const createImportance = (data: { title: string }) => {
  return instance.post('/importances/create', { ...data, significance: 10 })
}
