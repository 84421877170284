import { Button, ScrollShadow } from '@nextui-org/react'
import { Helmet } from 'react-helmet'
import { TbSquareRoundedPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Header, Layout } from '../../../features/ui'
import { CollectionsPagination } from '../components/CollectionsPagination'
import { CollectionsTable } from '../components/CollectionsTable'
import { menuItems } from '../constants/menuItems'

const pageTitle = 'Проекты'

export const Collections = () => {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <div>
                <Button
                  color='primary'
                  startContent={<TbSquareRoundedPlus className='text-2xl' />}
                  onPress={() => navigate(routesConst.createCollection)}
                >
                  Создать
                </Button>
              </div>
            }
          />
        }
      >
        <div className='space-y-4 overflow-hidden p-4'>
          <div className='flex space-x-4'>
            <ScrollShadow
              size={0}
              className='h-[calc(100vh-156px)] flex-1 overflow-auto'
            >
              <CollectionsTable />
            </ScrollShadow>
          </div>
          <CollectionsPagination />
        </div>
      </Layout>
    </>
  )
}
