import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { TbSquareRoundedPlus } from 'react-icons/tb'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { Header, Layout } from '../../../features/ui'
import { createTag } from '../api/tags.api'
import { TagsList } from '../components/TagsList'
import { menuItems } from '../constants/menuItems'
import { createTagSchema } from '../schemas/createTagSchema'
import { tagsAtom } from '../store/tags.store'

const pageTitle = 'Тэги'

type Form = z.infer<typeof createTagSchema>

export const Tags = () => {
  const [{ refetch }] = useAtom(tagsAtom)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const form = useForm<Form>({
    defaultValues: {
      title: '',
    },
    resolver: zodResolver(createTagSchema),
  })

  const onSubmit = useCallback(
    async (formData: Form) => {
      setIsFormLoading(true)
      try {
        const response = await createTag(formData)
        if (response.status && response.status === 201) {
          toast.success('Тэг успешно создан')
          form.reset()
          onClose()
          await refetch()
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [form, onClose, refetch],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <div>
                <Button
                  color='primary'
                  startContent={<TbSquareRoundedPlus className='text-2xl' />}
                  onPress={onOpen}
                >
                  Создать тэг
                </Button>
              </div>
            }
          />
        }
      >
        <div className='space-y-4 p-4'>
          <TagsList />
        </div>
      </Layout>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Создать новую папку
              </ModalHeader>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <ModalBody>
                  <Controller
                    control={form.control}
                    name='title'
                    render={({ field: { onChange, value } }) => (
                      <Input
                        isDisabled={isFormLoading}
                        variant='bordered'
                        label='Название тэга'
                        color='primary'
                        onValueChange={onChange}
                        value={value}
                        isInvalid={!!form.formState.errors.title}
                        errorMessage={form.formState.errors.title?.message}
                      />
                    )}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color='danger'
                    type='button'
                    variant='light'
                    onPress={onClose}
                  >
                    Закрыть
                  </Button>
                  <Button color='primary' type='submit'>
                    Создать
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
