import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input } from '@nextui-org/react'
import { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { IoCloseCircle } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { routesConst } from '../../../features/constants/routes.const'
import { Header, Layout } from '../../../features/ui'
import { createGroupPublic } from '../api/group.api'
import { menuItems } from '../constants/menuItems'
import { createPublicGroupSchema } from '../schemas/createPublicGroup.schema'

type Form = z.infer<typeof createPublicGroupSchema>

export const CreatePublicGroup = ({ isEdit }: { isEdit?: boolean }) => {
  const navigate = useNavigate()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const form = useForm<Form>({
    resolver: zodResolver(createPublicGroupSchema),
  })

  const pageTitle = useMemo(
    () =>
      isEdit ? 'Редактирование группы пабликов' : 'Созадние группы пабликов',
    [isEdit],
  )

  const onSubmit = useCallback(
    async (formData: Form) => {
      setIsFormLoading(true)
      try {
        const response = await createGroupPublic(formData)

        if (response.status && response.status === 201) {
          toast.success('Группа пабликов успешно создана')
          navigate(routesConst.publics)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [navigate],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        <form
          className='bg-background m-4 flex h-[calc(100vh-104px)] flex-col rounded-2xl p-4 shadow'
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className='flex-1 space-y-4'>
            <div className='flex justify-between'>
              <span className='text-lg font-semibold'>Основная информация</span>
              <Button
                onPress={() => {
                  form.reset()
                  navigate(-1)
                }}
                size='sm'
                variant='flat'
                color='primary'
                endContent={<IoCloseCircle className='text-xl' />}
              >
                Закрыть
              </Button>
            </div>
            <div className=''>
              <Controller
                control={form.control}
                name='title'
                render={({ field: { onChange, value } }) => (
                  <Input
                    isDisabled={isFormLoading}
                    variant='bordered'
                    size='sm'
                    label='Название'
                    color='primary'
                    onValueChange={onChange}
                    value={value}
                    isInvalid={!!form.formState.errors.title}
                    errorMessage={form.formState.errors.title?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className='flex justify-end'>
            <Button color='primary' type='submit'>
              Сохранить
            </Button>
          </div>
        </form>
      </Layout>
    </>
  )
}
