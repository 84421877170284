import { zodResolver } from '@hookform/resolvers/zod'
import { NewsbreakStatusEnum } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
} from '@nextui-org/react'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { updateNewsbreak } from '../api/newsbreaks.api'
import { planActionModalSchema } from '../schemas/planActionModal.schema'
import { allImportancesAtom, newsbreakAtom } from '../store/newsbreaks.store'

interface Props {
  onCloseModal: () => void
}

interface Form {
  deadline: Date | null
  importance: { id: string; title: string }
}

export const PlanActionModal = ({ onCloseModal }: Props) => {
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [{ refetch, data: newsbreak }] = useAtom(newsbreakAtom)

  const [{ data: importances, isPending: isPendingImportances }] =
    useAtom(allImportancesAtom)

  const form = useForm<Form>({
    defaultValues: {
      importance: undefined,
      deadline: newsbreak?.current.deadline,
    },
    resolver: zodResolver(planActionModalSchema),
  })

  const onSaveButtonPress = async (data: Form) => {
    if (!newsbreak) return null
    setIsFormLoading(true)
    try {
      const response = await updateNewsbreak(newsbreak.current.id, {
        ...data,
        deadline: dayjs(data.deadline).toDate(),
        status: NewsbreakStatusEnum.plan,
      })
      if (response.status && response.status === 200) {
        toast.success('Инфоповод успешно обновлен')
        form.reset()
        await refetch()
        onCloseModal()
      }
    } catch (e) {
      toast.error('Что то пошло не так')
    } finally {
      setIsFormLoading(false)
    }
  }

  return (
    <div className='space-y-4'>
      <div className='grid grid-cols-[130px,1fr] items-center gap-x-2 gap-y-4'>
        <div className='text-small text-default-400'>
          {newsbreak?.current.isUrgent ? 'Срок исполнения' : 'Дата события'}
        </div>

        <Controller
          control={form.control}
          name='deadline'
          render={({ field: { onChange, value } }) => (
            <div className='flex flex-col space-y-2'>
              <Input
                id='deadline'
                isDisabled={isFormLoading}
                type={newsbreak?.current.isAllDay ? 'date' : 'datetime-local'}
                variant='bordered'
                defaultValue={dayjs(value).format(
                  newsbreak?.current.isAllDay
                    ? 'YYYY-MM-DD'
                    : 'YYYY-MM-DDTHH:mm',
                )}
                onChange={onChange}
                isInvalid={!!form.formState.errors.deadline}
                errorMessage={form.formState.errors.deadline?.message}
              />
            </div>
          )}
        />
        <div className='text-small text-default-400'>Важность</div>
        <Controller
          control={form.control}
          name='importance'
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              isDisabled={isPendingImportances || isFormLoading}
              isLoading={isPendingImportances || isFormLoading}
              defaultItems={importances ? importances[0] : []}
              aria-label='Важность'
              placeholder='Выберите важность'
              variant='bordered'
              onSelectionChange={onChange}
              allowsCustomValue
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              onKeyDown={e => e.continuePropagation()}
              isInvalid={!!form.formState.errors.importance}
              errorMessage={form.formState.errors.importance?.message}
            >
              {item => (
                <AutocompleteItem key={item.id}>{item.title}</AutocompleteItem>
              )}
            </Autocomplete>
          )}
        />
      </div>
      <div className='flex justify-end space-x-4 pb-4'>
        <Button
          variant='light'
          color='danger'
          onClick={() => {
            form.reset()
            onCloseModal()
          }}
        >
          Отменить
        </Button>
        <Button
          variant='solid'
          color='primary'
          onClick={form.handleSubmit(onSaveButtonPress)}
        >
          Согласовать
        </Button>
      </div>
    </div>
  )
}
