import { TranscriptionsInterface } from '@hydra/interfaces'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { cleanText } from '../../../features/utils/cleanText'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { transcriptionsAtom } from '../store/transcription.store'

export const TranscriptionsList = () => {
  const navigate = useNavigate()
  const [{ data, isPending }] = useAtom(transcriptionsAtom)

  const renderCell = useCallback(
    (item: TranscriptionsInterface, columnKey: Key) => {
      if (columnKey === 'author') {
        return (
          <div>
            {item.author?.name} {item.author?.surname}
          </div>
        )
      }
      if (columnKey === 'createdAt') {
        return (
          <div>
            {getPrettyDate({
              date: item.createdAt,
            })}
          </div>
        )
      }
      if (columnKey === 'content') {
        return (
          <div className='line-clamp-2 break-all'>
            {cleanText(item.content)}
          </div>
        )
      }
    },
    [],
  )

  if (isPending) return <PageSpinner />

  if (!data || data[0].length === 0) return <Empty />

  return (
    <Table
      aria-label='Транскрибации'
      isHeaderSticky
      isStriped
      classNames={{
        wrapper: 'h-[calc(100vh-156px)] overflow-auto',
      }}
      onRowAction={key => {
        navigate(routesConst.transcriptions + '/' + key)
      }}
    >
      <TableHeader>
        <TableColumn key='author'>Пользователь</TableColumn>
        <TableColumn key='createdAt'>Дата создания</TableColumn>
        <TableColumn key='content'>Контент</TableColumn>
      </TableHeader>
      <TableBody
        items={data[0]}
        emptyContent={
          <div className='text-default-400 text-sm'>Нет данных</div>
        }
      >
        {item => (
          <TableRow key={item.id}>
            {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
