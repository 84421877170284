import { z } from 'zod'

export const createTagSchema = z.object({
  title: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, {
      message: 'Минимальное количество символов 2',
    })
    .max(250, {
      message: 'Максимальное количество символов 250',
    })
    .trim(),
})
