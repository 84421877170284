import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from '@udecode/plate-basic-marks'
import { useEditorReadOnly } from '@udecode/plate-common'
import React from 'react'

import { Icons } from '../icons'
import { CommentToolbarButton } from './comment-toolbar-button'
import { LinkToolbarButton } from './link-toolbar-button'
import { MarkToolbarButton } from './mark-toolbar-button'
import { TurnIntoDropdownMenu } from './turn-into-dropdown-menu'

interface Props {
  withComments: boolean
}

export function FloatingToolbarButtons({ withComments }: Props) {
  const readOnly = useEditorReadOnly()

  return (
    <>
      {!readOnly && (
        <>
          <TurnIntoDropdownMenu />
          <MarkToolbarButton nodeType={MARK_BOLD}>
            <Icons.bold />
          </MarkToolbarButton>
          <MarkToolbarButton nodeType={MARK_ITALIC}>
            <Icons.italic />
          </MarkToolbarButton>
          <MarkToolbarButton nodeType={MARK_UNDERLINE}>
            <Icons.underline />
          </MarkToolbarButton>
          <MarkToolbarButton nodeType={MARK_STRIKETHROUGH}>
            <Icons.strikethrough />
          </MarkToolbarButton>
          <LinkToolbarButton />
        </>
      )}

      {withComments && <CommentToolbarButton />}
    </>
  )
}
