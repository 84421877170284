import { Button, Chip, Input, Link } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { TbSend } from 'react-icons/tb'

import { routesConst } from '../../../features/constants/routes.const'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { createRiskComment } from '../api/risk.api'
import { riskAtom } from '../store/risk.store'

export const Comments = () => {
  const [{ data: risk, refetch }] = useAtom(riskAtom)
  const [newComment, setNewComment] = useState('')

  const onSendButtonPress = useCallback(async () => {
    if (!risk) return

    await createRiskComment({
      value: newComment,
      riskId: risk.id,
    })
    setNewComment('')
    await refetch()
  }, [risk, newComment, refetch])

  return (
    <div className='space-y-2'>
      {risk?.comments?.map(comment => (
        <div
          key={comment.id}
          className='bg-primary-50 space-y-4 rounded-xl p-4'
        >
          <div className='flex items-center justify-between'>
            <Link href={routesConst.users + '/' + comment.author.id}>
              <Chip size='sm' variant='bordered' color='primary'>
                {comment.author.name} {comment.author.surname}
              </Chip>
            </Link>
            <div className='text-primary-400 text-xs'>
              {getPrettyDate({
                date: comment.createdAt,
                template: 'DD MMMM YYYY, HH:mm',
              })}
            </div>
          </div>
          <div className='text-default-600 text-small'>{comment.value}</div>
        </div>
      ))}
      <div className='flex items-center space-x-2'>
        <Input
          variant='bordered'
          placeholder='Комментировать'
          value={newComment}
          onValueChange={setNewComment}
        />
        <Button
          variant='bordered'
          color='primary'
          isIconOnly
          onPress={onSendButtonPress}
        >
          <TbSend className='text-xl' />
        </Button>
      </div>
    </div>
  )
}
