import { useAtom } from 'jotai/index'

import { PeriodDatePicker } from '../../../features/ui'
import {
  analyticsPeriodAtom,
  newsbreaksAnalyticsAtom,
} from '../store/newsbreaks.store'

export const AnalyticsPeriod = () => {
  const [dateValue, setDateValue] = useAtom(analyticsPeriodAtom)
  const [{ isPending }] = useAtom(newsbreaksAnalyticsAtom)

  return (
    <PeriodDatePicker
      dateValue={dateValue}
      setDateValue={setDateValue}
      isPending={isPending}
    />
  )
}
