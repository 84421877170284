import { AxiosResponse } from 'axios'

import { instance } from '../../features/api'

export const login = async (data: {
  login: string
  password: string
}): Promise<AxiosResponse<{ accessToken: string }>> => {
  return instance.post('/users/login', data)
}

export const loginTelegram = async (data: {
  login: string
}): Promise<AxiosResponse<{ accessToken: string }>> => {
  return instance.post('/users/login-telegram', data)
}

export const sendOTP = (data: { login: string }) => {
  return instance.post('/users/sendotp', data)
}
