import { CollectionInterface, FileInterface } from '@hydra/interfaces'
import { TElement } from '@udecode/plate-common'
import { AxiosResponse } from 'axios'
import { SortDirection } from 'typeorm'

import { instance } from '../../../features/api'

export const getTasksCollections = async (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof CollectionInterface
}): Promise<AxiosResponse<[Array<CollectionInterface>, number]>> => {
  return instance.get('/tasks/all-collections', {
    params,
  })
}

export const getCollectionById = (
  id: string,
): Promise<
  AxiosResponse<{ current: CollectionInterface; next: string; prev: string }>
> => {
  return instance.get(`/tasks/collection-by-id/${id}`, {})
}

export const createCollectionFile = (data: {
  title: string
  link: string
  collectionId?: string
}): Promise<AxiosResponse<string>> => {
  return instance.post('/tasks/create-collection-file', data)
}

export const createCollection = (data: {
  title: string
  responsible?: { id: string }
  description?: Array<TElement>
  files: Array<Pick<FileInterface, 'id'>> | null
}) => {
  return instance.post('/tasks/create-collection', data)
}

export const updateCollection = (
  id: string,
  data: {
    title?: string
    description?: Array<TElement>
    author?: { id: string }
    responsible?: { id: string }
    files?: Array<Pick<FileInterface, 'id'>>
  },
) => {
  return instance.patch(`/tasks/update-collection/${id}`, data)
}

export const deleteCollectionFile = (id: string) => {
  return instance.delete(`/tasks/delete-collection-file/${id}`)
}

export const deleteCollection = (id: string) => {
  return instance.delete(`/tasks/delete-collection/${id}`)
}
