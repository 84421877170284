import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import { useAtomValue } from 'jotai/index'
import { FormEvent, useCallback } from 'react'
import { toast } from 'react-toastify'

import { updateChat } from '../api/chat.api'
import { chatAtom } from '../store/chat.store'

interface Props {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onClose: () => void
  defaultValue: string
}

export const RenameChatModal = ({
  isOpen,
  onOpenChange,
  defaultValue,
  onClose,
}: Props) => {
  const { data: chat, refetch } = useAtomValue(chatAtom)

  const handleRenameChat = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const form = event.target as HTMLFormElement
      const titleInput = form.elements.namedItem('title') as HTMLInputElement

      if (chat && titleInput && titleInput.value) {
        try {
          await updateChat(chat.id, { title: titleInput.value })
          onClose()
          toast.success('Название чата успешно изменено')
        } catch (error) {
          toast.error('Не удалось переименовать чат, попробуйте позже')
          console.error(error)
        } finally {
          await refetch()
        }
      }
    },
    [chat, onClose, refetch],
  )

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {onClose => (
          <form onSubmit={event => handleRenameChat(event)}>
            <ModalHeader className='flex flex-col gap-1'>
              Переименовать чат
            </ModalHeader>

            <ModalBody>
              <Input
                variant='bordered'
                color='primary'
                name='title'
                label='Название'
                defaultValue={defaultValue}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                type='button'
                color='danger'
                variant='light'
                onPress={onClose}
              >
                Закрыть
              </Button>
              <Button type='submit' color='primary'>
                Сохранить
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  )
}
