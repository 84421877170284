import { Grant, LinkFormatTypeEnum } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Select,
  SelectItem,
  Textarea,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { IoCloseCircle } from 'react-icons/io5'
import { TbMinus } from 'react-icons/tb'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { linkFormatConst } from '../../../features/constants/linkFormat.const'
import { Grants } from '../../../features/grants'
import { Header, Layout } from '../../../features/ui'
import { extractUrls } from '../../../features/utils/extractUrls'
import {
  createReport,
  createReportLink,
  deleteReportLink,
  updateReport,
  updateReportLink,
} from '../api/reports.api'
import { menuItems } from '../constants/menuItems'
import { allThemesAtom } from '../store/newsbreaks.store'

interface Props {
  isEdit?: boolean
}

export const CreateReport = ({ isEdit }: Props) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [{ data: themes, isPending: isPendingThemes }] = useAtom(allThemesAtom)

  const form = useForm<{
    title: string
    theme: string
    fields?: Array<{
      value: {
        id: string
        format: string
        link: string
      }
    }>
  }>({
    defaultValues: {
      title: searchParams.get('title') || '',
      theme: searchParams.get('theme') || '',
      fields: searchParams.get('links')
        ? JSON.parse(searchParams.get('links') || '').map(
            (link: {
              format: LinkFormatTypeEnum
              url: string
              id: string
            }) => ({
              value: {
                id: link.id,
                format: link.format,
                link: link.url,
              },
            }),
          )
        : [],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'fields',
  })

  const handleAddFiled = useCallback(() => {
    append({
      value: {
        id: '',
        link: '',
        format: '',
      },
    })
  }, [append])

  const onSubmit = async (formData: {
    title: string
    theme: string
    fields?: Array<{
      value: {
        id: string
        format: string
        link: string
      }
    }>
  }) => {
    if (!formData.theme) return toast.error('Выберите тему')

    if (!formData.title) return toast.error('Заполните заголовок')

    if (!formData.fields?.length)
      return toast.error('Добавьте хотя бы одну ссылку')

    const linkChecker = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/,
    )
    const isNotEmpty = formData.fields?.find(
      item => item.value.link === '' && item.value.format === '',
    )
    const isValidLinks = formData.fields?.reduce(
      (_acc: boolean, item) => linkChecker.test(item.value.link),
      false,
    )

    if (isNotEmpty) return toast.error('Перед сохранением удалите пустые поля')

    if (!isValidLinks)
      return toast.error(
        'Ссылка не верного формата, корректный вид ссылки -  https://example.com',
      )

    if (isEdit) {
      const id = searchParams.get('id')
      if (!id) {
        return null
      }
      try {
        setIsLoading(true)
        await Promise.allSettled([
          ...formData.fields
            .filter(field => field.value.id !== '')
            .map((field, index) => {
              return updateReportLink(field.value.id, {
                format: field.value.format,
                url: field.value.link,
              })
            }),
          ...formData.fields
            .filter(field => field.value.id === '')
            .map((field, index) => {
              return createReportLink({
                format: field.value.format,
                url: field.value.link,
                report: {
                  id,
                },
              })
            }),
          updateReport(id, {
            title: formData.title,
            theme: {
              id: formData.theme,
            },
          }),
        ])
        toast.success('Отчет успешно добавлен')
        form.reset()
        navigate(-1)
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
      return
    }

    try {
      setIsLoading(true)

      const createdLinks: Array<{ format: string; url: string }> = []

      formData.fields.forEach(field => {
        const links = extractUrls(field.value.link)

        links.forEach(link => {
          createdLinks.push({
            format: field.value.format,
            url: link,
          })
        })
      })

      const links = await Promise.allSettled(
        createdLinks.map(({ format, url }) => {
          return createReportLink({
            format,
            url,
          })
        }),
      )

      const response = await createReport({
        title: formData.title,
        theme: { id: formData.theme },
        links: links
          .filter(link => link.status === 'fulfilled')
          .map(link => {
            return {
              id: link.status === 'fulfilled' ? link.value.data : '',
            }
          }),
      })
      if (response.status && response.status === 201) {
        toast.success('Отчет успешно добавлен')
        form.reset()
        fields.forEach((_field, index) => remove(index))
      }
    } catch (e) {
      toast.error('Что то пошло не так')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>Гидра | {isEdit ? 'Редактирование' : 'Создание'} отчета</title>
      </Helmet>
      <Grants grants={[Grant.newsbreaksEditor, Grant.superadmin]}>
        <Layout
          menuItems={menuItems}
          headerContent={
            <Header text={`${isEdit ? 'Редактирование' : 'Создание'} отчета`} />
          }
        >
          <div className='p-4'>
            <div className='bg-background space-y-4 rounded-xl p-4 shadow'>
              <div className='flex items-center justify-between'>
                <div className='text-lg font-semibold'>Основная информация</div>
                <div>
                  <Button
                    onPress={() => {
                      navigate(-1)
                    }}
                    size='sm'
                    variant='flat'
                    color='primary'
                    endContent={<IoCloseCircle className='text-xl' />}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
              <div className='space-y-4'>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className='flex flex-col space-y-4'
                >
                  {' '}
                  <Controller
                    control={form.control}
                    name='theme'
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        isInvalid={!!form.formState.errors.theme}
                        errorMessage={form.formState.errors.theme?.message}
                        isDisabled={isLoading}
                        aria-label='тема'
                        isLoading={isPendingThemes || isLoading}
                        variant='bordered'
                        defaultItems={themes ? themes[0] : []}
                        placeholder='Выберите тему'
                        onSelectionChange={onChange}
                        defaultSelectedKey={value}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        onKeyDown={e => e.continuePropagation()}
                      >
                        {item => (
                          <AutocompleteItem key={item.id}>
                            {item.title}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    )}
                  />
                  <Controller
                    control={form.control}
                    name='title'
                    render={({ field: { onChange, value } }) => (
                      <Input
                        isDisabled={isLoading}
                        aria-label='Заголовок'
                        placeholder='Заголовок'
                        variant='bordered'
                        fullWidth
                        onValueChange={onChange}
                        defaultValue={value}
                      />
                    )}
                  />
                  {fields.map((field, index) => (
                    <div className='flex space-x-2' key={field.id}>
                      <div className='font-semibold'>{index + 1}.</div>
                      <div className='flex-1 space-y-2'>
                        <Controller
                          control={form.control}
                          name={`fields.${index}.value.format`}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isDisabled={isLoading}
                              isLoading={isLoading}
                              fullWidth
                              aria-label='Формат контента'
                              placeholder='Выберите формат контента'
                              variant='bordered'
                              items={Object.entries(linkFormatConst).map(
                                item => ({
                                  label: item[1],
                                  value: item[0],
                                }),
                              )}
                              onChange={onChange}
                              defaultSelectedKeys={[value]}
                            >
                              {item => (
                                <SelectItem key={item.value}>
                                  {item.label}
                                </SelectItem>
                              )}
                            </Select>
                          )}
                        />
                        <Controller
                          control={form.control}
                          name={`fields.${index}.value.link`}
                          render={({ field: { onChange, value } }) => (
                            <Textarea
                              isDisabled={isLoading}
                              aria-label='Формат контента'
                              placeholder='Ссылка на публикацию'
                              variant='bordered'
                              fullWidth
                              onValueChange={onChange}
                              defaultValue={value}
                            />
                          )}
                        />
                      </div>
                      <Button
                        variant='bordered'
                        size='sm'
                        isIconOnly
                        color='danger'
                        onClick={async () => {
                          if (isEdit) {
                            await deleteReportLink(field.value.id)
                            remove(index)
                          } else remove(index)
                        }}
                      >
                        <TbMinus className='text-xl' />
                      </Button>
                    </div>
                  ))}
                  <div className='flex justify-end'>
                    <Button
                      color='primary'
                      variant='light'
                      size='sm'
                      onPress={handleAddFiled}
                    >
                      Добавить ссылку
                    </Button>
                  </div>
                  <div className='flex justify-end'>
                    <Button
                      color='primary'
                      type='submit'
                      isDisabled={isLoading}
                      isLoading={isLoading}
                    >
                      {isEdit ? 'Сохранить' : 'Создать'}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Layout>
      </Grants>
    </>
  )
}
