export const audioExtensions = {
  'audio/mpeg': [],
  'audio/wav': [],
  'audio/ogg': [],
  'audio/aac': [],
  'audio/midi': [],
  'audio/webm': [],
}

export const videoExtensions = {
  'video/mp4': [],
  'video/quicktime': [],
  'video/x-msvideo': [],
  'video/mpeg': [],
  'video/webm': [],
  'video/ogg': [],
}

export const imageExtensions = {
  'image/jpeg': [],
  'image/png': [],
  'image/gif': [],
  'image/bmp': [],
  'image/webp': [],
}

export const docExtensions = {
  'text/plain': [],
  'application/pdf': [],
  'application/json': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.oasis.opendocument.text': [],
  'application/vnd.oasis.opendocument.spreadsheet': [],
  'application/vnd.oasis.opendocument.presentation': [],
  'application/zip': [],
}
