import { Chip } from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { TbFileFilled, TbMailFilled } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { newsbreaksAtom } from '../store/newsbreaks.store'

export const NewsbreaksList = () => {
  const navigate = useNavigate()
  const [{ data, isPending }] = useAtom(newsbreaksAtom)

  const onNewsbreakClick = useCallback(
    (id: string) => {
      navigate(routesConst.newsbreaks + '/' + id)
    },
    [navigate],
  )

  if (isPending) return <PageSpinner />

  if (!data || data[0].length === 0) return <Empty />

  return (
    <div className='space-y-4'>
      {data[0].map(item => (
        <div
          key={item.id}
          className='bg-background cursor-pointer space-y-4 rounded-xl p-3 shadow transition-all hover:shadow-lg'
          onClick={() => {
            onNewsbreakClick(item.id)
          }}
        >
          <div className='flex items-center space-x-2'>
            <Chip color='primary' variant='faded'>
              {getPrettyDate({
                date: item.createdAt,
                template: 'DD MMMM YYYY',
              })}
            </Chip>
            <Chip color={item.isUrgent ? 'danger' : 'primary'}>
              {item.isUrgent ? 'Текущий' : 'Плановый'}
            </Chip>
            {item.deadline && (
              <Chip color='danger' variant='dot'>
                Дата события:{' '}
                {getPrettyDate({
                  date: item.deadline,
                  template: 'DD MMMM YYYY',
                })}
              </Chip>
            )}
          </div>
          <div className='flex space-x-3'>
            <div className='flex-1'>
              <div className='text-default-400 text-xs'>
                {getPrettyDate({
                  date: item.createdAt,
                  template: 'HH:mm',
                })}
              </div>
              <div className='mb-2 line-clamp-2 font-semibold'>
                {item.title}
              </div>
              <div className='flex flex-wrap gap-2'>
                {item.address && (
                  <Chip color='secondary' variant='flat'>
                    {item.address}
                  </Chip>
                )}
                {item.type && (
                  <Chip color='secondary' variant='flat'>
                    {item.type.title}
                  </Chip>
                )}
                {item.theme && (
                  <Chip color='secondary' variant='flat'>
                    {item.theme.title}
                  </Chip>
                )}
                {item.priority && (
                  <Chip color='secondary' variant='flat'>
                    {item.priority.title}
                  </Chip>
                )}
                {item.project && (
                  <Chip color='secondary' variant='flat'>
                    {item.project.title}
                  </Chip>
                )}
              </div>
            </div>
            <div>
              <div className='flex space-x-2'>
                <div className='bg-primary-50 text-primary text-small flex space-x-2 rounded-lg p-1 font-medium'>
                  <TbFileFilled className='text-xl' />
                  <span>{item.files.length}</span>
                </div>
                <div className='bg-primary-50 text-danger text-small flex space-x-2 rounded-lg p-1 font-medium'>
                  <TbMailFilled className='text-xl' />
                  <span>{item.comments.length}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
