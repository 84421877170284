import { atomWithMutation } from 'jotai-tanstack-query'

import { login, loginTelegram } from './api'
import { loginResponseSchema } from './schemas/loginResponse.schema'

export const loginAtom = atomWithMutation(() => ({
  mutationKey: ['login'],
  mutationFn: async (data: { login: string; password: string }) => {
    try {
      const res = await login(data)
      loginResponseSchema.safeParse(res.data)
      return res.data
    } catch (e) {
      throw new Error('Что то пошло не так')
    }
  },
}))

export const loginTelegramAtom = atomWithMutation(() => ({
  mutationKey: ['login'],
  mutationFn: async (data: { login: string }) => {
    try {
      const res = await loginTelegram(data)
      loginResponseSchema.safeParse(res.data)
      return res.data
    } catch (e) {
      throw new Error('Что то пошло не так')
    }
  },
}))
