import { Link, Tooltip } from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { TbTrashX } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, Header, Layout, PageSpinner } from '../../../features/ui'
import { getPrettyNumber } from '../../../features/utils/getPrettyNumber'
import { getSocialNetworkName } from '../../../features/utils/getSocialIcon'
import { updatePublic } from '../api/public.api'
import { menuItems } from '../constants/menuItems'
import { publicGroupAtom, publicGroupIdAtom } from '../store/group.store'

const pageTitle = 'Группа пабликов'

export const PublicGroup = () => {
  const [{ data, isPending, refetch }] = useAtom(publicGroupAtom)
  const { id } = useParams()
  const [, setId] = useAtom(publicGroupIdAtom)

  const deletePublic = useCallback(
    async (id: string) => {
      try {
        const response = await updatePublic(id, {
          group: null,
        })
        if (response.status && response.status === 200) {
          toast.success('Паблик успешно удален')
          await refetch()
        }
      } catch (e) {
        console.error(e)
      }
    },
    [refetch],
  )

  useEffect(() => {
    if (id) {
      setId(id)
    }
    return () => {
      setId(undefined)
    }
  }, [id, setId])

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        {isPending ? (
          <PageSpinner />
        ) : !data ? (
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <Empty />
          </div>
        ) : (
          <div className='space-y-4 p-4'>
            <div className='flex justify-between'>
              <h2 className='text-2xl font-semibold'>
                Паблики ({data.publics?.length})
              </h2>
            </div>
            <div className='grid grid-cols-1 gap-4'>
              {data.publics?.map(p => (
                <div
                  key={p.id}
                  className='bg-background relative flex flex-col space-y-2 rounded-xl p-4 shadow'
                >
                  <div
                    className='text-foreground hover:text-danger absolute -right-1.5 -top-1.5 cursor-pointer transition-colors'
                    onClick={() => deletePublic(p.id)}
                  >
                    <TbTrashX />
                  </div>
                  <div className='grid grid-cols-[24px_1fr] items-center space-x-2'>
                    <div className='h-6 w-6'>
                      {p.link && (
                        <img
                          alt={getSocialNetworkName(p.link) || ''}
                          src={`/social-icons/${getSocialNetworkName(p.link)}.svg`}
                          className='w-full'
                        />
                      )}
                    </div>
                    <Link
                      showAnchorIcon
                      href={p.link}
                      className='text-foreground overflow-hidden text-sm underline'
                    >
                      {p.link}
                    </Link>
                  </div>
                  <div>
                    <Link
                      showAnchorIcon
                      href={routesConst.publics + '/' + p.id}
                      className='text-foreground overflow-hidden text-sm underline'
                    >
                      {p.title}
                    </Link>
                  </div>
                  <div className='grid grid-cols-7 gap-1'>
                    <div>
                      <Tooltip content='Число подписчиков'>
                        <div className='text-sm font-semibold'>
                          {p.subscribers ? getPrettyNumber(p.subscribers) : 0}
                        </div>
                      </Tooltip>
                      <div className='text-[10px]'>Число подписчиков</div>
                    </div>
                    <div>
                      <Tooltip content='Динамика подписчиков'>
                        <div className='text-sm font-semibold'>
                          {p.subscriberDynamics
                            ? getPrettyNumber(p.subscriberDynamics)
                            : 0}
                        </div>
                      </Tooltip>
                      <div className='text-[10px]'>Динамика подписчиков</div>
                    </div>
                    <div>
                      <Tooltip content='Частота публикаций'>
                        <div className='text-sm font-semibold'>
                          {p.postingFrequency
                            ? getPrettyNumber(p.postingFrequency)
                            : 0}
                        </div>
                      </Tooltip>
                      <div className='text-[10px]'>Частота публикаций</div>
                    </div>
                    <div>
                      <Tooltip content='Среднее количество просмотров'>
                        <div className='text-sm font-semibold'>
                          {p.viewsAverage ? getPrettyNumber(p.viewsAverage) : 0}
                        </div>
                      </Tooltip>
                      <div className='text-[10px]'>
                        Ср. количество просмотров
                      </div>
                    </div>
                    <div>
                      <Tooltip content='Динамика просмотров'>
                        <div className='text-sm font-semibold'>
                          {p.viewsDynamic ? getPrettyNumber(p.viewsDynamic) : 0}
                        </div>
                      </Tooltip>
                      <div className='text-[10px]'>Динамика просмотров</div>
                    </div>
                    <div>
                      <div className='text-sm font-semibold'>
                        <Tooltip content='SI'>
                          {p.si ? getPrettyNumber(p.si) : 0}
                        </Tooltip>
                      </div>
                      <div className='text-[10px]'>SI</div>
                    </div>
                    <div>
                      <div className='text-sm font-semibold'>
                        <Tooltip content='ER'>
                          {p.er ? getPrettyNumber(p.er) : 0}
                        </Tooltip>
                      </div>
                      <div className='text-[10px]'>ER</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Layout>
    </>
  )
}
