import { atomWithQuery } from 'jotai-tanstack-query'

import { getAllLocations } from '../api'

export const allLocationsAtom = atomWithQuery(() => {
  return {
    queryKey: ['newsbreaks-locations'],
    queryFn: async () => {
      const response = await getAllLocations()
      return response.data
    },
  }
})
