import { zodResolver } from '@hookform/resolvers/zod'
import {
  Grant,
  NewsbreakInterface,
  NewsbreakStatusEnum,
} from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Checkbox,
  Input,
  Select,
  SelectItem,
  Switch,
} from '@nextui-org/react'
import { useAsyncList } from '@react-stately/data'
import { TComment } from '@udecode/plate-comments'
import { TElement } from '@udecode/plate-common'
import axios from 'axios'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { IoCloseCircle } from 'react-icons/io5'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import slug from 'slug'

import { getFileUrlFromS3, uploadFileToS3 } from '../../../features/api'
import {
  audioExtensions,
  docExtensions,
  imageExtensions,
  videoExtensions,
} from '../../../features/constants/fileExtensions.const'
import { ONE_HUNDRED_MEGABYTES_IN_BYTES } from '../../../features/constants/fileSizes.const'
import { newsbreakFormOfWorkConst } from '../../../features/constants/newsbreakFormOfWork.const'
import { routesConst } from '../../../features/constants/routes.const'
import { statusConst } from '../../../features/constants/status.const'
import { Grants, useGrant } from '../../../features/grants'
import {
  FileDnD,
  FileItem,
  Header,
  Layout,
  PlateEditorWrapper,
} from '../../../features/ui'
import { DateAndTimePicker } from '../../../features/ui/components/DateAndTimePicker'
import { getFileExtension } from '../../../features/utils/getFileExtension'
import {
  createNewsbreak,
  createNewsbreakFile,
  updateNewsbreak,
} from '../api/newsbreaks.api'
import { menuItems } from '../constants/menuItems'
import { createNewsbreakSchema } from '../schemas/createNewsbreak.schema'
import {
  allPrioritiesAtom,
  allProjectsAtom,
  allThemesAtom,
  allTypesAtom,
} from '../store/newsbreaks.store'

type FormFields = Pick<
  NewsbreakInterface,
  'isUrgent' | 'title' | 'address' | 'formOfWork' | 'isAllDay' | 'status'
> & {
  deadline: string
  type: string
  theme: string
  project: string
  priority: string
}

interface Props {
  isEdit?: boolean
}

export const CreateNewsbreak = ({ isEdit }: Props) => {
  const [editorAccentsValue, setEditorAccentsValue] = useState<Array<TElement>>(
    [],
  )

  const [editorDescriptionValue, setEditorDescriptionValue] = useState<
    Array<TElement>
  >([])

  const [descriptionComments, setDescriptionComments] =
    useState<Record<string, TComment>>()

  const [searchParams] = useSearchParams()
  const [isAgreement, setIsAgreement] = useState(false)
  const navigate = useNavigate()
  const [isFormLoading, setIsFormLoading] = useState(false)

  const form = useForm<FormFields>({
    defaultValues: {
      isUrgent: searchParams.get('isUrgent') === 'true',
      isAllDay: searchParams.get('isAllDay') === 'true',
      title: searchParams.get('title') || '',
      deadline: searchParams.get('deadline') || '',
      address: searchParams.get('address') || '',
      theme: searchParams.get('theme') || '',
      type: searchParams.get('type') || '',
      project: searchParams.get('project') || '',
      priority: searchParams.get('priority') || '',
    },
    resolver: zodResolver(createNewsbreakSchema),
  })

  const [files, setFiles] = useState<File[] | null>()
  const [{ data: types, isPending: isPendingTypes }] = useAtom(allTypesAtom)
  const [{ data: themes, isPending: isPendingThemes }] = useAtom(allThemesAtom)

  const [{ data: projects, isPending: isPendingProjects }] =
    useAtom(allProjectsAtom)

  const [{ data: priorities, isPending: isPendingPriorities }] =
    useAtom(allPrioritiesAtom)

  const { isValidGrant: isModeratorOrProvider } = useGrant([
    Grant.newsbreaksModerator,
    Grant.newsbreaksProvider,
    Grant.newsbreaksCategoryModerator,
  ])

  const { isValidGrant: isProvider } = useGrant([Grant.newsbreaksProvider])

  const { isValidGrant: isModerator } = useGrant([
    Grant.newsbreaksModerator,
    Grant.newsbreaksCategoryModerator,
  ])

  const onDropAccepted = useCallback(
    (newFiles: Array<File>) => {
      setFiles(files ? [...files, ...newFiles] : newFiles)
    },
    [files],
  )

  const onSaveButtonPress = useCallback(
    async (formData: FormFields) => {
      if (isEdit) {
        const id = searchParams.get('id')
        if (!id) {
          return null
        }
        try {
          setIsFormLoading(true)

          await updateNewsbreak(id, {
            ...formData,
            deadline: dayjs(formData.deadline).toDate(),
            isUrgent:
              searchParams.get('status') === NewsbreakStatusEnum.plan ||
              formData.isUrgent,
            status: formData.status || NewsbreakStatusEnum.rework,
            theme: {
              id: formData.theme,
            },
            type: formData.type
              ? {
                  id: formData.type,
                }
              : null,
            project: formData.project
              ? {
                  id: formData.project,
                }
              : null,
            priority: formData.priority
              ? {
                  id: formData.priority,
                }
              : null,
          })
          toast.success('Инфоповод успешно обновлен')
          navigate(routesConst.newsbreaks + '/' + id)
        } catch (e) {
          console.error(e)
        } finally {
          setIsFormLoading(false)
        }
        return
      }

      if (!editorDescriptionValue.length)
        return toast.error('Необходимо ввести описание')

      try {
        setIsFormLoading(true)
        let createdFiles
        if (files) {
          const filesResponses = await Promise.allSettled(
            files.map(file => {
              return uploadFileToS3(
                new File(
                  [file],
                  slug(file.name.replace(/\.[^/.]+$/, '')) +
                    '.' +
                    getFileExtension(file.name),
                  {
                    type: file.type,
                  },
                ),
              )
            }),
          )
          const filesUrlResponses = await Promise.allSettled(
            filesResponses.map(file => {
              if (file.status === 'fulfilled' && file.value) {
                return getFileUrlFromS3(
                  file.value.data,
                  import.meta.env.VITE_BUCKET_NAME,
                )
              }

              return null
            }),
          )

          createdFiles = await Promise.allSettled(
            filesUrlResponses.map(file => {
              if (file.status === 'fulfilled' && file.value) {
                return createNewsbreakFile(file.value.data)
              }
              return null
            }),
          )
        }
        const response = await createNewsbreak({
          ...formData,
          deadline: dayjs(formData.deadline).toDate(),
          plateDescription: editorDescriptionValue,
          plateAccents: editorAccentsValue,
          plateComments: descriptionComments,
          theme: {
            id: formData.theme,
          },
          type: formData.type
            ? {
                id: formData.type,
              }
            : null,
          project: formData.project
            ? {
                id: formData.project,
              }
            : null,
          priority: formData.priority
            ? {
                id: formData.priority,
              }
            : null,
          status: isAgreement ? NewsbreakStatusEnum.agreement : undefined,
          files: createdFiles
            ? createdFiles.map(file => {
                if (file.status === 'fulfilled' && file.value) {
                  return { id: file.value.data }
                }
                return { id: '' }
              })
            : null,
        })
        if (response.status && response.status === 201) {
          toast.success('Инфоповод успешно создан')
          setFiles(null)
          navigate(routesConst.newsbreaks)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [
      descriptionComments,
      editorAccentsValue,
      editorDescriptionValue,
      files,
      isAgreement,
      isEdit,
      navigate,
      searchParams,
    ],
  )

  const list = useAsyncList({
    load: async ({ signal, filterText, items }) => {
      if (filterText && filterText.length % 3 !== 0) return { items }

      const { data } = await axios.post(
        `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
        { query: filterText },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token ' + import.meta.env.VITE_DADATA_TOKEN,
          },
          signal: signal,
        },
      )

      return {
        items: data.suggestions.map((el: { value: string }) => el.value),
      }
    },
  })

  const isUrgentGrant = useMemo(
    () => (isEdit ? isModerator : isModeratorOrProvider),
    [isEdit, isModerator, isModeratorOrProvider],
  )

  return (
    <>
      <Helmet>
        <title>
          Гидра | {isEdit ? 'Редактирование' : 'Создание'} инфоповода
        </title>
      </Helmet>
      <Grants
        grants={[
          Grant.newsbreaksModerator,
          Grant.newsbreaksProvider,
          Grant.newsbreaksCategoryModerator,
        ]}
      >
        <Layout
          headerContent={
            <Header
              text={
                isEdit ? 'Редактирование инфоповода' : 'Создание инфоповода'
              }
            />
          }
          menuItems={menuItems}
        >
          <form className='p-4' onSubmit={form.handleSubmit(onSaveButtonPress)}>
            <div className='bg-background space-y-6 rounded-xl p-4 shadow'>
              <div className='space-y-4'>
                <div className='flex items-center justify-between'>
                  <div className='flex-1 text-lg font-semibold'>
                    Основная информация
                  </div>
                  <div>
                    <Button
                      onPress={() => {
                        setFiles(null)
                        navigate(routesConst.newsbreaks)
                      }}
                      color='primary'
                      endContent={<IoCloseCircle className='text-xl' />}
                    >
                      Закрыть
                    </Button>
                  </div>
                </div>
                {isEdit && isModerator && (
                  <Controller
                    control={form.control}
                    name='status'
                    render={({ field: { onChange } }) => (
                      <Select
                        isDisabled={isFormLoading}
                        isLoading={isFormLoading}
                        aria-label='Выбор статуса инфоповода'
                        placeholder='Выберите статус инфоповода'
                        variant='bordered'
                        className='w-[250px]'
                        onChange={onChange}
                      >
                        <SelectItem key={NewsbreakStatusEnum.rework}>
                          {statusConst[NewsbreakStatusEnum.rework].title}
                        </SelectItem>
                        <SelectItem key={NewsbreakStatusEnum.plan}>
                          {statusConst[NewsbreakStatusEnum.plan].title}
                        </SelectItem>
                        <SelectItem key={NewsbreakStatusEnum.ok}>
                          {statusConst[NewsbreakStatusEnum.ok].title}
                        </SelectItem>
                        <SelectItem key={NewsbreakStatusEnum.inWork}>
                          {statusConst[NewsbreakStatusEnum.inWork].title}
                        </SelectItem>
                        <SelectItem key={NewsbreakStatusEnum.agreement}>
                          {statusConst[NewsbreakStatusEnum.agreement].title}
                        </SelectItem>
                        <SelectItem key={NewsbreakStatusEnum.done}>
                          {statusConst[NewsbreakStatusEnum.done].title}
                        </SelectItem>
                      </Select>
                    )}
                  />
                )}
                {isUrgentGrant && (
                  <div>
                    <Controller
                      control={form.control}
                      name='isUrgent'
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          isDisabled={
                            isFormLoading ||
                            (searchParams.get('status') ===
                              NewsbreakStatusEnum.ok &&
                              isProvider)
                          }
                          size='sm'
                          onValueChange={onChange}
                          isSelected={value}
                        >
                          {value ? 'Текущий инфоповод' : 'Плановый инфоповод'}
                        </Switch>
                      )}
                    />
                  </div>
                )}
                <div className='flex items-end space-x-4'>
                  <Controller
                    control={form.control}
                    name='deadline'
                    render={({ field: { onChange, value } }) => (
                      <div className='flex flex-col space-y-2'>
                        <DateAndTimePicker
                          onChange={onChange}
                          label={
                            form.watch('isUrgent')
                              ? 'Срок исполнения'
                              : 'Дата события'
                          }
                          isTimeShow={!form.watch('isAllDay')}
                          value={dayjs(value).isValid() ? value : ''}
                        />
                      </div>
                    )}
                  />
                  <div className=''>
                    <Controller
                      control={form.control}
                      name='isAllDay'
                      render={({ field: { onChange, value } }) => (
                        <div className='flex flex-col space-y-2'>
                          <label
                            htmlFor='isAllDay'
                            className='text-small flex-1 px-1'
                          >
                            Весь день
                          </label>
                          <Checkbox
                            size='lg'
                            id='isAllDay'
                            isSelected={value}
                            onValueChange={onChange}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div>
                  <Controller
                    control={form.control}
                    name='title'
                    render={({ field: { onChange, value } }) => (
                      <Input
                        isDisabled={
                          isFormLoading ||
                          searchParams.get('status') === NewsbreakStatusEnum.ok
                        }
                        variant='bordered'
                        placeholder='Заголовок'
                        color='primary'
                        onValueChange={onChange}
                        defaultValue={value}
                        isInvalid={!!form.formState.errors.title}
                        errorMessage={form.formState.errors.title?.message}
                      />
                    )}
                  />
                </div>
                {!form.watch('isUrgent') && !isEdit && (
                  <div>
                    <PlateEditorWrapper
                      withComments={false}
                      placeholder='Введите акценты'
                      onChange={setEditorAccentsValue}
                    />
                  </div>
                )}
                <div>
                  {!isEdit && (
                    <PlateEditorWrapper
                      withComments={true}
                      setComments={setDescriptionComments}
                      placeholder='Введите описание'
                      onChange={setEditorDescriptionValue}
                    />
                  )}
                </div>
              </div>
              <div className='space-y-4'>
                <div className='text-lg font-semibold '>Категории</div>
                <div className='space-y-4'>
                  <div className='flex space-x-4'>
                    {!form.watch('isUrgent') && (
                      <Controller
                        control={form.control}
                        name='type'
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            isInvalid={!!form.formState.errors.type}
                            errorMessage={form.formState.errors.type?.message}
                            isDisabled={
                              isFormLoading ||
                              searchParams.get('status') ===
                                NewsbreakStatusEnum.ok
                            }
                            aria-label='тип'
                            isLoading={isPendingTypes || isFormLoading}
                            variant='bordered'
                            defaultItems={types ? types[0] : []}
                            placeholder='Выберите тип'
                            onSelectionChange={onChange}
                            defaultSelectedKey={value}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            onKeyDown={e => e.continuePropagation()}
                          >
                            {item => (
                              <AutocompleteItem key={item.id}>
                                {item.title}
                              </AutocompleteItem>
                            )}
                          </Autocomplete>
                        )}
                      />
                    )}

                    <Controller
                      control={form.control}
                      name='theme'
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          isInvalid={!!form.formState.errors.theme}
                          errorMessage={form.formState.errors.theme?.message}
                          isDisabled={
                            isFormLoading ||
                            searchParams.get('status') ===
                              NewsbreakStatusEnum.ok
                          }
                          aria-label='тема'
                          isLoading={isPendingThemes || isFormLoading}
                          variant='bordered'
                          defaultItems={themes ? themes[0] : []}
                          placeholder='Выберите тему'
                          onSelectionChange={onChange}
                          defaultSelectedKey={value}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-expect-error
                          onKeyDown={e => e.continuePropagation()}
                        >
                          {item => (
                            <AutocompleteItem key={item.id}>
                              {item.title}
                            </AutocompleteItem>
                          )}
                        </Autocomplete>
                      )}
                    />
                  </div>
                  {!form.watch('isUrgent') && (
                    <div className='flex space-x-4'>
                      <Controller
                        control={form.control}
                        name='project'
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            isInvalid={!!form.formState.errors.project}
                            errorMessage={
                              form.formState.errors.project?.message
                            }
                            isDisabled={
                              isFormLoading ||
                              searchParams.get('status') ===
                                NewsbreakStatusEnum.ok
                            }
                            aria-label='нацпроект'
                            isLoading={isPendingProjects || isFormLoading}
                            variant='bordered'
                            defaultItems={projects ? projects[0] : []}
                            placeholder='Выберите нацпроект'
                            onSelectionChange={onChange}
                            defaultSelectedKey={value}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            onKeyDown={e => e.continuePropagation()}
                          >
                            {item => (
                              <AutocompleteItem key={item.id}>
                                {item.title}
                              </AutocompleteItem>
                            )}
                          </Autocomplete>
                        )}
                      />
                      <Controller
                        control={form.control}
                        name='priority'
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            isInvalid={!!form.formState.errors.priority}
                            errorMessage={
                              form.formState.errors.priority?.message
                            }
                            isDisabled={
                              isFormLoading ||
                              searchParams.get('status') ===
                                NewsbreakStatusEnum.ok
                            }
                            aria-label='приоритет'
                            isLoading={isPendingPriorities || isFormLoading}
                            variant='bordered'
                            defaultItems={priorities ? priorities[0] : []}
                            placeholder='Выберите приоритет'
                            onSelectionChange={onChange}
                            defaultSelectedKey={value}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            onKeyDown={e => e.continuePropagation()}
                          >
                            {item => (
                              <AutocompleteItem key={item.id}>
                                {item.title}
                              </AutocompleteItem>
                            )}
                          </Autocomplete>
                        )}
                      />
                    </div>
                  )}
                </div>
                {!form.watch('isUrgent') && (
                  <div className='flex space-x-4'>
                    <Controller
                      control={form.control}
                      name='address'
                      render={({ field: { onChange } }) => (
                        <Autocomplete
                          aria-label='адрес'
                          inputValue={list.filterText}
                          isLoading={list.isLoading}
                          className='flex-1'
                          items={
                            list.items
                              ? Object.entries(list.items).map(
                                  ([key, value]) => ({
                                    label: key,
                                    value,
                                  }),
                                )
                              : []
                          }
                          placeholder='Введите адрес события'
                          variant='bordered'
                          onSelectionChange={onChange}
                          onInputChange={list.setFilterText}
                        >
                          {item => (
                            <AutocompleteItem
                              key={String(item.value)}
                              className='capitalize'
                            >
                              {String(item.value)}
                            </AutocompleteItem>
                          )}
                        </Autocomplete>
                      )}
                    />
                    {!isEdit && (
                      <Controller
                        control={form.control}
                        name='formOfWork'
                        render={({ field: { onChange } }) => (
                          <Select
                            isDisabled={
                              isFormLoading ||
                              searchParams.get('status') ===
                                NewsbreakStatusEnum.ok
                            }
                            isLoading={isFormLoading}
                            items={Object.entries(newsbreakFormOfWorkConst).map(
                              item => ({
                                id: item[0],
                                title: item[1],
                              }),
                            )}
                            aria-label='Формат отработки'
                            placeholder='Выберите Формат отработки'
                            variant='bordered'
                            className='flex-1'
                            selectionMode='multiple'
                            onChange={event => {
                              onChange(event.target.value.split(','))
                            }}
                          >
                            {item => (
                              <SelectItem key={item.id}>
                                {item.title}
                              </SelectItem>
                            )}
                          </Select>
                        )}
                      />
                    )}
                  </div>
                )}
              </div>
              {!isEdit && (
                <>
                  <div className='space-y-4'>
                    <div className='flex space-x-4'>
                      <div className='w-1/3 space-y-3'>
                        <div className='text-lg font-semibold '>
                          Материалы публикации
                        </div>
                        <div className='text-default-600'>
                          Вы можете загрузить изображения, видео, аудиозапись,
                          текстовый документ.
                        </div>
                      </div>
                      <div className='flex-1'>
                        <FileDnD
                          disabled={isFormLoading}
                          maxFiles={100}
                          maxSize={ONE_HUNDRED_MEGABYTES_IN_BYTES}
                          multiple
                          onDropAccepted={onDropAccepted}
                          accept={{
                            ...audioExtensions,
                            ...videoExtensions,
                            ...imageExtensions,
                            ...docExtensions,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='grid grid-cols-3 gap-4'>
                    {files?.map((file, index) => (
                      <FileItem
                        key={Math.random()}
                        file={file}
                        setFiles={setFiles}
                        index={index}
                      />
                    ))}
                  </div>
                </>
              )}
              <div className='flex justify-end space-x-4'>
                {!isEdit && (
                  <Button
                    color='primary'
                    isDisabled={isFormLoading}
                    isLoading={isFormLoading}
                    onPress={() => setIsAgreement(true)}
                    onClick={form.handleSubmit(onSaveButtonPress)}
                  >
                    {form.watch('isUrgent')
                      ? 'Отдать на согласование'
                      : 'Предложить в план'}
                  </Button>
                )}
                <Button
                  color='primary'
                  variant='flat'
                  type='submit'
                  isDisabled={isFormLoading}
                  isLoading={isFormLoading}
                >
                  Сохранить
                </Button>
              </div>
            </div>
          </form>
        </Layout>
      </Grants>
    </>
  )
}
