import { DropdownMenuProps } from '@radix-ui/react-dropdown-menu'
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote'
import {
  focusEditor,
  insertEmptyElement,
  useEditorRef,
} from '@udecode/plate-common'
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading'
import { ELEMENT_HR } from '@udecode/plate-horizontal-rule'
import { ELEMENT_LINK, triggerFloatingLink } from '@udecode/plate-link'
import {
  ELEMENT_IMAGE,
  ELEMENT_MEDIA_EMBED,
  insertMedia,
} from '@udecode/plate-media'
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph'
import { ELEMENT_TABLE, insertTable } from '@udecode/plate-table'
import React from 'react'

import { Icons } from '../icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  useOpenState,
} from './dropdown-menu'
import { ToolbarButton } from './toolbar'

const items = [
  {
    label: 'Блоки',
    items: [
      {
        value: ELEMENT_PARAGRAPH,
        label: 'Абзац',
        description: 'Абзац',
        icon: Icons.paragraph,
      },
      {
        value: ELEMENT_H1,
        label: 'Заголовок 1',
        description: 'Заголовок 1',
        icon: Icons.h1,
      },
      {
        value: ELEMENT_H2,
        label: 'Заголовок 2',
        description: 'Заголовок 2',
        icon: Icons.h2,
      },
      {
        value: ELEMENT_H3,
        label: 'Заголовок 3',
        description: 'Заголовок 3',
        icon: Icons.h3,
      },
      {
        value: ELEMENT_BLOCKQUOTE,
        label: 'Цитата',
        description: 'Цитата',
        icon: Icons.blockquote,
      },
      {
        value: ELEMENT_TABLE,
        label: 'Таблица',
        description: 'Таблица',
        icon: Icons.table,
      },
      {
        value: ELEMENT_HR,
        label: 'Разделитель',
        description: 'Разделитель',
        icon: Icons.hr,
      },
    ],
  },
  {
    label: 'Медиа',
    items: [
      {
        value: ELEMENT_IMAGE,
        label: 'Картинка',
        description: 'Картинка',
        icon: Icons.image,
      },
      {
        value: ELEMENT_MEDIA_EMBED,
        label: 'Видео',
        description: 'Видео',
        icon: Icons.embed,
      },
    ],
  },
]

export function InsertDropdownMenu(props: DropdownMenuProps) {
  const editor = useEditorRef()
  const openState = useOpenState()

  return (
    <DropdownMenu modal={false} {...openState} {...props}>
      <DropdownMenuTrigger asChild>
        <ToolbarButton pressed={openState.open} isDropdown>
          <Icons.add />
        </ToolbarButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        align='start'
        className='flex max-h-[500px] min-w-0 flex-col gap-0.5 overflow-y-auto'
      >
        {items.map(({ items: nestedItems, label }, index) => (
          <React.Fragment key={label}>
            {index !== 0 && <DropdownMenuSeparator />}

            <DropdownMenuLabel>{label}</DropdownMenuLabel>
            {nestedItems.map(
              ({ value: type, label: itemLabel, icon: Icon }) => (
                <DropdownMenuItem
                  key={type}
                  className='min-w-[180px]'
                  onSelect={async () => {
                    switch (type) {
                      case ELEMENT_IMAGE: {
                        await insertMedia(editor, { type: ELEMENT_IMAGE })

                        break
                      }
                      case ELEMENT_MEDIA_EMBED: {
                        await insertMedia(editor, {
                          type: ELEMENT_MEDIA_EMBED,
                        })

                        break
                      }
                      case ELEMENT_TABLE: {
                        insertTable(editor)

                        break
                      }
                      case ELEMENT_LINK: {
                        triggerFloatingLink(editor, { focused: true })

                        break
                      }
                      default: {
                        insertEmptyElement(editor, type, {
                          select: true,
                          nextBlock: true,
                        })
                      }
                    }

                    focusEditor(editor)
                  }}
                >
                  <Icon className='mr-2 size-5' />
                  {itemLabel}
                </DropdownMenuItem>
              ),
            )}
          </React.Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
