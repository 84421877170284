import { zodResolver } from '@hookform/resolvers/zod'
import { LocationInterface } from '@hydra/interfaces'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { updateOrganization } from '../../../features/api'
import { allLocationsAtom } from '../../../features/store'
import { addLocationsSchema } from '../schemas/addLocations.schema'

interface Props {
  isOpen: boolean
  organizationId: string
  onOpenChange: (isOpen: boolean) => void
  onClose: () => void
  defaultLocations: Array<LocationInterface>
  refetch: () => void
}

export const AddLocationsModal = ({
  isOpen,
  onOpenChange,
  onClose,
  organizationId,
  defaultLocations,
  refetch,
}: Props) => {
  const [{ data: locations, isPending }] = useAtom(allLocationsAtom)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const form = useForm<z.infer<typeof addLocationsSchema>>({
    resolver: zodResolver(addLocationsSchema),
  })

  const onSubmit = async (formData: z.infer<typeof addLocationsSchema>) => {
    if (formData.locations.length && formData.locations[0] === '') {
      toast.info('Выберите хотя бы одно место публикации')
      return
    }
    setIsFormLoading(true)
    const response = await updateOrganization(organizationId, formData)
    if (response && response.status === 200) {
      toast.success('Места публикации успешно добавлены')
      form.reset()
      refetch()
      onClose()
    }
    setIsFormLoading(false)
  }

  const handleCloseButtonClick = () => {
    form.reset()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              Добавить место публикации
            </ModalHeader>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <ModalBody>
                <Controller
                  control={form.control}
                  name='locations'
                  render={({ field: { onChange } }) => (
                    <Select
                      isDisabled={isFormLoading || isPending}
                      isLoading={isFormLoading || isPending}
                      defaultSelectedKeys={defaultLocations.map(
                        location => location.id,
                      )}
                      items={locations ? locations[0] : []}
                      aria-label='Места публикации'
                      label='Выберите места публикации'
                      variant='bordered'
                      color='primary'
                      selectionMode='multiple'
                      onChange={event => {
                        onChange(event.target.value.split(','))
                      }}
                      isInvalid={!!form.formState.errors.locations}
                      errorMessage={form.formState.errors.locations?.message}
                    >
                      {item => (
                        <SelectItem key={item.id}>{item.title}</SelectItem>
                      )}
                    </Select>
                  )}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color='danger'
                  type='button'
                  variant='light'
                  onPress={handleCloseButtonClick}
                >
                  Отменить
                </Button>
                <Button color='primary' type='submit'>
                  Сохранить
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
