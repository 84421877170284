import { Grant } from '@hydra/interfaces'
import { useAtom } from 'jotai'
import { PropsWithChildren, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { routesConst } from '../../constants/routes.const'
import { grantsAtom } from '../store'

interface Props extends PropsWithChildren {
  grants: Grant | Grant[]
}

export const Grants = ({ grants, children }: Props) => {
  const [{ data }] = useAtom(grantsAtom)

  const isValidGrant = useMemo(() => {
    if (!data?.grants) return false

    if (Array.isArray(grants)) {
      return grants.some(g => data.grants.includes(g))
    } else {
      return data.grants.includes(grants)
    }
  }, [grants, data?.grants])

  if (!data) return null

  if (isValidGrant) return children

  return <Navigate to={routesConst.accessDenied} />
}
