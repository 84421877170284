import { zodResolver } from '@hookform/resolvers/zod'
import { parseDate } from '@internationalized/date'
import {
  Button,
  DatePicker,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
} from '@nextui-org/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { creteMissedNewsbreak } from '../../../features/api'
import { excuseConst } from '../../../features/constants/excuse.const'
import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import { createMissedNewsbreakSchema } from '../schemas/createMissedNewsbreak.schema'

interface Props {
  isOpen: boolean
  organizationId: string
  onOpenChange: (isOpen: boolean) => void
  onClose: () => void
  refetch: () => void
}

export const CreateMissedNewsbreakModal = ({
  isOpen,
  onOpenChange,
  onClose,
  organizationId,
  refetch,
}: Props) => {
  const [isFormLoading, setIsFormLoading] = useState(false)
  const form = useForm<z.infer<typeof createMissedNewsbreakSchema>>({
    defaultValues: {},
    resolver: zodResolver(createMissedNewsbreakSchema),
  })

  const onSubmit = async (
    formData: z.infer<typeof createMissedNewsbreakSchema>,
  ) => {
    setIsFormLoading(true)
    const response = await creteMissedNewsbreak({
      ...formData,
      organization: {
        id: organizationId,
      },
    })
    if (response && response.status === 201) {
      toast.success('Инфоповод вне темника успешно созадн')
      form.reset()
      refetch()
      onClose()
    }
    setIsFormLoading(false)
  }

  const handleCloseButtonClick = () => {
    form.reset()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              Добавление инфоповода вне темника
            </ModalHeader>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <ModalBody>
                <Controller
                  control={form.control}
                  name='createdAt'
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      isDisabled={isFormLoading}
                      variant='bordered'
                      aria-label='Дата'
                      value={
                        value
                          ? parseDate(dayjs(value).format('YYYY-MM-DD'))
                          : null
                      }
                      onChange={value =>
                        onChange(extendedDayjs(value.toString()).toISOString())
                      }
                      isInvalid={!!form.formState.errors.createdAt}
                      errorMessage={form.formState.errors.createdAt?.message}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='link'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      placeholder='Ссылка на публикацию'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.link}
                      errorMessage={form.formState.errors.link?.message}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='excuse'
                  render={({ field: { onChange } }) => (
                    <Select
                      isDisabled={isFormLoading}
                      isLoading={isFormLoading}
                      items={Object.entries(excuseConst).map(item => ({
                        label: item[1],
                        value: item[0],
                      }))}
                      aria-label='Тип оправдания'
                      placeholder='Выберите тип оправдания'
                      variant='bordered'
                      onChange={onChange}
                      isInvalid={!!form.formState.errors.excuse}
                      errorMessage={form.formState.errors.excuse?.message}
                    >
                      {item => (
                        <SelectItem key={item.value}>{item.label}</SelectItem>
                      )}
                    </Select>
                  )}
                />
                <Controller
                  control={form.control}
                  name='description'
                  render={({ field: { onChange, value } }) => (
                    <Textarea
                      isDisabled={isFormLoading}
                      variant='bordered'
                      placeholder='Введите комментарий'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.description}
                      errorMessage={form.formState.errors.description?.message}
                    />
                  )}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color='danger'
                  type='button'
                  variant='light'
                  onPress={handleCloseButtonClick}
                >
                  Отменить
                </Button>
                <Button type='submit'>Создать</Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
