import { NewsbreakStatusEnum } from '@hydra/interfaces'
import { Chip, Tab, Tabs } from '@nextui-org/react'
import { useAtom } from 'jotai'

import { statusConst } from '../../../features/constants/status.const'
import {
  newsbreaksStatusesCountsAtom,
  statusTabAtom,
} from '../store/newsbreaks.store'

export const NewsbreakStatusTabs = () => {
  const [{ data: counts }] = useAtom(newsbreaksStatusesCountsAtom)
  const [tab, setTab] = useAtom(statusTabAtom)

  return (
    <Tabs
      size='sm'
      aria-label='Статусы'
      selectedKey={tab}
      onSelectionChange={event => setTab(event as NewsbreakStatusEnum)}
      classNames={{
        tabList: 'gap-0',
        tab: 'px-0 m-0 ',
      }}
    >
      <Tab
        key='all'
        title={
          <Chip variant='light' color='default'>
            <div className='flex space-x-1'>
              <span>Все</span>
              <span
                className={`text-default-500 flex items-center justify-center text-xs`}
              >
                {counts && counts.allCount > 999 ? '999+' : counts?.allCount}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key='draft'
        title={
          <Chip variant='light' color={statusConst.draft.color}>
            <div className='flex space-x-1'>
              <span>{statusConst.draft.title}</span>
              <span
                className={`text-${statusConst.draft.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.draft > 999 ? '999+' : counts?.draft}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key='rework'
        title={
          <Chip variant='light' color={statusConst.rework.color}>
            <div className='flex space-x-1'>
              <span>{statusConst.rework.title}</span>
              <span
                className={`text-${statusConst.rework.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.rework > 999 ? '999+' : counts?.rework}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key='agreement'
        title={
          <Chip variant='light' color={statusConst.agreement.color}>
            <div className='flex space-x-1'>
              <span>{statusConst.agreement.title}</span>
              <span
                className={`text-${statusConst.agreement.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.agreement > 999 ? '999+' : counts?.agreement}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key='plan'
        title={
          <Chip variant='light' color={statusConst.plan.color}>
            <div className='flex space-x-1'>
              <span>{statusConst.plan.title}</span>
              <span
                className={`text-${statusConst.plan.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.plan > 999 ? '999+' : counts?.plan}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key='ok'
        title={
          <Chip variant='light' color={statusConst.ok.color}>
            <div className='flex space-x-1'>
              <span>{statusConst.ok.title}</span>
              <span
                className={`text-${statusConst.ok.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.ok > 999 ? '999+' : counts?.ok}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key='inWork'
        title={
          <Chip variant='light' color={statusConst.inWork.color}>
            <div className='flex space-x-1'>
              <span>{statusConst.inWork.title}</span>
              <span
                className={`text-${statusConst.inWork.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.inWork > 999 ? '999+' : counts?.inWork}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key='done'
        title={
          <Chip variant='light' color={statusConst.done.color}>
            <div className='flex space-x-1'>
              <span>{statusConst.done.title}</span>
              <span
                className={`text-${statusConst.done.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.done > 999 ? '999+' : counts?.done}
              </span>
            </div>
          </Chip>
        }
      />
      <Tab
        key='canceled'
        title={
          <Chip variant='light' color={statusConst.canceled.color}>
            <div className='flex space-x-1'>
              <span>{statusConst.canceled.title}</span>
              <span
                className={`text-${statusConst.canceled.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.canceled > 999 ? '999+' : counts?.canceled}
              </span>
            </div>
          </Chip>
        }
      />

      <Tab
        key='deleted'
        title={
          <Chip variant='light' color={statusConst.deleted.color}>
            <div className='flex space-x-1'>
              <span>{statusConst.deleted.title}</span>
              <span
                className={`text-${statusConst.deleted.color} flex items-center justify-center text-xs`}
              >
                {counts && counts.deleted > 999 ? '999+' : counts?.deleted}
              </span>
            </div>
          </Chip>
        }
      />
    </Tabs>
  )
}
