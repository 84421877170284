import { z } from 'zod'

export const addLocationsSchema = z.object({
  locations: z.array(
    z
      .string({
        required_error: 'Обязательное поле',
      })
      .trim(),
    {
      required_error: 'Обязательное поле',
    },
  ),
})
