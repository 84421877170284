import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { userByIdAtom, userIdAtom } from '../../../features/store'
import { Empty, Layout, PageSpinner } from '../../../features/ui'
import { UserInfo } from '../components/UserInfo'
import { menuItems } from '../constants/menuItems'

export const User = () => {
  const { id } = useParams()
  const [, setUserId] = useAtom(userIdAtom)
  const [{ data, isPending }] = useAtom(userByIdAtom)

  useEffect(() => {
    if (id) {
      setUserId(id)
    }
    return () => {
      setUserId(undefined)
    }
  }, [id, setUserId])

  return (
    <>
      <Helmet>
        <title>Гидра | Страница пользователя</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <div className='flex h-full items-center justify-between space-x-4 px-4'>
            <h1 className='flex-1 text-3xl font-semibold'>
              Страница пользователя
            </h1>
          </div>
        }
      >
        {isPending ? (
          <PageSpinner />
        ) : !data ? (
          <Empty />
        ) : (
          <UserInfo profile={data} isMyProfile={false} />
        )}
      </Layout>
    </>
  )
}
