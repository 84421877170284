import { Grant } from '@hydra/interfaces'
import { Button, Chip, Input, Link } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { TbSend } from 'react-icons/tb'

import { routesConst } from '../../../features/constants/routes.const'
import { useGrant } from '../../../features/grants'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { createComment } from '../api/newsbreaks.api'
import { newsbreakCommentsAtom } from '../store/newsbreaks.store'

export const Comments = () => {
  const { isValidGrant: canCreateNewsbreakComments } = useGrant([
    Grant.newsbreaksModerator,
    Grant.newsbreaksProvider,
  ])
  const [{ refetch, data: newsbreak }] = useAtom(newsbreakCommentsAtom)
  const [newComment, setNewComment] = useState('')

  const onSendButtonPress = useCallback(async () => {
    newsbreak &&
      (await createComment({
        value: newComment,
        newsbreakId: newsbreak.id,
      }))
    setNewComment('')
    await refetch()
  }, [newComment, newsbreak, refetch])

  if (!newsbreak) return null

  return (
    <div className='space-y-4'>
      <div className='font-semibold '>Комментарии</div>
      <div className='space-y-2'>
        {newsbreak.comments.map(comment => (
          <div
            key={comment.id}
            className='bg-primary-50 space-y-4 rounded-xl p-4'
          >
            <div className='flex items-center justify-between'>
              <Link href={routesConst.users + '/' + comment.author.id}>
                <Chip size='sm' variant='bordered' color='primary'>
                  {comment.author.name} {comment.author.surname}
                </Chip>
              </Link>
              <div className='text-primary-400 text-xs'>
                {getPrettyDate({
                  date: comment.createdAt,
                  template: 'DD MMMM YYYY, HH:mm',
                })}
              </div>
            </div>
            <div className='text-default-600 text-small'>{comment.value}</div>
          </div>
        ))}
        {canCreateNewsbreakComments && (
          <div className='flex items-center space-x-2'>
            <Input
              variant='bordered'
              placeholder='Комментировать'
              value={newComment}
              onValueChange={setNewComment}
            />
            <Button
              variant='bordered'
              color='primary'
              isIconOnly
              onPress={onSendButtonPress}
            >
              <TbSend className='text-xl' />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
