import { TaskInterface } from '@hydra/interfaces'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { Key, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { CollectionChip } from './CollectionChip'
import { DateChip } from './DateChip'
import { ExecutorChip } from './ExecutorChip'
import { PriorityChip } from './PriorityChip'
import { StatusChip } from './StatusChip'

interface Props {
  items: Array<TaskInterface>
  refetch: () => void
  isFullHeight?: boolean
  withCollection?: boolean
  withExecutor?: boolean
}

export const TasksTable = ({
  items,
  refetch,
  isFullHeight,
  withCollection,
  withExecutor,
}: Props) => {
  const navigate = useNavigate()

  const renderCell = useCallback(
    (item: TaskInterface, columnKey: Key) => {
      if (columnKey === 'title') {
        return <div className='line-clamp-3'>{item.title}</div>
      }
      if (columnKey === 'createdAt') {
        return (
          <div>
            {getPrettyDate({
              date: item.createdAt,
            })}
          </div>
        )
      }
      if (columnKey === 'priority') {
        return (
          <PriorityChip
            priority={item.priority}
            id={item.id}
            refetch={refetch}
          />
        )
      }
      if (columnKey === 'status') {
        return (
          <StatusChip
            withChangeAction
            id={item.id}
            status={item.status}
            refetch={refetch}
          />
        )
      }
      if (columnKey === 'date' && item.date) {
        return (
          <DateChip
            id={item.id}
            date={item.date}
            time={item.time}
            refetch={refetch}
          />
        )
      }
      if (columnKey === 'collection') {
        return (
          <CollectionChip
            id={item.id}
            collection={item.collection}
            refetch={refetch}
          />
        )
      }
      if (columnKey === 'executor' && item.executor) {
        return (
          <ExecutorChip
            id={item.id}
            executor={item.executor}
            refetch={refetch}
          />
        )
      }
    },
    [refetch],
  )

  const getColumns = useCallback(() => {
    const columns = [
      {
        key: 'title',
        label: 'Задача',
        width: 300,
      },
      {
        key: 'createdAt',
        label: 'Создана',
        width: 100,
      },
      {
        key: 'date',
        label: 'Срок',
        width: 140,
      },
      {
        key: 'priority',
        label: 'Приоритет',
      },
      {
        key: 'status',
        label: 'Статус',
      },
    ]

    if (withCollection) {
      columns.push({
        key: 'collection',
        label: 'Проект',
      })
    }

    if (withExecutor) {
      columns.push({
        key: 'executor',
        label: 'Исполнитель',
        width: 200,
      })
    }

    return columns
  }, [withCollection, withExecutor])

  return (
    <Table
      aria-label='Задачи'
      isHeaderSticky
      isStriped
      removeWrapper
      onRowAction={key => navigate(routesConst.tasks + '/' + key)}
      classNames={{
        base: isFullHeight ? 'h-[calc(100vh-220px)] overflow-auto' : '',
      }}
    >
      <TableHeader columns={getColumns()}>
        {column => (
          <TableColumn width={column.width} key={column.key}>
            {column.label}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        items={items}
        emptyContent={
          <div className='text-default-400 text-sm'>Нет данных</div>
        }
      >
        {item => (
          <TableRow key={item.id}>
            {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
