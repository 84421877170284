import { Grant } from '@hydra/interfaces'
import {
  Checkbox,
  Input,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback, useMemo, useState } from 'react'
import SimpleBar from 'simplebar-react'

import { updateUser } from '../../../features/api'
import { grantConst } from '../../../features/constants/grant.const'
import { allUsersAtom } from '../../../features/store'
import { Empty, PageSpinner } from '../../../features/ui'

const columns = [
  {
    key: 'name',
    label: 'ФИО',
  },
  ...Object.entries(grantConst)
    .sort((a, b) => a[1].localeCompare(b[1])) // Сортировка основана на втором элементе каждой пары (label)
    .map(grant => ({
      key: grant[0],
      label: grant[1],
    })),
]

export const PermissionsList = () => {
  const [{ data, isPending, refetch }] = useAtom(allUsersAtom)
  const [filter, setFilter] = useState('')

  const rows = useMemo(
    () =>
      data
        ? data[0].map(user =>
            Object.assign(
              {
                key: user.id,
                name: user.name + ' ' + user.surname,
                grants: user.grants,
                login: user.login,
              },
              ...Object.keys(grantConst).map(grant => ({
                [grant]: user.grants.includes(grant as Grant),
              })),
            ),
          )
        : [],
    [data],
  )

  const renderCell = useCallback(
    (
      user: {
        name: string
        key: string
        grants: Array<Grant>
        login: string
      } & keyof typeof grantConst,
      columnKey: Key,
    ) => {
      switch (columnKey) {
        case 'name':
          return (
            <div className='w-[200px]'>
              {user.name} ({user.login})
            </div>
          )
        default:
          return (
            <div>
              <Checkbox
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                isSelected={user[columnKey]}
                onValueChange={async value => {
                  await updateUser(user.key, {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    grants: !user[columnKey]
                      ? [...user.grants, columnKey as Grant]
                      : user.grants.filter(grant => grant !== columnKey),
                  })
                  await refetch()
                }}
              />
            </div>
          )
      }
    },
    [refetch],
  )

  if (isPending) return <PageSpinner />

  if (!data) return <Empty />

  return (
    <div className='space-y-4'>
      <div>
        <Input
          size='sm'
          label='Поиск по имени или фамилии'
          variant='bordered'
          value={filter}
          onValueChange={setFilter}
        />
      </div>
      <SimpleBar className='bg-background h-[calc(100vh-168px)] overflow-auto rounded-xl p-4 shadow'>
        <Table
          aria-label='Досутпы пользователей'
          isHeaderSticky
          isStriped
          removeWrapper
        >
          <TableHeader columns={columns}>
            {column => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody
            items={rows.filter(user =>
              // filter !== '' &&
              user.name.toLowerCase().includes(filter.toLowerCase()),
            )}
          >
            {item => (
              <TableRow key={item.key}>
                {columnKey => (
                  <TableCell>{renderCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </SimpleBar>
    </div>
  )
}
