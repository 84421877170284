import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'

import {
  audioExtensions,
  videoExtensions,
} from '../../../features/constants/fileExtensions.const'
import { ONE_HUNDRED_GIGABYTES_IN_BYTES } from '../../../features/constants/fileSizes.const'
import { FileDnD, Header, Layout } from '../../../features/ui'
import { create } from '../api/transcription.api'
import { TranscriptionsList } from '../components/TranscriptionsList'
import { TranscriptionsPagination } from '../components/TranscriptionsPagination'
import { menuItems } from '../constants/menuItems'
import { transcriptionsAtom } from '../store/transcription.store'

const pageTitle = 'Транскрибации'

export const Transcriptions = () => {
  const [files, setFiles] = useState<File[] | null>(null)
  const [{ refetch }] = useAtom(transcriptionsAtom)
  const { isOpen, onOpen, onOpenChange } = useDisclosure()

  const onDropAccepted = useCallback(
    (newFiles: Array<File>) => {
      setFiles(files ? [...files, ...newFiles] : newFiles)
    },
    [files],
  )

  const handleTranscription = useCallback(async () => {
    if (!files) {
      toast.error('Выберите файл для транскрибации')
      return
    }

    try {
      const response = await toast.promise(create(files[0]), {
        pending: 'Транскрибируем файл',
      })

      if (response.status && response.status === 201) {
        toast.success('Файл успешно транскрибирован')
        setFiles(null)
        refetch()
      }
    } catch (e) {
      console.error(e)
    }
  }, [files, refetch])

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <div>
                <Button color='primary' variant='ghost' onPress={onOpen}>
                  Транскрибировать
                </Button>
              </div>
            }
          />
        }
      >
        <div className='space-y-4 p-4'>
          <TranscriptionsList />
          <TranscriptionsPagination />
        </div>
      </Layout>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Транскрибация файла
              </ModalHeader>
              <ModalBody>
                <FileDnD
                  maxFiles={100}
                  maxSize={ONE_HUNDRED_GIGABYTES_IN_BYTES}
                  multiple={false}
                  onDropAccepted={onDropAccepted}
                  accept={{
                    ...audioExtensions,
                    ...videoExtensions,
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color='danger'
                  variant='light'
                  onPress={() => {
                    setFiles(null)
                    onClose()
                  }}
                >
                  Отменить
                </Button>
                <Button
                  color='primary'
                  onPress={() => {
                    handleTranscription()
                    onClose()
                  }}
                >
                  Транскрибировать
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
