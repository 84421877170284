export const statusConst = {
  draft: { title: 'Черновик', color: 'secondary' },
  rework: { title: 'На доработке', color: 'secondary' },
  agreement: { title: 'На согласовании', color: 'secondary' },
  plan: { title: 'В плане', color: 'primary' },
  ok: { title: 'Готово', color: 'primary' },
  inWork: { title: 'В работе у СМИ', color: 'primary' },
  done: { title: 'Отработано СМИ', color: 'success' },
  canceled: { title: 'Отклонено СМИ', color: 'danger' },
  deleted: { title: 'Архив', color: 'default' },
} as const
