import { DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu'
import { cn } from '@udecode/cn'
import React from 'react'

import { Icons } from '../icons'
import { buttonVariants } from './button'
import { TColor } from './color-dropdown-menu'
import { DropdownMenuItem } from './dropdown-menu'

type ColorDropdownMenuItemProps = {
  value: string
  isBrightColor: boolean
  isSelected: boolean
  updateColor: (color: string) => void
} & DropdownMenuItemProps

export function ColorDropdownMenuItem({
  value,
  isBrightColor,
  isSelected,
  updateColor,
  className,
  ...props
}: ColorDropdownMenuItemProps) {
  const content = (
    <DropdownMenuItem
      className={cn(
        buttonVariants({
          variant: 'outline',
          isMenu: true,
        }),
        'border-default-400 size-6 border border-solid p-0',
        !isBrightColor && 'border-transparent text-white',
        className,
      )}
      style={{ backgroundColor: value }}
      onSelect={e => {
        e.preventDefault()
        updateColor(value)
      }}
      {...props}
    >
      {isSelected ? <Icons.check /> : null}
    </DropdownMenuItem>
  )

  return content
}

type ColorDropdownMenuItemsProps = {
  color?: string
  colors: TColor[]
  updateColor: (color: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export function ColorDropdownMenuItems({
  color,
  colors,
  updateColor,
  className,
  ...props
}: ColorDropdownMenuItemsProps) {
  return (
    <div
      className={cn('grid w-full grid-cols-[repeat(10,24px)] gap-1', className)}
      {...props}
    >
      {colors.map(({ value, isBrightColor }) => (
        <ColorDropdownMenuItem
          className='h-6'
          key={value}
          value={value}
          isBrightColor={isBrightColor}
          isSelected={color === value}
          updateColor={updateColor}
        />
      ))}
    </div>
  )
}
