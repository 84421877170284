export const routesConst = {
  main: '/',
  login: '/login',
  transcriptions: '/transcriptions',
  newsbreaks: '/newsbreaks',
  createNewsbreak: '/create-newsbreaks',
  editNewsbreak: '/edit-newsbreaks',
  themeCalendar: '/theme-calendar',
  reports: '/reports',
  createReport: '/create-report',
  editReport: '/edit-report',
  rating: '/rating',
  groups: '/groups',
  createGroup: '/create-group',
  editGroup: '/edit-group',
  newsbreaksAnalytics: '/newsbreaks-analytics',
  profile: '/profile',
  accessDenied: '/access-denied',
  permissions: '/permissions',
  users: '/users',
  createUser: '/create-user',
  editUser: '/edit-user',
  organizations: '/organizations',
  createOrganization: '/create-organization',
  editOrganization: '/edit-organization',
  log: '/log',
  tasks: '/tasks',
  collections: '/collections',
  createTask: '/create-risk',
  editTask: '/edit-risk',
  createCollection: '/create-collection',
  editCollection: '/edit-collection',
  tasksCalendar: '/tasks-calendar',
  settings: '/settings',
  folders: '/folders',
  articles: '/articles',
  createArticle: '/create-article',
  tags: '/tags',
  publics: '/publics',
  createPublic: '/create-public',
  editPublic: '/edit-public',
  publicGroups: '/public-groups',
  createPublicGroup: '/create-public-group',
  editPublicGroup: '/edit-public-group',
  publicsDashboard: '/publics-dashboard',
  monitoring: '/monitoring',
  chats: '/chats',
  createChat: '/create-chat',
  risks: '/risks',
  risksCalendar: '/risks-calendar',
  createRisk: '/create-risks',
} as const
