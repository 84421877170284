import { LevelEnum } from '@hydra/interfaces'
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import { useCallback, useEffect, useState } from 'react'
import { TbChevronDown } from 'react-icons/tb'
import { toast } from 'react-toastify'

import { updateChat } from '../api/chat.api'
import { levelConst } from '../constants/level.const'

interface Props {
  id: string
  level: LevelEnum
  refetch: () => void
}

export const LevelChip = ({ id, level, refetch }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [color, setColor] = useState<
    'danger' | 'warning' | 'success' | 'default'
  >('default')

  const handleUpdatePriority = useCallback(
    async (level: LevelEnum) => {
      try {
        setIsLoading(true)
        const response = await updateChat(id, { level })

        if (response.status && response.status === 200) {
          toast.success('Уровень риска изменен')
          refetch()
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    },
    [id, refetch],
  )

  useEffect(() => {
    if (level === LevelEnum.red) {
      setColor('danger')
    }
    if (level === LevelEnum.yellow) {
      setColor('warning')
    }
    if (level === LevelEnum.green) {
      setColor('success')
    }
    if (level === LevelEnum.gray) {
      setColor('default')
    }
  }, [level])

  return (
    <Dropdown backdrop='blur' isDisabled={isLoading}>
      <DropdownTrigger>
        <Chip
          size='sm'
          variant='bordered'
          color={color}
          className='cursor-pointer'
          endContent={<TbChevronDown className='text-xl' />}
          as='span'
        >
          {levelConst[level] || 'Не задан'}
        </Chip>
      </DropdownTrigger>
      <DropdownMenu
        variant='faded'
        aria-label={`Приоритет задачи ${id}`}
        onAction={key => handleUpdatePriority(key as LevelEnum)}
      >
        <DropdownItem key={LevelEnum.red} textValue={LevelEnum.red}>
          <span className='text-red-500'>{levelConst[LevelEnum.red]}</span>
        </DropdownItem>
        <DropdownItem key={LevelEnum.yellow} textValue={LevelEnum.yellow}>
          <span className='text-yellow-500'>
            {levelConst[LevelEnum.yellow]}
          </span>
        </DropdownItem>
        <DropdownItem key={LevelEnum.green} textValue={LevelEnum.green}>
          <span className='text-green-500'>{levelConst[LevelEnum.green]}</span>
        </DropdownItem>
        <DropdownItem key={LevelEnum.gray} textValue={LevelEnum.gray}>
          <span className='text-gray-500'>{levelConst[LevelEnum.gray]}</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
