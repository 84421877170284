import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { PropsWithChildren } from 'react'

import { Items } from './types'

interface Props extends PropsWithChildren {
  date: Dayjs
  day: number
  isNotCurrentMonth: boolean
  items?: Items
  onSmallCalendarDayButtonClick: (day: number) => void
}

export const SmallMonthItem = ({
  date,
  isNotCurrentMonth,
  items,
  onSmallCalendarDayButtonClick,
  day,
}: Props) => {
  return (
    <div
      className={clsx('relative text-xs', {
        'text-primary': isNotCurrentMonth,
      })}
    >
      <div
        onClick={() => {
          if (!isNotCurrentMonth) {
            onSmallCalendarDayButtonClick(dayjs(date).date())
          }
        }}
        className={clsx(
          'hover:bg-primary-100 flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg p-1 transition-all',
          {
            'text-background !bg-primary text-small':
              dayjs(date).date() === day && !isNotCurrentMonth,
            '!cursor-not-allowed hover:bg-transparent': isNotCurrentMonth,
          },
        )}
      >
        {dayjs(date).date()}
        {items && items.length > 0 && (
          <div className='bg-foreground absolute top-[3px] h-[4px] w-[4px] rounded-full' />
        )}
      </div>
    </div>
  )
}
