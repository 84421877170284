import { useAtom } from 'jotai'
import { useMemo } from 'react'

import { Pagination } from '../../../features/ui'
import {
  newsbreaksWithReportsAtom,
  skipNewsbreatWithReportsAtom,
  takeNewsbreatWithReportsAtom,
} from '../store/newsbreaks.store'

export const NewsbreaksWithReportsPagination = () => {
  const [{ data }] = useAtom(newsbreaksWithReportsAtom)
  const [skip, setSkip] = useAtom(skipNewsbreatWithReportsAtom)
  const [take] = useAtom(takeNewsbreatWithReportsAtom)
  const count = useMemo(() => (data ? data[1] : 0), [data])

  return <Pagination count={count} take={take} skip={skip} setSkip={setSkip} />
}
