import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'

import { getArticleById, getArticles } from '../api/articles.api'

export const skipAtom = atom(0)
export const takeAtom = atom(25)
export const articleIdAtom = atom<string | undefined>(undefined)

export const articlesAtom = atomWithQuery(get => {
  return {
    queryKey: ['articles-list', get(skipAtom), get(takeAtom)],
    queryFn: async () => {
      const response = await getArticles({
        skip: get(skipAtom),
        take: get(takeAtom),
      })
      return response.data
    },
  }
})

export const articleAtom = atomWithQuery(get => {
  return {
    enabled: !!get(articleIdAtom),
    queryKey: ['article-by-id', get(articleIdAtom)],
    queryFn: async () => {
      const response = await getArticleById(get(articleIdAtom) || '')
      return response.data
    },
  }
})
