import { Button } from '@nextui-org/react'
import { Helmet } from 'react-helmet'
import { TbSquareRoundedPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Header, Layout } from '../../../features/ui'
import { TasksList } from '../components/TasksList'
import { TasksPagination } from '../components/TasksPagination'
import { menuItems } from '../constants/menuItems'

const pageTitle = 'Задачи'

export const Tasks = () => {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <div>
                <Button
                  color='primary'
                  startContent={<TbSquareRoundedPlus className='text-2xl' />}
                  onPress={() => navigate(routesConst.createTask)}
                >
                  Создать
                </Button>
              </div>
            }
          />
        }
      >
        <div className='space-y-4 p-4'>
          <TasksList />
          <TasksPagination />
        </div>
      </Layout>
    </>
  )
}
