import { TComment } from '@udecode/plate-comments'
import { TElement, Value } from '@udecode/plate-common'
import React from 'react'

import { PlateEditorComponent } from './plate-editor-component'
import { PlateEditorProvider } from './plate-providers'

interface Props {
  onChange: (value: Value) => void
  initialValue?: Array<TElement>
  placeholder?: string
  isReadOnly?: boolean
  isDisabled?: boolean
  commentsProps?: Record<string, TComment>
  setComments?: (comments: Record<string, TComment>) => void
  withComments: boolean
}

export const PlateEditorWrapper = (props: Props) => {
  return (
    <PlateEditorProvider comments={props.commentsProps}>
      <PlateEditorComponent {...props} />
    </PlateEditorProvider>
  )
}
