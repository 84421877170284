import { Grant } from '@hydra/interfaces'
import { Button } from '@nextui-org/react'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { TbSquareRoundedPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants, useGrant } from '../../../features/grants'
import { Layout } from '../../../features/ui'
import { UsersList } from '../components/UsersList'
import { menuItems } from '../constants/menuItems'

export const Users = () => {
  const navigate = useNavigate()
  const { isValidGrant: canCreateUser } = useGrant(Grant.newsbreaksModerator)

  const onCreateButtonClick = useCallback(() => {
    navigate(routesConst.createUser)
  }, [navigate])

  return (
    <>
      <Helmet>
        <title>Гидра | Пользователи</title>
      </Helmet>
      <Grants grants={[Grant.newsbreaksModerator]}>
        <Layout
          menuItems={menuItems}
          headerContent={
            <div className='flex h-full items-center justify-between space-x-4 px-4'>
              <h1 className='flex-1 text-3xl font-semibold'>Пользователи</h1>
              {canCreateUser && (
                <Button
                  color='primary'
                  startContent={<TbSquareRoundedPlus className='text-2xl' />}
                  onClick={onCreateButtonClick}
                >
                  Создать
                </Button>
              )}
            </div>
          }
        >
          <div className='p-4'>
            <UsersList />
          </div>
        </Layout>
      </Grants>
    </>
  )
}
