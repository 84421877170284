import { z } from 'zod'

export const loginFormSchema = z.object({
  login: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, {
      message: 'Минимальная длина 2 символа',
    })
    .max(250, {
      message: 'Максимальная длина 250 символов',
    })
    .trim(),
  password: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, {
      message: 'Минимальная длина 2 символа',
    })
    .max(250, {
      message: 'Максимальная длна 250 символов',
    })
    .trim(),
})
