export function cleanText(text: string): string {
  const timestampPattern = /\[\d{2}:\d{2}:\d{2} - \d{2}:\d{2}:\d{2}\]/g

  let cleanedText = text.replace(timestampPattern, '')

  cleanedText = cleanedText.replace(/\n\n/g, ' ')

  cleanedText = cleanedText.trim()

  return cleanedText
}
