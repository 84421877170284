import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { FaTelegramPlane } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getOTP } from '../../../features/api'
import { routesConst } from '../../../features/constants/routes.const'
import {
  EyeFilledIcon,
  EyeSlashFilledIcon,
  TextLogo,
} from '../../../features/ui'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../../../features/ui/'
import { sendOTP } from '../api'
import { loginFormSchema } from '../schemas/loginForm.schema'
import { loginAtom, loginTelegramAtom } from '../store'

type Form = {
  login: string
  password: string
}

const LoginSteps = {
  LOGIN: 'LOGIN',
  LOGIN_TELEGRAM: 'LOGIN_TELEGRAM',
  LOGIN_OTP: 'LOGIN_OTP',
} as const

const OTP_LENGTH = 6

export const LoginPage = () => {
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(false)
  const [isOTPTimeOut, setIsOTPTimeOut] = useState(false)
  const [isValidOTP, setIsOtpValid] = useState(true)
  const [telegramLogin, setTelegramLogin] = useState('')
  const [otpID, setOTPID] = useState('')
  const [loginStep, setLoginStep] = useState<string>(LoginSteps.LOGIN)
  const form = useForm<Form>({
    resolver: zodResolver(loginFormSchema),
  })

  const [{ mutateAsync, isPending }] = useAtom(loginAtom)
  const [{ mutateAsync: mutateAsyncTelegram }] = useAtom(loginTelegramAtom)

  const toggleVisibility = useCallback(
    () => setIsVisible(!isVisible),
    [isVisible],
  )

  const onSubmit = async (data: Form) => {
    const res = await mutateAsync(data)
    localStorage.setItem('token', res.accessToken)
    navigate(routesConst.main)
  }

  const loginTelegram = async () => {
    setIsOTPTimeOut(false)
    const { data: otpID } = await sendOTP({
      login: form.getValues().login,
    })

    if (otpID) {
      setTelegramLogin(form.getValues().login)

      setOTPID(otpID)

      setLoginStep(LoginSteps.LOGIN_OTP)
    } else {
      toast.error('Не верный логин telegram')
    }
  }

  const checkCode = async (value: string) => {
    const {
      data: { code, expiredAt },
    } = await getOTP(otpID)
    setIsOTPTimeOut(new Date().getTime() > new Date(expiredAt).getTime())
    if (value === code && !isOTPTimeOut) {
      const res = await mutateAsyncTelegram({ login: telegramLogin })
      localStorage.setItem('token', res.accessToken)
      navigate(routesConst.main)
    } else {
      setIsOtpValid(false)
    }
  }

  const handleChange = (value: string) => {
    if (value.length < 6) {
      setIsOtpValid(true)
    }
  }

  const nextStep = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      await loginTelegram()
    }
  }

  const failedOtpInputStyle = isValidOTP
    ? {}
    : { border: '1px solid red', boxShadow: 'none' }

  return (
    <>
      <Helmet>
        <title>Гидра | Вход</title>
      </Helmet>
      <div
        className='bg-default-50 flex h-screen w-screen'
        onKeyDown={e => nextStep(e)}
      >
        <form
          className='flex flex-1 items-center justify-center'
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className='bg-background flex w-[300px] flex-col space-y-8 rounded-2xl px-4 py-8 shadow-2xl'>
            <div className='flex justify-center'>
              <TextLogo />
            </div>
            {loginStep !== LoginSteps.LOGIN_OTP && (
              <Input
                {...form.register('login')}
                variant='bordered'
                label='Логин'
                labelPlacement='outside'
                errorMessage={form.formState.errors.login?.message}
                isDisabled={isPending}
              />
            )}
            {loginStep === LoginSteps.LOGIN && (
              <>
                <Input
                  {...form.register('password')}
                  autoComplete='password'
                  variant='bordered'
                  label='Пароль'
                  labelPlacement='outside'
                  errorMessage={form.formState.errors.password?.message}
                  isDisabled={isPending}
                  endContent={
                    <button
                      className='focus:outline-none'
                      type='button'
                      onClick={toggleVisibility}
                    >
                      {isVisible ? (
                        <EyeSlashFilledIcon className='text-default-400 pointer-events-none text-2xl' />
                      ) : (
                        <EyeFilledIcon className='text-default-400 pointer-events-none text-2xl' />
                      )}
                    </button>
                  }
                  type={isVisible ? 'text' : 'password'}
                />
                <Button
                  color='primary'
                  variant='solid'
                  type='submit'
                  isDisabled={isPending}
                  isLoading={isPending}
                >
                  Войти
                </Button>
                <Button
                  color='primary'
                  variant='solid'
                  type='button'
                  isDisabled={isPending}
                  isLoading={isPending}
                  className='bg-telegram-blue'
                  onClick={() => setLoginStep(LoginSteps.LOGIN_TELEGRAM)}
                >
                  Войти с помощью Telegram <FaTelegramPlane />
                </Button>
              </>
            )}
            {loginStep === LoginSteps.LOGIN_TELEGRAM && (
              <Button
                color='primary'
                variant='solid'
                type='button'
                isDisabled={isPending}
                isLoading={isPending}
                className='bg-telegram-blue'
                onClick={loginTelegram}
              >
                Отправить код
              </Button>
            )}
            {loginStep === LoginSteps.LOGIN_OTP && (
              <div className='mx-auto flex flex-col'>
                <InputOTP
                  maxLength={6}
                  onChange={value => handleChange(value)}
                  onComplete={value => checkCode(value)}
                >
                  <InputOTPGroup>
                    {Array(OTP_LENGTH)
                      .fill('')
                      .map((el, index) => (
                        <InputOTPSlot
                          style={failedOtpInputStyle}
                          index={index}
                          key={index}
                        />
                      ))}
                  </InputOTPGroup>
                </InputOTP>
                {isOTPTimeOut && (
                  <>
                    <span className='mt-3'>Одноразовый код истек</span>
                    <Button
                      color='primary'
                      variant='solid'
                      type='button'
                      isDisabled={isPending}
                      isLoading={isPending}
                      className='bg-telegram-blue mt-2'
                      onClick={loginTelegram}
                    >
                      Выслать новый код
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  )
}
