import { Grant, UserInterface } from '@hydra/interfaces'
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { allUsersAtom } from '../../../features/store'

interface Props {
  author: UserInterface | null
  refetch: () => void
  updateFn: (value: string) => void
}

export const AuthorChip = ({ author, updateFn }: Props) => {
  const [{ data: users, isPending }] = useAtom(allUsersAtom)

  const handleChange = useCallback(
    async (value: string) => {
      updateFn(value)
    },
    [updateFn],
  )

  return (
    <Dropdown
      backdrop='blur'
      placement='bottom-end'
      showArrow
      isDisabled={isPending}
    >
      <DropdownTrigger>
        <Chip
          as='span'
          color='default'
          variant='bordered'
          size='sm'
          className='cursor-pointer'
          endContent={<TbChevronDown className='text-xl' />}
        >
          {author?.name + ' ' + author?.surname || 'Не выбран'}
        </Chip>
      </DropdownTrigger>
      <DropdownMenu
        variant='faded'
        aria-label='Автор задачи'
        onAction={key => handleChange(key as string)}
        items={
          users
            ? users[0].filter(user => user.grants.includes(Grant.tasks))
            : []
        }
        className='max-h-[300px] overflow-auto'
      >
        {user => (
          <DropdownItem
            key={user.id}
            textValue={`${user.name} ${user.surname}`}
          >
            {user.name} {user.surname}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}
