import { Grant, NewsbreakStatusEnum } from '@hydra/interfaces'
import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
  Spinner,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback, useMemo } from 'react'
import { TbEdit, TbPlus, TbTrash } from 'react-icons/tb'

import { routesConst } from '../../../features/constants/routes.const'
import { allUsersAtom } from '../../../features/store'
import { updateNewsbreak } from '../api/newsbreaks.api'
import { newsbreakUsersAtom } from '../store/newsbreaks.store'

interface Props {
  newsbreakId: string
  status: NewsbreakStatusEnum
  canEdit: boolean
  isTooltip?: boolean
}

export const Users = ({
  status,
  newsbreakId,
  canEdit,
  isTooltip = false,
}: Props) => {
  const [{ data: users, isPending: isPendingAllUsers }] = useAtom(allUsersAtom)
  const [{ data, isPending: isPendingNewsbrekUsers, refetch }] =
    useAtom(newsbreakUsersAtom)

  const isShowUsersDropdown = useMemo(
    () =>
      canEdit &&
      [
        NewsbreakStatusEnum.canceled,
        NewsbreakStatusEnum.deleted,
        NewsbreakStatusEnum.done,
      ].includes(status),
    [canEdit, status],
  )

  const handleChangeAuthor = useCallback(
    async (authorId: string) => {
      data &&
        data.author &&
        (await updateNewsbreak(newsbreakId, {
          author: {
            id: authorId,
          },
          coAuthor:
            authorId === data.coAuthor?.id
              ? {
                  id: data.author.id,
                }
              : undefined,
        }))
      await refetch()
    },
    [data, newsbreakId, refetch],
  )

  const handleChangeCoAuthor = useCallback(
    async (coAuthorId: string) => {
      data &&
        (await updateNewsbreak(newsbreakId, {
          coAuthor: {
            id: coAuthorId,
          },
          author:
            coAuthorId === data.author?.id && data.coAuthor
              ? {
                  id: data.coAuthor.id,
                }
              : undefined,
        }))
      await refetch()
    },
    [data, newsbreakId, refetch],
  )

  const handleClearCoauthor = useCallback(async () => {
    await updateNewsbreak(newsbreakId, {
      coAuthor: null,
    })
    await refetch()
  }, [newsbreakId, refetch])

  if (!data) return null

  return (
    <div className={isTooltip ? 'space-y-4 bg-white p-2' : 'space-y-4'}>
      <div className='font-semibold '>Участники</div>
      {isPendingNewsbrekUsers ? (
        <div className='flex h-[160px] justify-center'>
          <Spinner />
        </div>
      ) : (
        <div className='text-small grid grid-cols-[160px,1fr] items-center gap-4'>
          <div className='text-default-400'>Автор</div>
          <div className='flex items-center space-x-1'>
            <Chip
              classNames={{
                base: '!max-w-[250px] h-fit p-1 whitespace-break-spaces',
              }}
              as={Link}
              href={routesConst.users + '/' + data.author?.id}
              color='primary'
              variant='bordered'
              size='sm'
            >
              {data.author?.name} {data.author?.surname}
            </Chip>
            {!isTooltip && (
              <Dropdown
                backdrop='blur'
                placement='bottom-end'
                showArrow
                isDisabled={isPendingAllUsers}
              >
                <DropdownTrigger>
                  <Button
                    size='sm'
                    isIconOnly
                    color='primary'
                    variant='light'
                    isDisabled={isShowUsersDropdown}
                  >
                    <TbEdit className='text-lg' />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  variant='faded'
                  aria-label='Автор инфоповода'
                  onAction={key => handleChangeAuthor(key as string)}
                  items={
                    users
                      ? users[0].filter(user =>
                          user.grants.includes(Grant.newsbreaksProvider),
                        )
                      : []
                  }
                  className='max-h-[300px] overflow-auto'
                >
                  {user => (
                    <DropdownItem
                      key={user.id}
                      textValue={`${user.name} ${user.surname}`}
                    >
                      {user.name} {user.surname}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>

          <div className='text-default-400'>Соавтор</div>
          <div className='flex items-center space-x-1'>
            {data.coAuthor ? (
              <Chip
                classNames={{
                  base: '!max-w-[250px] h-fit p-1 whitespace-break-spaces',
                }}
                as={Link}
                href={routesConst.users + '/' + data.coAuthor.id}
                color='primary'
                variant='bordered'
                size='sm'
              >
                {data.coAuthor.name} {data.coAuthor.surname}
              </Chip>
            ) : (
              <Chip color='default' variant='bordered' size='sm'>
                Не указан
              </Chip>
            )}
            {!isTooltip && (
              <>
                <Dropdown
                  backdrop='blur'
                  placement='bottom-end'
                  showArrow
                  isDisabled={isPendingAllUsers}
                >
                  <DropdownTrigger>
                    <Button
                      size='sm'
                      isIconOnly
                      color='primary'
                      variant='light'
                      isDisabled={isShowUsersDropdown}
                    >
                      {data.coAuthor ? (
                        <TbEdit className='text-lg' />
                      ) : (
                        <TbPlus className='text-lg' />
                      )}
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    variant='faded'
                    aria-label='Автор инфоповода'
                    onAction={key => handleChangeCoAuthor(key as string)}
                    items={
                      users
                        ? users[0].filter(user =>
                            user.grants.includes(Grant.newsbreaksProvider),
                          )
                        : []
                    }
                    className='max-h-[300px] overflow-auto'
                  >
                    {user => (
                      <DropdownItem
                        key={user.id}
                        textValue={`${user.name} ${user.surname}`}
                      >
                        {user.name} {user.surname}
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
                {data.coAuthor && (
                  <Button
                    onPress={handleClearCoauthor}
                    size='sm'
                    isIconOnly
                    color='danger'
                    variant='light'
                    isDisabled={isShowUsersDropdown}
                  >
                    <TbTrash className='text-lg' />
                  </Button>
                )}
              </>
            )}
          </div>

          <div className='text-default-400'>Организация автора</div>
          <Chip
            classNames={{
              base: 'max-w-[250px] w-fit h-fit p-1 whitespace-break-spaces',
            }}
            size='sm'
            variant='bordered'
            as={Link}
            href={
              routesConst.organizations + '/' + data.author?.organization.id
            }
          >
            {data.author?.organization?.name}
          </Chip>

          {data.coAuthor && (
            <>
              <div className='text-default-400'>Организация соавтора</div>
              <Chip
                classNames={{
                  base: 'max-w-[250px] w-fit h-fit p-1 whitespace-break-spaces',
                }}
                size='sm'
                variant='bordered'
                as={Link}
                href={
                  routesConst.organizations +
                  '/' +
                  data.coAuthor.organization.id
                }
              >
                {data.coAuthor.organization?.name}
              </Chip>
            </>
          )}
        </div>
      )}
    </div>
  )
}
