export enum NewsbreakStatusEnum {
  draft = 'draft', // Черновик
  rework = 'rework', // На доработке
  agreement = 'agreement', // На согласовании
  plan = 'plan', // В Плане
  ok = 'ok', // Готово
  deleted = 'deleted', // Архив
  inWork = 'inWork', // В работе
  canceled = 'canceled', // Отклонено
  done = 'done', // Отработано
}
