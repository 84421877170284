import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { createArea } from '../../../features/api'
import { areaConst } from '../../../features/constants/area.const'
import { createAreaSchema } from '../schemas/createArea.schema'

interface Props {
  isOpen: boolean
  organizationId: string
  onOpenChange: (isOpen: boolean) => void
  onClose: () => void
  refetch: () => void
}

export const CreateAreaModal = ({
  isOpen,
  onOpenChange,
  onClose,
  organizationId,
  refetch,
}: Props) => {
  const [isFormLoading, setIsFormLoading] = useState(false)
  const form = useForm<z.infer<typeof createAreaSchema>>({
    defaultValues: {
      title: '',
      link: '',
      type: undefined,
    },
    resolver: zodResolver(createAreaSchema),
  })

  const onSubmit = async (formData: z.infer<typeof createAreaSchema>) => {
    setIsFormLoading(true)
    const response = await createArea({
      ...formData,
      organization: { id: organizationId },
    })
    if (response && response.status === 201) {
      toast.success('Площадка успешно созадна')
      form.reset()
      refetch()
      onClose()
    }
    setIsFormLoading(false)
  }

  const handleCloseButtonClick = () => {
    form.reset()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              Создание площадки
            </ModalHeader>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <ModalBody>
                <Controller
                  control={form.control}
                  name='title'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Название'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.title}
                      errorMessage={form.formState.errors.title?.message}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='link'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      isDisabled={isFormLoading}
                      variant='bordered'
                      label='Ссылка'
                      color='primary'
                      onValueChange={onChange}
                      value={value}
                      isInvalid={!!form.formState.errors.link}
                      errorMessage={form.formState.errors.link?.message}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='type'
                  render={({ field: { onChange } }) => (
                    <Select
                      isDisabled={isFormLoading}
                      isLoading={isFormLoading}
                      items={Object.entries(areaConst).map(item => ({
                        label: item[1],
                        value: item[0],
                      }))}
                      aria-label='Тип площадки'
                      label='Выберите тип площадки'
                      variant='bordered'
                      color='primary'
                      onChange={onChange}
                      isInvalid={!!form.formState.errors.type}
                      errorMessage={form.formState.errors.type?.message}
                    >
                      {item => (
                        <SelectItem key={item.value}>{item.label}</SelectItem>
                      )}
                    </Select>
                  )}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color='danger'
                  type='button'
                  variant='light'
                  onPress={handleCloseButtonClick}
                >
                  Отменить
                </Button>
                <Button color='primary' type='submit'>
                  Создать
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
