import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Selection,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { updateArticle } from '../api/articles.api'
import { tagsAtom } from '../store/tags.store'

interface Props {
  id: string
  tags: Array<string> | null
  refetch: () => void
}

export const TagsChip = ({ id, tags, refetch }: Props) => {
  const [{ data: allTags, isPending }] = useAtom(tagsAtom)

  const handleChangeUsers = useCallback(
    async (values: Selection) => {
      if (values !== 'all') {
        await updateArticle(id, {
          tags: Array.from(values) as Array<string>,
        })
      }
      refetch()
    },
    [id, refetch],
  )

  return (
    <Dropdown
      backdrop='blur'
      placement='bottom-end'
      showArrow
      isDisabled={isPending}
    >
      <DropdownTrigger>
        {tags && allTags && tags.length > 0 ? (
          <div className='flex flex-wrap gap-2'>
            {tags.map(p => {
              const tag = allTags.find(t => t.id === p)
              return (
                <Chip
                  as='span'
                  color='secondary'
                  variant='faded'
                  size='sm'
                  className='cursor-pointer'
                  endContent={<TbChevronDown className='text-xl' />}
                >
                  {tag?.title}
                </Chip>
              )
            })}
          </div>
        ) : (
          <Chip
            as='span'
            color='default'
            variant='bordered'
            size='sm'
            className='cursor-pointer'
            endContent={<TbChevronDown className='text-xl' />}
          >
            Не выбраны
          </Chip>
        )}
      </DropdownTrigger>
      <DropdownMenu
        closeOnSelect={false}
        selectionMode='multiple'
        variant='faded'
        aria-label={`Участники задачи ${id}`}
        selectedKeys={tags || []}
        onSelectionChange={keys => handleChangeUsers(keys)}
        items={allTags || []}
        className='max-h-[300px] overflow-auto'
      >
        {user => <DropdownItem key={user.id}>{user.title}</DropdownItem>}
      </DropdownMenu>
    </Dropdown>
  )
}
