import { Link } from '@nextui-org/react'
import { Helmet } from 'react-helmet'

import { routesConst } from '../../../features/constants/routes.const'

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Гидра | Страница не найдена</title>
      </Helmet>
      <div className='absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center'>
        <h1 className='text-9xl'>404</h1>
        <p>Такой страницы не существует.</p>
        <p>
          Вернуться на <Link href={routesConst.main}>главную</Link>
        </p>
      </div>
    </>
  )
}
