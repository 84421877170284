export const areaConst = {
  smi: 'СМИ',
  unofficialPublic: 'Неофициальные паблики',
  officialPublic: 'Госпаблики',
  blogger: 'Блогеры',
  lom: 'ЛОМ',
  pages: 'Страницы Губернатора, Правительства',
  target: 'Тагрет',
  sowing: 'Посев',
} as const
