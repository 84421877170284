import {
  Button,
  Chip,
  Divider,
  ScrollShadow,
  Textarea,
  useDisclosure,
} from '@nextui-org/react'
import { Value } from '@udecode/plate-common'
import { useAtom } from 'jotai/index'
import { equals } from 'ramda'
import { useCallback, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { IoCloseCircle } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  DeleteModal,
  Empty,
  Header,
  Layout,
  PageSpinner,
  PlateEditorWrapper,
} from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { deleteArticle, updateArticle } from '../api/articles.api'
import { FolderChip } from '../components/FolderChip'
import { TagsChip } from '../components/TagsChip'
import { menuItems } from '../constants/menuItems'
import { articleAtom, articleIdAtom } from '../store/articles.store'
import { tagsAtom } from '../store/tags.store'

export const Article = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [, setId] = useAtom(articleIdAtom)
  const [{ data, isPending, refetch }] = useAtom(articleAtom)
  const [{ data: tags }] = useAtom(tagsAtom)
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
    onClose: onDeleteClose,
  } = useDisclosure()

  const pageTitle = useMemo(() => `Статья: ${data?.title}`, [data?.title])

  const handleChangeDescription = useCallback(
    async (value: Value) => {
      if (data && !equals(value, data.description)) {
        await updateArticle(data.id, {
          description: value,
        })
        await refetch()
      }
    },
    [data, refetch],
  )

  const handleChangeTitle = useCallback(
    async (value: string) => {
      if (data) {
        await updateArticle(data.id, {
          title: value,
        })
      }
    },
    [data],
  )

  const handleChangeFolder = useCallback(
    async (value: string) => {
      if (data) {
        await updateArticle(data.id, {
          folder: { id: value },
        })
        await refetch()
      }
    },
    [data, refetch],
  )

  useEffect(() => {
    if (id) {
      setId(id)
    }
    return () => {
      setId(undefined)
    }
  }, [id, setId])

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        {isPending ? (
          <PageSpinner />
        ) : !data ? (
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <Empty />
          </div>
        ) : (
          <div className='p-4'>
            <div className='bg-background h-[calc(100vh-104px)] overflow-hidden rounded-xl  shadow'>
              <div className='space-y-4 p-4'>
                <div className='flex items-center justify-end'>
                  <div className='flex space-x-2'>
                    <Button
                      onPress={onDeleteOpen}
                      color='danger'
                      variant='flat'
                      endContent={<IoCloseCircle className='text-xl' />}
                    >
                      Удалить
                    </Button>
                    <Button
                      onPress={() => {
                        navigate(-1)
                      }}
                      color='primary'
                      variant='flat'
                      endContent={<IoCloseCircle className='text-xl' />}
                    >
                      Закрыть
                    </Button>
                  </div>
                </div>

                <Divider />

                <div className='flex space-x-4'>
                  <div className='flex-1 space-y-4'>
                    <div className='flex space-x-4'>
                      <div className='flex-1 items-start text-lg font-semibold'>
                        <Textarea
                          minRows={1}
                          color='primary'
                          variant='underlined'
                          defaultValue={data.title}
                          onValueChange={handleChangeTitle}
                        />
                      </div>
                    </div>
                    <ScrollShadow
                      size={0}
                      className='tiptap h-[calc(100vh-265px)] space-y-2 overflow-auto'
                    >
                      <PlateEditorWrapper
                        withComments={false}
                        initialValue={data.description}
                        onChange={handleChangeDescription}
                      />
                    </ScrollShadow>
                  </div>

                  <Divider orientation='vertical' className='h-[initial]' />

                  <ScrollShadow
                    size={0}
                    className='h-[calc(100vh-210px)] w-[500px] space-y-6 overflow-auto'
                  >
                    <div className='space-y-4'>
                      <div className='font-semibold '>Пользователи</div>
                      <div className='text-small grid grid-cols-[130px,1fr] items-center gap-4'>
                        <div className='text-default-400'>Автор:</div>
                        <Chip size='sm' variant='bordered' color='primary'>
                          {data.author
                            ? `${data.author?.name} ${data.author?.surname}`
                            : 'Не указан'}
                        </Chip>
                      </div>

                      <Divider />

                      <div className='font-semibold'>Основная информация</div>
                      <div className='text-small grid grid-cols-[130px,1fr] items-center gap-4'>
                        <div className='text-default-400'>Создана:</div>
                        {getPrettyDate({
                          date: data.createdAt,
                        })}
                        <div className='text-default-400'>Папка:</div>
                        <FolderChip
                          refetch={refetch}
                          folder={data.folder}
                          updateFn={handleChangeFolder}
                        />

                        <div className='text-default-400'>Тэги:</div>
                        <div className='flex flex-wrap gap-1'>
                          <TagsChip
                            refetch={refetch}
                            tags={data.tags}
                            id={data.id}
                          />
                        </div>
                      </div>
                    </div>
                  </ScrollShadow>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        onClose={onDeleteClose}
        message='Удалить статью?'
        deleteAction={async () => {
          await deleteArticle(id || '')
          navigate(-1)
          toast.success('Статья удалена')
        }}
      />
    </>
  )
}
