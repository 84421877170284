import { ExcuseEnum } from '@hydra/interfaces'
import { z } from 'zod'

export const createMissedNewsbreakSchema = z.object({
  createdAt: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, 'Минимальная длина 2'),
  link: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, 'Минимальная длина 2')
    .url('Некорректный url'),
  excuse: z.nativeEnum(ExcuseEnum, {
    required_error: 'Обязательное поле',
  }),
  description: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, 'Минимальная длина 2'),
})
