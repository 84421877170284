import { zodResolver } from '@hookform/resolvers/zod'
import { Grant } from '@hydra/interfaces'
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
} from '@nextui-org/react'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants } from '../../../features/grants'
import { Header, Layout } from '../../../features/ui'
import { createChat } from '../api/chat.api'
import { levelConst } from '../constants/level.const'
import { menuItems } from '../constants/menuItems'
import { createChatSchema } from '../schemas/createChat.schema'

const pageTitle = 'Создание чата'

type Form = z.infer<typeof createChatSchema>

export const CreateChat = () => {
  const navigate = useNavigate()
  const [isFormLoading, setIsFormLoading] = useState(false)

  const form = useForm<Form>({
    resolver: zodResolver(createChatSchema),
  })

  const onSubmit = useCallback(
    async (formData: Form) => {
      setIsFormLoading(true)

      try {
        const response = await createChat({
          ...formData,
        })

        if (response.status && response.status === 201) {
          toast.success('Чат успешно создан')
          navigate(routesConst.chats)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [navigate],
  )

  return (
    <Grants grants={[Grant.publics]}>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className='bg-background m-4 flex h-[calc(100vh-104px)] flex-col space-y-4 rounded-2xl p-4 shadow'
        >
          <div className='space-y-4'>
            <Controller
              control={form.control}
              name='title'
              render={({ field: { onChange, value } }) => (
                <Input
                  isRequired
                  isDisabled={isFormLoading}
                  variant='bordered'
                  size='sm'
                  label='Название'
                  color='primary'
                  onValueChange={onChange}
                  value={value}
                  isInvalid={!!form.formState.errors.title}
                  errorMessage={form.formState.errors.title?.message}
                />
              )}
            />
            <Controller
              control={form.control}
              name='link'
              render={({ field: { onChange, value } }) => (
                <Input
                  isDisabled={isFormLoading}
                  variant='bordered'
                  size='sm'
                  label='Пригласительная ссылка'
                  color='primary'
                  onValueChange={onChange}
                  value={value}
                  isInvalid={!!form.formState.errors.link}
                  errorMessage={form.formState.errors.link?.message}
                />
              )}
            />
            <Controller
              control={form.control}
              name='level'
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  isDisabled={isFormLoading}
                  aria-label='Уровень риска'
                  isLoading={isFormLoading}
                  variant='bordered'
                  color='primary'
                  defaultItems={Object.entries(levelConst).map(item => ({
                    id: item[0],
                    title: item[1],
                  }))}
                  size='sm'
                  label='Выберите уровень риска'
                  onSelectionChange={onChange}
                  defaultSelectedKey={value}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  onKeyDown={e => e.continuePropagation()}
                >
                  {item => (
                    <AutocompleteItem key={item.id} textValue={item.title}>
                      {item.title}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
              )}
            />
          </div>

          <div className='flex flex-1 items-end justify-end'>
            <Button
              color='primary'
              type='submit'
              isDisabled={isFormLoading}
              isLoading={isFormLoading}
            >
              Сохранить
            </Button>
          </div>
        </form>
      </Layout>
    </Grants>
  )
}
