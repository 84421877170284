import { Button, Input } from '@nextui-org/react'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { toast } from 'react-toastify'

import {
  endOfCreatedDayAtom,
  startOfCreatedDayAtom,
} from '../store/newsbreaks.store'

export const CreatedDateFilter = () => {
  const [startOfCreatedDay, setStartOfCreatedDay] = useAtom(
    startOfCreatedDayAtom,
  )
  const [endOfCreatedDay, setEndOfCreatedDay] = useAtom(endOfCreatedDayAtom)

  return (
    <div className='space-y-1'>
      <div className='flex w-full items-center'>
        <label
          className='text-small text-default-400 flex-1'
          htmlFor='newsbreaks-date-filter-start'
        >
          Период создания
        </label>
        <Button
          variant='light'
          size='sm'
          color='primary'
          onPress={() => {
            setStartOfCreatedDay('')
            setEndOfCreatedDay('')
          }}
        >
          Сбросить
        </Button>
      </div>
      <div className='flex flex-col space-y-2'>
        <Input
          aria-labelledby='Начало периода'
          id='newsbreaks-date-filter-start'
          type='date'
          variant='bordered'
          value={
            startOfCreatedDay
              ? dayjs(startOfCreatedDay).format('YYYY-MM-DD').toString()
              : ''
          }
          onValueChange={value => setStartOfCreatedDay(dayjs(value))}
          classNames={{
            inputWrapper: 'h-10',
          }}
        />
        <Input
          aria-labelledby='Конец периода'
          id='newsbreaks-date-filter-end'
          type='date'
          variant='bordered'
          value={
            endOfCreatedDay
              ? dayjs(endOfCreatedDay).format('YYYY-MM-DD').toString()
              : ''
          }
          onValueChange={value => {
            if (startOfCreatedDay > dayjs(value)) {
              toast.error('Конец периода должен быть после его начала')
            } else {
              setEndOfCreatedDay(dayjs(value))
            }
          }}
          classNames={{
            inputWrapper: 'h-10',
          }}
        />
      </div>
    </div>
  )
}
