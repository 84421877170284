export const MenuLogo = ({ isFull }: { isFull: boolean }) => {
  return isFull ? (
    <svg
      width='120'
      height='21'
      viewBox='0 0 120 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.7142 21V0H38.9994V3.81276H29.9953V21H25.7142Z'
        fill='#757F99'
      />
      <path
        d='M41.4393 21V0H45.4165V13.4936L55.1388 0H58.3151V21H54.3378V7.44681L44.588 21H41.4393Z'
        fill='#757F99'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.6056 17.366V21H82.0663V17.366H79.3043V0H65.0248V5.54042C65.0248 11.2199 64.2054 15.1617 62.5666 17.366H60.6056ZM74.9956 17.366H66.8753C67.575 16.1546 68.0998 14.6255 68.4497 12.7787C68.7995 10.9121 68.9744 8.64822 68.9744 5.98723V3.60425H74.9956V17.366Z'
        fill='#757F99'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.6224 21V0H93.5161C95.6888 0 97.3645 0.595745 98.5429 1.78723C99.7214 2.95886 100.311 4.59716 100.311 6.70213C100.311 8.80709 99.7214 10.4553 98.5429 11.6468C97.3645 12.8383 95.6888 13.434 93.5161 13.434H88.9035V21H84.6224ZM88.9035 9.85957H92.798C95.0628 9.85957 96.1952 8.80709 96.1952 6.70213C96.1952 4.63688 95.0628 3.60425 92.798 3.60425H88.9035V9.85957Z'
        fill='#757F99'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M107.599 0L98.6497 21H102.986L104.781 16.4426H113.868L115.691 21H120L111.051 0H107.599ZM106.218 12.8383L109.283 4.91489H109.339L112.46 12.8383H106.218Z'
        fill='#757F99'
      />
      <path
        d='M0 0H18.0952V17.3891L14.3965 21H0V17.1818L2.85714 14.3182L7.61905 19.0909V16.2273H10.4762L8.57143 14.3182H2.85714H0V11.4545H2.85714L5.71429 8.59091L10.4762 13.3636V10.5H13.3333L11.4286 8.59091H5.71429H0V5.72727H5.71429L8.57143 2.86364L13.3333 7.63636V4.77273H16.1905L14.2857 2.86364H8.57143H0V0Z'
        fill='#006FEE'
      />
    </svg>
  ) : (
    <svg
      width='19'
      height='21'
      viewBox='0 0 19 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 0H18.0952V17.3891L14.3965 21H0V17.1818L2.85714 14.3182L7.61905 19.0909V16.2273H10.4762L8.57143 14.3182H2.85714H0V11.4545H2.85714L5.71429 8.59091L10.4762 13.3636V10.5H13.3333L11.4286 8.59091H5.71429H0V5.72727H5.71429L8.57143 2.86364L13.3333 7.63636V4.77273H16.1905L14.2857 2.86364H8.57143H0V0Z'
        fill='#006FEE'
      />
    </svg>
  )
}
