import { Grant } from '@hydra/interfaces'
import { Button, Divider, Link } from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { TbEdit } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { organizationTypeConst } from '../../../features/constants/organizationType.const'
import { routesConst } from '../../../features/constants/routes.const'
import { useGrant } from '../../../features/grants'
import { organizationByIdAtom } from '../../../features/store'
import { Empty } from '../../../features/ui'
import { formatRussianPhoneNumber } from '../../../features/utils/formatRussianPhoneNumber.util'
import { generateMailtoLink } from '../../../features/utils/generateMailtoLink.util'

export const OrganizationMainInfo = () => {
  const navigate = useNavigate()
  const [{ data }] = useAtom(organizationByIdAtom)
  const { isValidGrant: canEditOrganization } = useGrant(
    Grant.newsbreaksModerator,
  )

  const onEditButtonPress = useCallback(() => {
    if (data) {
      const params = new URLSearchParams({
        id: data.id,
        name: data.name,
        contactPerson: data.contactPerson,
        contactPersonPhone: data.contactPersonPhone,
        contactPersonEmail: data.contactPersonEmail,
        type: data.type,
        site: data.site || '',
        apiKey: data.apiKey || '',
      })
      navigate(routesConst.editOrganization + '?' + params)
    }
  }, [data, navigate])

  if (!data) return <Empty />

  return (
    <div className='space-y-4'>
      <div className='bg-background space-y-4 rounded-xl p-4 shadow'>
        <div className='flex items-center justify-between'>
          <div>
            <div className='text-lg font-semibold'>{data.name}</div>
          </div>
          <div>
            {canEditOrganization && (
              <Button
                variant='bordered'
                startContent={<TbEdit className='text-2xl' />}
                onPress={onEditButtonPress}
              >
                Редактировать
              </Button>
            )}
          </div>
        </div>
        <Divider />
        <div className='flex space-x-8'>
          <div className='space-y-1'>
            <div className='text-default-400 text-sm'>Имя руководителя</div>
            <div className='font-semibold'>{data.contactPerson}</div>
          </div>
          <Divider orientation='vertical' className='h-[initial]' />
          <div className='space-y-1'>
            <div className='text-default-400 text-sm'>Телефон руководителя</div>
            <div className='font-semibold'>
              {formatRussianPhoneNumber(data.contactPersonPhone)}
            </div>
          </div>
          <Divider orientation='vertical' className='h-[initial]' />
          <div className='space-y-1'>
            <div className='text-default-400 text-sm'>Email руководителя</div>
            <Link
              isBlock
              isExternal
              href={generateMailtoLink(data.contactPersonEmail)}
            >
              {data.contactPersonEmail}
            </Link>
          </div>
          <Divider orientation='vertical' className='h-[initial]' />
          <div className='space-y-1'>
            <div className='text-default-400 text-sm'>Сайт</div>
            <div className='font-semibold'>
              {data.site && (
                <Link isExternal isBlock size='sm' href={data.site}>
                  {data.site}
                </Link>
              )}
            </div>
          </div>
          <Divider orientation='vertical' className='h-[initial]' />
          <div className='space-y-1'>
            <div className='text-default-400 text-sm'>Тип организации</div>
            <div className='font-semibold'>
              {organizationTypeConst[data.type]}
            </div>
          </div>
        </div>
      </div>
      {data.apiKey && (
        <div className='bg-background space-y-2 rounded-xl p-4 shadow'>
          <div className='font-semibold'>Ключ API для Яндекс.Метрики</div>
          <div>{data.apiKey}</div>
        </div>
      )}
    </div>
  )
}
