import { Grant, UserInterface } from '@hydra/interfaces'
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { allUsersAtom } from '../../../features/store'
import { updateCollection } from '../api/collections.api'

interface Props {
  id: string
  responsible: UserInterface | null
  refetch: () => void
}

export const ResponsibleChip = ({ id, responsible, refetch }: Props) => {
  const [{ data: users, isPending }] = useAtom(allUsersAtom)

  const handleChangeUsers = useCallback(
    async (value: string) => {
      await updateCollection(id, {
        responsible: { id: value },
      })
      refetch()
    },
    [id, refetch],
  )

  return (
    <Dropdown
      backdrop='blur'
      placement='bottom-end'
      showArrow
      isDisabled={isPending}
    >
      <DropdownTrigger>
        <Chip
          as='span'
          color='default'
          variant='bordered'
          size='sm'
          className='cursor-pointer'
          endContent={<TbChevronDown className='text-xl' />}
        >
          {responsible
            ? responsible.name + ' ' + responsible.surname
            : 'Не выбран'}
        </Chip>
      </DropdownTrigger>
      <DropdownMenu
        variant='faded'
        aria-label={`Исполнитель задачи ${id}`}
        onAction={key => handleChangeUsers(key as string)}
        items={
          users
            ? users[0].filter(user => user.grants.includes(Grant.tasks))
            : []
        }
        className='max-h-[300px] overflow-auto'
      >
        {user => (
          <DropdownItem key={user.id}>
            {user.name} {user.surname}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}
