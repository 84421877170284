import { getLocalTimeZone, parseDate } from '@internationalized/date'
import {
  Button,
  DateRangePicker,
  DateValue,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  RangeValue,
  Selection,
} from '@nextui-org/react'
import { useDateFormatter } from '@react-aria/i18n'
import plural from 'plural-ru'
import { useCallback } from 'react'
import { TbCalendar } from 'react-icons/tb'

import { extendedDayjs } from '../../utils/dayjsConfig'

const PERIODS: { [key: string]: number } = {
  'last-24-hour': 1,
  'last-7-days': 7,
  'last-14-days': 14,
  'last-30-days': 30,
  'last-90-days': 90,
  'last-180-days': 180,
  'last-365-days': 365,
}

interface Props {
  dateValue: RangeValue<DateValue>
  setDateValue: (value: RangeValue<DateValue>) => void
  isPending: boolean
}

export const PeriodDatePicker = ({
  isPending,
  dateValue,
  setDateValue,
}: Props) => {
  const formatter = useDateFormatter({ dateStyle: 'medium' })

  const handleChangeDateRange = useCallback(
    (period: RangeValue<DateValue>) => {
      setDateValue(period)
    },
    [setDateValue],
  )

  const handleChangePeriod = useCallback(
    (keys: Selection) => {
      const key = Array.from(keys)[0]
      const days = PERIODS[key]

      if (days) {
        setDateValue({
          start: parseDate(
            extendedDayjs().subtract(days, 'days').format('YYYY-MM-DD'),
          ),
          end: parseDate(extendedDayjs().format('YYYY-MM-DD')),
        })
      }
    },
    [setDateValue],
  )

  return (
    <Dropdown
      shouldCloseOnInteractOutside={e => false}
      backdrop='opaque'
      isDisabled={isPending}
    >
      <DropdownTrigger>
        <Button
          className='justify-start'
          variant='bordered'
          startContent={<TbCalendar />}
        >
          {formatter.formatRange(
            dateValue.start.toDate(getLocalTimeZone()),
            dateValue.end.toDate(getLocalTimeZone()),
          )}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label='Период дат'
        closeOnSelect={false}
        selectionMode='single'
        onSelectionChange={handleChangePeriod}
      >
        <DropdownSection showDivider>
          {Object.keys(PERIODS).map(key => (
            <DropdownItem key={key} textValue={key} closeOnSelect>
              {`За ${plural(PERIODS[key], 'последний', 'посделние', 'последние')} ${PERIODS[key]} ${plural(PERIODS[key], 'день', 'дня', ' дней')}`}
            </DropdownItem>
          ))}
        </DropdownSection>
        <DropdownItem
          textValue='custom-period'
          key='custom-period'
          closeOnSelect={false}
          hideSelectedIcon
          className='data-[hover=true]:bg-background data-[selectable=true]:focus:bg-background focus:bg-background" '
        >
          <DateRangePicker
            labelPlacement='outside'
            aria-label='Выберите период'
            variant='bordered'
            visibleMonths={2}
            value={dateValue}
            onChange={handleChangeDateRange}
          />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
