import { z } from 'zod'

export const createUserSchema = z.object({
  name: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  surname: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  login: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  phone: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, 'Минимальная длина 2')
    .trim(),
  email: z
    .string({
      required_error: 'Обязательное поле',
    })
    .email({ message: 'Некорретный email' })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  telegram: z
    .string()
    .regex(/^(?!.*t\.me\/|.*@).*$/, {
      message: 'Нужно ввести никнейм пользователя телеграм',
    })
    .trim()
    .optional(),
  chatId: z.string().optional(),
  organization: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Обязательное поле')
    .min(2, 'Обязательное поле')
    .trim(),
  newsbreakRole: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Обязательное поле')
    .min(2, 'Обязательное поле')
    .trim(),
})
export const editUserSchema = z.object({
  name: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  surname: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  login: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  phone: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, 'Минимальная длина 11')
    .trim(),
  email: z
    .string({
      required_error: 'Обязательное поле',
    })
    .email({ message: 'Некорретный email' })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  telegram: z
    .string({
      required_error: 'Обязательное поле',
    })
    .regex(/^(?!.*t\.me\/|.*@).*$/, {
      message: 'Нужно ввести никнейм пользователя телеграм',
    })
    .trim()
    .optional(),
  chatId: z.string().trim().optional(),
  organization: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Обязательное поле')
    .min(2, 'Обязательное поле')
    .trim(),
})
