import { Grant } from '@hydra/interfaces'
import { Button, Divider } from '@nextui-org/react'
import clsx from 'clsx'
import { ReactNode, useCallback, useEffect } from 'react'
import { TbCircleArrowLeft, TbUser } from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsClient, useLocalStorage } from 'usehooks-ts'

import { routesConst } from '../../../constants/routes.const'
import { useMobileWidth } from '../../../hooks/useMobileWidth'
import { MenuItem } from './MenuItem'
import { ThemeSwitcher } from './ThemeSwitcher'

interface Props {
  items: Array<{
    title: string
    link: string
    icon: ReactNode
    grant: Grant | Grant[] | undefined
  }>
}

export const Menu = ({ items }: Props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isMobile = useMobileWidth()
  const isClient = useIsClient()
  const [isMenuCollapsed, setIsMenuCollapsed] = useLocalStorage(
    'isMenuCollapsed',
    false,
  )

  const handleToggleSidebar = useCallback(
    () => setIsMenuCollapsed(prev => !prev),
    [setIsMenuCollapsed],
  )

  useEffect(() => {
    if (isMobile && isClient) {
      setIsMenuCollapsed(false)
    }
  }, [isClient, isMobile, setIsMenuCollapsed])

  return (
    <div className='flex flex-1 flex-col items-center space-y-4 px-2 pb-6'>
      <div className='flex w-full flex-1 flex-col items-center space-y-1'>
        {items.map(item => (
          <MenuItem
            key={item.title}
            title={item.title}
            icon={item.icon}
            link={item.link}
            grant={item.grant}
          />
        ))}
      </div>

      <Button
        fullWidth
        size='sm'
        variant={pathname === routesConst.profile ? 'flat' : 'light'}
        onClick={() => navigate(routesConst.profile)}
        isIconOnly={isMenuCollapsed}
        startContent={isMenuCollapsed ? null : <TbUser className='text-lg' />}
        className={clsx('justify-start space-x-2', {
          'justify-center': isMenuCollapsed,
        })}
      >
        {isMenuCollapsed ? (
          <span className='text-lg'>
            <TbUser />
          </span>
        ) : (
          <span>Личный кабинет</span>
        )}
      </Button>

      <Divider />

      {!isMobile && (
        <Button
          fullWidth
          variant='bordered'
          onClick={handleToggleSidebar}
          size='sm'
          isIconOnly={isMenuCollapsed}
          startContent={
            isMenuCollapsed ? null : <TbCircleArrowLeft className='text-lg' />
          }
        >
          {!isMenuCollapsed ? (
            <span>Свернуть</span>
          ) : (
            <TbCircleArrowLeft className='rotate-180 text-lg' />
          )}
        </Button>
      )}

      <ThemeSwitcher />
    </div>
  )
}
