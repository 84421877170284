import {
  Button,
  Divider,
  ScrollShadow,
  Textarea,
  useDisclosure,
} from '@nextui-org/react'
import { Value } from '@udecode/plate-common'
import { useAtom } from 'jotai/index'
import { equals } from 'ramda'
import { useCallback, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { IoCloseCircle } from 'react-icons/io5'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { routesConst } from '../../../features/constants/routes.const'
import {
  DeleteModal,
  Empty,
  Header,
  Layout,
  PageSpinner,
  PlateEditorWrapper,
} from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { deleteCollection, updateCollection } from '../api/collections.api'
import { AuthorChip } from '../components/AuthorChip'
import { CollectionFiles } from '../components/CollectionFiles'
import { ResponsibleChip } from '../components/ResponsibleChip'
import { menuItems } from '../constants/menuItems'
import { collectionAtom, collectionIdAtom } from '../store/colletcions.store'

export const Collection = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [, setId] = useAtom(collectionIdAtom)
  const [{ data, isPending, refetch }] = useAtom(collectionAtom)
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
    onClose: onDeleteClose,
  } = useDisclosure()

  const pageTitle = useMemo(
    () => `Проект: ${data?.current.title || ''}`,
    [data],
  )

  const onPrevCollection = useCallback(() => {
    if (data?.prev) navigate(routesConst.collections + '/' + data.prev)
  }, [data?.prev, navigate])

  const onNextCollection = useCallback(() => {
    if (data?.next) navigate(routesConst.collections + '/' + data.next)
  }, [data?.next, navigate])

  const handleChangeDescription = useCallback(
    async (value: Value) => {
      if (data && !equals(value, data.current.description)) {
        await updateCollection(data.current.id, {
          description: value,
        })
        await refetch()
      }
    },
    [data, refetch],
  )

  const handleChangeTitle = useCallback(
    async (value: string) => {
      if (data?.current) {
        await updateCollection(data.current.id, {
          title: value,
        })
      }
    },
    [data],
  )

  const handleChangeAuthor = useCallback(
    async (value: string) => {
      if (data?.current) {
        await updateCollection(data.current.id, {
          author: { id: value },
        })
        await refetch()
      }
    },
    [data, refetch],
  )

  useEffect(() => {
    if (id) {
      setId(id)
    }
    return () => {
      setId(undefined)
    }
  }, [id, setId])

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        <div className='p-4'>
          {isPending ? (
            <PageSpinner />
          ) : !data ? (
            <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
              <Empty />
            </div>
          ) : (
            <div className='p-4'>
              <div className='bg-background h-[calc(100vh-104px)] overflow-hidden rounded-xl  shadow'>
                <div className='space-y-4 p-4'>
                  <div className='flex items-center justify-between'>
                    <div className='flex space-x-2'>
                      <Button
                        isIconOnly
                        variant='bordered'
                        color='default'
                        size='sm'
                        isDisabled={!data.next || data.next === data.current.id}
                        onPress={onNextCollection}
                      >
                        <TbChevronUp className='text-primary text-lg' />
                      </Button>
                      <Button
                        isIconOnly
                        variant='bordered'
                        color='default'
                        size='sm'
                        isDisabled={!data.prev || data.prev === data.current.id}
                        onPress={onPrevCollection}
                      >
                        <TbChevronDown className='text-primary text-lg' />
                      </Button>
                    </div>
                    <div className='flex space-x-2'>
                      <Button
                        onPress={onDeleteOpen}
                        color='danger'
                        variant='flat'
                        endContent={<IoCloseCircle className='text-xl' />}
                      >
                        Удалить
                      </Button>
                      <Button
                        onPress={() => {
                          navigate(-1)
                        }}
                        color='primary'
                        variant='flat'
                        endContent={<IoCloseCircle className='text-xl' />}
                      >
                        Закрыть
                      </Button>
                    </div>
                  </div>

                  <Divider />

                  <div className='flex space-x-4'>
                    <div className='flex-1 space-y-4'>
                      <div className='flex space-x-4'>
                        <div className='flex-1 items-start text-lg font-semibold'>
                          <Textarea
                            minRows={1}
                            color='primary'
                            variant='underlined'
                            defaultValue={data.current.title}
                            onValueChange={handleChangeTitle}
                          />
                        </div>
                      </div>
                      <ScrollShadow
                        size={0}
                        className='tiptap h-[calc(100vh-265px)] space-y-2 overflow-auto'
                      >
                        <PlateEditorWrapper
                          withComments={false}
                          initialValue={data.current.description}
                          onChange={handleChangeDescription}
                        />
                      </ScrollShadow>
                    </div>

                    <Divider orientation='vertical' className='h-[initial]' />

                    <ScrollShadow
                      size={0}
                      className='h-[calc(100vh-210px)] w-[500px] space-y-6 overflow-auto'
                    >
                      <div className='space-y-4'>
                        <div className='font-semibold '>Пользователи</div>
                        <div className='text-small grid grid-cols-[130px,1fr] items-center gap-4'>
                          <div className='text-default-400'>Автор:</div>
                          <AuthorChip
                            author={data.current.author}
                            refetch={refetch}
                            updateFn={handleChangeAuthor}
                          />
                          <div className='text-default-400'>Отвественный:</div>
                          <ResponsibleChip
                            id={data.current.id}
                            responsible={data.current.responsible}
                            refetch={refetch}
                          />
                        </div>

                        <Divider />

                        <div className='font-semibold'>Основная информация</div>
                        <div className='text-small grid grid-cols-[130px,1fr] items-center gap-4'>
                          <div className='text-default-400'>Создан:</div>
                          {getPrettyDate({
                            date: data.current.createdAt,
                          })}
                        </div>

                        <Divider />

                        <div className='font-semibold'>Материалы</div>
                        <CollectionFiles collection={data.current} />
                      </div>
                    </ScrollShadow>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        onClose={onDeleteClose}
        message='Удалить проект?'
        deleteAction={async () => {
          await deleteCollection(id || '')
          navigate(-1)
          toast.success('Проект удалена')
        }}
      />
    </>
  )
}
