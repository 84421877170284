import { Grant, NewsbreakInterface, ReportInterface } from '@hydra/interfaces'
import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback, useEffect, useState } from 'react'
import { TbDotsVertical, TbFileDownload } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { linkFormatConst } from '../../../features/constants/linkFormat.const'
import { routesConst } from '../../../features/constants/routes.const'
import { useGrant } from '../../../features/grants'
import { DeleteModal } from '../../../features/ui'
import { createLinkAndDownloadFile } from '../../../features/utils/createLinkAndDownloadFile'
import { deleteReport, getExcelFile } from '../api/reports.api'
import { newsbreaksWithReportsAtom } from '../store/newsbreaks.store'

interface Props {
  isOpen: boolean
  onOpenChange: () => void
  onClose: () => void
  newsbreak: NewsbreakInterface
}

const columns = [
  {
    key: 'author',
    label: 'Организация',
    width: 200,
  },
  {
    key: 'theme',
    label: 'Тема',
  },
  {
    key: 'links',
    label: 'Ссылки',
  },
  {
    key: 'actions',
    label: '',
  },
]

export const ReportsModal = ({
  isOpen,
  onOpenChange,
  onClose,
  newsbreak,
}: Props) => {
  const [reportId, setReportId] = useState<string | null>(null)
  const navigate = useNavigate()
  const [disabledKeys, setDisabledKeys] = useState<Array<string>>([])
  const [{ refetch }] = useAtom(newsbreaksWithReportsAtom)
  const { isValidGrant: isEditor } = useGrant(Grant.newsbreaksEditor)

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
    onClose: onDeleteClose,
  } = useDisclosure()

  const onDeleteReportButtonPress = useCallback(async () => {
    if (reportId) {
      await deleteReport(reportId)
      await refetch()
      toast.success('Отчет успешно удален')
      setReportId(null)
      onDeleteClose()
    }
  }, [onDeleteClose, refetch, reportId])

  const renderCell = useCallback(
    (report: ReportInterface, columnKey: Key) => {
      switch (columnKey) {
        case 'author':
          return <div>{report.author.organization?.name}</div>
        case 'theme':
          return (
            <div>
              <Chip variant='flat' size='sm' color='secondary'>
                {report.theme?.title}
              </Chip>
            </div>
          )
        case 'links':
          return (
            <div className='space-y-2 break-all'>
              {report.links.map(link => (
                <div
                  key={Math.random()}
                  className='flex items-center justify-between space-x-2'
                >
                  <Link
                    isExternal
                    isBlock
                    href={link.url}
                    className='underline '
                  >
                    {link.url}
                  </Link>
                  <Chip variant='flat' size='sm'>
                    {linkFormatConst[link.format]}
                  </Chip>
                </div>
              ))}
            </div>
          )
        case 'actions':
          return (
            <Dropdown>
              <DropdownTrigger>
                <Button size='sm' isIconOnly variant='light'>
                  <TbDotsVertical />
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label='Меню выбора действий с файлом'
                items={[
                  {
                    key: 'edit',
                    label: 'Редактировать',
                    action: () => {
                      const params = new URLSearchParams({
                        id: report.id,
                        title: report.title,
                        theme: report.theme.id,
                        links: JSON.stringify(report.links),
                      })
                      onClose()
                      navigate(routesConst.editReport + '?' + params)
                    },
                  },
                  {
                    key: 'delete',
                    label: 'Удалить',
                    action: () => {
                      setReportId(report.id)
                      onDeleteOpen()
                    },
                  },
                ]}
                disabledKeys={disabledKeys}
              >
                {item => (
                  <DropdownItem key={item.key} onPress={item.action}>
                    {item.label}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          )

        default:
          return <div>-</div>
      }
    },
    [disabledKeys, navigate, onClose, onDeleteOpen],
  )

  const handleDownloadReport = useCallback(async () => {
    const response = await getExcelFile(newsbreak.id)
    const fileURL = URL.createObjectURL(response.data)
    createLinkAndDownloadFile(
      fileURL,
      `Отчет по инфоповоду ${newsbreak.title}.xlsx`,
    )
  }, [newsbreak.id, newsbreak.title])

  useEffect(() => {
    if (!isEditor) {
      setDisabledKeys(prev => [...prev, 'edit', 'delete'])
    }
  }, [isEditor])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size='full'
        scrollBehavior='inside'
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className='mr-4 flex justify-between'>
                <div>Отчеты</div>
                {newsbreak.reports.length > 0 && (
                  <Button
                    startContent={<TbFileDownload className='text-lg' />}
                    size='sm'
                    color='primary'
                    onPress={handleDownloadReport}
                  >
                    Скачать отчет
                  </Button>
                )}
              </ModalHeader>
              <ModalBody>
                <Table
                  aria-label='Отчеты инфоповода'
                  isHeaderSticky
                  isStriped
                  removeWrapper
                  classNames={{
                    base: 'overflow-auto',
                  }}
                >
                  <TableHeader columns={columns}>
                    {column => (
                      <TableColumn width={column.width} key={column.key}>
                        {column.label}
                      </TableColumn>
                    )}
                  </TableHeader>
                  <TableBody items={newsbreak.reports}>
                    {item => (
                      <TableRow key={item.id}>
                        {columnKey => (
                          <TableCell className='align-baseline'>
                            {renderCell(item, columnKey)}
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <DeleteModal
        onClose={onDeleteClose}
        message='Удалить отчет?'
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteAction={onDeleteReportButtonPress}
      />
    </>
  )
}
