import { TaskInterface } from '@hydra/interfaces'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'

import {
  getTranscriptionById,
  getTranscriptions,
} from '../api/transcription.api'

export const skipAtom = atom(0)
export const takeAtom = atom(25)
export const orderAtom = atom<keyof TaskInterface>('createdAt')
export const directionAtom = atom<'desc' | 'asc'>('desc')
export const transcriptionIdAtom = atom<string | undefined>(undefined)

export const transcriptionsAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'transcriptions-list',
      get(skipAtom),
      get(takeAtom),
      get(orderAtom),
      get(directionAtom),
    ],
    queryFn: async () => {
      const response = await getTranscriptions({
        skip: get(skipAtom),
        take: get(takeAtom),
        order: get(orderAtom),
        direction: get(directionAtom),
      })
      return response.data
    },
  }
})

export const transcriptionAtom = atomWithQuery(get => {
  return {
    enabled: !!get(transcriptionIdAtom),
    queryKey: ['transcription-by-id', get(transcriptionIdAtom)],
    queryFn: async () => {
      const response = await getTranscriptionById(
        get(transcriptionIdAtom) || '',
      )
      return response.data
    },
  }
})
