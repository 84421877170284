import { Chip, Link } from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, Header, Layout, PageSpinner } from '../../../features/ui'
import { cleanText } from '../../../features/utils/cleanText'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { menuItems } from '../constants/menuItems'
import {
  transcriptionAtom,
  transcriptionIdAtom,
} from '../store/transcription.store'

const pageTitle = 'Транскрибация'

export const Transcription = () => {
  const { id } = useParams()
  const [, setId] = useAtom(transcriptionIdAtom)
  const [{ data, isPending }] = useAtom(transcriptionAtom)

  useEffect(() => {
    if (id) {
      setId(id)
    }
    return () => {
      setId(undefined)
    }
  }, [id, setId])

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        {isPending ? (
          <PageSpinner />
        ) : !data ? (
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <Empty />
          </div>
        ) : (
          <div className='space-y-4 p-4'>
            <div className='bg-background flex min-h-[calc(100vh-104px)] flex-col space-y-4 rounded-2xl p-4 shadow'>
              <div className='grid grid-cols-[150px_1fr]'>
                <div className='font-semibold'>Автор:</div>
                <Chip
                  as={Link}
                  href={routesConst.users + '/' + data.author?.id}
                  color='primary'
                  variant='bordered'
                  size='sm'
                >
                  {data.author?.name} {data.author?.surname}
                </Chip>
              </div>
              <div className='grid grid-cols-[150px_1fr]'>
                <div className='font-semibold'>Дата создания:</div>
                <Chip color='primary' variant='bordered' size='sm'>
                  {getPrettyDate({
                    date: data.createdAt,
                  })}
                </Chip>
              </div>
              <div className='grid grid-cols-[150px_1fr]'>
                <div className='font-semibold'>Контент:</div>
                <div className='overflow-auto'>{cleanText(data.content)}</div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  )
}
