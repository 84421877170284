import { atomWithQuery } from 'jotai-tanstack-query'

import { getTags } from '../api/tags.api'

export const tagsAtom = atomWithQuery(get => {
  return {
    queryKey: ['tags-list'],
    queryFn: async () => {
      const response = await getTags()
      return response.data
    },
  }
})
