import phone from 'phone'

export function formatRussianPhoneNumber(number: string): string {
  const { phoneNumber } = phone(number, {
    country: 'RU',
  })

  // Определение регулярного выражения для проверки формата номера
  const regex = /^\+?7\d{10}$/

  const prepareNumber = phoneNumber || number

  // Если номер не соответствует ожидаемому формату, возвращаем исходное значение
  if (!regex.test(prepareNumber)) {
    return prepareNumber
  }

  // Удаление всех нечисловых символов
  let digits = prepareNumber.replace(/\D/g, '')

  if (digits.length === 11 && digits.startsWith('8')) {
    digits = '7' + digits.slice(1)
  } else if (digits.length === 10) {
    digits = '7' + digits
  }

  if (digits.length !== 11 || !digits.startsWith('7')) {
    return prepareNumber // Возвращаем исходное значение, если формат все еще неверный
  }

  return `+7(${digits.substring(1, 4)}) ${digits.substring(
    4,
    7,
  )} ${digits.substring(7, 9)} ${digits.substring(9)}`
}
