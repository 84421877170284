export enum AreaTypeEnum {
  smi = 'smi',
  unofficialPublic = 'unofficialPublic',
  officialPublic = 'officialPublic',
  blogger = 'blogger',
  lom = 'lom',
  pages = 'pages',
  target = 'target',
  sowing = 'sowing',
}
