import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ScrollShadow,
  Switch,
  Tab,
  Tabs,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { TbSquareRoundedPlus } from 'react-icons/tb'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { Header, Layout } from '../../../features/ui'
import { createFolder } from '../api/folders.api'
import { FoldersList } from '../components/FoldersList'
import { menuItems } from '../constants/menuItems'
import { createFolderSchema } from '../schemas/createFolderSchema'
import { foldersAtom, folderTabAtom } from '../store/folders.store'

const pageTitle = 'Папки'

type Form = z.infer<typeof createFolderSchema>

export const Folders = () => {
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [{ refetch }] = useAtom(foldersAtom)
  const [foldersTab, setFoldersTab] = useAtom(folderTabAtom)
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const form = useForm<Form>({
    defaultValues: {
      title: '',
      isPublic: true,
    },
    resolver: zodResolver(createFolderSchema),
  })

  const onSubmit = useCallback(
    async (formData: Form) => {
      setIsFormLoading(true)
      try {
        const response = await createFolder(formData)
        if (response.status && response.status === 201) {
          toast.success('Папка успешно создана')
          form.reset()
          onClose()
          await refetch()
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFormLoading(false)
      }
    },
    [form, onClose, refetch],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <div>
                <Button
                  color='primary'
                  startContent={<TbSquareRoundedPlus className='text-2xl' />}
                  onPress={onOpen}
                >
                  Создать папку
                </Button>
              </div>
            }
          />
        }
      >
        <div className='space-y-4 overflow-hidden p-4'>
          <Tabs
            aria-label='Вид задач'
            color='primary'
            variant='bordered'
            size='sm'
            selectedKey={foldersTab}
            onSelectionChange={tab => {
              setFoldersTab(tab as 'personal' | 'public')
            }}
          >
            <Tab key='personal' title='Личная' />
            <Tab key='public' title='Общая' />
          </Tabs>
          <div className='flex space-x-4'>
            <ScrollShadow
              size={0}
              className='h-[calc(100vh-160px)] flex-1 overflow-auto'
            >
              <FoldersList />
            </ScrollShadow>
          </div>
        </div>
      </Layout>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Создать новую папку
              </ModalHeader>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <ModalBody>
                  <Controller
                    control={form.control}
                    name='title'
                    render={({ field: { onChange, value } }) => (
                      <Input
                        isDisabled={isFormLoading}
                        variant='bordered'
                        label='Название папки'
                        color='primary'
                        onValueChange={onChange}
                        value={value}
                        isInvalid={!!form.formState.errors.title}
                        errorMessage={form.formState.errors.title?.message}
                      />
                    )}
                  />
                  <Controller
                    control={form.control}
                    name='isPublic'
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        isDisabled={isFormLoading}
                        size='sm'
                        defaultSelected={value}
                        onValueChange={onChange}
                        checked={value}
                      >
                        {value ? 'Общая' : 'Личная'}
                      </Switch>
                    )}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color='danger'
                    type='button'
                    variant='light'
                    onPress={onClose}
                  >
                    Закрыть
                  </Button>
                  <Button color='primary' type='submit'>
                    Создать
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
