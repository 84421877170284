import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from '@udecode/plate-basic-marks'
import { useEditorReadOnly } from '@udecode/plate-common'
import { MARK_BG_COLOR, MARK_COLOR } from '@udecode/plate-font'
import { ListStyleType } from '@udecode/plate-indent-list'
import React from 'react'
import { TbDotsVertical } from 'react-icons/tb'

import { Icons, iconVariants } from '../icons'
import { AlignDropdownMenu } from './align-dropdown-menu'
import { ClearTextFormatting } from './clear-text-formatting'
import { ColorDropdownMenu } from './color-dropdown-menu'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from './dropdown-menu'
import { IndentListToolbarButton } from './indent-list-toolbar-button'
import { IndentToolbarButton } from './indent-toolbar-button'
import { InsertDropdownMenu } from './insert-dropdown-menu'
import { LineHeightDropdownMenu } from './line-height-dropdown-menu'
import { MarkToolbarButton } from './mark-toolbar-button'
import { OutdentToolbarButton } from './outdent-toolbar-button'
import { TableDropdownMenu } from './table-dropdown-menu'
import { ToolbarGroup } from './toolbar'
import { TurnIntoDropdownMenu } from './turn-into-dropdown-menu'

export function FixedToolbarButtons() {
  const readOnly = useEditorReadOnly()

  return (
    <div className='w-full overflow-hidden'>
      <div
        className='flex flex-wrap'
        style={{
          transform: 'translateX(calc(-1px))',
        }}
      >
        {!readOnly && (
          <>
            <ToolbarGroup noSeparator>
              <InsertDropdownMenu />
              <TurnIntoDropdownMenu />
            </ToolbarGroup>

            <ToolbarGroup className='hidden sm:flex'>
              <MarkToolbarButton nodeType={MARK_BOLD}>
                <Icons.bold />
              </MarkToolbarButton>
              <MarkToolbarButton nodeType={MARK_ITALIC}>
                <Icons.italic />
              </MarkToolbarButton>
              <MarkToolbarButton nodeType={MARK_UNDERLINE}>
                <Icons.underline />
              </MarkToolbarButton>

              <MarkToolbarButton nodeType={MARK_STRIKETHROUGH}>
                <Icons.strikethrough />
              </MarkToolbarButton>
            </ToolbarGroup>

            <ToolbarGroup className='hidden sm:flex'>
              <ColorDropdownMenu nodeType={MARK_COLOR}>
                <Icons.color className={iconVariants({ variant: 'toolbar' })} />
              </ColorDropdownMenu>
              <ColorDropdownMenu nodeType={MARK_BG_COLOR}>
                <Icons.bg className={iconVariants({ variant: 'toolbar' })} />
              </ColorDropdownMenu>
              <ClearTextFormatting>
                <Icons.clearFormating />
              </ClearTextFormatting>
            </ToolbarGroup>

            <ToolbarGroup className='hidden lg:flex'>
              <AlignDropdownMenu />

              <LineHeightDropdownMenu />

              <IndentListToolbarButton nodeType={ListStyleType.Disc} />
              <IndentListToolbarButton nodeType={ListStyleType.Decimal} />

              <OutdentToolbarButton />
              <IndentToolbarButton />
            </ToolbarGroup>

            <ToolbarGroup className='hidden lg:flex'>
              <TableDropdownMenu />
            </ToolbarGroup>

            <ToolbarGroup className='ml-auto lg:hidden'>
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                  <div>
                    <TbDotsVertical />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align='start'
                  className='flex min-w-0 flex-col gap-0.5'
                >
                  <div className='sm:hidden'>
                    <MarkToolbarButton nodeType={MARK_BOLD}>
                      <Icons.bold />
                    </MarkToolbarButton>
                    <MarkToolbarButton nodeType={MARK_ITALIC}>
                      <Icons.italic />
                    </MarkToolbarButton>
                    <MarkToolbarButton nodeType={MARK_UNDERLINE}>
                      <Icons.underline />
                    </MarkToolbarButton>

                    <MarkToolbarButton nodeType={MARK_STRIKETHROUGH}>
                      <Icons.strikethrough />
                    </MarkToolbarButton>

                    <ColorDropdownMenu nodeType={MARK_COLOR}>
                      <Icons.color
                        className={iconVariants({ variant: 'toolbar' })}
                      />
                    </ColorDropdownMenu>
                    <ColorDropdownMenu nodeType={MARK_BG_COLOR}>
                      <Icons.bg
                        className={iconVariants({ variant: 'toolbar' })}
                      />
                    </ColorDropdownMenu>
                  </div>

                  <AlignDropdownMenu />

                  <LineHeightDropdownMenu />

                  <IndentListToolbarButton nodeType={ListStyleType.Disc} />
                  <IndentListToolbarButton nodeType={ListStyleType.Decimal} />

                  <OutdentToolbarButton />
                  <IndentToolbarButton />

                  <TableDropdownMenu />
                </DropdownMenuContent>
              </DropdownMenu>
            </ToolbarGroup>
          </>
        )}
      </div>
    </div>
  )
}
