import { StrictMode, useEffect } from 'react'
import * as ReactDOM from 'react-dom/client'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import 'react-toastify/dist/ReactToastify.css'
import 'simplebar-react/dist/simplebar.min.css'
import 'yet-another-react-lightbox/styles.css'

import {
  captureConsoleIntegration,
  contextLinesIntegration,
  debugIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
} from '@sentry/integrations'
import * as Sentry from '@sentry/react'

import { Providers } from './providers'

function showErrorButton() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.isShowErrorButton = true
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
window.showErrorButton = showErrorButton

Sentry.init({
  dsn: 'https://156a31c7c8dbcae394cfdd1acb562086@sentry.yamal.media/9',
  enabled: import.meta.env.PROD,
  environment: import.meta.env.MODE,
  integrations: [
    httpClientIntegration(),
    extraErrorDataIntegration(),
    debugIntegration(),
    contextLinesIntegration(),
    captureConsoleIntegration({
      levels: ['error'],
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        window.location.origin,
        'api.hydra.experts',
        /^https:\/\/api\.hydra\.experts/,
      ],
      networkRequestHeaders: ['Cache-Control'],
      networkResponseHeaders: ['Referrer-Policy'],
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: ['localhost', /^https:\/\/hydra\.expert\/api/],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <BrowserRouter>
      <Providers />
    </BrowserRouter>
  </StrictMode>,
)
