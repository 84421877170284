import { Grant, NewsbreakStatusEnum } from '@hydra/interfaces'
import { Divider, Tab, Tabs, Tooltip } from '@nextui-org/react'
import { clsx } from 'clsx'
import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet'

import { statusConst } from '../../../features/constants/status.const'
import { Grants, useGrant } from '../../../features/grants'
import { Header, Layout } from '../../../features/ui'
import { getPrettyNumber } from '../../../features/utils/getPrettyNumber'
import { AnalyticsAdditionalInformation } from '../components/AnalyticsAdditionalInformation'
import { AnalyticsIndicators } from '../components/AnalyticsIndicators'
import { AnalyticsOrganizationsFilters } from '../components/AnalyticsOrganizationsFilters'
import { AnalyticsPeriod } from '../components/AnalyticsPeriod'
import { menuItems } from '../constants/menuItems'
import {
  analyticsNewsbreaksStatus,
  newsbreaksAnalyticsAtom,
} from '../store/newsbreaks.store'

export const NewsbreaksAnalytics = () => {
  const [{ data, isPending }] = useAtom(newsbreaksAnalyticsAtom)
  const [filterStatus, setFilterStatus] = useAtom(analyticsNewsbreaksStatus)
  const { isValidGrant: isCategoryModerator } = useGrant([
    Grant.newsbreaksCategoryModerator,
  ])

  const handleChangeStatusFilter = useCallback(
    (status: NewsbreakStatusEnum | undefined) => {
      setFilterStatus(status)
    },
    [setFilterStatus],
  )

  return (
    <>
      <Helmet>
        <title>Гидра | Аналитика</title>
      </Helmet>
      <Grants
        grants={[Grant.newsbreaksModerator, Grant.newsbreaksCategoryModerator]}
      >
        <Layout
          menuItems={menuItems}
          headerContent={<Header text='Аналитика' />}
        >
          <div className='flex flex-col space-y-4 p-4'>
            <div className='grid w-full grid-cols-4 gap-4'>
              <AnalyticsPeriod />
              {!isCategoryModerator && <AnalyticsOrganizationsFilters />}
            </div>

            <div className='flex justify-between space-x-2'>
              <div
                onClick={() => handleChangeStatusFilter(undefined)}
                className={clsx(
                  'bg-background flex flex-1 cursor-pointer flex-col space-y-2 rounded-xl border-2 p-4 shadow transition-all',
                  {
                    'border-default-500': filterStatus === undefined,
                  },
                )}
              >
                <div className='text-sm font-semibold'>Всего</div>
                <Divider />
                <div className='text-3xl'>
                  {getPrettyNumber(data?.all || 0)}
                </div>
              </div>
              {Object.entries(statusConst).map(status => (
                <div
                  onClick={() =>
                    handleChangeStatusFilter(status[0] as NewsbreakStatusEnum)
                  }
                  key={status[0]}
                  className={clsx(
                    'bg-background flex flex-1 cursor-pointer flex-col space-y-2 rounded-xl border-2 p-4 shadow transition-all',
                    {
                      'border-default-500':
                        filterStatus === (status[0] as NewsbreakStatusEnum),
                    },
                  )}
                >
                  <Tooltip showArrow content={status[1].title}>
                    <div
                      className={`text-${status[1].color} line-clamp-1 break-all text-sm font-semibold`}
                    >
                      {status[1].title}
                    </div>
                  </Tooltip>
                  <Divider />
                  <div className={`text-3xl text-${status[1].color}`}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-expect-error */}
                    {data ? getPrettyNumber(data[status[0]]) : 0}
                  </div>
                </div>
              ))}
            </div>
            <Tabs color='primary' aria-label='Аналитика' isDisabled={isPending}>
              <Tab key='indicators' title='Ключевые показатели'>
                <AnalyticsIndicators />
              </Tab>
              <Tab key='additional' title='Дополнительная информация'>
                <AnalyticsAdditionalInformation />
              </Tab>
            </Tabs>
          </div>
        </Layout>
      </Grants>
    </>
  )
}
