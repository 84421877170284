import { UserInterface } from '@hydra/interfaces'
import { Checkbox } from '@nextui-org/react'
import { useAtomValue } from 'jotai/index'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { updateChatUser } from '../api/chat.api'
import { chatAtom } from '../store/chat.store'

interface Props {
  item: UserInterface
}

export const InChatUserCheckbox = ({ item }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { data: chat, refetch } = useAtomValue(chatAtom)

  const userInChat = useMemo(
    () => chat?.users?.find(u => u.id === item.id),
    [item.id, chat?.users],
  )

  const handleUpdateChatUser = useCallback(
    async (id: string, data: { user: UserInterface; value: boolean }) => {
      try {
        setIsLoading(true)
        await updateChatUser(id, data)
      } catch (error) {
        toast.error('Не удалось добавить пользователя в чат, попробуйте позже')
        console.error(error)
      } finally {
        setIsLoading(false)
        await refetch()
      }
    },
    [refetch],
  )

  const handleUserInChat = useCallback(
    async (value: boolean, item: UserInterface) => {
      await handleUpdateChatUser(chat?.id || '', {
        user: item,
        value,
      })
    },
    [chat?.id, handleUpdateChatUser],
  )

  return (
    <Checkbox
      isSelected={!!userInChat}
      isDisabled={isLoading || (!item.telegram && !item.chatId)}
      size='lg'
      onValueChange={value => handleUserInChat(value, item)}
    />
  )
}
