import { Grant } from '@hydra/interfaces'
import { Button, Link, Tab, Tabs, useDisclosure } from '@nextui-org/react'
import { useAtom, useAtomValue } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { TbCopy } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useCopyToClipboard } from 'usehooks-ts'

import { routesConst } from '../../../features/constants/routes.const'
import { Grants } from '../../../features/grants'
import {
  DeleteModal,
  Empty,
  Header,
  Layout,
  PageSpinner,
} from '../../../features/ui'
import { getPrettyNumber } from '../../../features/utils/getPrettyNumber'
import { deleteChat } from '../api/chat.api'
import { ChatRisks } from '../components/ChatRisks'
import { ExternalChatUsers } from '../components/ExternalChatUsers'
import { InternalChatUsers } from '../components/InternalChatUsers'
import { LevelChip } from '../components/LevelChip'
import { RenameChatModal } from '../components/RenameChatModal'
import { SendMessageMediaModal } from '../components/SendMessageMediaModal'
import { menuItems } from '../constants/menuItems'
import { chatAtom, chatIdAtom } from '../store/chat.store'

export const Chat = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [, copy] = useCopyToClipboard()
  const { data: chat, isPending, refetch } = useAtomValue(chatAtom)
  const [, setId] = useAtom(chatIdAtom)
  const { isOpen, onOpenChange, onClose, onOpen } = useDisclosure()

  const {
    isOpen: isOpenRename,
    onOpenChange: onOpenChangeRename,
    onClose: onCloseRename,
    onOpen: onOpenRename,
  } = useDisclosure()

  const {
    isOpen: isOpenSendMessageMedia,
    onOpenChange: onOpenChangeSendMessageMedia,
    onClose: onCloseSendMessageMedia,
    onOpen: onOpenSendMessageMedia,
  } = useDisclosure()

  const pageTitle = useMemo(() => `Чат: ${chat?.title || ''}`, [chat])

  const handleDeleteChat = useCallback(
    async (id: string) => {
      await deleteChat(id)
      navigate(routesConst.chats)
    },
    [navigate],
  )

  useEffect(() => {
    if (id) {
      setId(id)
    }
    return () => {
      setId(undefined)
    }
  }, [id, setId])

  return (
    <Grants grants={[Grant.publics]}>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>

      <Layout menuItems={menuItems} headerContent={<Header text={pageTitle} />}>
        {isPending ? (
          <PageSpinner />
        ) : !chat ? (
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <Empty />
          </div>
        ) : (
          <div className='bg-background mx-4 mt-4 h-[calc(100vh-104px)] space-y-4 rounded-2xl p-4 shadow'>
            <div className='flex items-center space-x-2 font-semibold'>
              <span className='text-default-400'>Пригласительная ссылка:</span>
              {chat.link ? (
                <Link isExternal showAnchorIcon href={chat.link} size='sm'>
                  {chat.link}
                </Link>
              ) : (
                <span>не указана</span>
              )}
              <Button
                color='primary'
                size='sm'
                variant='light'
                isIconOnly
                onPress={async () => {
                  try {
                    await copy(chat.link)
                    toast.success('Ссылка скопирована в буфер обмена')
                  } catch (error) {
                    toast.error('Не удалось скопировать ссылку')
                  }
                }}
              >
                <TbCopy className='text-lg' />
              </Button>
              <div className='flex flex-1 justify-end space-x-4'>
                <Button
                  size='sm'
                  color='primary'
                  onPress={onOpenSendMessageMedia}
                >
                  Отрпавить сообщение
                </Button>
                <Button
                  size='sm'
                  color='primary'
                  variant='bordered'
                  onPress={onOpenRename}
                >
                  Переименовать
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  variant='bordered'
                  onPress={onOpen}
                >
                  Удалить чат
                </Button>
              </div>
            </div>
            <div className='flex h-10 space-x-4 text-sm font-semibold'>
              <div className='bg-default-100 flex items-center justify-center space-x-2 rounded-lg px-2 shadow'>
                <span className='text-primary'>Количество пользователей:</span>
                <span>
                  {getPrettyNumber(
                    (chat.users?.length || 0) +
                      (chat.externalUsers?.length || 0),
                  )}
                </span>
              </div>
              <div className='bg-default-100 flex items-center justify-center space-x-2 rounded-lg px-2 shadow'>
                <span className='text-primary'>Количество рисков:</span>
                <span>{getPrettyNumber(chat.risks?.length || 0)}</span>
              </div>
              <div className='bg-default-100 flex items-center justify-center space-x-2 rounded-lg px-2 shadow'>
                <span className='text-primary'>Уровень риска:</span>
                <span>
                  <LevelChip
                    id={chat.id}
                    level={chat.level}
                    refetch={refetch}
                  />
                </span>
              </div>
            </div>
            <Tabs size='sm' variant='bordered' color='primary'>
              <Tab title='Внутренние пользователи'>
                <InternalChatUsers />
              </Tab>
              <Tab title='Внешние пользователи'>
                <ExternalChatUsers />
              </Tab>
              <Tab title='Риски'>
                <ChatRisks />
              </Tab>
            </Tabs>
          </div>
        )}
      </Layout>

      <SendMessageMediaModal
        isOpen={isOpenSendMessageMedia}
        onOpenChange={onOpenChangeSendMessageMedia}
        onClose={onCloseSendMessageMedia}
      />

      <RenameChatModal
        defaultValue={chat?.title || ''}
        isOpen={isOpenRename}
        onOpenChange={onOpenChangeRename}
        onClose={onCloseRename}
      />

      <DeleteModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        deleteAction={() => handleDeleteChat(chat?.id || '')}
        message='Вы действительно хотите удалить чат?'
        description='Это действие нельзя отменить'
        onClose={onClose}
      />
    </Grants>
  )
}
