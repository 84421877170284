import { Grant } from '@hydra/interfaces'
import { AxiosResponse } from 'axios'

import { instance } from '../api'

export const getUserGrants = (): Promise<
  AxiosResponse<{ grants: Array<Grant> }>
> => {
  return instance.get('/users/grants')
}
