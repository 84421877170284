import { AxiosResponse } from 'axios'

import { instance } from './index'

export const revalidateFilesUrl = (): Promise<
  AxiosResponse<{ ok: boolean }>
> => {
  return instance.get('/files/revalidate-files', {})
}

export const getFileUrlFromS3 = (
  fileName: string,
  bucketName: string,
): Promise<AxiosResponse<{ title: string; link: string }>> => {
  return instance.get('/files/get-file-url', {
    params: {
      fileName,
      bucketName,
    },
  })
}

export const deleteFileFromS3 = (
  fileName: string,
  bucketName: string,
): Promise<AxiosResponse<{ title: string; link: string }>> => {
  return instance.delete('/files/delete-file', {
    data: {
      fileName,
      bucketName,
    },
  })
}

export const uploadFileToS3 = async (
  file: File,
): Promise<AxiosResponse<string>> => {
  const formData = new FormData()
  formData.append('file', file)

  return instance.post('/files/upload', formData)
}
