import { z } from 'zod'

export const createOrganizationSchema = z.object({
  name: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  contactPerson: z
    .string({
      required_error: 'Обязательное поле',
    })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  contactPersonPhone: z
    .string({
      required_error: 'Обязательное поле',
    })
    .min(2, 'Минимальная длина 11')
    .trim(),
  contactPersonEmail: z
    .string({
      required_error: 'Обязательное поле',
    })
    .email({ message: 'Некорретный email' })
    .max(250, 'Максимальная длина 250')
    .min(2, 'Минимальная длина 2')
    .trim(),
  type: z
    .string({
      required_error: 'Обязательное поле',
    })
    .trim(),
  site: z.string().trim().optional(),
  apiKey: z.string().trim().optional(),
})
