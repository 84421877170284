import { Button, Divider, Select, SelectItem } from '@nextui-org/react'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { TbArrowsExchange, TbChevronLeft, TbChevronRight } from 'react-icons/tb'
import { useLocalStorage } from 'usehooks-ts'

import { extendedDayjs } from '../../../utils/dayjsConfig'
import { PageSpinner } from '../PageSpinner'
import { getMonthData } from './getMonthData'
import { MonthView } from './MonthView'
import { SmallMonth } from './SmallMonth'
import { Items } from './types'
import { WeekView } from './WeekView'

interface Props {
  year: number
  month: number
  week: number
  day: number
  onSmallCalendarDayButtonClick: (day: number) => void
  onPrevMonthButtonPress: () => void
  onNextMonthButtonPress: () => void
  onPrevWeekButtonPress: () => void
  onNextWeekButtonPress: () => void
  onTodayButtonPress: () => void
  monthItems?: Items
  weekItems?: Items
  calendarView: 'month' | 'week'
  setCalendarView: (view: 'month' | 'week') => void
  isLoading: boolean
}

export const Calendar = ({
  year,
  month,
  week,
  day,
  onSmallCalendarDayButtonClick,
  onPrevMonthButtonPress,
  onNextMonthButtonPress,
  onTodayButtonPress,
  onPrevWeekButtonPress,
  onNextWeekButtonPress,
  calendarView,
  setCalendarView,
  monthItems,
  weekItems,
  isLoading,
}: Props) => {
  const [isSmallcalendarShow, setIsSmallCalendarShow] = useLocalStorage(
    'is-small-calendar-show',
    true,
  )

  const months = useMemo(() => getMonthData(year), [year])

  const prevMonthDaysCount = useMemo(
    () => (months[month].firstWeekDay > -1 ? months[month].firstWeekDay : 6),
    [month, months],
  )

  const nextMonthDaysCount = useMemo(
    () =>
      (months[month].daysCount + prevMonthDaysCount <= 35 ? 35 : 42) -
      (months[month].daysCount + prevMonthDaysCount),
    [month, months, prevMonthDaysCount],
  )

  const onIsSmallCalendarShowButtonPress = useCallback(() => {
    setIsSmallCalendarShow(prev => !prev)
  }, [setIsSmallCalendarShow])

  const renderShowSmallCalendarButton = useCallback(
    () => (
      <Button
        size='sm'
        isIconOnly
        variant='bordered'
        onPress={onIsSmallCalendarShowButtonPress}
      >
        <TbArrowsExchange className='text-lg' />
      </Button>
    ),
    [onIsSmallCalendarShowButtonPress],
  )

  const onCalendarViewSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setCalendarView(event.target.value as 'month' | 'week')
    },
    [setCalendarView],
  )

  return (
    <div className='flex justify-between space-x-4 p-4'>
      <div className='flex-1 space-y-4'>
        <div className='flex justify-between'>
          <div className='flex items-center space-x-4'>
            <Button
              isIconOnly
              size='sm'
              variant='bordered'
              onPress={
                calendarView === 'month'
                  ? onPrevMonthButtonPress
                  : onPrevWeekButtonPress
              }
            >
              <TbChevronLeft className='text-primary text-lg' />
            </Button>
            <Button
              isIconOnly
              size='sm'
              variant='bordered'
              onPress={
                calendarView === 'month'
                  ? onNextMonthButtonPress
                  : onNextWeekButtonPress
              }
            >
              <TbChevronRight className='text-primary text-lg' />
            </Button>
            <div className='text-default-600 text-small flex space-x-2'>
              <span>
                {extendedDayjs().month(month).year(year).format('MMMM YYYY')}
              </span>
              {calendarView === 'week' && (
                <>
                  <Divider orientation='vertical' className='h-[initial]' />
                  <span>Нед {week}</span>
                </>
              )}
            </div>
            <Button
              size='sm'
              variant='light'
              color='primary'
              onPress={onTodayButtonPress}
            >
              Сегодня
            </Button>
          </div>
          <div className='flex items-center space-x-2'>
            <Select
              aria-label='Вид календаря'
              variant='bordered'
              selectedKeys={[calendarView]}
              onChange={onCalendarViewSelectChange}
              classNames={{
                base: 'w-[200px]',
                trigger: '!h-8 min-h-8',
              }}
            >
              <SelectItem key='month' value='month'>
                Месяц
              </SelectItem>
              <SelectItem key='week' value='week'>
                Неделя
              </SelectItem>
            </Select>
            {!isSmallcalendarShow && renderShowSmallCalendarButton()}
          </div>
        </div>
        {isLoading ? (
          <PageSpinner />
        ) : (
          <>
            {calendarView === 'month' && (
              <MonthView
                year={year}
                month={month}
                items={monthItems}
                months={months}
                prevMonthDaysCount={prevMonthDaysCount}
                nextMonthDaysCount={nextMonthDaysCount}
              />
            )}

            {calendarView === 'week' && weekItems && (
              <WeekView
                year={year}
                month={month}
                week={week}
                items={weekItems}
              />
            )}
          </>
        )}
      </div>

      {isSmallcalendarShow && (
        <SmallMonth
          year={year}
          month={month}
          day={day}
          months={months}
          items={monthItems}
          prevMonthDaysCount={prevMonthDaysCount}
          nextMonthDaysCount={nextMonthDaysCount}
          onSmallCalendarDayButtonClick={onSmallCalendarDayButtonClick}
          onPrevMonthButtonPress={onPrevMonthButtonPress}
          onNextMonthButtonPress={onNextMonthButtonPress}
          renderShowSmallCalendarButton={renderShowSmallCalendarButton}
        />
      )}
    </div>
  )
}
