import { clsx } from 'clsx'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/index'
import { useCallback } from 'react'
import SimpleBar from 'simplebar-react'

import {
  activeGroupAtom,
  activePublicAtom,
  platformAtom,
  postsPublicsGroupsAtom,
} from '../store/post.store'

export const GroupFilter = () => {
  const { data } = useAtomValue(postsPublicsGroupsAtom)
  const [activeGroup, setActiveGroup] = useAtom(activeGroupAtom)
  const [, setActivePublic] = useAtom(activePublicAtom)
  const [, setPlatform] = useAtom(platformAtom)

  const handleGroupClick = useCallback(
    (id: string) => {
      setActivePublic(undefined)
      setPlatform(undefined)
      if (activeGroup === id) {
        setActiveGroup(undefined)
      } else {
        setActiveGroup(id)
      }
    },
    [activeGroup, setActiveGroup],
  )

  return (
    <SimpleBar className='bg-primary-50 h-[calc(100vh-72px)] py-4'>
      <div className='space-y-2'>
        {data?.map(g => (
          <div
            className={clsx(
              'hover:text-background hover:bg-primary-500 relative line-clamp-3 h-[56px] cursor-pointer break-all p-1 text-center align-middle text-xs transition-colors',
              {
                'text-background bg-primary-500': activeGroup === g.id,
              },
            )}
            key={g.id}
            onClick={() => handleGroupClick(g.id)}
          >
            <span>{g.title}</span>
            {g.publics && g.publics.length > 0 && (
              <div className='text-primary bg-background absolute right-1 top-1 flex items-center justify-center rounded-full px-[6px] py-0 text-[8px]'>
                {g.publics.length}
              </div>
            )}
          </div>
        ))}
      </div>
    </SimpleBar>
  )
}
