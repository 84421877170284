import { TaskStatusEnum } from '@hydra/interfaces'
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import { useCallback, useEffect, useState } from 'react'
import { TbChevronDown } from 'react-icons/tb'

import { updateTask } from '../api/tasks.api'
import { taskStatusConst } from '../constants/taskStatus.const'

interface Props {
  id: string
  status: TaskStatusEnum
  refetch?: () => void
  withChangeAction?: boolean
}

export const StatusChip = ({
  id,
  status,
  refetch,
  withChangeAction,
}: Props) => {
  const [color, setColor] = useState<
    'warning' | 'danger' | 'default' | 'success'
  >('default')

  const handleUpdateStatus = useCallback(
    async (status: TaskStatusEnum) => {
      await updateTask(id, { status })
      refetch && refetch()
    },
    [id, refetch],
  )

  useEffect(() => {
    if (status === TaskStatusEnum.backlog) {
      setColor('danger')
    }
    if (status === TaskStatusEnum.inProgress) {
      setColor('warning')
    }
    if (status === TaskStatusEnum.done) {
      setColor('success')
    }
    if (status === TaskStatusEnum.archive) {
      setColor('default')
    }
  }, [status])

  return withChangeAction ? (
    <Dropdown backdrop='blur'>
      <DropdownTrigger>
        <Chip
          as='span'
          variant='dot'
          size='sm'
          color={color}
          className='cursor-pointer'
          endContent={<TbChevronDown className='text-xl' />}
        >
          {taskStatusConst[status]}
        </Chip>
      </DropdownTrigger>
      <DropdownMenu
        variant='faded'
        aria-label={`Статус задачи ${id}`}
        onAction={key => handleUpdateStatus(key as TaskStatusEnum)}
      >
        <DropdownItem key={TaskStatusEnum.backlog}>
          {taskStatusConst[TaskStatusEnum.backlog]}
        </DropdownItem>
        <DropdownItem key={TaskStatusEnum.inProgress}>
          {taskStatusConst[TaskStatusEnum.inProgress]}
        </DropdownItem>
        <DropdownItem key={TaskStatusEnum.done}>
          {taskStatusConst[TaskStatusEnum.done]}
        </DropdownItem>
        <DropdownItem key={TaskStatusEnum.archive}>
          {taskStatusConst[TaskStatusEnum.archive]}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  ) : (
    <Chip as='span' variant='dot' size='sm' color={color}>
      {taskStatusConst[status]}
    </Chip>
  )
}
