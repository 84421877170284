import { Grant } from '@hydra/interfaces'
import { Button, Input } from '@nextui-org/react'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/index'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { TbSearch, TbSquareRoundedPlus, TbX } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { routesConst } from '../../../features/constants/routes.const'
import { useGrant } from '../../../features/grants'
import { Header, Layout } from '../../../features/ui'
import { updateStatistics } from '../api/public.api'
import { PublicsList } from '../components/PublicsList'
import { PublicsPagination } from '../components/PublicsPagination'
import { menuItems } from '../constants/menuItems'
import {
  publicCountsAtom,
  publicsAtom,
  searchStringAtom,
  skipAtom,
  takeAtom,
} from '../store/public.store'

const pageTitle = 'Паблики'

export const Publics = () => {
  const { isValidGrant: isSuperadmin } = useGrant([Grant.superadmin])
  const navigate = useNavigate()
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(false)
  const { refetch: refetchPublics } = useAtomValue(publicsAtom)
  const { refetch: refetchCounts } = useAtomValue(publicCountsAtom)
  const [value, setValue] = useState('')
  const [, setSearchString] = useAtom(searchStringAtom)
  const [, setSkip] = useAtom(skipAtom)
  const [, setTake] = useAtom(takeAtom)

  const handleSearch = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setSkip(0)
      setTake(25)
      if (value !== '') {
        setSearchString(value)
      } else {
        setSearchString(undefined)
      }
    },
    [setSearchString, setSkip, setTake, value],
  )

  useEffect(() => {
    if (value === '') {
      setSearchString(undefined)
    }
  }, [setSearchString, value])

  const handleUpdateStatistics = useCallback(async () => {
    setIsStatisticsLoading(true)
    try {
      const response = await updateStatistics()

      if (response.status && response.status === 200) {
        toast.success('Статистика успешно обновлена')
        await refetchPublics()
        await refetchCounts()
      }
    } catch (e) {
      toast.error(
        'Что то пошло не так при обновлении статситики, попробуй еще раз позже',
      )
    } finally {
      setIsStatisticsLoading(false)
    }
  }, [refetchCounts, refetchPublics])

  return (
    <>
      <Helmet>
        <title>Гидра | {pageTitle}</title>
      </Helmet>
      <Layout
        menuItems={menuItems}
        headerContent={
          <Header
            text={pageTitle}
            actionButton={
              <form onSubmit={handleSearch} className='flex space-x-2'>
                <Input
                  className='w-[450px]'
                  value={value}
                  onValueChange={setValue}
                  placeholder='Найти по заголовку, описанию...'
                  variant='bordered'
                  endContent={
                    <div className='flex space-x-1'>
                      {value && (
                        <Button
                          type='button'
                          isIconOnly
                          size='sm'
                          variant='light'
                          color='danger'
                          onPress={() => setValue('')}
                        >
                          <TbX />
                        </Button>
                      )}
                      <Button
                        type='submit'
                        isIconOnly
                        size='sm'
                        variant='light'
                      >
                        <TbSearch />
                      </Button>
                    </div>
                  }
                />
                <div className='flex space-x-4'>
                  {isSuperadmin && (
                    <Button
                      isLoading={isStatisticsLoading}
                      isDisabled={isStatisticsLoading}
                      color='primary'
                      variant='ghost'
                      onPress={handleUpdateStatistics}
                    >
                      Обновить статистику
                    </Button>
                  )}

                  <Button
                    color='primary'
                    startContent={<TbSquareRoundedPlus className='text-2xl' />}
                    onPress={() => navigate(routesConst.createPublic)}
                  >
                    Создать
                  </Button>
                </div>
              </form>
            }
          />
        }
      >
        <div className='space-y-4 p-4'>
          <PublicsList />
          <PublicsPagination />
        </div>
      </Layout>
    </>
  )
}
