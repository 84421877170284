import { CollectionInterface } from '@hydra/interfaces'
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { Key, useCallback, useMemo } from 'react'
import { BiTask } from 'react-icons/bi'
import { TbFileFilled } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { routesConst } from '../../../features/constants/routes.const'
import { Empty, PageSpinner } from '../../../features/ui'
import { getPrettyDate } from '../../../features/utils/getPrettyDate'
import { collectionsAtom } from '../store/colletcions.store'

export const CollectionsTable = () => {
  const [{ data, isPending }] = useAtom(collectionsAtom)
  const navigate = useNavigate()

  const renderCell = useCallback(
    (item: CollectionInterface, columnKey: Key) => {
      if (columnKey === 'title') {
        return <div>{item.title}</div>
      }
      if (columnKey === 'createdAt') {
        return (
          <div>
            {getPrettyDate({
              date: item.createdAt,
            })}
          </div>
        )
      }
      if (columnKey === 'author' && item.author) {
        return (
          <Link
            href={routesConst.users + '/' + item.author.id}
            size='sm'
            isBlock
          >
            {item.author.name} {item.author.surname}
          </Link>
        )
      }
      if (columnKey === 'responsible' && item.responsible) {
        return (
          <Link
            href={routesConst.users + '/' + item.responsible.id}
            size='sm'
            isBlock
          >
            {item.responsible.name} {item.responsible.surname}
          </Link>
        )
      }
      if (columnKey === 'tasks') {
        return (
          <div className='bg-primary-50 text-primary text-small flex w-min space-x-2 rounded-lg p-1 font-medium'>
            <BiTask className='text-xl' />
            <span>{item.tasks.length}</span>
          </div>
        )
      }
      if (columnKey === 'files') {
        return (
          <div className='bg-primary-50 text-primary text-small flex w-min space-x-2 rounded-lg p-1 font-medium'>
            <TbFileFilled className='text-xl' />
            <span>{item.files.length}</span>
          </div>
        )
      }
    },
    [],
  )

  const columns = useMemo(
    () => [
      {
        key: 'title',
        label: 'Название',
        width: 300,
      },
      {
        key: 'createdAt',
        label: 'Создан',
      },
      {
        key: 'author',
        label: 'Автор',
      },
      {
        key: 'responsible',
        label: 'Ответственный',
      },
      {
        key: 'tasks',
        label: 'Кол-во задач',
      },
      {
        key: 'files',
        label: 'Кол-во файлов',
      },
    ],
    [],
  )

  if (isPending) return <PageSpinner />

  if (!data || data[0].length === 0) return <Empty />

  return (
    <Table
      aria-label='Проекты'
      isHeaderSticky
      isStriped
      removeWrapper
      onRowAction={key => navigate(routesConst.collections + '/' + key)}
      classNames={{
        base: 'h-[calc(100vh-156px)] overflow-auto',
      }}
    >
      <TableHeader columns={columns}>
        {column => (
          <TableColumn width={column.width} key={column.key}>
            {column.label}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        items={data[0]}
        emptyContent={
          <div className='text-default-400 text-sm'>Нет данных</div>
        }
      >
        {item => (
          <TableRow key={item.id}>
            {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
