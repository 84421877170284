import { zodResolver } from '@hookform/resolvers/zod'
import { Grant } from '@hydra/interfaces'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  useDisclosure,
} from '@nextui-org/react'
import { useAtom } from 'jotai/index'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { TbEdit, TbSquareRoundedPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { z } from 'zod'

import {
  createLocation,
  revalidateFilesUrl,
  updateLocation,
} from '../../../features/api'
import { Grants, useGrant } from '../../../features/grants'
import { allLocationsAtom } from '../../../features/store'
import { Header, Layout } from '../../../features/ui'
import {
  createImportance,
  updateImportance,
} from '../../newsbreaks/api/importances.api'
import {
  createPriority,
  updatePriority,
} from '../../newsbreaks/api/priorities.api'
import { createProject, updateProject } from '../../newsbreaks/api/projects.api'
import { createTheme, updateTheme } from '../../newsbreaks/api/themes.api'
import { createType, updateType } from '../../newsbreaks/api/types.api'
import { createSettingsSchema } from '../../newsbreaks/schemas/createSettings.schema'
import {
  allImportancesAtom,
  allPrioritiesAtom,
  allProjectsAtom,
  allThemesAtom,
  allTypesAtom,
} from '../../newsbreaks/store/newsbreaks.store'
import { menuItems } from '../constants/menuItems'

export const Settings = () => {
  const { isValidGrant: isSuperAdmin } = useGrant([Grant.superadmin])
  const [type, setType] = useState<keyof typeof dataMap>('themes')
  const [isUpdate, setIsUpdate] = useState(false)
  const [updateItemId, setUpdateItemId] = useState('')
  const [updateTitle, setUpdateTitle] = useState('')
  const navigate = useNavigate()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [{ data: themes, refetch: refetchThemes }] = useAtom(allThemesAtom)
  const [{ data: priorities, refetch: refetchPriorities }] =
    useAtom(allPrioritiesAtom)
  const [{ data: projects, refetch: refetchProjects }] =
    useAtom(allProjectsAtom)
  const [{ data: types, refetch: refetchTypes }] = useAtom(allTypesAtom)
  const [{ data: importances, refetch: refetchImportances }] =
    useAtom(allImportancesAtom)
  const [{ data: locations, refetch: refetchLocations }] =
    useAtom(allLocationsAtom)

  const dataMap = useMemo(() => {
    return {
      themes: {
        toastMessage: 'Тема успешно создана',
        toastMessageUpdate: 'Тема успешно обновлена',
        popupTitle: 'темы',
        create: createTheme,
        update: updateTheme,
        refetch: refetchThemes,
      },
      priorities: {
        toastMessage: 'Приоритет успешно создан',
        toastMessageUpdate: 'Приоритет успешно обновлен',
        popupTitle: 'приоритета',
        create: createPriority,
        update: updatePriority,
        refetch: refetchPriorities,
      },
      projects: {
        toastMessage: 'Проект успешно создан',
        toastMessageUpdate: 'Проект успешно обновлен',
        popupTitle: 'проекта',
        create: createProject,
        update: updateProject,
        refetch: refetchProjects,
      },
      types: {
        toastMessage: 'Тип успешно создан',
        toastMessageUpdate: 'Тип успешно обновлен',
        popupTitle: 'типа',
        create: createType,
        update: updateType,
        refetch: refetchTypes,
      },
      importances: {
        toastMessage: 'Важность успешно создана',
        toastMessageUpdate: 'Важность успешно обновлена',
        popupTitle: 'важности',
        create: createImportance,
        update: updateImportance,
        refetch: refetchImportances,
      },
      locations: {
        toastMessage: 'Место публикации успешно создано',
        toastMessageUpdate: 'Место публикации успешно обновлено',
        popupTitle: 'места публикации',
        create: createLocation,
        update: updateLocation,
        refetch: refetchLocations,
      },
    }
  }, [
    refetchThemes,
    refetchPriorities,
    refetchProjects,
    refetchTypes,
    refetchImportances,
    refetchLocations,
  ])

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onOpenChange: onOpenChangeModal,
    onClose: onCloseModal,
  } = useDisclosure()

  const form = useForm<z.infer<typeof createSettingsSchema>>({
    defaultValues: {
      title: '',
    },
    resolver: zodResolver(createSettingsSchema),
  })

  const onSubmit = async (formData: z.infer<typeof createSettingsSchema>) => {
    setIsFormLoading(true)
    const response = isUpdate
      ? await dataMap[type].update(updateItemId, formData)
      : await dataMap[type].create(formData)
    if (response.status && response.status === 201) {
      toast.success(dataMap[type].toastMessage)
      form.reset()
      dataMap[type].refetch()
      onCloseModal()
    }

    if (response.status && response.status === 200) {
      toast.success(dataMap[type].toastMessageUpdate)
      form.reset()
      dataMap[type].refetch()
      onCloseModal()
      setIsUpdate(false)
    }

    setIsFormLoading(false)
  }

  const handleCloseButtonClick = () => {
    form.reset()
    onCloseModal()
  }

  const onButtonEditPress = (id: string, title: string) => {
    setUpdateItemId(id)
    setUpdateTitle(title)
    setIsUpdate(true)
    onOpenModal()
  }

  useEffect(() => {
    if (!isOpenModal) {
      form.reset()
      setUpdateItemId('')
      setUpdateTitle('')
      setIsUpdate(false)
    }
  }, [navigate, isOpenModal, form])

  return (
    <>
      <Helmet>
        <title>Гидра | Настройки</title>
      </Helmet>
      <Grants grants={[Grant.newsbreaksModerator]}>
        <Layout
          menuItems={menuItems}
          headerContent={
            <Header
              text='Настройки'
              actionButton={
                <div>
                  {isSuperAdmin && (
                    <Button size='sm' onPress={() => revalidateFilesUrl()}>
                      Обновить ссылки файлов
                    </Button>
                  )}
                </div>
              }
            />
          }
        >
          <div className='p-4'>
            <div className='p-4'>
              <Tabs
                aria-label='Табы настроек'
                onSelectionChange={key => setType(key as keyof typeof dataMap)}
                classNames={{
                  panel: '!pt-4 !px-0 !pb-0',
                }}
              >
                <Tab key='themes' title='Темы'>
                  <div className='space-y-4'>
                    <div className='flex justify-end'>
                      <Button
                        size='sm'
                        color='primary'
                        startContent={
                          <TbSquareRoundedPlus className='text-lg' />
                        }
                        onPress={onOpenModal}
                      >
                        Добавить
                      </Button>
                    </div>
                    <SimpleBar className='bg-background h-[calc(100vh-240px)] overflow-auto rounded-xl p-4 shadow'>
                      <Table
                        aria-label='Темы'
                        isHeaderSticky
                        isStriped
                        removeWrapper
                      >
                        <TableHeader>
                          <TableColumn key='title'>Название</TableColumn>
                        </TableHeader>
                        <TableBody
                          items={themes ? themes[0] : []}
                          emptyContent={
                            <div className='text-default-400 text-sm'>
                              Не указаны
                            </div>
                          }
                        >
                          {({ id, title }) => (
                            <TableRow key={id}>
                              <TableCell className='align-center flex justify-between'>
                                <div>{title}</div>
                                <Button
                                  isIconOnly
                                  onPress={() => onButtonEditPress(id, title)}
                                  variant='light'
                                >
                                  <TbEdit className='text-2xl' />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </SimpleBar>
                  </div>
                </Tab>
                <Tab key='priorities' title='Приоритеты'>
                  <div className='space-y-4'>
                    <div className='flex justify-end'>
                      <Button
                        size='sm'
                        color='primary'
                        startContent={
                          <TbSquareRoundedPlus className='text-lg' />
                        }
                        onPress={onOpenModal}
                      >
                        Добавить
                      </Button>
                    </div>
                    <SimpleBar className='bg-background h-[calc(100vh-240px)] overflow-auto rounded-xl p-4 shadow'>
                      <Table
                        aria-label='Приоритеты'
                        isHeaderSticky
                        isStriped
                        removeWrapper
                      >
                        <TableHeader>
                          <TableColumn key='title'>Название</TableColumn>
                        </TableHeader>
                        <TableBody
                          items={priorities ? priorities[0] : []}
                          emptyContent={
                            <div className='text-default-400 text-sm'>
                              Не указаны
                            </div>
                          }
                        >
                          {({ id, title }) => (
                            <TableRow key={id}>
                              <TableCell className='align-center flex justify-between'>
                                <div>{title}</div>
                                <Button
                                  isIconOnly
                                  onPress={() => onButtonEditPress(id, title)}
                                  variant='light'
                                >
                                  <TbEdit className='text-2xl' />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </SimpleBar>
                  </div>
                </Tab>
                <Tab key='projects' title='Проекты'>
                  <div className='space-y-4'>
                    <div className='flex justify-end'>
                      <Button
                        size='sm'
                        color='primary'
                        startContent={
                          <TbSquareRoundedPlus className='text-lg' />
                        }
                        onPress={onOpenModal}
                      >
                        Добавить
                      </Button>
                    </div>
                    <SimpleBar className='bg-background h-[calc(100vh-240px)] overflow-auto rounded-xl p-4 shadow'>
                      <Table
                        aria-label='Проекты'
                        isHeaderSticky
                        isStriped
                        removeWrapper
                      >
                        <TableHeader>
                          <TableColumn key='title'>Название</TableColumn>
                        </TableHeader>
                        <TableBody
                          items={projects ? projects[0] : []}
                          emptyContent={
                            <div className='text-default-400 text-sm'>
                              Не указаны
                            </div>
                          }
                        >
                          {({ id, title }) => (
                            <TableRow key={id}>
                              <TableCell className='align-center flex justify-between'>
                                <div>{title}</div>
                                <Button
                                  isIconOnly
                                  onPress={() => onButtonEditPress(id, title)}
                                  variant='light'
                                >
                                  <TbEdit className='text-2xl' />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </SimpleBar>
                  </div>
                </Tab>
                <Tab key='types' title='Типы'>
                  <div className='space-y-4'>
                    <div className='flex justify-end'>
                      <Button
                        size='sm'
                        color='primary'
                        startContent={
                          <TbSquareRoundedPlus className='text-lg' />
                        }
                        onPress={onOpenModal}
                      >
                        Добавить
                      </Button>
                    </div>
                    <SimpleBar className='bg-background h-[calc(100vh-240px)] overflow-auto rounded-xl p-4 shadow'>
                      <Table
                        aria-label='Приоритеты'
                        isHeaderSticky
                        isStriped
                        removeWrapper
                      >
                        <TableHeader>
                          <TableColumn key='title'>Название</TableColumn>
                        </TableHeader>
                        <TableBody
                          items={types ? types[0] : []}
                          emptyContent={
                            <div className='text-default-400 text-sm'>
                              Не указаны
                            </div>
                          }
                        >
                          {({ id, title }) => (
                            <TableRow key={id}>
                              <TableCell className='align-center flex justify-between'>
                                <div>{title}</div>
                                <Button
                                  isIconOnly
                                  onPress={() => onButtonEditPress(id, title)}
                                  variant='light'
                                >
                                  <TbEdit className='text-2xl' />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </SimpleBar>
                  </div>
                </Tab>
                <Tab key='importances' title='Важность'>
                  <div className='space-y-4'>
                    <div className='flex justify-end'>
                      <Button
                        size='sm'
                        color='primary'
                        startContent={
                          <TbSquareRoundedPlus className='text-lg' />
                        }
                        onPress={onOpenModal}
                      >
                        Добавить
                      </Button>
                    </div>
                    <SimpleBar className='bg-background h-[calc(100vh-240px)] overflow-auto rounded-xl p-4 shadow'>
                      <Table
                        aria-label='Важность'
                        isHeaderSticky
                        isStriped
                        removeWrapper
                      >
                        <TableHeader>
                          <TableColumn key='title'>Название</TableColumn>
                        </TableHeader>
                        <TableBody
                          items={importances ? importances[0] : []}
                          emptyContent={
                            <div className='text-default-400 text-sm'>
                              Не указаны
                            </div>
                          }
                        >
                          {({ id, title }) => (
                            <TableRow key={id}>
                              <TableCell className='align-center flex justify-between'>
                                <div>{title}</div>
                                <Button
                                  isIconOnly
                                  onPress={() => onButtonEditPress(id, title)}
                                  variant='light'
                                >
                                  <TbEdit className='text-2xl' />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </SimpleBar>
                  </div>
                </Tab>
                <Tab key='locations' title='Места публикации'>
                  <div className='space-y-4'>
                    <div className='flex justify-end'>
                      <Button
                        size='sm'
                        color='primary'
                        startContent={
                          <TbSquareRoundedPlus className='text-lg' />
                        }
                        onPress={onOpenModal}
                      >
                        Добавить
                      </Button>
                    </div>
                    <SimpleBar className='bg-background h-[calc(100vh-240px)] overflow-auto rounded-xl p-4 shadow'>
                      <Table
                        aria-label='Места публикации'
                        isHeaderSticky
                        isStriped
                        removeWrapper
                      >
                        <TableHeader>
                          <TableColumn key='title'>Название</TableColumn>
                        </TableHeader>
                        <TableBody
                          items={locations ? locations[0] : []}
                          emptyContent={
                            <div className='text-default-400 text-sm'>
                              Не указаны
                            </div>
                          }
                        >
                          {({ id, title }) => (
                            <TableRow key={id}>
                              <TableCell className='align-center flex justify-between'>
                                <div>{title}</div>
                                <Button
                                  isIconOnly
                                  onPress={() => onButtonEditPress(id, title)}
                                  variant='light'
                                >
                                  <TbEdit className='text-2xl' />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </SimpleBar>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Layout>

        <Modal isOpen={isOpenModal} onOpenChange={onOpenChangeModal}>
          <ModalContent>
            {() => (
              <>
                <ModalHeader className='flex flex-col gap-1'>
                  {!updateTitle ? 'Создание' : 'Обновление'}{' '}
                  {dataMap[type].popupTitle}
                </ModalHeader>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <ModalBody>
                    <Controller
                      control={form.control}
                      name='title'
                      render={({ field: { onChange, value } }) => (
                        <Input
                          isDisabled={isFormLoading}
                          variant='bordered'
                          label='Название'
                          color='primary'
                          onValueChange={onChange}
                          value={value ? value : updateTitle}
                          isInvalid={!!form.formState.errors.title}
                          errorMessage={form.formState.errors.title?.message}
                        />
                      )}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color='danger'
                      type='button'
                      variant='light'
                      onPress={handleCloseButtonClick}
                    >
                      Отменить
                    </Button>
                    <Button color='primary' type='submit'>
                      {!updateTitle ? 'Создать' : 'Обновить'}
                    </Button>
                  </ModalFooter>
                </form>
              </>
            )}
          </ModalContent>
        </Modal>
      </Grants>
    </>
  )
}
